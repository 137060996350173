const metroCode= [
    {
    codeTy: "metroCd",
    code: "11",
    codeNm: "서울특별시"
    },
    {
    codeTy: "metroCd",
    code: "21",
    codeNm: "부산광역시"
    },
    {
    codeTy: "metroCd",
    code: "22",
    codeNm: "대구광역시"
    },
    {
    codeTy: "metroCd",
    code: "23",
    codeNm: "인천광역시"
    },
    {
    codeTy: "metroCd",
    code: "24",
    codeNm: "광주광역시"
    },
    {
    codeTy: "metroCd",
    code: "25",
    codeNm: "대전광역시"
    },
    {
    codeTy: "metroCd",
    code: "26",
    codeNm: "울산광역시"
    },
    {
    codeTy: "metroCd",
    code: "31",
    codeNm: "경기도"
    },
    {
    codeTy: "metroCd",
    code: "32",
    codeNm: "강원도"
    },
    {
    codeTy: "metroCd",
    code: "33",
    codeNm: "충청북도"
    },
    {
    codeTy: "metroCd",
    code: "34",
    codeNm: "충청남도"
    },
    {
    codeTy: "metroCd",
    code: "35",
    codeNm: "전라북도"
    },
    {
    codeTy: "metroCd",
    code: "36",
    codeNm: "전라남도"
    },
    {
    codeTy: "metroCd",
    code: "37",
    codeNm: "경상북도"
    },
    {
    codeTy: "metroCd",
    code: "38",
    codeNm: "경상남도"
    },
    {
    codeTy: "metroCd",
    code: "39",
    codeNm: "제주특별자치도"
    },
    {
    codeTy: "metroCd",
    code: "41",
    codeNm: "세종특별자치시"
    }
    ]

    export default metroCode;