const mydata=[
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한국전력",
    cent: "중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 1
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "횡성댐2소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.3,
    company: "한국수자원공사",
    rn: 2
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "구이소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.36,
    company: "한국농어촌공사 전주완주지사",
    rn: 3
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영월복합태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.055,
    company: "한국남부발전(주)",
    rn: 4
    },
    {
    area: "수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "경인항풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 5
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태백풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 16,
    company: "태백풍력발전(주)",
    rn: 6
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강원솔라파크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "강원솔라파크(주)",
    rn: 7
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "화천수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 108,
    company: "한국수력원자력(주)",
    rn: 8
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "화천#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 27,
    company: "한국수력원자력(주)",
    rn: 9
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "화천#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 27,
    company: "한국수력원자력(주)",
    rn: 10
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "화천#3",
    cent: "중앙",
    fuel: "수력",
    pcap: 27,
    company: "한국수력원자력(주)",
    rn: 11
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "화천#4",
    cent: "중앙",
    fuel: "수력",
    pcap: 27,
    company: "한국수력원자력(주)",
    rn: 12
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "춘천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.898,
    company: "춘천태양광발전소(주)",
    rn: 13
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.936,
    company: "에스케이이엔에스(주)",
    rn: 14
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동보건소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.04,
    company: "한국남부발전(주)",
    rn: 15
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.093,
    company: "한국남부발전(주)",
    rn: 16
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.055,
    company: "한국남부발전(주)",
    rn: 17
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "춘천수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 62.28,
    company: "한국수력원자력(주)",
    rn: 18
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "춘천#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 31.14,
    company: "한국수력원자력(주)",
    rn: 19
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "춘천#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 31.14,
    company: "한국수력원자력(주)",
    rn: 20
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진옥내저탄장지붕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.402,
    company: "한국동서발전(주)",
    rn: 21
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동공설운동장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.37,
    company: "한국남부발전(주)",
    rn: 22
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이티썬칠곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.97,
    company: "와이티썬(주)",
    rn: 23
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비오앤태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.397,
    company: "(주)비오앤",
    rn: 24
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "의암수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 48,
    company: "한국수력원자력(주)",
    rn: 25
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "의암#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 24,
    company: "한국수력원자력(주)",
    rn: 26
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "의암#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 24,
    company: "한국수력원자력(주)",
    rn: 27
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "청평수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 140.1,
    company: "한국수력원자력(주)",
    rn: 28
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "청평#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 19.8,
    company: "한국수력원자력(주)",
    rn: 29
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "청평#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 19.8,
    company: "한국수력원자력(주)",
    rn: 30
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "청평#3",
    cent: "중앙",
    fuel: "수력",
    pcap: 40.5,
    company: "한국수력원자력(주)",
    rn: 31
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "청평#4",
    cent: "중앙",
    fuel: "수력",
    pcap: 60,
    company: "한국수력원자력(주)",
    rn: 32
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "팔당수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 120,
    company: "한국수력원자력(주)",
    rn: 33
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "팔당#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 60,
    company: "한국수력원자력(주)",
    rn: 34
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "팔당수력#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 30,
    company: "한국수력원자력(주)",
    rn: 35
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "팔당#3",
    cent: "중앙",
    fuel: "수력",
    pcap: 60,
    company: "한국수력원자력(주)",
    rn: 36
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "팔당수력#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 30,
    company: "한국수력원자력(주)",
    rn: 37
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "강릉수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 82,
    company: "한국수력원자력(주)",
    rn: 38
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "강릉#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 41,
    company: "한국수력원자력(주)",
    rn: 39
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "강릉#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 41,
    company: "한국수력원자력(주)",
    rn: 40
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "소양강#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 100,
    company: "한국수자원공사",
    rn: 41
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "소양강#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 100,
    company: "한국수자원공사",
    rn: 42
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "충주수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 412,
    company: "한국수자원공사",
    rn: 43
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "충주수력#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 206,
    company: "한국수자원공사",
    rn: 44
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "충주#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 100,
    company: "한국수자원공사",
    rn: 45
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "충주수력#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 206,
    company: "한국수자원공사",
    rn: 46
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "충주#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 100,
    company: "한국수자원공사",
    rn: 47
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "충주#3",
    cent: "중앙",
    fuel: "수력",
    pcap: 100,
    company: "한국수자원공사",
    rn: 48
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "충주#4",
    cent: "중앙",
    fuel: "수력",
    pcap: 100,
    company: "한국수자원공사",
    rn: 49
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "대청수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 90,
    company: "한국수자원공사",
    rn: 50
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "대청#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 45,
    company: "한국수자원공사",
    rn: 51
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "대청#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 45,
    company: "한국수자원공사",
    rn: 52
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "단양수중보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.4,
    company: "한국수자원공사",
    rn: 53
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "안동수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 90,
    company: "한국수자원공사",
    rn: 54
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "안동#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 45,
    company: "한국수자원공사",
    rn: 55
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "안동#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 45,
    company: "한국수자원공사",
    rn: 56
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "합천수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 100,
    company: "한국수자원공사",
    rn: 57
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "합천#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 50,
    company: "한국수자원공사",
    rn: 58
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "합천#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 50,
    company: "한국수자원공사",
    rn: 59
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "합천소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.2,
    company: "한국수자원공사",
    rn: 60
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "합천#4",
    cent: "중앙",
    fuel: "수력",
    pcap: 0.6,
    company: "한국수자원공사",
    rn: 61
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "합천2수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.8,
    company: "한국수자원공사",
    rn: 62
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "임하수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 50,
    company: "한국수자원공사",
    rn: 63
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "임하#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 25,
    company: "한국수자원공사",
    rn: 64
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "임하#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 25,
    company: "한국수자원공사",
    rn: 65
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "괴산수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.6,
    company: "한국수력원자력(주)",
    rn: 66
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "괴산#1",
    cent: "비중앙",
    fuel: "수력",
    pcap: 1.3,
    company: "한국수력원자력(주)",
    rn: 67
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "괴산#2",
    cent: "비중앙",
    fuel: "수력",
    pcap: 1.3,
    company: "한국수력원자력(주)",
    rn: 68
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "칠보수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 35,
    company: "한국수력원자력(주)",
    rn: 69
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "칠보#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 14.4,
    company: "한국수력원자력(주)",
    rn: 70
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "칠보#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 14.6,
    company: "한국수력원자력(주)",
    rn: 71
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "칠보#3",
    cent: "중앙",
    fuel: "수력",
    pcap: 6,
    company: "한국수력원자력(주)",
    rn: 72
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "보성강수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 4.5,
    company: "한국수력원자력(주)",
    rn: 73
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "보성강#1",
    cent: "비중앙",
    fuel: "수력",
    pcap: 2.5,
    company: "한국수력원자력(주)",
    rn: 74
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "보성강#2",
    cent: "비중앙",
    fuel: "수력",
    pcap: 2,
    company: "한국수력원자력(주)",
    rn: 75
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "강림수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.45,
    company: "한국수력원자력(주)",
    rn: 76
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "강림#1",
    cent: "비중앙",
    fuel: "수력",
    pcap: 0.15,
    company: "한국수력원자력(주)",
    rn: 77
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "강림#2",
    cent: "비중앙",
    fuel: "수력",
    pcap: 0.15,
    company: "한국수력원자력(주)",
    rn: 78
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "강림#3",
    cent: "비중앙",
    fuel: "수력",
    pcap: 0.15,
    company: "한국수력원자력(주)",
    rn: 79
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "주암수력",
    cent: "중앙",
    fuel: "수력",
    pcap: 22.5,
    company: "한국수자원공사",
    rn: 80
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "남강수력발전",
    cent: "비중앙",
    fuel: "수력",
    pcap: 18,
    company: "한국수자원공사",
    rn: 81
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "남강수력#1",
    cent: "비중앙",
    fuel: "수력",
    pcap: 9,
    company: "한국수자원공사",
    rn: 82
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "용담제1수력#1",
    cent: "중앙",
    fuel: "수력",
    pcap: 11.05,
    company: "한국수자원공사",
    rn: 83
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "용담제1수력#2",
    cent: "중앙",
    fuel: "수력",
    pcap: 11.05,
    company: "한국수자원공사",
    rn: 84
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "달방소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.17,
    company: "한국수자원공사",
    rn: 85
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "강천보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 4.95,
    company: "한국수자원공사",
    rn: 86
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "강정고령보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 87
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "죽산보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.22,
    company: "한국수자원공사",
    rn: 88
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "함안보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국수자원공사",
    rn: 89
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "승촌보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.8,
    company: "한국수자원공사",
    rn: 90
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "달성보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.856,
    company: "한국수자원공사",
    rn: 91
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "합천.창녕보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국수자원공사",
    rn: 92
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "용림소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.6,
    company: "한국농어촌공사 무진장지사",
    rn: 93
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "극동소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.5,
    company: "(유)극동소수력발전소",
    rn: 94
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "대청댐소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.8,
    company: "한국수자원공사",
    rn: 95
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "삿갓보소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.4,
    company: "한국농어촌공사 정읍지사",
    rn: 96
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "칠곡보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 97
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "구미보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 98
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "낙단보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 99
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "상주보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 100
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "이포보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 101
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "여주보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 4.95,
    company: "한국수자원공사",
    rn: 102
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "백제보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.64,
    company: "한국수자원공사",
    rn: 103
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "성남제2소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.36,
    company: "한국수자원공사",
    rn: 104
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "포천소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.485,
    company: "한국수력발전(주)",
    rn: 105
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "덕송수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.6,
    company: "애플에너지 주식회사",
    rn: 106
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "대아소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국농어촌공사 전주완주임실지사",
    rn: 107
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "안동소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.5,
    company: "한국수자원공사",
    rn: 108
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "운문소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.36,
    company: "한국수자원공사",
    rn: 109
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "무주양수 소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.4,
    company: "한국수력원자력(주)",
    rn: 110
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "천상정수장소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.25,
    company: "울산광역시",
    rn: 111
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "성남소수력 제1호기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.34,
    company: "한국수자원공사",
    rn: 112
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "한석소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.214,
    company: "한석소수력",
    rn: 113
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "세종보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.31,
    company: "한국수자원공사",
    rn: 114
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "공주보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 115
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "주암역조정지댐소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.055,
    company: "한국수자원공사",
    rn: 116
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "존포1태양광(옛 존포태양광)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "거명산업(주)",
    rn: 117
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌신안존포태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "안좌신안존포태양광발전소",
    rn: 118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌신안존포태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "안좌신안존포태양광발전소",
    rn: 119
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "존포2태양광(옛 에스비태양광)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.774,
    company: "거명산업(주)",
    rn: 120
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "용담소수력 제1호기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.8,
    company: "한국수자원공사",
    rn: 121
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "양양양수소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.4,
    company: "한국수력원자력(주)",
    rn: 122
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령화력수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.172,
    company: "한국중부발전(주)",
    rn: 123
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "동화댐소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1,
    company: "한국농촌공사 남원지사",
    rn: 124
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "애플에너지 발전기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.8,
    company: "애플에너지 주식회사",
    rn: 125
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "담양호소수력발전기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.274,
    company: "한국농어촌공사 담양지사",
    rn: 126
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "정우소수력#2",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.075,
    company: "한국농어촌공사 정읍지사",
    rn: 127
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "정우소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2,
    company: "한국농촌공사 정읍지사",
    rn: 128
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "하동호소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.825,
    company: "한국농어촌공사 하동남해지사",
    rn: 129
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "주암소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1,
    company: "한국수자원공사",
    rn: 130
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "백곡소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.43,
    company: "한국농어촌공사 진천지사",
    rn: 131
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "원정수 소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.6,
    company: "(주)포스코",
    rn: 132
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "포천바이오가스",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.1,
    company: "포천바이오에너지주식회사",
    rn: 133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스지솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 남부홀딩스 남부신안지점",
    rn: 134
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자인정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "한국수자원공사",
    rn: 135
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예천태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.385,
    company: "한국수력원자력(주)",
    rn: 136
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "전주페이퍼소각로",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.385,
    company: "(주)전주페이퍼",
    rn: 137
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "진주LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.925,
    company: "누리에코넷(주)",
    rn: 138
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "경주환경에너지소각로",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 4.75,
    company: "(주)경주환경에너지",
    rn: 139
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "지엔씨바이오#2",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.2,
    company: "(주)지엔씨에너지",
    rn: 140
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "극동제2소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.75,
    company: "(유)극동제2소수력발전소",
    rn: 141
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.157,
    company: "케이에너지(주)",
    rn: 142
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영동화력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.065,
    company: "한국남동발전(주)",
    rn: 143
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "사당CES",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 2,
    company: "(주)GIMCO",
    rn: 144
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수질복원센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "한국서부발전(주)",
    rn: 145
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종시자전거도로태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "한국서부발전(주)",
    rn: 146
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종시자전거도로태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.638,
    company: "한국서부발전(주)",
    rn: 147
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종시자전거도로태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.677,
    company: "한국서부발전(주)",
    rn: 148
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.088,
    company: "민솔라에너지",
    rn: 149
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우쏠라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "우쏠라에너지(유)",
    rn: 150
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종시폐기물매립장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.628,
    company: "한국서부발전(주)",
    rn: 151
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "사당CES(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 2,
    company: "(주)GIMCO(구매)",
    rn: 152
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "동상소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.59,
    company: "한국농어촌공사 전주완주임실지사",
    rn: 153
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대유신소재태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.701,
    company: "케이앤에이치솔라(주)",
    rn: 154
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "공촌정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.469,
    company: "케이앤에이치솔라(주)",
    rn: 155
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영진공사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.42,
    company: "케이앤에이치솔라(주)",
    rn: 156
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "장성소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.392,
    company: "한국농어촌공사 장성지사",
    rn: 157
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "대곡댐소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.3,
    company: "한국수자원공사",
    rn: 158
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "삼천포해양소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국남동발전(주)",
    rn: 159
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "고문소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.5,
    company: "신에너지주식회사",
    rn: 160
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "태안소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.2,
    company: "한국서부발전(주)",
    rn: 161
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "경기그린연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 58.8,
    company: "경기그린에너지(주)",
    rn: 162
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "율현소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.56,
    company: "한국농어촌공사 진주산청지사",
    rn: 163
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "보령화력소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 164
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "보령화력소수력#2",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.5,
    company: "한국중부발전(주)",
    rn: 165
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "영흥소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국남동발전(주)",
    rn: 166
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "경천지구소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.44,
    company: "한국농어촌공사 전주완주지사",
    rn: 167
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "경천소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.8,
    company: "한국농어촌공사 문경지사",
    rn: 168
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "산내소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.82,
    company: "산내소수력발전소",
    rn: 169
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "당진화력소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 4.998,
    company: "한국동서발전(주)",
    rn: 170
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "판교가압장소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.2,
    company: "한국수자원공사",
    rn: 171
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수산정수사업소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국남부발전(주)",
    rn: 172
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이글스에너지태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.249,
    company: "이글스에너지(주)",
    rn: 173
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이글스에너지태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.789,
    company: "이글스에너지(주)",
    rn: 174
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "창죽풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 16,
    company: "창죽풍력발전(주)",
    rn: 175
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양항태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.293,
    company: "한국동서발전(주)",
    rn: 176
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "초당환경소각로",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.26,
    company: "초당환경(유)",
    rn: 177
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성훈철강태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.624,
    company: "(주)성훈철강",
    rn: 178
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안파워태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.966,
    company: "포스코에너지(주)",
    rn: 179
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕동하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "경남에너지(주)",
    rn: 180
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "k-water본사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "한국수자원공사",
    rn: 181
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진매립장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.311,
    company: "한국동서발전(주)",
    rn: 182
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세아베스틸태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.14,
    company: "케이앤에이치솔라(주)",
    rn: 183
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.507,
    company: "한국수자원공사",
    rn: 184
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여수엑스포태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.219,
    company: "한국중부발전(주)",
    rn: 185
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주대태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.092,
    company: "한국중부발전(주)",
    rn: 186
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "신안풍력복합발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 9,
    company: "신안풍력복합발전(주)",
    rn: 187
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.984,
    company: "이글스에너지(주)",
    rn: 188
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "한국남동발전(주)",
    rn: 189
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.85,
    company: "한국남동발전(주)",
    rn: 190
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전자구미태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주)에스앤아이코퍼레이션",
    rn: 191
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화학오창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.989,
    company: "(주)에스앤아이코퍼레이션",
    rn: 192
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국제강태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이앤에이치솔라(주)",
    rn: 193
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명전기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이앤에이치솔라(주)",
    rn: 194
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "경주풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 16.8,
    company: "경주풍력발전(주)",
    rn: 195
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지파크",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(유)에너지파크",
    rn: 196
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "합천수상태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "한국수자원공사",
    rn: 197
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남제주화력소내태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.196,
    company: "한국남부발전(주)",
    rn: 198
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼광산업개발태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "(주)삼광산업개발",
    rn: 199
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "경주풍력2단계",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20.7,
    company: "경주풍력발전(주)",
    rn: 200
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해솔에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "(주)해솔에너지",
    rn: 201
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(유)에너지팜",
    rn: 202
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코레일대전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.356,
    company: "한전산업개발(주)",
    rn: 203
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.048,
    company: "한국수자원공사",
    rn: 204
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제일산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.672,
    company: "제일산업태양광발전소",
    rn: 205
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "가이아소각로발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.3,
    company: "(주)가이아",
    rn: 206
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "무주양수",
    cent: "중앙",
    fuel: "양수",
    pcap: 600,
    company: "한국수력원자력(주)",
    rn: 207
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "무주양수#1",
    cent: "중앙",
    fuel: "양수",
    pcap: 300,
    company: "한국수력원자력(주)",
    rn: 208
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "무주양수#2",
    cent: "중앙",
    fuel: "양수",
    pcap: 300,
    company: "한국수력원자력(주)",
    rn: 209
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "예천양수",
    cent: "중앙",
    fuel: "양수",
    pcap: 800,
    company: "한국수력원자력(주)",
    rn: 210
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "예천양수#1",
    cent: "중앙",
    fuel: "양수",
    pcap: 400,
    company: "한국수력원자력(주)",
    rn: 211
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "예천양수#2",
    cent: "중앙",
    fuel: "양수",
    pcap: 400,
    company: "한국수력원자력(주)",
    rn: 212
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "삼랑진양수",
    cent: "중앙",
    fuel: "양수",
    pcap: 600,
    company: "한국수력원자력(주)",
    rn: 213
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "삼랑진양수#1",
    cent: "중앙",
    fuel: "양수",
    pcap: 300,
    company: "한국수력원자력(주)",
    rn: 214
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "삼랑진양수#2",
    cent: "중앙",
    fuel: "양수",
    pcap: 300,
    company: "한국수력원자력(주)",
    rn: 215
    },
    {
    area: "수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "청평양수",
    cent: "중앙",
    fuel: "양수",
    pcap: 400,
    company: "한국수력원자력(주)",
    rn: 216
    },
    {
    area: "수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "청평양수#1",
    cent: "중앙",
    fuel: "양수",
    pcap: 200,
    company: "한국수력원자력(주)",
    rn: 217
    },
    {
    area: "수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "청평양수#2",
    cent: "중앙",
    fuel: "양수",
    pcap: 200,
    company: "한국수력원자력(주)",
    rn: 218
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "양양양수",
    cent: "중앙",
    fuel: "양수",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 219
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "양양양수#1",
    cent: "중앙",
    fuel: "양수",
    pcap: 250,
    company: "한국수력원자력(주)",
    rn: 220
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "양양양수#2",
    cent: "중앙",
    fuel: "양수",
    pcap: 250,
    company: "한국수력원자력(주)",
    rn: 221
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "양양양수#3",
    cent: "중앙",
    fuel: "양수",
    pcap: 250,
    company: "한국수력원자력(주)",
    rn: 222
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "양양양수#4",
    cent: "중앙",
    fuel: "양수",
    pcap: 250,
    company: "한국수력원자력(주)",
    rn: 223
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장성북이일호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "(유)장성북이일호",
    rn: 224
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "산청양수",
    cent: "중앙",
    fuel: "양수",
    pcap: 700,
    company: "한국수력원자력(주)",
    rn: 225
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "산청양수#1",
    cent: "중앙",
    fuel: "양수",
    pcap: 350,
    company: "한국수력원자력(주)",
    rn: 226
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "산청양수#2",
    cent: "중앙",
    fuel: "양수",
    pcap: 350,
    company: "한국수력원자력(주)",
    rn: 227
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "산청소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.995,
    company: "한국수력원자력(주)",
    rn: 228
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "성주소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.8,
    company: "성주발전(주)",
    rn: 229
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "장흥댐소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.8,
    company: "한국수자원공사",
    rn: 230
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "광주호소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.24,
    company: "한국농어촌공사 담양지사",
    rn: 231
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "죽전소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.19,
    company: "한국농어촌공사 합천지사",
    rn: 232
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "불갑호소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.6,
    company: "한국농어촌공사 영광지사",
    rn: 233
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "청송양수",
    cent: "중앙",
    fuel: "양수",
    pcap: 600,
    company: "한국수력원자력(주)",
    rn: 234
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "청송양수#1",
    cent: "중앙",
    fuel: "양수",
    pcap: 300,
    company: "한국수력원자력(주)",
    rn: 235
    },
    {
    area: "비수도권",
    genFom: "양수",
    genSrc: "양수",
    genNm: "청송양수#2",
    cent: "중앙",
    fuel: "양수",
    pcap: 300,
    company: "한국수력원자력(주)",
    rn: 236
    },
    {
    area: "비수도권",
    genFom: "해양에너지",
    genSrc: "신재생에너지",
    genNm: "울돌목조류발전",
    cent: "비중앙",
    fuel: "해양에너지",
    pcap: 1,
    company: "한국해양연구원",
    rn: 237
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "보성소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.1,
    company: "대한수력발전(주)",
    rn: 238
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "고산소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.56,
    company: "한국수자원공사",
    rn: 239
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "영흥해양소수력#2",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 4.599,
    company: "한국남동발전(주)",
    rn: 240
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "희승풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "주식회사 희승풍력발전",
    rn: 241
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "성신양회폐열회수발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 29.5,
    company: "성신양회주식회사",
    rn: 242
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민쏠라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "민쏠라에너지(유)",
    rn: 243
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "뉴세제제주지사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.988,
    company: "주식회사 세왕",
    rn: 244
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "오성연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 6.16,
    company: "평택에너지서비스(주)",
    rn: 245
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경남창원태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.957,
    company: "에스케이이엔에스(주)",
    rn: 246
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "한솔홈데코열병합",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 2.55,
    company: "(주)한솔홈데코",
    rn: 247
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청주정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.379,
    company: "한국수자원공사",
    rn: 248
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥솔론익산공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.4,
    company: "하이태양광발전(주)",
    rn: 249
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광지산풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "한국동서발전(주)",
    rn: 250
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "안계소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.99,
    company: "한국수자원공사",
    rn: 251
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도시개발신월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.975,
    company: "도시개발(주)",
    rn: 252
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동천2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.25,
    company: "유한회사 이오동천솔라",
    rn: 253
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무안그린에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.694,
    company: "(주)무안그린에너지",
    rn: 254
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호포차량기지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.452,
    company: "호포차량기지태양광발전소(주)",
    rn: 255
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산신호태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 11,
    company: "부산신호태양광발전(주)",
    rn: 256
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산신호태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9,
    company: "부산신호태양광발전(주)",
    rn: 257
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산이앤이RDF",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 24.8,
    company: "(주)부산이앤이",
    rn: 258
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "리콘타태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.78,
    company: "케이앤에이치솔라(주)",
    rn: 259
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목포샤링태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.325,
    company: "케이앤에이치솔라(주)",
    rn: 260
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천교통공사태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "큐원솔라(주)",
    rn: 261
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천교통공사태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.702,
    company: "큐원솔라(주)",
    rn: 262
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산복합자재창고태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.187,
    company: "한국남부발전(주)",
    rn: 263
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대척태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.5,
    company: "(주)대척태양광발전소",
    rn: 264
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9,
    company: "(주)금산태양광발전소",
    rn: 265
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지유태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "지유태양광발전소",
    rn: 266
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원마을풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "(주)행원풍력특성화마을 ",
    rn: 267
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.405,
    company: "세화태양광발전소",
    rn: 268
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "개취보 소수력 발전소",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.42,
    company: "산청군",
    rn: 269
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "가북소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.65,
    company: "한국농어촌공사 거창함양지사",
    rn: 270
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동천1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "유한회사 로즈솔라",
    rn: 271
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산연성정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.49,
    company: "한국서부발전(주)",
    rn: 272
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "자인정수장소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.065,
    company: "한국수자원공사",
    rn: 273
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛솔라파크#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 10.947,
    company: "한국수력원자력(주)",
    rn: 274
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진자재창고태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.672,
    company: "한국동서발전(주)",
    rn: 275
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "한국수자원공사",
    rn: 276
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.853,
    company: "(주)한국태양광발전5호",
    rn: 277
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "난지바이오가스엔진발전기",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 3.06,
    company: "한국지역난방공사",
    rn: 278
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동부당진솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "동부당진솔라(주)",
    rn: 279
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "충북증평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "에스케이이엔에스(주)",
    rn: 280
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "광주양과 LFG #2",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 1.06,
    company: "파낙스에너지(주)광주LFG발전소",
    rn: 281
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예천태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.629,
    company: "한국수력원자력(주)",
    rn: 282
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암에프원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 13.296,
    company: "영암에프원태양광발전(주)",
    rn: 283
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.821,
    company: "(주)세아네트웍스",
    rn: 284
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸2기태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.956,
    company: "유한회사 라미솔라",
    rn: 285
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸3기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.718,
    company: "유한회사 라미솔라",
    rn: 286
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산제강1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.404,
    company: "유한회사 라미솔라",
    rn: 287
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산제강2기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.906,
    company: "유한회사 라미솔라",
    rn: 288
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천제강1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.501,
    company: "유한회사 라미솔라",
    rn: 289
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥그리거코리아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.465,
    company: "맥그리거코리아(주)",
    rn: 290
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "대관령풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "강원도",
    rn: 291
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안면태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.803,
    company: "안면태양광(주)",
    rn: 292
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영목태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.203,
    company: "영목태양광(주)",
    rn: 293
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.534,
    company: "고남태양광(주)",
    rn: 294
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다담1호태양광발전소",
    rn: 295
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "키스트론태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.154,
    company: "키스트론(주)",
    rn: 296
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "일산연료전지#3",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.8,
    company: "한국동서발전(주)",
    rn: 297
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신인천소내태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "한국남부발전(주)",
    rn: 298
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.771,
    company: "부산솔라(주)",
    rn: 299
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.286,
    company: "부산솔라(주)",
    rn: 300
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강변사업소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.807,
    company: "부산솔라(주)",
    rn: 301
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KTC태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.378,
    company: "태근에너지",
    rn: 302
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한미전선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.299,
    company: "케이앤에이치솔라(주)",
    rn: 303
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산공단태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.21,
    company: "한국서부발전(주)",
    rn: 304
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산와동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.21,
    company: "한국서부발전(주)",
    rn: 305
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산목내태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.18,
    company: "한국서부발전(주)",
    rn: 306
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산성곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.17,
    company: "한국서부발전(주)",
    rn: 307
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산초지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.16,
    company: "한국서부발전(주)",
    rn: 308
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.878,
    company: "코스콘태양광(주)",
    rn: 309
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영월태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 8.237,
    company: "(주)영월에너지스테이션",
    rn: 310
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영월태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 19.06,
    company: "(주)영월에너지스테이션",
    rn: 311
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영월태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 11.529,
    company: "(주)영월에너지스테이션",
    rn: 312
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#5",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 313
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#6",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 314
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#7",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 315
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#8",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 316
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.977,
    company: "코리아에너지발전소(주)",
    rn: 317
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소2호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.321,
    company: "코리아에너지발전소(주)",
    rn: 318
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.75,
    company: "대동건설기계(주)",
    rn: 319
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구노제주솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.32,
    company: "(주)구노제주솔라",
    rn: 320
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "(주)한라솔라팜",
    rn: 321
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코스콘해맞이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "코스콘태양광(주)",
    rn: 322
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "청운태양광발전소",
    rn: 323
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제일모직태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.692,
    company: "코스콘태양광(주)",
    rn: 324
    },
    {
    area: "비수도권",
    genFom: "차압",
    genSrc: "차압",
    genNm: "김해천연가스압터빈",
    cent: "비중앙",
    fuel: "가스압",
    pcap: 0.3,
    company: "한국가스공사 경남지역본부",
    rn: 325
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리에너지태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.096,
    company: "(주)우리에너지",
    rn: 326
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린테크노파크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)그린테크노파크에너지",
    rn: 327
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베누스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.03,
    company: "베누스태양광발전소",
    rn: 328
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "예천소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.9,
    company: "한국수력원자력(주)",
    rn: 329
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도시개발원덕태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.565,
    company: "코스콘태양광(주)",
    rn: 330
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "신재생에너지",
    genNm: "동해바이오매스",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 30,
    company: "한국동서발전(주)",
    rn: 331
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안면도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.1,
    company: "안면도태양광발전소(주)",
    rn: 332
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진도그린에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.704,
    company: "(유)진도그린에너지",
    rn: 333
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의신솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "(유)의신솔라에너지",
    rn: 334
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진도솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "(유)진도솔라에너지",
    rn: 335
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(유)참솔라",
    rn: 336
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "분당연료전지#2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 3.08,
    company: "한국남동발전(주)",
    rn: 337
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부경에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)부경에너지",
    rn: 338
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지에이치에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)지에이치에너지",
    rn: 339
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "익산포항선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.674,
    company: "해사랑태양광 주식회사",
    rn: 340
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김천시하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.478,
    company: "(주)하나로햇빛발전소",
    rn: 341
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아산태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.571,
    company: "한국수자원공사",
    rn: 342
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지레이크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.399,
    company: "에너지레이크 태양광발전소",
    rn: 343
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국태양광발전5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.981,
    company: "코스콘태양광(주)",
    rn: 344
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의정부하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.327,
    company: "의정부시(하수처리과)",
    rn: 345
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민솔라에너지 #3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "민솔라에너지",
    rn: 346
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SANC에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.831,
    company: "에스에이앤씨에너지(주)",
    rn: 347
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거금솔라파크태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.354,
    company: "(주)거금솔라파크",
    rn: 348
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화이트에너지태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(유)화이트에너지",
    rn: 349
    },
    {
    area: "제주",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "KC하이드로소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.4,
    company: "KC하이드로(주)",
    rn: 350
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암에프원태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 13.296,
    company: "한국서부발전(주)",
    rn: 351
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "계림태양광발전소",
    rn: 352
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "안양자원회수시설터빈",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.65,
    company: "안양시",
    rn: 353
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산하수처리시설태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.248,
    company: "안산시상하수도사업소(하수)",
    rn: 354
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔에스쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.966,
    company: "(주)엔에스쏠라",
    rn: 355
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안파워태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.003,
    company: "포스코에너지(주)",
    rn: 356
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.537,
    company: "안산시상하수도사업소(정수장)",
    rn: 357
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.399,
    company: "농업회사법인 청명유한회사",
    rn: 358
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이오니아1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.822,
    company: "큐원솔라(주)",
    rn: 359
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이오니아2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.897,
    company: "큐원솔라(주)",
    rn: 360
    },
    {
    area: "수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영흥풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 24,
    company: "한국남동발전(주)",
    rn: 361
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경남창원태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.944,
    company: "에스케이이엔에스(주)",
    rn: 362
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "붓드기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.2,
    company: "붓드기태양광발전소(주)",
    rn: 363
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "대흥 태양광발전소",
    rn: 364
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거금솔라파크태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.394,
    company: "(주)거금솔라파크",
    rn: 365
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서부하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.972,
    company: "대구태양광발전(주)",
    rn: 366
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북부하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.167,
    company: "대구태양광발전(주)",
    rn: 367
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신천하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.555,
    company: "대구태양광발전(주)",
    rn: 368
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세일GAT솔라태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 4.386,
    company: "(주)내추럴오투피아",
    rn: 369
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세일GAT솔라태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.783,
    company: "(주)탑선태양광1호",
    rn: 370
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "달성솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.8,
    company: "달성솔라에너지(주)",
    rn: 371
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "부항댐소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.6,
    company: "한국수자원공사",
    rn: 372
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스트솔라태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.776,
    company: "베스트솔라에너지(주)",
    rn: 373
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스트솔라태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.934,
    company: "베스트솔라에너지(주)",
    rn: 374
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한솔파워텍1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.989,
    company: "방축태양광 주식회사",
    rn: 375
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진화력수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국동서발전(주)",
    rn: 376
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서울나눔발전소1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.5,
    company: "(주)불가리아나눔발전소",
    rn: 377
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다남솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6.886,
    company: "(주)다쓰테크",
    rn: 378
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "교통안전공단태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "큐원솔라(주)",
    rn: 379
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지랜드태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(유)에너지랜드",
    rn: 380
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "울산화력 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.8,
    company: "한국동서발전(주)",
    rn: 381
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "노온정수장태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.784,
    company: "큐원솔라(주)",
    rn: 382
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "OCI암사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.499,
    company: "오씨아이서울태양광발전(주)",
    rn: 383
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한사랑태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한사랑 태양광발전소",
    rn: 384
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이레태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이레 태양광발전소",
    rn: 385
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "은호태양광발전소",
    rn: 386
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광주탄소중립태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.281,
    company: "광주광역시청",
    rn: 387
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서울전선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.795,
    company: "케이에스솔라(주)",
    rn: 388
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해맞이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.22,
    company: "도시개발(주)",
    rn: 389
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.495,
    company: "에스에너지(주)",
    rn: 390
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성산하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.158,
    company: "제주도(수자원본부)",
    rn: 391
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도련정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.216,
    company: "제주도(수자원본부)",
    rn: 392
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월산정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "제주도(수자원본부)",
    rn: 393
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "수도권매립지열병합",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 5,
    company: "한국동서발전(주)",
    rn: 394
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이호동오도마을태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.016,
    company: "이호동오도마을회",
    rn: 395
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.075,
    company: "다담2호태양광발전소",
    rn: 396
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평택서부공설운동장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.753,
    company: "(주)한국태양광발전5호",
    rn: 397
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "금암소각로발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.9,
    company: "(주)우진엔비텍",
    rn: 398
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "동양그린폐기물소각발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.4,
    company: "동양그린폐기물발전소",
    rn: 399
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대아산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 10.063,
    company: "현대아산태양광발전(주)",
    rn: 400
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영암풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20,
    company: "영암풍력발전(주)",
    rn: 401
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진화물주차장1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.982,
    company: "전남솔라(주)",
    rn: 402
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진화물주차장2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.974,
    company: "전남솔라(주)",
    rn: 403
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진환경정환센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.034,
    company: "전남솔라(주)",
    rn: 404
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진청자박물관태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.348,
    company: "전남솔라(주)",
    rn: 405
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "곤지암발전#1",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.95,
    company: "경기환경에너지 주식회사",
    rn: 406
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주화력 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.206,
    company: "한국중부발전(주)",
    rn: 407
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백산태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "한국녹색철도태양광발전(주)",
    rn: 408
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "통동태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.601,
    company: "한국녹색철도태양광발전(주)",
    rn: 409
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율이동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "율이동태양광발전 주식회사",
    rn: 410
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율이서태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "율이서태양광발전주식회사",
    rn: 411
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창원쏠라랜드제1발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.535,
    company: "창원쏠라랜드(주)",
    rn: 412
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창원쏠라랜드제7발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.675,
    company: "창원쏠라랜드(주)",
    rn: 413
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더케이제주호텔태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "더케이제주호텔(주)",
    rn: 414
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스트솔라태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.17,
    company: "베스트솔라에너지(주)",
    rn: 415
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "법영2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "법영2호태양광발전소",
    rn: 416
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "법영3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "법영3호태양광발전소",
    rn: 417
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "(주)햇빛태양광발전",
    rn: 418
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주상모태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)대정태양광발전(상모)",
    rn: 419
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주영락태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "(주)대정태양광발전(영락)",
    rn: 420
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "호남풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20,
    company: "호남풍력발전(주)",
    rn: 421
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "유진태양광발전소",
    rn: 422
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "와이에스 태양광발전소",
    rn: 423
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스지에너지고남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "고남에너지 주식회사",
    rn: 424
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "킨태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "킨태양광발전소",
    rn: 425
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코파워텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.02,
    company: "(주)에코파워텍",
    rn: 426
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "이에스파워",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 29.5,
    company: "이에스파워(유)",
    rn: 427
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "영주댐수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국수자원공사",
    rn: 428
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(유)퓨쳐썬에너지",
    rn: 429
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.743,
    company: "(주)우진솔라파워아암태양광발전소",
    rn: 430
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에프태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "주식회사 나라솔라파워",
    rn: 431
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "비봉태양광발전소",
    rn: 432
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "수아태양광발전소",
    rn: 433
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.034,
    company: "제이에스태양광발전소",
    rn: 434
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산쏠라에너지1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.827,
    company: "대산농수산(주)",
    rn: 435
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "유진태양광발전소",
    rn: 436
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연우파워플랜트태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "솔솔라 주식회사",
    rn: 437
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다온태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.04,
    company: "다온태양광발전소",
    rn: 438
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지상탐라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "지상탐라태양광발전소",
    rn: 439
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지상한림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "(주)지상",
    rn: 440
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕소정수장정수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.012,
    company: "한국수자원공사",
    rn: 441
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영암풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20,
    company: "영암풍력발전(주)",
    rn: 442
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산솔라자동차부품단지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.79,
    company: "부산솔라(주)",
    rn: 443
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "GMK창원공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.966,
    company: "케이에스솔라(주)",
    rn: 444
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "합천수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국수자원공사",
    rn: 445
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영광염전태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 10.981,
    company: "디쏠라에너지(주)",
    rn: 446
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.599,
    company: "제우태양광발전(주)",
    rn: 447
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KGC예본태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "(주)케이지씨예본",
    rn: 448
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.496,
    company: "(주)쏠라파워태양광발전소",
    rn: 449
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도시환경이엔지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.198,
    company: "(주)도시환경이엔지",
    rn: 450
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주혁태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(유)주혁",
    rn: 451
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천안정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.655,
    company: "한국수자원공사",
    rn: 452
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "양산에덴벨리풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 10,
    company: "윈드밀파워(주)",
    rn: 453
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화목맑은물센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.243,
    company: "(주)김해코리아",
    rn: 454
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월평정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.402,
    company: "대전광역시",
    rn: 455
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남원에코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)부여에너지팜",
    rn: 456
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청운2태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "청운2태양광발전소",
    rn: 457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "클린에코에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "(유)클린에코에너지",
    rn: 458
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "협신태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "협신개발(주)",
    rn: 459
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "용수태양광발전소",
    rn: 460
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "합천수상태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국수자원공사",
    rn: 461
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신신솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.2,
    company: "신신토건(주)",
    rn: 462
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아드반테스트코리아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.21,
    company: "아드반테스트코리아(주)",
    rn: 463
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남창리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.496,
    company: "에너지(유)",
    rn: 464
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진원산업개발태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.208,
    company: "진원산업개발(주)",
    rn: 465
    },
    {
    area: "비수도권",
    genFom: "가스터빈(단독)",
    genSrc: "신재생에너지",
    genNm: "전주페이퍼바이오가스",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 2.852,
    company: "(주)전주페이퍼",
    rn: 466
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "전주페이퍼바이오매스",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 14.955,
    company: "(주)전주페이퍼",
    rn: 467
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정명하이텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "동부태양광발전소",
    rn: 468
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에이치에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.45,
    company: "(주)에스에이치에너지",
    rn: 469
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주도시가스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)제주도시가스",
    rn: 470
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신성8호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "케이에스솔라(주)",
    rn: 471
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "신우태양광발전소",
    rn: 472
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "학산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "학산태양광",
    rn: 473
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고남산3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.799,
    company: "고남산3호태양광발전소",
    rn: 474
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원풍력#4",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "제주에너지공사",
    rn: 475
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원풍력#5",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 1.5,
    company: "제주에너지공사",
    rn: 476
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원풍력#16",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 1.65,
    company: "제주에너지공사",
    rn: 477
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.415,
    company: "제주에너지공사",
    rn: 478
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한반도세종태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 4.025,
    company: "하이패스태양광(주)",
    rn: 479
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "칠서정수장태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "창원해모아발전(주)",
    rn: 480
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "불곡배수지태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.335,
    company: "창원해모아발전(주)",
    rn: 481
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명곡배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "창원해모아발전(주)",
    rn: 482
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진해석동정수장태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "창원해모아발전(주)",
    rn: 483
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "의암태양광발전소(주)",
    rn: 484
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송암태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.05,
    company: "송암태양광발전소(주)",
    rn: 485
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경상대가좌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.905,
    company: "한국남동발전(주)",
    rn: 486
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨제이대한통운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.689,
    company: "전남도시가스(주)",
    rn: 487
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "예빛태양광발전소",
    rn: 488
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "푸른태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "푸른태양광발전소",
    rn: 489
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "남원시폐기물가스화발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.6,
    company: "남원시",
    rn: 490
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서남물재생센터태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "코스콘태양광(주)",
    rn: 491
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도봉차량기지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "해맞이태양광발전(주)",
    rn: 492
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중랑물재생센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "코스콘태양광(주)",
    rn: 493
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거금솔라파크태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 4.571,
    company: "(주)거금솔라파크",
    rn: 494
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거금솔라파크태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.075,
    company: "(주)거금솔라파크",
    rn: 495
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거금솔라파크태양광#5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.606,
    company: "(주)거금솔라파크",
    rn: 496
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암테크노태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.584,
    company: "영암테크노태양광(주) #1",
    rn: 497
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암테크노태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.717,
    company: "영암테크노태양광(주) #2",
    rn: 498
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암해오름태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.724,
    company: "영암해오름태양광(주)",
    rn: 499
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미정수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.179,
    company: "한국수자원공사",
    rn: 500
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포항하수처리장태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.75,
    company: "영남에너지서비스(주)",
    rn: 501
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "썬태양광발전소",
    rn: 502
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "VE태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "VE에너지",
    rn: 503
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고양정수장태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.268,
    company: "한국수자원공사",
    rn: 504
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕소정수장태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.327,
    company: "한국수자원공사",
    rn: 505
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "김녕풍력단지발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 10.5,
    company: "제주도청(김녕풍력실증단지)",
    rn: 506
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시흥정수장태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국수자원공사",
    rn: 507
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세이브에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.816,
    company: "유한회사 세이브에너지",
    rn: 508
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 481,
    company: "포천파워(주)",
    rn: 509
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 240.5,
    company: "포천파워(주)",
    rn: 510
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 240.5,
    company: "포천파워(주)",
    rn: 511
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합 ST#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 244,
    company: "포천파워(주)",
    rn: 512
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합 1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 727,
    company: "포천파워(주)",
    rn: 513
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 479,
    company: "포천파워(주)",
    rn: 514
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합2GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 240.5,
    company: "포천파워(주)",
    rn: 515
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합2GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 240.5,
    company: "포천파워(주)",
    rn: 516
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합 ST#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 245.3,
    company: "포천파워(주)",
    rn: 517
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양산태양광(유산폐기물매립장)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.976,
    company: "(주)양산햇빛발전소",
    rn: 518
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영화에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영화에너지2호태양광발전소",
    rn: 519
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.761,
    company: "우주태양광발전소",
    rn: 520
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안산복합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 552,
    company: "(주)에스파워",
    rn: 521
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안산복합 CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 835,
    company: "(주)에스파워",
    rn: 522
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안산복합 ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 283,
    company: "(주)에스파워",
    rn: 523
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안산복합 GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 275.6,
    company: "(주)에스파워",
    rn: 524
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안산복합 GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 276,
    company: "(주)에스파워",
    rn: 525
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천복합 2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 725,
    company: "포천파워(주)",
    rn: 526
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 527
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 528
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 529
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 530
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "호윤태양광발전소",
    rn: 531
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 532
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 533
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 534
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 535
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민서태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "민서 태양광발전소",
    rn: 536
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "건우 태양광발전소",
    rn: 537
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화순태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 신독",
    rn: 538
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동선 태양광발전소",
    rn: 539
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정포1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정포 1호 태양광발전소",
    rn: 540
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정포2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정포 2호 태양광발전소",
    rn: 541
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해든햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "해든햇빛 태양광발전소",
    rn: 542
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주현2호태양광발전소",
    rn: 543
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주현1호 태양광발전소",
    rn: 544
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "드림태양광발전소",
    rn: 545
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건우2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "건우2호태양광발전소",
    rn: 546
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "주현3호태양광발전소",
    rn: 547
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금호1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "금호1호태양광발전소",
    rn: 548
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "소원태양광발전소",
    rn: 549
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 550
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 551
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#7",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 552
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1GT#8",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국서부발전(주)",
    rn: 553
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참진3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "참진3태양광발전소",
    rn: 554
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일동3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "일동3태양광발전소",
    rn: 555
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 556
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 557
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#7",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 558
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서인천복합1CC#8",
    cent: "중앙",
    fuel: "LNG",
    pcap: 225,
    company: "한국서부발전(주)",
    rn: 559
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이수백태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이수백태양광발전소",
    rn: 560
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여주늘푸른태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "여주늘푸른태양광발전소",
    rn: 561
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선애태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "선애 태양광 발전소",
    rn: 562
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광나지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "광나진",
    rn: 563
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대랑원광 태양광발전소",
    rn: 564
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스테루키2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "에스테루키2호태양광발전소",
    rn: 565
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "오성연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 9.68,
    company: "오성연료전지주식회사",
    rn: 566
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "항아4호에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "항아4호에너지태양광발전소",
    rn: 567
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 568
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 569
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한에너지3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 대한에너지3호",
    rn: 570
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테라에너지제이호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 테라에너지제이호",
    rn: 571
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테라에너지제일호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 테라에너지제일호",
    rn: 572
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일지테크경주공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.652,
    company: "(주)일지테크",
    rn: 573
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 574
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 575
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 576
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 577
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동구2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.086,
    company: "동구2호태양광발전소",
    rn: 578
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명원태양광발전소",
    rn: 579
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월승리1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(주)리치스톤",
    rn: 580
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "간현리태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.951,
    company: "세종썬에너지주식회사",
    rn: 581
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현원1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "현원1호 태양광발전소",
    rn: 582
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현원2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현원2호 태양광발전소",
    rn: 583
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합CC#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 584
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합CC#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 585
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창금2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "창금2호 태양광발전소",
    rn: 586
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무등스크린 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.42,
    company: "무등스크린 주식회사",
    rn: 587
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코스모링크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.189,
    company: "충청에너지서비스(주)",
    rn: 588
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행죽태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.753,
    company: "유한회사 행죽태양광발전소",
    rn: 589
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진안황금2호태양광발전소",
    rn: 590
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진안황금5호태양광발전소",
    rn: 591
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진안황금6호태양광발전소",
    rn: 592
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금7호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진안황금7호태양광발전소",
    rn: 593
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금8호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진안황금8호 태양광발전소",
    rn: 594
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합3GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 595
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합4GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 596
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "남제주복합CC",
    cent: "중앙",
    fuel: "경유",
    pcap: 146.245,
    company: "한국남부발전(주)",
    rn: 597
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 598
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 599
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#7",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 600
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합GT#8",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 601
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "남제주복합GT",
    cent: "중앙",
    fuel: "경유",
    pcap: 97.028,
    company: "한국남부발전(주)",
    rn: 602
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "남제주복합GT#1",
    cent: "중앙",
    fuel: "경유",
    pcap: 48.514,
    company: "한국남부발전(주)",
    rn: 603
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "남제주복합GT#2",
    cent: "중앙",
    fuel: "경유",
    pcap: 48.514,
    company: "한국남부발전(주)",
    rn: 604
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "남제주복합ST",
    cent: "중앙",
    fuel: "경유",
    pcap: 49.217,
    company: "한국남부발전(주)",
    rn: 605
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "신서천화력1호기",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1018,
    company: "한국중부발전(주)",
    rn: 606
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합CC#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 607
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신인천복합CC#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 608
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "별내열병합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 39,
    company: "별내에너지(주)",
    rn: 609
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "별내열병합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 91.4,
    company: "별내에너지(주)",
    rn: 610
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "별내열병합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 130.4,
    company: "별내에너지(주)",
    rn: 611
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "별내열병합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 45.7,
    company: "별내에너지(주)",
    rn: 612
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "별내열병합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 45.7,
    company: "별내에너지(주)",
    rn: 613
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 3CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 382,
    company: "GS EPS(주)",
    rn: 614
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합(대표)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 99.8,
    company: "한국지역난방공사 삼송지사",
    rn: 615
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합#1(송전)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 50,
    company: "한국지역난방공사 삼송지사",
    rn: 616
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합#2(송전)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 50,
    company: "한국지역난방공사 삼송지사",
    rn: 617
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합(구매)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국지역난방공사 삼송지사(구매)",
    rn: 618
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합#1(구매)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국지역난방공사 삼송지사(구매)",
    rn: 619
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합#2(구매)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국지역난방공사 삼송지사(구매)",
    rn: 620
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "오성복합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 769.83,
    company: "평택에너지서비스(주)",
    rn: 621
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "오성복합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 549,
    company: "평택에너지서비스(주)",
    rn: 622
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "오성복합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 220,
    company: "평택에너지서비스(주)",
    rn: 623
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합3GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국중부발전(주)",
    rn: 624
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합3CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국중부발전(주)",
    rn: 625
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "세종천연가스GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 334.208,
    company: "한국중부발전(주)",
    rn: 626
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "세종천연가스CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 530.441,
    company: "한국중부발전(주)",
    rn: 627
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "세종천연가스ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 196.233,
    company: "한국중부발전(주)",
    rn: 628
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "율촌복합2호기 GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 578,
    company: "엠피씨율촌전력(주)",
    rn: 629
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "율촌복합 2호기 CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 884.5,
    company: "씨지앤율촌전력(주)",
    rn: 630
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "율촌복합 GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 289,
    company: "씨지앤율촌전력(주)",
    rn: 631
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "율촌복합 GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 289,
    company: "씨지앤율촌전력(주)",
    rn: 632
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "율촌복합 2호기 ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 306.5,
    company: "씨지앤율촌전력(주)",
    rn: 633
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "세종천연가스GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 167.104,
    company: "한국중부발전(주)",
    rn: 634
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "세종천연가스GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 167.104,
    company: "한국중부발전(주)",
    rn: 635
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완열병합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 115.246,
    company: "수완에너지주식회사",
    rn: 636
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완열병합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 80.066,
    company: "수완에너지주식회사",
    rn: 637
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완열병합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 40.033,
    company: "수완에너지주식회사",
    rn: 638
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완열병합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 40.033,
    company: "수완에너지주식회사",
    rn: 639
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "CNCITY에너지송전#1",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 12.54,
    company: "씨엔씨티에너지 주식회사",
    rn: 640
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "CNCITY에너지송전#2",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 13.54,
    company: "씨엔씨티에너지 주식회사",
    rn: 641
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "CNCITY에너지수전#1",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "씨엔씨티에너지 주식회사(구매)",
    rn: 642
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "CNCITY에너지수전#2",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "씨엔씨티에너지 주식회사(구매)",
    rn: 643
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "양주열병합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 524.3,
    company: "(주)대륜발전",
    rn: 644
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "양주열병합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 165,
    company: "(주)대륜발전",
    rn: 645
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "양주열병합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 165,
    company: "(주)대륜발전",
    rn: 646
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양복합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "지에스파워(주)",
    rn: 647
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양복합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "지에스파워(주)",
    rn: 648
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양열병합2-1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 481.69,
    company: "지에스파워(주)",
    rn: 649
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양열병합2-1CC GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 320.72,
    company: "지에스파워(주)",
    rn: 650
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양열병합2-1CC ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 160.97,
    company: "지에스파워(주)",
    rn: 651
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양열병합2-2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 481.69,
    company: "지에스파워(주)",
    rn: 652
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양열병합2-2CC GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 320.72,
    company: "지에스파워(주)",
    rn: 653
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양열병합2-2CC ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 160.97,
    company: "지에스파워(주)",
    rn: 654
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 75,
    company: "지에스파워(주)",
    rn: 655
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 75,
    company: "지에스파워(주)",
    rn: 656
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 75,
    company: "지에스파워(주)",
    rn: 657
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 75,
    company: "지에스파워(주)",
    rn: 658
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안양복합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "지에스파워(주)",
    rn: 659
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수원환경사업소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "한국동서발전(주)",
    rn: 660
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동남태양광LG전자창원1공장발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.187,
    company: "동남태양광발전(주)",
    rn: 661
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동남태양광LG전자창원2공장발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.098,
    company: "동남태양광발전(주)",
    rn: 662
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동남태양광동양특수금속발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.148,
    company: "동남태양광발전(주)",
    rn: 663
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동남태양광동양산업발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.244,
    company: "동남태양광발전(주)",
    rn: 664
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동남태양광창원공동물류센터발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.728,
    company: "동남태양광발전(주)",
    rn: 665
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하정솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.3,
    company: "(주)하정솔라테크",
    rn: 666
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광성중태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.074,
    company: "케이에스솔라(주)",
    rn: 667
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대전태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "케이에스솔라(주)",
    rn: 668
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라마이스터원주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "케이에스솔라(주)",
    rn: 669
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에스솔라#14",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.382,
    company: "케이에스솔라(주)",
    rn: 670
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에스솔라#15",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "케이에스솔라(주)",
    rn: 671
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코원이에스햇빛발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "코원에너지서비스(주)",
    rn: 672
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "인천뉴파워#1",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.98,
    company: "(주)인천뉴파워",
    rn: 673
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원연안국산화풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "제주도청",
    rn: 674
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래보성제3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)미래에코",
    rn: 675
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "앤씨개발태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.395,
    company: "앤씨개발",
    rn: 676
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일산태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "(주)일산태양광제3발전소",
    rn: 677
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영지솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.75,
    company: "(유)영지솔라",
    rn: 678
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남송지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.75,
    company: "(주)길쌈에너지 해남송지일호점",
    rn: 679
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남송지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.75,
    company: "(주)길쌈에너지해남송지이호점",
    rn: 680
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가조은혜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "(주)가조은혜개발",
    rn: 681
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현풍솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.513,
    company: "현풍솔라(주)",
    rn: 682
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨엘태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주)제이씨엘태양광발전소",
    rn: 683
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청용태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "청용태양광발전소",
    rn: 684
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청석태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "청석태양광발전소",
    rn: 685
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 388.79,
    company: "한국남동발전(주)",
    rn: 686
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 233.274,
    company: "한국남동발전(주)",
    rn: 687
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 688
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 689
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 690
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 691
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 692
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 693
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#7",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 694
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합GT#8",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.758,
    company: "한국남동발전(주)",
    rn: 695
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼척그린파워#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1022,
    company: "한국남부발전(주)",
    rn: 696
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼척그린파워#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1022,
    company: "한국남부발전(주)",
    rn: 697
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 573.79,
    company: "한국남동발전(주)",
    rn: 698
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "분당복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 348.274,
    company: "한국남동발전(주)",
    rn: 699
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 400,
    company: "한국동서발전(주)",
    rn: 700
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 200,
    company: "한국동서발전(주)",
    rn: 701
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 702
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 703
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 704
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 705
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합GT#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 706
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합GT#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 707
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 600,
    company: "한국동서발전(주)",
    rn: 708
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "일산복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국동서발전(주)",
    rn: 709
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부천복합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "지에스파워(주)",
    rn: 710
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부천복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "지에스파워(주)",
    rn: 711
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부천복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "지에스파워(주)",
    rn: 712
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부천복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "지에스파워(주)",
    rn: 713
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부천복합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "지에스파워(주)",
    rn: 714
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부천복합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "지에스파워(주)",
    rn: 715
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 320,
    company: "한국서부발전(주)",
    rn: 716
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 160,
    company: "한국서부발전(주)",
    rn: 717
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 80,
    company: "한국서부발전(주)",
    rn: 718
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 80,
    company: "한국서부발전(주)",
    rn: 719
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 80,
    company: "한국서부발전(주)",
    rn: 720
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 80,
    company: "한국서부발전(주)",
    rn: 721
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 480,
    company: "한국서부발전(주)",
    rn: 722
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 572.6,
    company: "한국서부발전(주)",
    rn: 723
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 868.5,
    company: "한국서부발전(주)",
    rn: 724
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합2GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.3,
    company: "한국서부발전(주)",
    rn: 725
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합2GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.3,
    company: "한국서부발전(주)",
    rn: 726
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "평택복합2ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 295.9,
    company: "한국서부발전(주)",
    rn: 727
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 200,
    company: "한국동서발전(주)",
    rn: 728
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국동서발전(주)",
    rn: 729
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합3GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국동서발전(주)",
    rn: 730
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합4GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 573.2,
    company: "한국동서발전(주)",
    rn: 731
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합 ST#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 298.7,
    company: "한국동서발전(주)",
    rn: 732
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 733
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "한국동서발전(주)",
    rn: 734
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국동서발전(주)",
    rn: 735
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국동서발전(주)",
    rn: 736
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국동서발전(주)",
    rn: 737
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국동서발전(주)",
    rn: 738
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#7",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.6,
    company: "한국동서발전(주)",
    rn: 739
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합GT#8",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.6,
    company: "한국동서발전(주)",
    rn: 740
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합CC#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국동서발전(주)",
    rn: 741
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합CC#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국동서발전(주)",
    rn: 742
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합CC#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국동서발전(주)",
    rn: 743
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "울산복합CC#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 871.9,
    company: "한국동서발전(주)",
    rn: 744
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국중부발전(주)",
    rn: 745
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국중부발전(주)",
    rn: 746
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합3GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국중부발전(주)",
    rn: 747
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합4GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국중부발전(주)",
    rn: 748
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 749
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 750
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 751
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 752
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 753
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 754
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#7",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 755
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합GT#8",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 756
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서울복합#1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 369.173,
    company: "한국중부발전(주)",
    rn: 757
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서울복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 241.298,
    company: "한국중부발전(주)",
    rn: 758
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서울복합ST#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 127.875,
    company: "한국중부발전(주)",
    rn: 759
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서울복합#2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 369.173,
    company: "한국중부발전(주)",
    rn: 760
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서울복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 241.298,
    company: "한국중부발전(주)",
    rn: 761
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "서울복합ST#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 127.875,
    company: "한국중부발전(주)",
    rn: 762
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국중부발전(주)",
    rn: 763
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국중부발전(주)",
    rn: 764
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합3CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국중부발전(주)",
    rn: 765
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합4CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국중부발전(주)",
    rn: 766
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합#1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 767
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합#2ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 768
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합#3ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 769
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "보령복합#4ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국중부발전(주)",
    rn: 770
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "포스코에너지(주)",
    rn: 771
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 351,
    company: "포스코에너지(주)",
    rn: 772
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합3GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 351,
    company: "포스코에너지(주)",
    rn: 773
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합4GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 351,
    company: "포스코에너지(주)",
    rn: 774
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합5GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 366.4,
    company: "포스코에너지(주)",
    rn: 775
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합6GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 366.4,
    company: "포스코에너지(주)",
    rn: 776
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "포항부생복합#1",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 131.6,
    company: "포스코에너지(주)",
    rn: 777
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "포항부생복합#2",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 131.6,
    company: "포스코에너지(주)",
    rn: 778
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합3ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "포스코에너지(주)",
    rn: 779
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합4ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "포스코에너지(주)",
    rn: 780
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한화복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 781
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한화복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 782
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한화복합GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 783
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한화복합GT#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 784
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한화복합GT#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 785
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한화복합GT#6",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 786
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합GT#7",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 787
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합GT#8",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 788
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합GT#9",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 789
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합GT#10",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 790
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합GT#11",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 791
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합GT#12",
    cent: "중앙",
    fuel: "LNG",
    pcap: 100,
    company: "포스코에너지(주)",
    rn: 792
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합5GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 183.2,
    company: "포스코에너지(주)",
    rn: 793
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합5GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 183.2,
    company: "포스코에너지(주)",
    rn: 794
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합9GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 242.7,
    company: "포스코에너지(주)",
    rn: 795
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합8GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 242.7,
    company: "포스코에너지(주)",
    rn: 796
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합7GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 242.7,
    company: "포스코에너지(주)",
    rn: 797
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합9CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 382.4,
    company: "포스코에너지(주)",
    rn: 798
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합8CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 382.4,
    company: "포스코에너지(주)",
    rn: 799
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합7CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 382.4,
    company: "포스코에너지(주)",
    rn: 800
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "포스코에너지(주)",
    rn: 801
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "포스코에너지(주)",
    rn: 802
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합3CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "포스코에너지(주)",
    rn: 803
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합4CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "포스코에너지(주)",
    rn: 804
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합5CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 574.6,
    company: "포스코에너지(주)",
    rn: 805
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코에너지복합6CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 574.6,
    company: "포스코에너지(주)",
    rn: 806
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 321.92,
    company: "GS EPS(주)",
    rn: 807
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 161,
    company: "GS EPS(주)",
    rn: 808
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 161,
    company: "GS EPS(주)",
    rn: 809
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 178.83,
    company: "GS EPS(주)",
    rn: 810
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "하남열병합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 398.9,
    company: "나래에너지서비스 주식회사",
    rn: 811
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "하남열병합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 267.3,
    company: "나래에너지서비스 주식회사",
    rn: 812
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "하남열병합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 131.6,
    company: "나래에너지서비스 주식회사",
    rn: 813
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 500.75,
    company: "GS EPS(주)",
    rn: 814
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 4CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 846,
    company: "GS EPS(주)",
    rn: 815
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 4GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 539.6,
    company: "GS EPS(주)",
    rn: 816
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합4GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 269.8,
    company: "GS EPS(주)",
    rn: 817
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합4GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 269.8,
    company: "GS EPS(주)",
    rn: 818
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합4ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 306.4,
    company: "GS EPS(주)",
    rn: 819
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "목동열병합#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 21,
    company: "서울에너지공사",
    rn: 820
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "목동열병합#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 21,
    company: "서울에너지공사",
    rn: 821
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "노원열병합#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 37,
    company: "서울에너지공사",
    rn: 822
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 823
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 824
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합3GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 825
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합4GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국남부발전(주)",
    rn: 826
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 827
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합3GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 828
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합4GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150,
    company: "한국남부발전(주)",
    rn: 829
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 830
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 831
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합3CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 832
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산복합4CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 450,
    company: "한국남부발전(주)",
    rn: 833
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "안동복합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 417,
    company: "한국남부발전(주)",
    rn: 834
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "율촌복합 1호기 GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 327.6,
    company: "씨지앤율촌전력(주)",
    rn: 835
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "율촌복합 1호기 CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 525.5,
    company: "씨지앤율촌전력(주)",
    rn: 836
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천공항가스터빈",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 47,
    company: "인천공항에너지(주)",
    rn: 837
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "광양복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 306.2,
    company: "케이파워(주)",
    rn: 838
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "광양복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 494.6,
    company: "에스케이이엔에스(주)",
    rn: 839
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "광양복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 374,
    company: "케이파워(주)",
    rn: 840
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "광양복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 494.6,
    company: "에스케이이엔에스(주)",
    rn: 841
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대구그린파워 GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 274,
    company: "대구그린파워(주)",
    rn: 842
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대구그린파워 CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 415,
    company: "대구그린파워(주)",
    rn: 843
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대구그린파워 ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 141,
    company: "대구그린파워(주)",
    rn: 844
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영흥화력태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "한국남동발전(주)",
    rn: 845
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진천나들목태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "하이패스태양광(주)",
    rn: 846
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스지태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "에스에스에이치 태양광발전소",
    rn: 847
    },
    {
    area: "수도권",
    genFom: "가스터빈(단독)",
    genSrc: "신재생에너지",
    genNm: "지엔씨바이오(신)",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.9,
    company: "(주)지엔씨에너지",
    rn: 848
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경창산업3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "경창산업제3태양광발전소",
    rn: 849
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕평물류센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.858,
    company: "디에스에너지(주)",
    rn: 850
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천물류센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.245,
    company: "디에스에너지(주)",
    rn: 851
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원풍력#3",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 0.66,
    company: "제주대학교 산학협력단",
    rn: 852
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "담양석재태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "담양석재산업(주)",
    rn: 853
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나주3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.828,
    company: "에너지농장나주3호태양광발전소",
    rn: 854
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지월드태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 에너지월드",
    rn: 855
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.7,
    company: "(주)제주햇빛발전소",
    rn: 856
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국차량공업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.197,
    company: "한국차량공업(주)",
    rn: 857
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.066,
    company: "동화태양광발전소",
    rn: 858
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기헌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "기헌태양광발전소",
    rn: 859
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일맥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.066,
    company: "일맥태양광발전소",
    rn: 860
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서영아이티태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.902,
    company: "(주)서영아이티",
    rn: 861
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민솔라태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "민솔라에너지",
    rn: 862
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아산시스템즈태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.506,
    company: "오대양태양광발전(주)",
    rn: 863
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.507,
    company: "삼성산업(주)",
    rn: 864
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해광태양광발전소",
    rn: 865
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여명태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "여명태양광발전소",
    rn: 866
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해마루태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "해마루1호태양광발전소",
    rn: 867
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해마루태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "다원태양광",
    rn: 868
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성태양광(815)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.034,
    company: "도토리 태양광발전소",
    rn: 869
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여명2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "여명2태양광발전소",
    rn: 870
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "첨단태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.748,
    company: "현대이엔티(주)",
    rn: 871
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.064,
    company: "하영태양광발전소",
    rn: 872
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하은솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "하은솔라태양광발전소",
    rn: 873
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 300,
    company: "한국중부발전(주)",
    rn: 874
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 327.958,
    company: "한국중부발전(주)",
    rn: 875
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 182.081,
    company: "한국중부발전(주)",
    rn: 876
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 503.539,
    company: "한국중부발전(주)",
    rn: 877
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 508.908,
    company: "한국중부발전(주)",
    rn: 878
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 160.729,
    company: "한국중부발전(주)",
    rn: 879
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 160.729,
    company: "한국중부발전(주)",
    rn: 880
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합2GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 163.979,
    company: "한국중부발전(주)",
    rn: 881
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천복합2GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 163.979,
    company: "한국중부발전(주)",
    rn: 882
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "화성열병합 1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 321.6,
    company: "한국지역난방공사",
    rn: 883
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "화성열병합 1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 160.8,
    company: "한국지역난방공사",
    rn: 884
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "화성열병합 1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 160.8,
    company: "한국지역난방공사",
    rn: 885
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "화성열병합 CC#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 511.8,
    company: "한국지역난방공사",
    rn: 886
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "군산복합화력1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 718.4,
    company: "한국서부발전(주)",
    rn: 887
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "여천NCC복합#1",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 89.1,
    company: "여천NCC(주)",
    rn: 888
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "군산복합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 251.8,
    company: "한국서부발전(주)",
    rn: 889
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "여천NCC복합#1(구매)",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 119,
    company: "여천NCC(주)(구매)",
    rn: 890
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "군산복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 233.3,
    company: "한국서부발전(주)",
    rn: 891
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "군산복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 233.3,
    company: "한국서부발전(주)",
    rn: 892
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 349,
    company: "GS EPS(주)",
    rn: 893
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 2GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 174.5,
    company: "GS EPS(주)",
    rn: 894
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 2GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 174.5,
    company: "GS EPS(주)",
    rn: 895
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 533,
    company: "GS EPS(주)",
    rn: 896
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "논현열병합",
    cent: "중앙",
    fuel: "LNG",
    pcap: 24,
    company: "(주)미래엔인천에너지",
    rn: 897
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "명품오산열병합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 436.1,
    company: "디에스파워(주)",
    rn: 898
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "명품오산열병합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 289.3,
    company: "디에스파워(주)",
    rn: 899
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "명품오산열병합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 146.8,
    company: "디에스파워(주)",
    rn: 900
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천공항복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 94,
    company: "인천공항에너지(주)",
    rn: 901
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "송도열병합발전1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 52.4,
    company: "인천종합에너지(주)",
    rn: 902
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "송도열병합발전1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 187.3,
    company: "인천종합에너지(주)",
    rn: 903
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천공항복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 127,
    company: "인천공항에너지(주)",
    rn: 904
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "영월복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 848,
    company: "한국남부발전(주)",
    rn: 905
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천공항복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 47,
    company: "인천공항에너지(주)",
    rn: 906
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천공항복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 47,
    company: "인천공항에너지(주)",
    rn: 907
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "영월복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 183,
    company: "한국남부발전(주)",
    rn: 908
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "영월복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 183,
    company: "한국남부발전(주)",
    rn: 909
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "영월복합1GT#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 183,
    company: "한국남부발전(주)",
    rn: 910
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "판교열병합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 77.863,
    company: "한국지역난방공사",
    rn: 911
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "광주전남열병합발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 21.99,
    company: "한국지역난방공사",
    rn: 912
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천천연복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 874.2,
    company: "포천민자발전(주)",
    rn: 913
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천천연복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 575.4,
    company: "포천민자발전(주)",
    rn: 914
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천천연복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 287.7,
    company: "포천민자발전(주)",
    rn: 915
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천천연복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 287.7,
    company: "포천민자발전(주)",
    rn: 916
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포천천연복합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 298.8,
    company: "포천민자발전(주)",
    rn: 917
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "판교열병합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 146.314,
    company: "한국지역난방공사",
    rn: 918
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "아산배방열병합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 101.7,
    company: "한국토지주택공사 아산에너지사업단",
    rn: 919
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "아산배방열병합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 79.3,
    company: "한국토지주택공사 아산에너지사업단",
    rn: 920
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "아산배방열병합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 22.4,
    company: "한국토지주택공사 아산에너지사업단",
    rn: 921
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주열병합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 326.8,
    company: "한국지역난방공사",
    rn: 922
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주열병합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 515.5,
    company: "한국지역난방공사",
    rn: 923
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주열병합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 163.4,
    company: "한국지역난방공사",
    rn: 924
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주열병합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 163.4,
    company: "한국지역난방공사",
    rn: 925
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "신정열병합",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 6,
    company: "서울에너지공사",
    rn: 926
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "아산배방열병합GT1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 39.65,
    company: "한국토지주택공사 아산에너지사업단",
    rn: 927
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "아산배방열병합(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국토지주택공사 아산에너지사업단(구매)",
    rn: 928
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "아산배방열병합GT2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 39.65,
    company: "한국토지주택공사 아산에너지사업단",
    rn: 929
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "아산배방열병합(구매_예비)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국토지주택공사 아산에너지사업단(구매)",
    rn: 930
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "춘천열병합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 431.2,
    company: "춘천에너지(주)",
    rn: 931
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "춘천열병합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 287.8,
    company: "춘천에너지(주)",
    rn: 932
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "영남파워1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 442.8,
    company: "코스포영남파워 주식회사",
    rn: 933
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "영남파워1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 292,
    company: "코스포영남파워 주식회사",
    rn: 934
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "위례열병합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 412.6,
    company: "나래에너지서비스 주식회사",
    rn: 935
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "위례열병합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 270.4,
    company: "나래에너지서비스 주식회사",
    rn: 936
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "포천그린에너지",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 169.9,
    company: "주식회사 지에스포천그린에너지",
    rn: 937
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 847.6,
    company: "파주에너지서비스 주식회사",
    rn: 938
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 551,
    company: "파주에너지서비스 주식회사",
    rn: 939
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 275.5,
    company: "파주에너지서비스 주식회사",
    rn: 940
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 275.5,
    company: "파주에너지서비스 주식회사",
    rn: 941
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 296.6,
    company: "파주에너지서비스 주식회사",
    rn: 942
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 551,
    company: "파주에너지서비스 주식회사",
    rn: 943
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합2GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 275.5,
    company: "파주에너지서비스 주식회사",
    rn: 944
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합2GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 275.5,
    company: "파주에너지서비스 주식회사",
    rn: 945
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합2ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 296.6,
    company: "파주에너지서비스 주식회사",
    rn: 946
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "파주문산복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 847.6,
    company: "파주에너지서비스 주식회사",
    rn: 947
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동탄열병합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 378.38,
    company: "한국지역난방공사",
    rn: 948
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동탄열병합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 246.47,
    company: "한국지역난방공사",
    rn: 949
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동탄열병합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 131.91,
    company: "한국지역난방공사",
    rn: 950
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동탄열병합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 378.38,
    company: "한국지역난방공사",
    rn: 951
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동탄열병합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 246.47,
    company: "한국지역난방공사",
    rn: 952
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동탄열병합2ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 131.91,
    company: "한국지역난방공사",
    rn: 953
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "광교열병합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 144.788,
    company: "한국지역난방공사",
    rn: 954
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 858.4,
    company: "동두천드림파워(주)",
    rn: 955
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 858.4,
    company: "동두천드림파워(주)",
    rn: 956
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 572.6,
    company: "동두천드림파워(주)",
    rn: 957
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 572.6,
    company: "동두천드림파워(주)",
    rn: 958
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합1GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.3,
    company: "동두천드림파워(주)",
    rn: 959
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합1GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.3,
    company: "동두천드림파워(주)",
    rn: 960
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합2GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.3,
    company: "동두천드림파워(주)",
    rn: 961
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합2GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 286.3,
    company: "동두천드림파워(주)",
    rn: 962
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 285.8,
    company: "동두천드림파워(주)",
    rn: 963
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "동두천복합2ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 285.8,
    company: "동두천드림파워(주)",
    rn: 964
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "어암태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "어암태양광발전소",
    rn: 965
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)썬에너팜태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "유한회사 썬에너팜",
    rn: 966
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "IPA북항햇빛태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "인천항만공사",
    rn: 967
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "골든태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "골든태양광",
    rn: 968
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사천태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)사천태양광발전소",
    rn: 969
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신기태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)신기태양광발전소",
    rn: 970
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "서울#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 137.5,
    company: "한국중부발전(주)",
    rn: 971
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "서울#5",
    cent: "중앙",
    fuel: "LNG",
    pcap: 250,
    company: "한국중부발전(주)",
    rn: 972
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유림이피씨해상태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 유림이피씨",
    rn: 973
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "SH태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "SH태양광발전소",
    rn: 974
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동아태양광에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "동아태양광에너지주식회사",
    rn: 975
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "인천#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 250,
    company: "한국중부발전(주)",
    rn: 976
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "인천#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 250,
    company: "한국중부발전(주)",
    rn: 977
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "인천#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 325,
    company: "한국중부발전(주)",
    rn: 978
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "인천#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 325,
    company: "한국중부발전(주)",
    rn: 979
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신평택복합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 863.3,
    company: "신평택발전(주)",
    rn: 980
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신평택복합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 580.4,
    company: "신평택발전(주)",
    rn: 981
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신평택복합#1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 290.2,
    company: "신평택발전(주)",
    rn: 982
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신평택복합#2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 290.2,
    company: "신평택발전(주)",
    rn: 983
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "신평택복합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 282.9,
    company: "신평택발전(주)",
    rn: 984
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한천2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "골드에스비건설 주식회사",
    rn: 985
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한천3호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "골드에스비건설 주식회사",
    rn: 986
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금산태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 7,
    company: "(주)금산태양광발전소",
    rn: 987
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대척태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 6,
    company: "(주)대척태양광발전소",
    rn: 988
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "섬진태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "섬진태양광발전소",
    rn: 989
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성심태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "성심태양광발전소",
    rn: 990
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "혜성태양광aESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "혜성태양광발전소",
    rn: 991
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림태양광bESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "드림태양광발전소",
    rn: 992
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "두남태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "두남태양광발전소",
    rn: 993
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "목곡2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "목곡2호태양광발전소",
    rn: 994
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해피2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해피2호 태양광 발전소",
    rn: 995
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해피태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "메가나인 주식회사",
    rn: 996
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가나태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "가나태양광발전소",
    rn: 997
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용운태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "용운태양광발전소",
    rn: 998
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "용태양광발전소",
    rn: 999
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "승경2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "승경태양광발전소",
    rn: 1000
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화남제2태양광_ESS_2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 화남이",
    rn: 1001
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화남제1태양광ESS_2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 화남일",
    rn: 1002
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가곡솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)가곡솔라",
    rn: 1003
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아폴로에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)아폴로에너지",
    rn: 1004
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "처용솔라2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "처용솔라 주식회사",
    rn: 1005
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "적암1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "하이웨이솔라(주)",
    rn: 1006
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "적암2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "하이웨이솔라(주)",
    rn: 1007
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "적암3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "하이웨이솔라(주)",
    rn: 1008
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호남태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "하이웨이솔라(주)",
    rn: 1009
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조현준1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "조현준1호 태양광발전소",
    rn: 1010
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장판11호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 제이에스에너지",
    rn: 1011
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제오태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "호황에너지 주식회사",
    rn: 1012
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 광덕 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.3,
    company: "주식회사 광덕",
    rn: 1013
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장산1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "하이웨이솔라(주)",
    rn: 1014
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장산2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "하이웨이솔라(주)",
    rn: 1015
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정조 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "정조 태양광발전소",
    rn: 1016
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보령선에너지1호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 보령선에너지",
    rn: 1017
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호남1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "호남1호태양광발전소",
    rn: 1018
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호남2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "호남2호태양광발전소",
    rn: 1019
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "태지태양광발전소",
    rn: 1020
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호재2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "호재2호태양광발전소",
    rn: 1021
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호재1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "호재1호태양광발전소",
    rn: 1022
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의성율현태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "주식회사 대정물산",
    rn: 1023
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "낙안솔라10호 태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "낙안솔라10호 태양광발전소",
    rn: 1024
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "양주솔라3호 태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "양주솔라3호 태양광발전소",
    rn: 1025
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쏠17호 태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "쏠17호 태양광발전소",
    rn: 1026
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "썬플라워태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 썬플라워",
    rn: 1027
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진현태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 진현",
    rn: 1028
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백마태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(유)백마",
    rn: 1029
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태평태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "태평태양광발전소",
    rn: 1030
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의성발전태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "의성발전 주식회사",
    rn: 1031
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금광태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 제이엠피일렉트릭",
    rn: 1032
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유천태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유천태양광발전소",
    rn: 1033
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우포태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "우포태양광발전소",
    rn: 1034
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화남제3태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 화남삼",
    rn: 1035
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지산3호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지산3호 태양광발전소",
    rn: 1036
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용정태양광1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "용정태양광발전소1호",
    rn: 1037
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "류천에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "류천에너지 주식회사",
    rn: 1038
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송암1태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "송암1태양광",
    rn: 1039
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송암2태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 송암에너지",
    rn: 1040
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용정태양광2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "용정태양광발전소2호",
    rn: 1041
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용정태양광4호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "용정태양광발전소4호",
    rn: 1042
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한들1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한들1호 태양광발전소",
    rn: 1043
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무안그린에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)무안그린에너지",
    rn: 1044
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "팔마ECO 태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "순천시청",
    rn: 1045
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그린솔라에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 그린솔라에너지",
    rn: 1046
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "KH에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "KH에너지1호발전소",
    rn: 1047
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희망태양광b ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "희망 태양광발전소",
    rn: 1048
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "햇살태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 신자연쏠라",
    rn: 1049
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "여수엑스포태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국중부발전(주)",
    rn: 1050
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신보령화력태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국중부발전(주)",
    rn: 1051
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보령화력수상태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국중부발전(주)",
    rn: 1052
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "네모1태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)네모8태양광발전소",
    rn: 1053
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "네모2태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)네모8태양광발전소",
    rn: 1054
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "네모4태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)네모8태양광발전소",
    rn: 1055
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "네모5태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(유)네모8태양광발전소",
    rn: 1056
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영일 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "영일엔지니어링 주식회사",
    rn: 1057
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청소 세기1호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "청소 세기1호 태양광발전소",
    rn: 1058
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지산2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지산2호 태양광발전소",
    rn: 1059
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "검디태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "검디태양발전소1호",
    rn: 1060
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신우솔라_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "주식회사 신우솔라",
    rn: 1061
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세창1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "세창물산 주식회사",
    rn: 1062
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제주대태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국중부발전(주)",
    rn: 1063
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제주화력 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국중부발전(주)",
    rn: 1064
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정우태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "정우태양광발전소",
    rn: 1065
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼항태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "하이웨이솔라(주)",
    rn: 1066
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세종태양광1호기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "한국중부발전(주)",
    rn: 1067
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태민태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "태민태양광발전소",
    rn: 1068
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소리솔라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)소리솔라",
    rn: 1069
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보은1호 연계형 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "제이비 주식회사(발전)",
    rn: 1070
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시온유 태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.3,
    company: "시온유 태양광발전소",
    rn: 1071
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우리발전2호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "우리발전",
    rn: 1072
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이진혁1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "이진혁1호태양광발전소",
    rn: 1073
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔텍솔라2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)엔텍솔라",
    rn: 1074
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔텍솔라4호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)엔텍솔라",
    rn: 1075
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세이브ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "세이브에너지태양광발전소",
    rn: 1076
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보광태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 미래솔라",
    rn: 1077
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "내담에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유한회사 내담에너지",
    rn: 1078
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다산태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 다산태양광",
    rn: 1079
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "육일태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "육일태양광발전소",
    rn: 1080
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무지개 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 무지개태양광발전소",
    rn: 1081
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태승가애ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "주식회사 태승가애",
    rn: 1082
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "첨단발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "첨단발전소",
    rn: 1083
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "리차징팜 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "리차징팜 태양광발전소",
    rn: 1084
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용곡2태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 미원",
    rn: 1085
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보담에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 보담에너지",
    rn: 1086
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼읍태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 삼읍태양광발전소",
    rn: 1087
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "준상태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "준상태양광발전소",
    rn: 1088
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용곡1태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "유한회사 네모1태양광발전소",
    rn: 1089
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해마루3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "유한회사해마루",
    rn: 1090
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "주광 태양광발전소",
    rn: 1091
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세이브3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 엠디이엔지",
    rn: 1092
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화성ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "화성태양광발전소",
    rn: 1093
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "나리ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "나리 태양광발전소",
    rn: 1094
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솜티ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "솜티태양광발전소",
    rn: 1095
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용곡3태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "세이프솔라 유한회사",
    rn: 1096
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용곡4태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 미래빛",
    rn: 1097
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "디원2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주) 디원",
    rn: 1098
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림1호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "드림1호 태양광발전소",
    rn: 1099
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "갈화태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "갈화태양광발전소",
    rn: 1100
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "든솔에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 든솔에너지",
    rn: 1101
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백운개발5 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "백운개발5 태양광발전소",
    rn: 1102
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금란 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "금란 태양광발전소",
    rn: 1103
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일심ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "일심태양광발전소",
    rn: 1104
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가은태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "가은 태양광발전소",
    rn: 1105
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "경 태양광발전소",
    rn: 1106
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미르 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유한회사 미르태양광",
    rn: 1107
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서진13호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "서진13호 태양광발전소",
    rn: 1108
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "준서태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 영도쏠라",
    rn: 1109
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신평태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "제이케이머트리얼즈(주)",
    rn: 1110
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거창온빛태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "거창온빛 태양광발전소",
    rn: 1111
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한민2 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "한민2태양광발전소",
    rn: 1112
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한민3 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "한민3태양광발전소",
    rn: 1113
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남성주태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 정종솔라",
    rn: 1114
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크여주1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "해테크여주1호",
    rn: 1115
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크여주4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "해테크여주4호",
    rn: 1116
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정종솔라2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 정종솔라",
    rn: 1117
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "월막1호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)달천유니온솔라",
    rn: 1118
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가야4호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)달천유니온솔라",
    rn: 1119
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정종솔라3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 정종솔라",
    rn: 1120
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "벽진태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 벽진태양광발전소",
    rn: 1121
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "모든태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "모든태양광발전소",
    rn: 1122
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세현에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "세현에너지주식회사",
    rn: 1123
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대한태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "대한태양광발전소주식회사",
    rn: 1124
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광찬태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)베스컨에스",
    rn: 1125
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도순1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.04,
    company: "도순 태양광발전소",
    rn: 1126
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해찬태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)베스컨썬",
    rn: 1127
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유제2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 제이더블유제2호발전소",
    rn: 1128
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "별량그린에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "별량그린 태양광발전소",
    rn: 1129
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화암태양광발전소 10호기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "화암태양광발전소 제10호기",
    rn: 1130
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태원 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "태원 태양광발전소",
    rn: 1131
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청산에너지태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 청산에너지",
    rn: 1132
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "농협회사법인부경 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인부경주식회사",
    rn: 1133
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정선솔라팜태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 정선솔라팜",
    rn: 1134
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의령 고니 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "고니솔라파워(주)",
    rn: 1135
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의령 까치 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "까치(주)",
    rn: 1136
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의령 참새 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "참새(주)",
    rn: 1137
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한들2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한들2호 태양광발전소",
    rn: 1138
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강릉제일솔라4호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "강릉제일솔라 4호",
    rn: 1139
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "북산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "북산태양광발전소",
    rn: 1140
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유제3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 제이더블유제3호발전소",
    rn: 1141
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대신1호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 대신1호에너지",
    rn: 1142
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대신2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 대신2호에너지",
    rn: 1143
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조은태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "조은태양광발전소",
    rn: 1144
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대신이엔텍 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "대신이엔텍 주식회사",
    rn: 1145
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무한에너지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 무한에너지",
    rn: 1146
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이제이에너지 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "(주)제이제이에너지",
    rn: 1147
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이파워 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "제이파워 주식회사",
    rn: 1148
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엠에스솔라 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "엠에스솔라 주식회사",
    rn: 1149
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진말태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "진말태양광발전주식회사",
    rn: 1150
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영임태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "영임태양광 주식회사",
    rn: 1151
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의령솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "의령솔라 주식회사",
    rn: 1152
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "석종리태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "석종리 태양광발전소",
    rn: 1153
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "슬기태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "슬기태양광발전소",
    rn: 1154
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해기태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해기태양광발전소",
    rn: 1155
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인희태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "인희태양광발전소",
    rn: 1156
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정우엔지니어링ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "(주)정우엔지니어링",
    rn: 1157
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남전1호태양광ESS1호",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 남전에너지",
    rn: 1158
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남전1호태양광ESS2호",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 남전에너지",
    rn: 1159
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남전2호태양광ESS1호",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 개령에너지",
    rn: 1160
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남전2호태양광ESS2호",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 개령에너지",
    rn: 1161
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화산1태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "화산1태양광발전소",
    rn: 1162
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한교태양광ESS-1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)한교태양광발전소",
    rn: 1163
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한교태양광ESS-2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)한교태양광발전소",
    rn: 1164
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이한산업제일차 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 이한산업제일차",
    rn: 1165
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이한산업 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 이한산업",
    rn: 1166
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송정제일태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "송정제일태양광발전소",
    rn: 1167
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세종태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "세종태양광발전소",
    rn: 1168
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "온누리태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "온누리태양광발전소",
    rn: 1169
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영화태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영화태양광발전소",
    rn: 1170
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "스마일태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "스마일 태양광발전소",
    rn: 1171
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "단비신장ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 에스아이",
    rn: 1172
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송암2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 신안소순태양광발전소",
    rn: 1173
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송암1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 신안복지태양광발전소",
    rn: 1174
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희창태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "희창태양광발전소",
    rn: 1175
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "씨그마렌텍2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "씨그마렌텍2영월태양광 주식회사",
    rn: 1176
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "[서인천]발전부지내 태양광 1단계ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국서부발전(주)",
    rn: 1177
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명동정수장 침전지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "김해시",
    rn: 1178
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼계정수장 침전지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "김해시",
    rn: 1179
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원골드2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "원골드2호태양광발전소",
    rn: 1180
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원골드1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "원골드1호태양광발전소",
    rn: 1181
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용덕1호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "주식회사 용덕발전소",
    rn: 1182
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "부안상영ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "부안상영태양광발전소",
    rn: 1183
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)슬아에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(유)슬아에너지",
    rn: 1184
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)한울에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)한울에너지",
    rn: 1185
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진천삼채나라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "진천삼채나라영농조합법인",
    rn: 1186
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우보 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "우보태양광발전소",
    rn: 1187
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태두 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "태두태양광발전소",
    rn: 1188
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지후 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "오솔레 태양광발전소",
    rn: 1189
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "정원 태양광발전소",
    rn: 1190
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백현태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)자혜태양광",
    rn: 1191
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "yd솔라에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "yd솔라에너지",
    rn: 1192
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스케이디앤디 가시리풍력발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 9,
    company: "에스케이디앤디(주)가시리풍력발전소",
    rn: 1193
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "복용쏠라 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "복용쏠라",
    rn: 1194
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화서 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "화서태양광발전소",
    rn: 1195
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성원2태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "성원태양광발전소",
    rn: 1196
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성원 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "성원태양발전소",
    rn: 1197
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "네모12태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(유)네모8태양광발전소",
    rn: 1198
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아찬리태양광1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "아찬리 태양광발전소 1호",
    rn: 1199
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "군산복합2단계태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "한국서부발전(주)",
    rn: 1200
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "[평택]발전부지내 1단계 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국서부발전(주)",
    rn: 1201
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태안_ESS#2_#1수상",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국서부발전(주)",
    rn: 1202
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태안_ESS#2_1단계_건축물",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국서부발전(주)",
    rn: 1203
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태안_ESS#2_2단계_건축물",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국서부발전(주)",
    rn: 1204
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태안_ESS#2_2단계_#1옥내저탄장",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국서부발전(주)",
    rn: 1205
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대광1호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)자혜태양광",
    rn: 1206
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "예산증곡태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "예산증곡태양광발전소",
    rn: 1207
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영승가애 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "주식회사 영승가애",
    rn: 1208
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)봉남리솔라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.999,
    company: "유한회사 봉남리솔라",
    rn: 1209
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)라이프에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 남포에너지",
    rn: 1210
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남도태양광제1기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "남도태양광제1발전소 주식회사",
    rn: 1211
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)청광6호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 청광6태양광발전소",
    rn: 1212
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)늘솔길에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "유한회사 늘솔길에너지",
    rn: 1213
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남도태양광제2기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "남도태양광제2발전소 주식회사",
    rn: 1214
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이씨씨 여주태양광 제3발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.841,
    company: "주식회사 케이씨씨글라스 여주공장",
    rn: 1215
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "매일태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "매일 태양광발전소",
    rn: 1216
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하이에스2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "하이에스2호 태양광발전소",
    rn: 1217
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하이에스1호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "하이에스1호 태양광발전소",
    rn: 1218
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "균남1호태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "균남1호 태양광발전소",
    rn: 1219
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "균남2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "균남2호 태양광발전소",
    rn: 1220
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "월성에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "(주)월성에너지",
    rn: 1221
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "상용 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "상용태양광발전소",
    rn: 1222
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서산 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 52,
    company: "현대에코에너지주식회사",
    rn: 1223
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)예스에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)예스에너지",
    rn: 1224
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스엠에너지태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)에스엠에너지",
    rn: 1225
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신세계태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)신세계태양광발전소",
    rn: 1226
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)엑스파워태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)엑스파워",
    rn: 1227
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "월림산태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 광일에너지",
    rn: 1228
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)성우에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 성우에너지",
    rn: 1229
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한국서부발전(주)태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국서부발전(주)",
    rn: 1230
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "푸르미농산 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "푸르미농산 태양광발전소",
    rn: 1231
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)청운 태양광발전소 1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "주식회사 청운",
    rn: 1232
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)너울에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 너울에너지",
    rn: 1233
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문길태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "문길태양광발전소",
    rn: 1234
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "상현 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "상현 태양광발전소",
    rn: 1235
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "민승 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "민승 태양광발전소",
    rn: 1236
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천광 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "천광태양광발전소",
    rn: 1237
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)일조 솔라플랜트 1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 일조솔라플랜트",
    rn: 1238
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)금성광에너지태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)금성광에너지",
    rn: 1239
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남천태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "남천태양광발전소",
    rn: 1240
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동건태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동건태양광발전소",
    rn: 1241
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은송예빈태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "은송예빈태양광발전소",
    rn: 1242
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대창태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 대창발전소",
    rn: 1243
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인종2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 인종솔라",
    rn: 1244
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "음성태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "음성장수태양광발전소",
    rn: 1245
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신세계2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.3,
    company: "신세계2호 태양광발전소",
    rn: 1246
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신세계1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "신세계1호 태양광발전소",
    rn: 1247
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그린에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "그린에너지주식회사",
    rn: 1248
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장미태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "장미태양광발전소",
    rn: 1249
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "훈민2태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "훈민2태양광발전소",
    rn: 1250
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이디에스 주식회사(1호) ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.21,
    company: "이디에스 주식회사 나주지점",
    rn: 1251
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명환 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "명환 태양광발전소",
    rn: 1252
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔아이씨 안동태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 엔아이씨 코리아",
    rn: 1253
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장신태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)금성광에너지",
    rn: 1254
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕은1호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "솔라플러스 주식회사",
    rn: 1255
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔아이씨 안동태양광발전소 ESS 2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 엔아이씨 코리아",
    rn: 1256
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엠엘에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "엠엘에너지",
    rn: 1257
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "준성원에너지ess",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "준성원에너지태양광발전소",
    rn: 1258
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)옥종에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.3,
    company: "주식회사 옥종에너지",
    rn: 1259
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "반야ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "반야 태양광발전소",
    rn: 1260
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태원ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "태원 태양광발전소",
    rn: 1261
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "라온하제 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.6,
    company: "라온하제 태양광발전소",
    rn: 1262
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원화 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 원화태양광발전소",
    rn: 1263
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "KT무릉송신소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 케이티",
    rn: 1264
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "야광태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "야광태양광발전소",
    rn: 1265
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)청향에너지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "유한회사 청향에너지",
    rn: 1266
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "씨그마렌텍3원주태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "씨그마렌텍3원주태양광 주식회사",
    rn: 1267
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영동화력태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국남동발전(주)",
    rn: 1268
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영파워 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 영파워",
    rn: 1269
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "DSP6호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 디에스피에너지3호",
    rn: 1270
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명원정읍 태양광발전소(주) ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "명원정읍태양광발전소 주식회사",
    rn: 1271
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "매포태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "메가솔라하나(주)",
    rn: 1272
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서리태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "서리태양광발전소",
    rn: 1273
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성용태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "성용 태양광 발전소",
    rn: 1274
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "후암1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "아리산전",
    rn: 1275
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래-2 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "미래-2 태양광발전소",
    rn: 1276
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "OCI스페셜티 태양광ESS#1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "오씨아이파워주식회사",
    rn: 1277
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "첼시4호태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 첼시4호태양광발전소",
    rn: 1278
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)송전태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 송전태양광",
    rn: 1279
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엠에이2호에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "엠에이2호에너지",
    rn: 1280
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성운2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)성운",
    rn: 1281
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "맥스에너지월성 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 맥스에너지월성",
    rn: 1282
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "맥스에너지안정 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 맥스에너지안정",
    rn: 1283
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래농산 4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인 미래농산주식회사",
    rn: 1284
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "내척 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "내척태양광발전소",
    rn: 1285
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쏠8호태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "쏠8호 태양광발전소",
    rn: 1286
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쏠9호태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "쏠9호 태양광발전소",
    rn: 1287
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "디원3 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주) 디원",
    rn: 1288
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대화태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "대화 태양광발전소",
    rn: 1289
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "BJ SSP 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "비제이(BJ) SSP 태양광발전소",
    rn: 1290
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스솔라 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스솔라",
    rn: 1291
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "낙안솔라9호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "낙안솔라9호 태양광발전소",
    rn: 1292
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)라이프에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 라이프에너지",
    rn: 1293
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "뉴신의에너지 일호점 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)뉴신의에너지",
    rn: 1294
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "뉴신의에너지 이호점 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)뉴신의에너지",
    rn: 1295
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "뉴신의에너지 4호점 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)뉴신의에너지",
    rn: 1296
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무량 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 무량에너지",
    rn: 1297
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "건천 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 윌라스건천",
    rn: 1298
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성광태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)성광태양광발전소",
    rn: 1299
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "혜성태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "혜성태양광발전소",
    rn: 1300
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미암우리 6호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 미암우리6호 태양광발전소",
    rn: 1301
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "HD태양광발전소3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 현대에너지",
    rn: 1302
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "푸른태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "푸른 태양광발전소",
    rn: 1303
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스에스태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "에스에스태양광발전소",
    rn: 1304
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동고지솔라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 동고지솔라",
    rn: 1305
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동양이엔지(주)태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "동양이엔지 주식회사",
    rn: 1306
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정선태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "(주)정선 태양광발전소",
    rn: 1307
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "굿파워예산3호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "유한회사 굿파워",
    rn: 1308
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "방제에너텍태양광ESS1호",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 방제에너텍",
    rn: 1309
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "길성태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "길성 태양광발전소",
    rn: 1310
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)대연1태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)대연1태양광발전소",
    rn: 1311
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)대연2태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)대연2태양광발전소",
    rn: 1312
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "중현태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "중현태양광발전소",
    rn: 1313
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "길현태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "길현태양광발전소",
    rn: 1314
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "와이엔비ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유한회사 와이엔비",
    rn: 1315
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "기산솔라4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "기산솔라4호",
    rn: 1316
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "써니에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "써니에너지태양광발전소",
    rn: 1317
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청리에너지-2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)솔라시너지햇살3호",
    rn: 1318
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청리에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)솔라시너지햇살4호",
    rn: 1319
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고도농원 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "농업회사법인 고도농원 주식회사",
    rn: 1320
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "기산솔라3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "기산솔라3호",
    rn: 1321
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엘비에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "엘비에너지",
    rn: 1322
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "개화햇빛 ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 개화햇빛",
    rn: 1323
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "개화햇빛 ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 개화햇빛",
    rn: 1324
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지유태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "지유태양광발전소",
    rn: 1325
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솔라시도태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 78,
    company: "솔라시도 태양광발전 주식회사",
    rn: 1326
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이룡2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 인종솔라",
    rn: 1327
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "기산솔라1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)삼흥홍성태양광발전소 (기산솔라1호)",
    rn: 1328
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성주솔라팜 ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 티에스에너지15호",
    rn: 1329
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성주솔라팜 ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 티에스에너지15호",
    rn: 1330
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성진 태양광 발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "성진태양광발전소",
    rn: 1331
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티원 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "티원 태양광발전소",
    rn: 1332
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영운 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영운태양광발전소",
    rn: 1333
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "구슬3 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "구슬3 태양광발전소",
    rn: 1334
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "기산솔라2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "주식회사 에이탑에너지",
    rn: 1335
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)경호에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)경호에너지 태양광발전소",
    rn: 1336
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성원에너지(주) ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 8,
    company: "(주)성원에너지",
    rn: 1337
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JCG솔라 2호 태양광발전기(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "JCG솔라",
    rn: 1338
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태연3호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "유한회사 태연",
    rn: 1339
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "구하기솔라에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 구하기솔라에너지",
    rn: 1340
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "산강에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 산강에너지",
    rn: 1341
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아마에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 아마에너지",
    rn: 1342
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "녹사태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "녹사태양광발전 주식회사",
    rn: 1343
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 에스이태양광1호 발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "주식회사 에스이태양광",
    rn: 1344
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 에스이태양광2호 발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "주식회사 에스이태양광",
    rn: 1345
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 에스이태양광3호 발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "주식회사 에스이태양광",
    rn: 1346
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼포태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "삼포태양광발전소",
    rn: 1347
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영정이엔지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "영정이엔지 태양광발전소",
    rn: 1348
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일신1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "일신1호태양광발전소 주식회사",
    rn: 1349
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지석리태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "우리환경에너지(주)",
    rn: 1350
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "점리태양광발전소이호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "에너지나눔주식회사",
    rn: 1351
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "점리태양광발전소삼호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "에코솔라파워 주식회사",
    rn: 1352
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금제이노텍태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 금제이노텍",
    rn: 1353
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "입석태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "입석태양광발전 주식회사",
    rn: 1354
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송림태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "송림태양광발전 주식회사",
    rn: 1355
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광염에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 광염에너지",
    rn: 1356
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우평태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "우평태양광발전 주식회사",
    rn: 1357
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광백에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 광백에너지",
    rn: 1358
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "교촌태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "교촌태양광발전 주식회사",
    rn: 1359
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무령태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "무령태양광발전 주식회사",
    rn: 1360
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "계송태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "계송태양광발전 주식회사",
    rn: 1361
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래에너지영광태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 미래에너지영광",
    rn: 1362
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하승서승에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 하승서승에너지",
    rn: 1363
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "예아솔라에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 예아솔라에너지",
    rn: 1364
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광백태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 광백태양광",
    rn: 1365
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "코로나에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 코로나에너지",
    rn: 1366
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "바다태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 바다태양광",
    rn: 1367
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "마스터즈광백태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 마스터즈광백",
    rn: 1368
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비에스에너지 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "비에스에너지(유)",
    rn: 1369
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "와이엠에너지 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 와이엠에너지",
    rn: 1370
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "덕호태양광발전 주식회사",
    rn: 1371
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강토에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 강토에너지",
    rn: 1372
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "라미에너지 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 라미에너지",
    rn: 1373
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송하2태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "송하2 태양광발전소유한회사",
    rn: 1374
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신하태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "신하태양광발전 주식회사",
    rn: 1375
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천일에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 천일에너지",
    rn: 1376
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이와이에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "제이와이에너지 주식회사",
    rn: 1377
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이제이케이에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "제이제이케이에너지 주식회사",
    rn: 1378
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도영태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 도영태양광",
    rn: 1379
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신월태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "신월태양광발전 주식회사",
    rn: 1380
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "단주태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "단주태양광발전 주식회사",
    rn: 1381
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지승태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 지승태양광",
    rn: 1382
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스와이에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "에스와이에너지 주식회사",
    rn: 1383
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거창태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "거창태양광발전소",
    rn: 1384
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "피브이와트태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 피브이와트",
    rn: 1385
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미숙 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "미숙 태양광",
    rn: 1386
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "TY1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "TY1호 태양광발전소",
    rn: 1387
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "TY2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "TY2호 태양광발전소",
    rn: 1388
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해성 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해성태양광발전소",
    rn: 1389
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "월평태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "월평태양광발전 주식회사",
    rn: 1390
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "글로리어스태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 글로리어스",
    rn: 1391
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강수에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 강수에너지",
    rn: 1392
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "갈바람에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 갈바람에너지",
    rn: 1393
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대각에너지태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "유한회사 대각에너지",
    rn: 1394
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)한강에스 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)한강에스성환지점",
    rn: 1395
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)삼남1호에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 삼남",
    rn: 1396
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)삼남2호에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 삼남",
    rn: 1397
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔텍솔라광치태양광발전소1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 엔텍솔라광치",
    rn: 1398
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔텍솔라광치태양광발전소2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 엔텍솔라광치",
    rn: 1399
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서오창1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "한국동서발전(주)",
    rn: 1400
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서오창2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "한국동서발전(주)",
    rn: 1401
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "헬파이어 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "헬파이어 태양광발전소",
    rn: 1402
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕은3호 ESS 태양광발전소 ",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "은돌발전쏠라 주식회사",
    rn: 1403
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)쏠라파워 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)쏠라파워",
    rn: 1404
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)파워제네레이션 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)파워제네레이션",
    rn: 1405
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)쏠라뱅크 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)쏠라뱅크",
    rn: 1406
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한솔클린에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한솔클린에너지",
    rn: 1407
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스솔루션ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인 유한회사 티에스솔루션",
    rn: 1408
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송죽에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 송죽에너지",
    rn: 1409
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하리 태양광발전소(ess)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "하리 태양광발전소1호",
    rn: 1410
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 티에스에너지",
    rn: 1411
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비에스에스태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "비에스에스 주식회사",
    rn: 1412
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼원 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "삼원 태양광발전소",
    rn: 1413
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명품3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "명품3호 태양광발전소",
    rn: 1414
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "햇빛봉남1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "햇빛봉남1호태양광발전소",
    rn: 1415
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용산42호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "용산 42호 태양광발전소",
    rn: 1416
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉산효교제1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "봉산효교 제1호 태양광발전소",
    rn: 1417
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉산효교제2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "예산조창기발전소",
    rn: 1418
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "둥지에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "둥지에너지 태양광발전소",
    rn: 1419
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다온에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "다온에너지 태양광발전소",
    rn: 1420
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티2호 태양광발전소",
    rn: 1421
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티3호 태양광발전소",
    rn: 1422
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티4호 태양광발전소 ",
    rn: 1423
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티5호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티5호 태양광발전소",
    rn: 1424
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티7호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티7호 태양광발전소 ",
    rn: 1425
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티8호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티8호 태양광발전소",
    rn: 1426
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티9호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티9호 태양광발전소",
    rn: 1427
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티10호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티10호 태양광발전소",
    rn: 1428
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강이준희태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "강이준희태양광발전소",
    rn: 1429
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 성진2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.3,
    company: "주식회사 성진2호태양광발전소",
    rn: 1430
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스에너지호동ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "티에스에너지27호",
    rn: 1431
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한자3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 티에스에너지22호",
    rn: 1432
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호티1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "호티1호 태양광발전소",
    rn: 1433
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "피에스솔라1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "피에스솔라1호 주식회사",
    rn: 1434
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "현태양광",
    rn: 1435
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희승태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "희승태양광",
    rn: 1436
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희정태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "희정태양광",
    rn: 1437
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희연태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "희연태양광",
    rn: 1438
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희란태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "희란태양광",
    rn: 1439
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "당진에코태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 8,
    company: "당진에코파워 주식회사",
    rn: 1440
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "교성리태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "와이즈솔라 주식회사",
    rn: 1441
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)방장 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 방장",
    rn: 1442
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)노령 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 노령",
    rn: 1443
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "SH 발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "에스에이치(SH) 발전소",
    rn: 1444
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문수태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "문수태양광발전소",
    rn: 1445
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가인리태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "가인리태양광발전소",
    rn: 1446
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "민결 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 민결발전소",
    rn: 1447
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "평천태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "평천 태양광발전소",
    rn: 1448
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "썬샤인태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "썬샤인 태양광발전소",
    rn: 1449
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무위용 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 무위용",
    rn: 1450
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다림에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "다림에너지",
    rn: 1451
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "파란하늘 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "파란하늘 태양광발전소",
    rn: 1452
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "파란하늘2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "파란하늘2호 태양광발전소",
    rn: 1453
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "적산 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "적산태양광발전소",
    rn: 1454
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼일 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "삼일전설 (주)",
    rn: 1455
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명품5호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "명품5호 태양광발전소",
    rn: 1456
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엘도라도 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 엘도라도",
    rn: 1457
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명품4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "명품4호 태양광발전소",
    rn: 1458
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "치웅 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "치웅태양광발전소",
    rn: 1459
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이엠에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "이엠에너지",
    rn: 1460
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금애 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "금애태양광발전소",
    rn: 1461
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신덕 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "신덕태양광발전소",
    rn: 1462
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "SOLVIC2호 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "세방(주)",
    rn: 1463
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)여주케이원솔라 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 여주케이원솔라",
    rn: 1464
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현진태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "현진태양광발전소",
    rn: 1465
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강천게이트볼장ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "여주에너지협동조합",
    rn: 1466
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "당산1리공동저온저장고ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "여주에너지협동조합",
    rn: 1467
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대신전천후게이트볼장ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "여주에너지협동조합",
    rn: 1468
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "여주추모공원주차장ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "여주에너지협동조합",
    rn: 1469
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼교동게이트볼장ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "여주에너지협동조합",
    rn: 1470
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼삼태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "삼삼태양광발전소",
    rn: 1471
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성진 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "범우솔라 주식회사",
    rn: 1472
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "준행복밸리발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "준행복밸리발전소",
    rn: 1473
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화성KC2호 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "주식회사 케이씨솔팜1호",
    rn: 1474
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "외입1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.825,
    company: "주식회사 외입태양광",
    rn: 1475
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에너지타운 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "안산도시개발(주)",
    rn: 1476
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소사4호 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "소사4호 태양광발전소",
    rn: 1477
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소사10호 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "소사10호 태양광발전소",
    rn: 1478
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남양태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)남양태양광",
    rn: 1479
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대광태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 대광쏠라",
    rn: 1480
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대광태양광발전소2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "대광태양광발전소2호",
    rn: 1481
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉암태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)봉암태양광",
    rn: 1482
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경주동산태양광발전ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "경주동산태양광발전 주식회사",
    rn: 1483
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보대태양광ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 보대에너지",
    rn: 1484
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보대태양광ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 보대에너지",
    rn: 1485
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장지태양광ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 장지솔라",
    rn: 1486
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장지태양광ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 장지솔라",
    rn: 1487
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주명태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주명 태양광발전소",
    rn: 1488
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신광쏠라 태양광발전소 연계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 신광쏠라",
    rn: 1489
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인천태양광2호기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국중부발전(주)",
    rn: 1490
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인천태양광3호기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "한국중부발전(주)",
    rn: 1491
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수정발전소1 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "수정발전소1",
    rn: 1492
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수정발전소2 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "수정발전소2",
    rn: 1493
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수정발전소3 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "수정발전소3",
    rn: 1494
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가람태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "가람태양광발전소",
    rn: 1495
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아토태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "아토태양광발전소",
    rn: 1496
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이산태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "이산태양광발전소",
    rn: 1497
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사랑말태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "사랑말 태양광 발전소",
    rn: 1498
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "목사동에너지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사목사동에너지",
    rn: 1499
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스오 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "티에스오주식회사",
    rn: 1500
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스오2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "티에스오주식회사",
    rn: 1501
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태광에너지 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 태광에너지",
    rn: 1502
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서촌 성창태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "서촌 성창태양광발전소",
    rn: 1503
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동서태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동서태양광발전소",
    rn: 1504
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해바라기5호발전기(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해바라기발전소5호",
    rn: 1505
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동인태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동인태양광발전소",
    rn: 1506
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "메건태양발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "메건태양광발전소",
    rn: 1507
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유한회사 민채하동1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 민채하동1호",
    rn: 1508
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕은2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "검단태양광 주식회사",
    rn: 1509
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "외입3호ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 소리도에너지",
    rn: 1510
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "외입3호ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 소리도에너지",
    rn: 1511
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신정솔라2호ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)티에스에너지19호",
    rn: 1512
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신정솔라2호ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)티에스에너지19호",
    rn: 1513
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "연도태양광ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 유송태양광",
    rn: 1514
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "연도태양광ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 유송태양광",
    rn: 1515
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "재호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "재호 태양광발전소",
    rn: 1516
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광진태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "광진 태양광발전소",
    rn: 1517
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "민석2태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "민석2태양광발전소",
    rn: 1518
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영란2ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영란2태양광발전소",
    rn: 1519
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세종시수질복원센터태양광연계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국서부발전(주)",
    rn: 1520
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아산시 폐기물 매립장 태양광연계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국서부발전(주)",
    rn: 1521
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "승세2태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "승세2태양광발전소",
    rn: 1522
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영희1태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영희1태양광발전소",
    rn: 1523
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아폴로2호태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "아폴로2호",
    rn: 1524
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)삼원에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 삼원에너지",
    rn: 1525
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼호솔라태양광발전기(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.6,
    company: "유한회사 삼호솔라",
    rn: 1526
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고려오트론(주) 1호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "고려오트론주식회사 장성지점",
    rn: 1527
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사랑태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "사랑태양광발전소",
    rn: 1528
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참솔태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "참솔 태양광발전소",
    rn: 1529
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대한몰드태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "대한몰드 주식회사",
    rn: 1530
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)태원에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 태원에너지",
    rn: 1531
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "종원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "종원 태양광발전소",
    rn: 1532
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청산햇빛ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 청산햇빛태양광발전소",
    rn: 1533
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "갈고개ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "갈고개 태양광발전소",
    rn: 1534
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "옥산1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국동서발전(주)",
    rn: 1535
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "옥산3호태양광ess",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국동서발전(주)",
    rn: 1536
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제토탈11호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "김제토탈11호태양광",
    rn: 1537
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제토탈12호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "김제토탈12호태양광",
    rn: 1538
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제토탈16호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "김제토탈16호태양광",
    rn: 1539
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제토탈17호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "김제토탈17호태양광",
    rn: 1540
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제토탈13호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "김제토탈13호태양광",
    rn: 1541
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제토탈10호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "김제토탈10호태양광",
    rn: 1542
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "토탈23호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "토탈23호태양광발전소",
    rn: 1543
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)칠곡지천발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)칠곡지천발전소",
    rn: 1544
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)피노 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 피노",
    rn: 1545
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "혁신 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "혁신태양광발전소",
    rn: 1546
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대창 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "상주황금소 영농조합법인",
    rn: 1547
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "준에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 준에너지",
    rn: 1548
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소명에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "소명에너지 주식회사",
    rn: 1549
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "중부발전태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "중부발전 주식회사",
    rn: 1550
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다복태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "다복태양광발전소",
    rn: 1551
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미자태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "미자발전소",
    rn: 1552
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태백하늘빛 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 태백하늘빛",
    rn: 1553
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화성지구 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국농어촌공사 화안사업단",
    rn: 1554
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)원호라원에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 원호",
    rn: 1555
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "찬기태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "찬기 태양광발전소",
    rn: 1556
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "미래 태양광발전소",
    rn: 1557
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)아빈 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 아빈",
    rn: 1558
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)비즌비츠로9 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로9",
    rn: 1559
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)비즌비츠로13 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로13",
    rn: 1560
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동부소라1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "동부전기기술단 (주)",
    rn: 1561
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동부소라2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "동부소라2호 태양광발전소",
    rn: 1562
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "재은소라1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "재은소라1호 태양광발전소",
    rn: 1563
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소라1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "소라1호 태양광발전소",
    rn: 1564
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)엠제이에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)엠제이에너지",
    rn: 1565
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문경솔라팜ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 문경솔라팜",
    rn: 1566
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문경솔라팜ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 문경솔라팜",
    rn: 1567
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "복동태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "복동태양광발전소",
    rn: 1568
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신봉태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 신봉태양광발전소",
    rn: 1569
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조주암태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.16,
    company: "조주암태양광발전소",
    rn: 1570
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "훈풍태양광1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 훈풍일렉트릭",
    rn: 1571
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "훈풍태양광2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 훈풍일렉트릭",
    rn: 1572
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다인태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "다인태양광발전소 (주)",
    rn: 1573
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "화원태양광발전소",
    rn: 1574
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)비즌비츠로8 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로8",
    rn: 1575
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문막포진리태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "제주발전에너지 주식회사",
    rn: 1576
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "여주걸은1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "여주걸은1호 태양광발전소",
    rn: 1577
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동송2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동송2호 태양광발전소",
    rn: 1578
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동송3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동송3호 태양광발전소",
    rn: 1579
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참다리발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "참다리태양광발전소",
    rn: 1580
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "홈다리 발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "홈다리태양광발전소",
    rn: 1581
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "박말정1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.24,
    company: "박말정1호태양광발전소",
    rn: 1582
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "목포MBC태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "목포문화방송(주)",
    rn: 1583
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일신2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "일신2호태양광발전소 주식회사",
    rn: 1584
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "Gridsol청주1ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "엘에스산전(주)",
    rn: 1585
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "허브태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "허브태양광발전소",
    rn: 1586
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "더우진태양광발전소 ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "더우진태양광발전소",
    rn: 1587
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도원옥곤태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "도원옥곤태양광발전소",
    rn: 1588
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "더유건태양광발전소 ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 더바른",
    rn: 1589
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "더규하태양광발전소 ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "더규하태양광발전소",
    rn: 1590
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "운두태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "운두 태양광발전소",
    rn: 1591
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "복골태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "복골 태양광발전소",
    rn: 1592
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 문산솔라",
    rn: 1593
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한빛일렉트릭태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 한빛일렉트릭",
    rn: 1594
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남양쏠라에너지역덕태양광(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 남양쏠라에너지",
    rn: 1595
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉개동주민대책위원회1호태양광(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "봉개동 쓰레기매립장 주민대책위원회",
    rn: 1596
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉개동주민대책위원회 2호(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "봉개동 쓰레기매립장 주민대책위원회",
    rn: 1597
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성도7호태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "성도7호태양광발전소",
    rn: 1598
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사천안쏠라에너지(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 천안쏠라에너지",
    rn: 1599
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "스카이1000 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 스카이태양광",
    rn: 1600
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래아이앤지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "유한회사 미래아이앤지",
    rn: 1601
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "더존태양광발전소 연계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유한회사 더존",
    rn: 1602
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼마여주2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "주식회사 삼마",
    rn: 1603
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 무진솔라 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 무진솔라",
    rn: 1604
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "피비태선오계1태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 피비태선",
    rn: 1605
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동해바이오화력본부태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국동서발전(주)",
    rn: 1606
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대덕3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)대덕3호태양광발전소",
    rn: 1607
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대덕4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)대덕4호 태양광발전소",
    rn: 1608
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "피비태선오계2ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인알곡주식회사",
    rn: 1609
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "평택#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 350,
    company: "한국서부발전(주)",
    rn: 1610
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "평택#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 350,
    company: "한국서부발전(주)",
    rn: 1611
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "평택#3",
    cent: "중앙",
    fuel: "LNG",
    pcap: 350,
    company: "한국서부발전(주)",
    rn: 1612
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "평택#4",
    cent: "중앙",
    fuel: "LNG",
    pcap: 350,
    company: "한국서부발전(주)",
    rn: 1613
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)사나래 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유) 사나래",
    rn: 1614
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)비즌비츠로10 태양광발전ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로10",
    rn: 1615
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솔라팩토리3 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 솔라팩토리 ",
    rn: 1616
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "만만에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 만만에너지",
    rn: 1617
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이씨태백1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "유)제이씨태백1호",
    rn: 1618
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "선그린태백5호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "유)선그린태백5호",
    rn: 1619
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "울산#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 200,
    company: "한국동서발전(주)",
    rn: 1620
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "울산#2",
    cent: "중앙",
    fuel: "중유",
    pcap: 200,
    company: "한국동서발전(주)",
    rn: 1621
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "울산#3",
    cent: "중앙",
    fuel: "중유",
    pcap: 200,
    company: "한국동서발전(주)",
    rn: 1622
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "울산#4",
    cent: "중앙",
    fuel: "중유",
    pcap: 400,
    company: "한국동서발전(주)",
    rn: 1623
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "울산#5",
    cent: "중앙",
    fuel: "중유",
    pcap: 400,
    company: "한국동서발전(주)",
    rn: 1624
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "울산#6",
    cent: "중앙",
    fuel: "중유",
    pcap: 400,
    company: "한국동서발전(주)",
    rn: 1625
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "스카이에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 스카이에너지",
    rn: 1626
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아텍솔라파워태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)아텍솔라파워",
    rn: 1627
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미림에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)미림에너지",
    rn: 1628
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지에스에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)지에스에너지",
    rn: 1629
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영남#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 200,
    company: "한국남부발전(주)",
    rn: 1630
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영남#2",
    cent: "중앙",
    fuel: "중유",
    pcap: 200,
    company: "한국남부발전(주)",
    rn: 1631
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "군위태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "군위 태양광발전소",
    rn: 1632
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "맥스에너지 안계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "주식회사 맥스에너지7호",
    rn: 1633
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해뜰날 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.101,
    company: "해뜰날솔라에너지",
    rn: 1634
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "길태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "길토백이",
    rn: 1635
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)아나톨1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 아나톨",
    rn: 1636
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)아나톨2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 아나톨",
    rn: 1637
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이목 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.3,
    company: "이목 태양광발전소",
    rn: 1638
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수화력#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 340,
    company: "한국남동발전(주)",
    rn: 1639
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 200,
    company: "한국남동발전(주)",
    rn: 1640
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 328.6,
    company: "한국남동발전(주)",
    rn: 1641
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)동호쏠라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주) 동호쏠라",
    rn: 1642
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)동성에너텍태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 동성에너텍",
    rn: 1643
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)장쏠라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 장쏠라",
    rn: 1644
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아란태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 아란태양광",
    rn: 1645
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수리태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 수리태양광",
    rn: 1646
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두량2수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.958,
    company: "한국농어촌공사 사천지사",
    rn: 1647
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "상주현대ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 상주현대태양광발전소",
    rn: 1648
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미르태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 미르태양광",
    rn: 1649
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소수태양광 ESS ",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 소수태양광",
    rn: 1650
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산#3",
    cent: "중앙",
    fuel: "중유",
    pcap: 105,
    company: "한국남부발전(주)",
    rn: 1651
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산#4",
    cent: "중앙",
    fuel: "중유",
    pcap: 105,
    company: "한국남부발전(주)",
    rn: 1652
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "라온태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 라온태양광",
    rn: 1653
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "마루태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 마루태양광",
    rn: 1654
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사담리1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "에코솔라에너지 주식회사",
    rn: 1655
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사담리2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "환경사랑 주식회사",
    rn: 1656
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "자등태양광발전소ESS장치",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 자등태양광발전소",
    rn: 1657
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "시화",
    cent: "비중앙",
    fuel: "중유",
    pcap: 20,
    company: "케이지이티에스(주)",
    rn: 1658
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "케이지이티에스열병합",
    cent: "중앙",
    fuel: "중유",
    pcap: 20.8,
    company: "케이지이티에스(주)",
    rn: 1659
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "선보전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)선보전기",
    rn: 1660
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "6호광장 EV충전스테이션ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "제주도청",
    rn: 1661
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "두량ESS발전소",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국농어촌공사 사천지사",
    rn: 1662
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 지에스피브이4호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 지에스피브이4호",
    rn: 1663
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남해에너지2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "남해에너지2호 태양광발전소",
    rn: 1664
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남해에너지3호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "남해에너지3호 태양광발전소",
    rn: 1665
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)아리랑에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 아리랑에너지",
    rn: 1666
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "안산도시개발열병합",
    cent: "중앙",
    fuel: "LNG",
    pcap: 60,
    company: "안산도시개발(주)",
    rn: 1667
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "안산도시개발열병합#",
    cent: "중앙",
    fuel: "LNG",
    pcap: 60,
    company: "안산도시개발(주)",
    rn: 1668
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)한빛에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 한빛에너지",
    rn: 1669
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)산다라에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 산다라에너지",
    rn: 1670
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)다정에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 다정에너지",
    rn: 1671
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)노들에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 노들에너지",
    rn: 1672
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)나비잠에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 나비잠에너지",
    rn: 1673
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)고은에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 고은에너지",
    rn: 1674
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)어진에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 어진에너지",
    rn: 1675
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)꽃잠에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 꽃잠에너지",
    rn: 1676
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)은가비에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 은가비에너지",
    rn: 1677
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)지니에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 지니에너지",
    rn: 1678
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)나무에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 나무에너지",
    rn: 1679
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)토리에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 토리에너지",
    rn: 1680
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "홍성에그원 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "홍성에그원 태양광발전소",
    rn: 1681
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "예안 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "예안태양광발전소",
    rn: 1682
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세양2 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)세양",
    rn: 1683
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지암ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지암태양광발전소",
    rn: 1684
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서진1호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 서진인쇄지기",
    rn: 1685
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지암8호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지암8호태양광발전소",
    rn: 1686
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가암태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "가암 태양광 발전소",
    rn: 1687
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "청주열병합#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 58.3,
    company: "한국지역난방공사",
    rn: 1688
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)무안햇빛발전소 ESS [18MW]",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "주식회사 무안햇빛발전소",
    rn: 1689
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)무안햇빛발전소 ESS [12MW]",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 12,
    company: "주식회사 무안햇빛발전소",
    rn: 1690
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "복사골발전소 1 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주) 틸에너지",
    rn: 1691
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "복사골발전소 2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주) 틸에너지",
    rn: 1692
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정숙염산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 정숙염산태양광발전소",
    rn: 1693
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정남염산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 정남염산태양광발전소",
    rn: 1694
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스와이에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 에스와이에너지",
    rn: 1695
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "휴먼영광에너지3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "(주)휴먼영광에너지3호",
    rn: 1696
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성환염산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사 성환염산태양광발전소",
    rn: 1697
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "수원열병합#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 43.2,
    company: "한국지역난방공사",
    rn: 1698
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가화태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)가화태양광발전",
    rn: 1699
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "충화태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)충화태양광발전",
    rn: 1700
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시문5태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "시문5태양광발전소",
    rn: 1701
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래농산3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인 미래농산주식회사",
    rn: 1702
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가나안 1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "가나안1호 태양광발전소",
    rn: 1703
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가나안 2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "가나안2호태양광발전소 ",
    rn: 1704
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가나안 3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "가나안3호태양광발전소",
    rn: 1705
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지암9호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지암9호태양광발전소",
    rn: 1706
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참조은1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "참조은1호 태양광발전소",
    rn: 1707
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대구열병합#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 43.5,
    company: "한국지역난방공사",
    rn: 1708
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참조은2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "참조은2호태양광발전소",
    rn: 1709
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참조은3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "참조은3호태양광발전소",
    rn: 1710
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)대정신재생에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)대정신재생에너지",
    rn: 1711
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백석태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "유한회사 백석태양광발전소",
    rn: 1712
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송란태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "송란 태양광발전소",
    rn: 1713
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "석환2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "석환2호 태양광발전소",
    rn: 1714
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주) 드림솔라",
    rn: 1715
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시흥정수장태양광#2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국수자원공사",
    rn: 1716
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행운1태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "행운1 태양광발전소",
    rn: 1717
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행운2태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "행운2 태양광발전소",
    rn: 1718
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안산에너지2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)안산에너지2호태양광발전소",
    rn: 1719
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안산에너지3호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)안산에너지3호태양광발전소",
    rn: 1720
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대정2ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "대정2태양광발전소",
    rn: 1721
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼일제2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "삼일제2호태양광발전소",
    rn: 1722
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 천지",
    rn: 1723
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "압해6호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 압해발전소6호 ",
    rn: 1724
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "넥스포17-1 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)넥스포 쏠라",
    rn: 1725
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "넥스포17-2태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)넥스포 에너지",
    rn: 1726
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "넥스포17-4태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)넥스포 에너지",
    rn: 1727
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태양3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주) 우림",
    rn: 1728
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "판리2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 장판신에너지",
    rn: 1729
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화인9호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "(주)파인이엠텍",
    rn: 1730
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인제두바이2 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "인제두바이2 태양광발전소",
    rn: 1731
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크예천21호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해테크발전소 충주1호",
    rn: 1732
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크 예천24호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해테크솔라클럽",
    rn: 1733
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크예천3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해테크예천3호",
    rn: 1734
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대장태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "대장태양광 주식회사",
    rn: 1735
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광덕태양광 ess",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)광덕태양광발전소",
    rn: 1736
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼호태양광연계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)삼호태양광발전소",
    rn: 1737
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태안 삼양태양광 발전사업 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 17,
    company: "삼양태양광 주식회사",
    rn: 1738
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림천안ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "주식회사 드림천안에너지",
    rn: 1739
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솔라텍스에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 솔라텍스에너지",
    rn: 1740
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스텍 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "에스텍 주식회사",
    rn: 1741
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "SOLVIC 1호 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "세방(주)",
    rn: 1742
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "SOLVIC 3호 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "세방(주)",
    rn: 1743
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크예천29호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해테크의성18호",
    rn: 1744
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "빌드윈문경태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 빌드윈문경태양광발전",
    rn: 1745
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대산복합#1G/T",
    cent: "중앙",
    fuel: "경유",
    pcap: 281.1,
    company: "엠피씨대산전력(주)",
    rn: 1746
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대산복합#2G/T",
    cent: "중앙",
    fuel: "경유",
    pcap: 93.7,
    company: "엠피씨대산전력(주)",
    rn: 1747
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주봉3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 주봉솔라",
    rn: 1748
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원남5호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 원남솔라",
    rn: 1749
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대성SUN태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)대성태양광발전소",
    rn: 1750
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조율태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "라성모방 (주)",
    rn: 1751
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "넥스포17-3태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)넥스포 쏠라",
    rn: 1752
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)비즌비츠로1 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로1",
    rn: 1753
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대산복합1GT#1",
    cent: "중앙",
    fuel: "경유",
    pcap: 93.7,
    company: "엠피씨대산전력(주)",
    rn: 1754
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대산복합1GT#2",
    cent: "중앙",
    fuel: "경유",
    pcap: 93.7,
    company: "엠피씨대산전력(주)",
    rn: 1755
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대산복합1GT#3",
    cent: "중앙",
    fuel: "경유",
    pcap: 93.7,
    company: "엠피씨대산전력(주)",
    rn: 1756
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대산복합2GT#4",
    cent: "중앙",
    fuel: "경유",
    pcap: 93.7,
    company: "엠피씨대산전력(주)",
    rn: 1757
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탁1호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "탁1호태양광발전소",
    rn: 1758
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탁2호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "탁2호태양광발전소",
    rn: 1759
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "황금6호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)황금6호태양광발전소",
    rn: 1760
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌1호태양광발전소",
    rn: 1761
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비즌비츠로2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로2",
    rn: 1762
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비즌비츠로3 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로3",
    rn: 1763
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대산복합#1C/C",
    cent: "중앙",
    fuel: "경유",
    pcap: 372.1,
    company: "엠피씨대산전력(주)",
    rn: 1764
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비즌비츠로4 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로4",
    rn: 1765
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비즌비츠로5 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로5",
    rn: 1766
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비즌비츠로6 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 비즌비츠로6",
    rn: 1767
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌2호태양광발전소",
    rn: 1768
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌5호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌5호태양광발전소",
    rn: 1769
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌6호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌6호태양광발전소",
    rn: 1770
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌7호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌7호태양광발전소",
    rn: 1771
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌11호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌11호태양광발전소",
    rn: 1772
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌12호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌12호태양광발전소",
    rn: 1773
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "오산열병합(중앙)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 0,
    company: "디에스파워(주)",
    rn: 1774
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌14호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌14호태양광발전소",
    rn: 1775
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌15호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌15호태양광발전소",
    rn: 1776
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌16호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌16호태양광발전소",
    rn: 1777
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌17호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌17호태양광발전소",
    rn: 1778
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌18호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌18호태양광발전소",
    rn: 1779
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌13호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 안좌13호태양광발전소",
    rn: 1780
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제네시스파워태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "유한회사제네시스파워",
    rn: 1781
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유진 태양광발전소",
    rn: 1782
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무한태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 무한태양광발전소",
    rn: 1783
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "무림파워텍열병합(중앙)",
    cent: "중앙",
    fuel: "중유",
    pcap: 26.3,
    company: "무림파워텍(주)",
    rn: 1784
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부곡배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.957,
    company: "대구솔라에너지 주식회사",
    rn: 1785
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서변배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "대구솔라에너지 주식회사",
    rn: 1786
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그리드신파워태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 그리드신파워태양광발전소",
    rn: 1787
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이곡배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.302,
    company: "대구솔라에너지 주식회사",
    rn: 1788
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "죽곡정수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.342,
    company: "대구솔라에너지 주식회사",
    rn: 1789
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "칠곡배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.26,
    company: "대구솔라에너지 주식회사",
    rn: 1790
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KCC김천공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.76,
    company: "(주)케이씨씨 본부영업소",
    rn: 1791
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신발산업진흥센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.441,
    company: "세원썬파워(주)",
    rn: 1792
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그리드썬파워태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 그리드썬파워태양광발전소",
    rn: 1793
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종솔라5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "주식회사 세종솔라",
    rn: 1794
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사당태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.949,
    company: "주식회사 세종솔라",
    rn: 1795
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남성주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.856,
    company: "주식회사 정종솔라",
    rn: 1796
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백양사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.441,
    company: "주식회사 중종솔라",
    rn: 1797
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장성1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.924,
    company: "주식회사 중종솔라",
    rn: 1798
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새만금햇빛누리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 10.872,
    company: "씨엔피브이파워코리아 주식회사",
    rn: 1799
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대동햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 대동햇빛발전소",
    rn: 1800
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영순태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.701,
    company: "유니청정에너지 주식회사",
    rn: 1801
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용인청정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.085,
    company: "동서건설(주)",
    rn: 1802
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태원솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "주식회사태원솔라",
    rn: 1803
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "주식회사태호에너지",
    rn: 1804
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "원에코폐기물발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.8,
    company: "(주)이에스지경주",
    rn: 1805
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양로지스풀태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.173,
    company: "한국로지스풀(주)",
    rn: 1806
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영광1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "주식회사 영광1호태양광발전소",
    rn: 1807
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에쓰태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.423,
    company: "주식회사 이에쓰",
    rn: 1808
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령댐수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "한국수자원공사",
    rn: 1809
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양광시열",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "유한회사 태양광시열",
    rn: 1810
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송호2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "송호2호태양광발전소",
    rn: 1811
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해찬태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.596,
    company: "주식회사 해찬",
    rn: 1812
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "청암태양광발전소",
    rn: 1813
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고성발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "자연발전",
    rn: 1814
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SDH태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.536,
    company: "주식회사 신광에너지",
    rn: 1815
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DH태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.83,
    company: "주식회사 신광에너지",
    rn: 1816
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옥산이엔지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 옥산이엔지",
    rn: 1817
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.954,
    company: "(유)디에스에너지",
    rn: 1818
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에이치태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "에스에이치태양광발전소",
    rn: 1819
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.065,
    company: "신성태양광발전소",
    rn: 1820
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼양사울산기력발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3.7,
    company: "(주)삼양사 울산1공장",
    rn: 1821
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케드코에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "케드코에너지 주식회사",
    rn: 1822
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 화성에너지",
    rn: 1823
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탐라그린태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.003,
    company: "농업회사법인 주식회사 탐라그린",
    rn: 1824
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "디엔아이풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 1.65,
    company: "디엔아이풍력발전(주)",
    rn: 1825
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강산에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.748,
    company: "주식회사 강산에너지",
    rn: 1826
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소매곡리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.343,
    company: "소매곡리에너지타운 영농조합법인",
    rn: 1827
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신성회태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.035,
    company: "수산리예원동신성회",
    rn: 1828
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비봉태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "에코에너지1호태양광발전소",
    rn: 1829
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "천일기력발전",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 0.2,
    company: "주식회사 천일에너지",
    rn: 1830
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신양태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "신양태양광발전소1호",
    rn: 1831
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "그린태양광발전소(주)",
    rn: 1832
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "그린태양광발전소(주)",
    rn: 1833
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린태양광3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "그린태양광발전소(주)",
    rn: 1834
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동미래태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "하동미래태양광발전(주)",
    rn: 1835
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "곤양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 곤양태양광발전소",
    rn: 1836
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린오산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.922,
    company: "씨이솔라 주식회사",
    rn: 1837
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)아시아에너지서산태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주) 아시아에너지",
    rn: 1838
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "별량삼숙태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(유)삼숙",
    rn: 1839
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서보의성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)서보의성발전소",
    rn: 1840
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "천사풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 62.7,
    company: "주식회사 신안그린에너지",
    rn: 1841
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "하장풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "하장풍력발전 주식회사",
    rn: 1842
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에스피태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 케이에스피",
    rn: 1843
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "GMK창원공장태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.4,
    company: "케이에스솔라(주)",
    rn: 1844
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경선신도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.302,
    company: "주식회사 피엠제이",
    rn: 1845
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경선무릉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.373,
    company: "(주)경선태양광무릉지점",
    rn: 1846
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남정수상상모#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.254,
    company: "(주)남정수상태양광",
    rn: 1847
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남정수상상모#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.451,
    company: "(주)남정수상태양광",
    rn: 1848
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사랑태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "사랑 태양광발전소",
    rn: 1849
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이와이태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "제이와이태양광발전소",
    rn: 1850
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이와이태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "제이에이치태양광발전소",
    rn: 1851
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복주태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "복주태양광발전소",
    rn: 1852
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복주태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "복주2호태양광발전소",
    rn: 1853
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "백운제소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.16,
    company: "한국농어촌공사 순천광양여수지사",
    rn: 1854
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "구만소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.17,
    company: "한국농어촌공사 구례지사",
    rn: 1855
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현준태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "현준태양광발전소",
    rn: 1856
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "협신무릉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.556,
    company: "협신개발(주)",
    rn: 1857
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "영흥해양수력#3",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국남동발전(주)",
    rn: 1858
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영암태양광발전ESS-A",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 20,
    company: "영암태양광발전 주식회사",
    rn: 1859
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영암태양광발전ESS-B",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 20,
    company: "영암태양광발전 주식회사",
    rn: 1860
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명월태양광발전소",
    rn: 1861
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인다태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "인다태양광발전소",
    rn: 1862
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한사랑2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한사랑2호 태양광발전소",
    rn: 1863
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영주1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영주1호 태양광발전소",
    rn: 1864
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "덕남정수장소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.27,
    company: "지에스파워(주)",
    rn: 1865
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광동일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.55,
    company: "서광그린파워(동일신도)",
    rn: 1866
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광신도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.302,
    company: "서광그린파워(동일신도)",
    rn: 1867
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광무릉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.518,
    company: "서광그린파워(무릉그린)",
    rn: 1868
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광그린태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.349,
    company: "서광그린파워(무릉그린)",
    rn: 1869
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강북정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.981,
    company: "오씨아이남부태양광발전(주)",
    rn: 1870
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "주영에너지",
    rn: 1871
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정윤태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "정윤에너지",
    rn: 1872
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알파태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "알파태양광발전소",
    rn: 1873
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영암태양광발전ESS-C",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "영암태양광발전 주식회사",
    rn: 1874
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영암태양광발전ESS-D",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 20,
    company: "영암태양광발전 주식회사",
    rn: 1875
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용인플라자CC태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.038,
    company: "한화솔루션 주식회사 ",
    rn: 1876
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "노포차량기지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.693,
    company: "종로전기(주)",
    rn: 1877
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.3,
    company: "(주)대한솔라",
    rn: 1878
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "열매태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.65,
    company: "(주)열매",
    rn: 1879
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스포원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.786,
    company: "종로전기(주)",
    rn: 1880
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양항황금물류센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.1,
    company: "한국동서발전(주)",
    rn: 1881
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "당진화력2소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3.2,
    company: "한국동서발전(주)",
    rn: 1882
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대은계전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.03,
    company: "(주)대은계전",
    rn: 1883
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "벨리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "벨리태양광발전소",
    rn: 1884
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은혜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "(주)은혜태양광발전",
    rn: 1885
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주동진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)동진",
    rn: 1886
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔에스쏠라제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.45,
    company: "(주)엔에스쏠라제2발전소",
    rn: 1887
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "위미태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "위미태양광발전소",
    rn: 1888
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "병옥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "병옥태양광발전소",
    rn: 1889
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동신실업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "(주)동신실업",
    rn: 1890
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.2,
    company: "(주)에스앤아이코퍼레이션",
    rn: 1891
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "자원회수시설발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.5,
    company: "안산시",
    rn: 1892
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "고덕그린연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.6,
    company: "고덕그린에너지(주)",
    rn: 1893
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "범호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "범호태양광발전소",
    rn: 1894
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인다2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "인다2호태양광발전소",
    rn: 1895
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현준태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "현준태양광발전소",
    rn: 1896
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서회천마을태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "서회천마을회",
    rn: 1897
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포에버태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.1,
    company: "(주)포에버",
    rn: 1898
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안파워태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.5,
    company: "포스코에너지(주)",
    rn: 1899
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한양솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한양솔라(주)",
    rn: 1900
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "유림태양광발전소",
    rn: 1901
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.45,
    company: "(주)제이쏠라",
    rn: 1902
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건화에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "건화에너지",
    rn: 1903
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강북아리수정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.8,
    company: "코스콘태양광(주)",
    rn: 1904
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대주당진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.522,
    company: "대주중공업(주)",
    rn: 1905
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "맥스에너지 근영 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 맥스에너지근영",
    rn: 1906
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "맥스에너지 고은 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 맥스에너지고은",
    rn: 1907
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)토교태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)토교태양광발전소",
    rn: 1908
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉현태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "재단법인하이산업",
    rn: 1909
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산신호르노태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6,
    company: "부산신호태양광발전2호(주)",
    rn: 1910
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화성지구태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.993,
    company: "한국농어촌공사 화안사업단",
    rn: 1911
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한중태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "한중엔시에스(주)",
    rn: 1912
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영락태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.302,
    company: "주식회사 영락태양광",
    rn: 1913
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주청산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "주식회사 제주청산태양광",
    rn: 1914
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성진태양광발전소",
    rn: 1915
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "차탄리 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.105,
    company: "차탄태양광발전소",
    rn: 1916
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백일에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "백일에너지",
    rn: 1917
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "백일태양광",
    rn: 1918
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "지영태양광발전소",
    rn: 1919
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주무릉태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "제주무릉태양광발전소1호",
    rn: 1920
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주무릉태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "제주무릉태양광발전소2호",
    rn: 1921
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금동태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "금동1태양광발전소",
    rn: 1922
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금동태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "금동1태양광발전소",
    rn: 1923
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "꿈꾸는태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "꿈꾸는1발전소",
    rn: 1924
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "꿈꾸는태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "꿈꾸는1발전소",
    rn: 1925
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "우주태양광발전소",
    rn: 1926
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상모태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.32,
    company: "주식회사 상모태양광발전",
    rn: 1927
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만풍1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "만풍1호태양광발전소",
    rn: 1928
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만풍2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "만풍2호태양광발전소",
    rn: 1929
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬열태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "썬열태양광발전소",
    rn: 1930
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "윤이태양광발전소",
    rn: 1931
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YJ테크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "YJ테크태양광발전소",
    rn: 1932
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제일풍력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "(주)제일풍력",
    rn: 1933
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정한태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "정한태양광발전소",
    rn: 1934
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "순1호태양광발전소",
    rn: 1935
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "순2호태양광발전소",
    rn: 1936
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "임마누엘태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "임마누엘태양광발전소",
    rn: 1937
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "써니태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "써니태양광발전소",
    rn: 1938
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아가페태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "아가페태양광발전소",
    rn: 1939
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신천3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "신천3호태양광발전소",
    rn: 1940
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신천4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "신천4호 태양광발전소",
    rn: 1941
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "길태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "정오의 태양 발전소",
    rn: 1942
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.976,
    company: "농업회사법인(유)일광발전",
    rn: 1943
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "미성태양광발전소",
    rn: 1944
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석마태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "석마태양광발전소",
    rn: 1945
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "신흥태양광발전소",
    rn: 1946
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "계화2발전소 연계ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국농어촌공사 부안지사",
    rn: 1947
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농심포승태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.27,
    company: "농심엔지니어링(주)",
    rn: 1948
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농심율촌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.84,
    company: "농심엔지니어링(주)",
    rn: 1949
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9,
    company: "대산에너지(주)",
    rn: 1950
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팜스코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.99,
    company: "(주)팜스코",
    rn: 1951
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "계화3발전소 연계ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국농어촌공사 부안지사",
    rn: 1952
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해미솔라태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 4.5,
    company: "해미솔라에너지(주)",
    rn: 1953
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해미솔라태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "해미솔라에너지(주)",
    rn: 1954
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "계화4발전소 연계ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국농어촌공사 부안지사",
    rn: 1955
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대전하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.008,
    company: "코스콘태양광(주)",
    rn: 1956
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함안휴게소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "해사랑태양광 주식회사",
    rn: 1957
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세명대태양광(우림에너지산업)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.47,
    company: "우림에너지산업(주)",
    rn: 1958
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연안솔라6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "태안안면솔라(주)",
    rn: 1959
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천북항쏠라#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.08,
    company: "인천북항쏠라(주)",
    rn: 1960
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천북항쏠라#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.02,
    company: "인천북항쏠라(주)",
    rn: 1961
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "대상군산공장발전기",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.89,
    company: "대상(주)",
    rn: 1962
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가시리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)다쓰테크",
    rn: 1963
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "M5구미태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.617,
    company: "(주)에스앤아이코퍼레이션",
    rn: 1964
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "P456구미태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.26,
    company: "(주)에스앤아이코퍼레이션",
    rn: 1965
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "서포소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.35,
    company: "한국농어촌공사 금강사업단",
    rn: 1966
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대죽A태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "주식회사 화신태양광발전소",
    rn: 1967
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "계화5발전소 연계ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국농어촌공사 부안지사",
    rn: 1968
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지셀태양광4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "(주)지셀태양광발전소",
    rn: 1969
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원당중공업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.85,
    company: "목포솔라 주식회사",
    rn: 1970
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "목포솔라 주식회사",
    rn: 1971
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "준태양광발전소",
    rn: 1972
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "운서보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.4,
    company: "함양군청",
    rn: 1973
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "계화6발전소 연계ESS설비",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국농어촌공사 부안지사",
    rn: 1974
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "신재생에너지",
    genNm: "태안IGCC CC",
    cent: "비중앙",
    fuel: "IGCC",
    pcap: 346.33,
    company: "한국서부발전(주)",
    rn: 1975
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "전곡한빛1호태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 지송",
    rn: 1976
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "전곡한빛2호태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 전곡한빛2호 태양광발전소",
    rn: 1977
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양산물류센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.6,
    company: "디에스에너지(주)",
    rn: 1978
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "건영솔라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "플러스타임",
    rn: 1979
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DS부안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.13,
    company: "길산에스티 (주)",
    rn: 1980
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민규태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "민규태양광발전소",
    rn: 1981
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "골든태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.074,
    company: "골든태양광발전소",
    rn: 1982
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래동연 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "미래동연 태양광발전소",
    rn: 1983
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부여에너지팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.005,
    company: "(주)부여에너지팜",
    rn: 1984
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부안그린태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "농업회사법인 유한회사 부안그린에너지",
    rn: 1985
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명승태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "명승태양광발전소",
    rn: 1986
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서민행복8호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "무한에코에너지(유)",
    rn: 1987
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑선전남태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6.7,
    company: "(주)탑선전남에너지",
    rn: 1988
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑선전남태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.3,
    company: "(주)탑선전남에너지",
    rn: 1989
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스트솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "넥스트솔라",
    rn: 1990
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "표선재경솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "제주솔라팜 ",
    rn: 1991
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안안면태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)유진",
    rn: 1992
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탑솔라여주1 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 탑솔라여주1",
    rn: 1993
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "신보령화력#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1019.029,
    company: "한국중부발전(주)",
    rn: 1994
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "신보령화력#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1019.029,
    company: "한국중부발전(주)",
    rn: 1995
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "신보령연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 7.48,
    company: "한국중부발전(주)",
    rn: 1996
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유민태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "유민 태양광발전소",
    rn: 1997
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "북평해양수력",
    cent: "비중앙",
    fuel: "수력",
    pcap: 4.872,
    company: "(주)지에스동해전력",
    rn: 1998
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "원주그린열병합",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 10,
    company: "한국중부발전(주)",
    rn: 1999
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에코썬 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유) 에코태양광발전",
    rn: 2000
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명원1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명원1호태양광발전소",
    rn: 2001
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명원2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명원2호태양광발전소",
    rn: 2002
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적암1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "하이웨이솔라(주)",
    rn: 2003
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적암2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "하이웨이솔라(주)",
    rn: 2004
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적암3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "하이웨이솔라(주)",
    rn: 2005
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "하이웨이솔라(주)",
    rn: 2006
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "하이웨이솔라(주)",
    rn: 2007
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼항태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "하이웨이솔라(주)",
    rn: 2008
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "하이웨이솔라(주)",
    rn: 2009
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장산2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.488,
    company: "하이웨이솔라(주)",
    rn: 2010
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경창산업1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.39,
    company: "경창산업제3태양광발전소",
    rn: 2011
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경창산업2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "경창산업제3태양광발전소",
    rn: 2012
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포에버태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)포에버",
    rn: 2013
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "썬텍바이오발전소",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 3,
    company: "(주)썬텍에너지",
    rn: 2014
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "광명연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5,
    company: "(주)에스파워",
    rn: 2015
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대륜발전1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "(주)대륜발전",
    rn: 2016
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대륜발전2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.24,
    company: "(주)대륜발전",
    rn: 2017
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천제강2기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 라미솔라",
    rn: 2018
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포항제강1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.1,
    company: "유한회사 라미솔라",
    rn: 2019
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창녕베스틸1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.6,
    company: "유한회사 라미솔라",
    rn: 2020
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.848,
    company: "(주)가야태양광발전소",
    rn: 2021
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "정현태양광발전소",
    rn: 2022
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "태광태양광발전소",
    rn: 2023
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "현진태양광발전소",
    rn: 2024
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "주식회사 솔라텍코리아",
    rn: 2025
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나눔태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "(주)나눔장학회",
    rn: 2026
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라뱅크강화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)다쓰테크",
    rn: 2027
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "건희태양광발전소",
    rn: 2028
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코플랜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "에코플랜태양광발전소(#1)",
    rn: 2029
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코플랜2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.074,
    company: "에코플랜태양광발전소(#2)",
    rn: 2030
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "대재바이오발전소",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.44,
    company: "대재에너지(주)",
    rn: 2031
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "승경발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.15,
    company: "(주)이엠케이승경",
    rn: 2032
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자농태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.06,
    company: "자농태양광발전소",
    rn: 2033
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.06,
    company: "문영태양광발전소",
    rn: 2034
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반석태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "반석태양광발전소",
    rn: 2035
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한솔1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한솔1호태양광발전소",
    rn: 2036
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소망태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "소망태양광발전소",
    rn: 2037
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사랑태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "사랑 태양광발전소",
    rn: 2038
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.26,
    company: "은성기업(주)",
    rn: 2039
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스마트태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "스마트태양광발전소",
    rn: 2040
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동일SEP태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동일에스이피태양광발전",
    rn: 2041
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이디이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.03,
    company: "케이디이태양광발전소",
    rn: 2042
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파워택태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "파워택태양광발전",
    rn: 2043
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비젼지식센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.66,
    company: "대구솔라시티(주)",
    rn: 2044
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "믿음태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "믿음태양광발전소",
    rn: 2045
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솔라태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "솔라태양광발전소",
    rn: 2046
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세원정공태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.36,
    company: "대구솔라시티(주)",
    rn: 2047
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "MJ테크노파크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.98,
    company: "대구솔라시티(주)",
    rn: 2048
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "풍력시스템평가센터발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.3,
    company: "(재)전남테크노파크",
    rn: 2049
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛에스지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "한빛에스지(주)",
    rn: 2050
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매고1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "매고1호태양광발전소",
    rn: 2051
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "행원햇빛태양광발전소",
    rn: 2052
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "서인천연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 16.64,
    company: "한국서부발전(주)",
    rn: 2053
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지수공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.4,
    company: "(주)성훈철강",
    rn: 2054
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유니온스틸부산공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.706,
    company: "(주)부산도시가스",
    rn: 2055
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "닥모루태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "닥모루태양광발전소",
    rn: 2056
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안솔라뱅크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.481,
    company: "(주)태안솔라뱅크에너지",
    rn: 2057
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천부태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "천부태양광발전",
    rn: 2058
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금용태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.25,
    company: "(주)금용태양광발전",
    rn: 2059
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하예1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.45,
    company: "제주하예1호태양광발전소",
    rn: 2060
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "진송태양광발전소",
    rn: 2061
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "하나1호태양광발전소",
    rn: 2062
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "하나2호태양광발전소",
    rn: 2063
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "하나3호태양광발전소",
    rn: 2064
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼일방직태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "삼일방직(주)",
    rn: 2065
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대죽2공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.6,
    company: "(주)케이씨씨 본부영업소",
    rn: 2066
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포에이스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "주식회사 포에이스",
    rn: 2067
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린레일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)솔라오션",
    rn: 2068
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은서태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "은서태양광발전소",
    rn: 2069
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은빈냉동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "은빈냉동태양광발전소",
    rn: 2070
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이티태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "에이티태양광발전소",
    rn: 2071
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엠태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "에스엠태양광발전소",
    rn: 2072
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원동취수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국수자원공사",
    rn: 2073
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "감포댐풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "한국수자원공사",
    rn: 2074
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "성덕댐소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.23,
    company: "한국수자원공사",
    rn: 2075
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스비파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "에스비파워태양광발전소",
    rn: 2076
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "가시리풍력발전소",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 30,
    company: "에스케이디앤디(주)가시리풍력발전소",
    rn: 2077
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "밀양정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.233,
    company: "한국수자원공사",
    rn: 2078
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양산정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.394,
    company: "한국수자원공사",
    rn: 2079
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.049,
    company: "미희태양광발전소",
    rn: 2080
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.36,
    company: "(주)에너솔라",
    rn: 2081
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "덕천태양광발전소",
    rn: 2082
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "동양환경폐기물소각발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.99,
    company: "(주)동양환경 서산지점",
    rn: 2083
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "용 태양광발전소",
    rn: 2084
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탑곡1호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.105,
    company: "답곡1호 태양광발전소",
    rn: 2085
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대원솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.4,
    company: "(주)대원솔라제주",
    rn: 2086
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "신재생에너지",
    genNm: "서남바이오가스발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 5.8,
    company: "서남바이오에너지(주)",
    rn: 2087
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "진희태양광발전소",
    rn: 2088
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "귀빈태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.05,
    company: "귀빈 태양광발전소",
    rn: 2089
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "더조은 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "더조은 태양광발전소",
    rn: 2090
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.7,
    company: "농업회사법인(유)복평",
    rn: 2091
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "뉴럴태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.04,
    company: "뉴럴태양광발전소",
    rn: 2092
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.911,
    company: "송암태양광발전소",
    rn: 2093
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보현솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.896,
    company: "(주)탑인프라보현",
    rn: 2094
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라앤와이즈태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.361,
    company: "솔라앤와이즈(주)",
    rn: 2095
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산이엔씨태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.32,
    company: "(주)대산이엔씨",
    rn: 2096
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "클린에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.9,
    company: "유한회사 클린에너지",
    rn: 2097
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서귀포태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.8,
    company: "(주)서귀포태양광발전소",
    rn: 2098
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)제주태양광발전소",
    rn: 2099
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "보현산댐소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.17,
    company: "한국수자원공사",
    rn: 2100
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령참빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "보령참빛태양광발전소",
    rn: 2101
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "GS당진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.334,
    company: "GS EPS(주)",
    rn: 2102
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산쏠라태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.851,
    company: "대산농산(주)",
    rn: 2103
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "제주김녕풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 30,
    company: "제주김녕풍력발전(주)",
    rn: 2104
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이즈솔라군산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.9,
    company: "주식회사 와이즈솔라군산",
    rn: 2105
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔비플러스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.467,
    company: "주식회사 엔비플러스",
    rn: 2106
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이티솔라2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(유)제이티에스앤씨",
    rn: 2107
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이티솔라3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유한회사 엠제이솔라",
    rn: 2108
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "새만금열병합#1",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 151.5,
    company: "OCI SE 주식회사",
    rn: 2109
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "새만금열병합#2",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 151.5,
    company: "OCI SE 주식회사",
    rn: 2110
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "맹동소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.36,
    company: "한국농어촌공사 진천음성지사",
    rn: 2111
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이뱅크4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "디와이뱅크(주)",
    rn: 2112
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성정공2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.335,
    company: "(주)삼성정공",
    rn: 2113
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이뱅크2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "디와이뱅크(주)",
    rn: 2114
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이뱅크3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.237,
    company: "디와이뱅크(주)",
    rn: 2115
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이뱅크5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "디와이뱅크(주)",
    rn: 2116
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용산1 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "용산1.2태양광발전소",
    rn: 2117
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.398,
    company: "농업회사법인(유)드림팜에너지",
    rn: 2118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "리치웨이 유한회사",
    rn: 2119
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명문에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.821,
    company: "명문에너지 주식회사",
    rn: 2120
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "울산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.943,
    company: "(주)에스앤아이코퍼레이션",
    rn: 2121
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청호전기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.633,
    company: "종로전기(주)",
    rn: 2122
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "대관령풍력#3",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 1.668,
    company: "강원도",
    rn: 2123
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "안산연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.64,
    company: "한국남동발전(주)",
    rn: 2124
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용산2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "용산1.2태양광발전소",
    rn: 2125
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.36,
    company: "(주)삼우태양광발전",
    rn: 2126
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사알티태양광무안1호(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 알티태양광무안1호",
    rn: 2127
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대호원포태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.196,
    company: "원포79호",
    rn: 2128
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영원솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "농업회사법인(유)영원솔라에너지",
    rn: 2129
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팜스코태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.314,
    company: "(주)팜스코",
    rn: 2130
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팜스코태양광#5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.782,
    company: "(주)팜스코",
    rn: 2131
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라팟태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.172,
    company: "솔라팟주식회사",
    rn: 2132
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "레일에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.247,
    company: "레일에너지(주)",
    rn: 2133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양산솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.967,
    company: "주식회사양산솔라",
    rn: 2134
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사알티태양광무안2호(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 알티태양광무안2호",
    rn: 2135
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유성태양광발전소",
    rn: 2136
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사알티태양광무안3호(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 알티태양광무안3호",
    rn: 2137
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사알티태양광무안4호(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 알티태양광무안4호",
    rn: 2138
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SUPEX2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.476,
    company: "전남도시가스(주)",
    rn: 2139
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알파에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "알파에너지",
    rn: 2140
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이레태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이레태양광발전소",
    rn: 2141
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팜스코태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.496,
    company: "(주)팜스코푸드",
    rn: 2142
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구국태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "구국태양광발전소",
    rn: 2143
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해평정수장침전지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.084,
    company: "한국수자원공사",
    rn: 2144
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현승태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "현승태양광발전소",
    rn: 2145
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "서연태양광발전소",
    rn: 2146
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이승1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "이승1호태양광발전소",
    rn: 2147
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이승2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "이승2호태양광발전소",
    rn: 2148
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "Jin's 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.288,
    company: "Jins2태양광발전소",
    rn: 2149
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원햇빛2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.04,
    company: "행원햇빛2호태양광발전소",
    rn: 2150
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예스에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "예스에너지",
    rn: 2151
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "보원태양광발전소",
    rn: 2152
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명월3호태양광발전소",
    rn: 2153
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이호동오도마을회2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.03,
    company: "이호동오도마을회",
    rn: 2154
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "SGC에너지 AC발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 12.4,
    company: "에스지씨에너지(주) AC발전소",
    rn: 2155
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼용태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "삼용물류(주)",
    rn: 2156
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕년7호 태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 태려에너지",
    rn: 2157
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕년에너지 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 덕년에너지",
    rn: 2158
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성솔라2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "삼성솔라 주식회사",
    rn: 2159
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "꿈의태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "꿈의태양광발전소",
    rn: 2160
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성읍리태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.248,
    company: "(주)다쓰테크",
    rn: 2161
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한민고태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.543,
    company: "해사랑태양광 주식회사",
    rn: 2162
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한백대구태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.71,
    company: "주식회사 한백에어솔라",
    rn: 2163
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "석성리제1태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "석성리제1태양광발전소",
    rn: 2164
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "석성리제2태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "석성리제2호태양광발전소",
    rn: 2165
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산(정)침전지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.089,
    company: "한국수자원공사",
    rn: 2166
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팜스코태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.68,
    company: "(주)팜스코",
    rn: 2167
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동회천마을회태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.41,
    company: "동회천마을회",
    rn: 2168
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연실태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "(주)연실태양광발전소",
    rn: 2169
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두야태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "(주)두야태양광발전소",
    rn: 2170
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "녹색에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.26,
    company: "주식회사 녹색에너지1호",
    rn: 2171
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "녹색에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.72,
    company: "주식회사 녹색에너지1호",
    rn: 2172
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코제주에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.623,
    company: "농업회사법인주식회사에코제주에너지",
    rn: 2173
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린솔라텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.388,
    company: "유한회사 그린솔라텍",
    rn: 2174
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.915,
    company: "세현에너지주식회사",
    rn: 2175
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.021,
    company: "대한태양광발전소주식회사",
    rn: 2176
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "영희태양광발전소",
    rn: 2177
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산나우배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.59,
    company: "(주)지셀태양광발전소",
    rn: 2178
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산오식도배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.84,
    company: "(주)지셀태양광발전소",
    rn: 2179
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산공공하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.798,
    company: "(주)지셀태양광발전소",
    rn: 2180
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평택2복합태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.455,
    company: "한국서부발전(주)",
    rn: 2181
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "LH본사신사옥태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.04,
    company: "한국토지주택공사",
    rn: 2182
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "청주바이오가스발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.99,
    company: "(주)지엔씨에너지",
    rn: 2183
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "동복북촌풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 30,
    company: "제주에너지공사",
    rn: 2184
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "메가솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.796,
    company: "유한회사 메가솔라",
    rn: 2185
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은미 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "은미 태양광발전소",
    rn: 2186
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "환경과에너지 제 3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.292,
    company: "환경과에너지제3호태양광발전소",
    rn: 2187
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "영주태양광발전소",
    rn: 2188
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우람태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "우람태양광발전소",
    rn: 2189
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "평창풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 16,
    company: "평창풍력발전주식회사",
    rn: 2190
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서부솔라서천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.476,
    company: "서부솔라발전(주)",
    rn: 2191
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "우진태양광(주)",
    rn: 2192
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "씨제이 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "씨제이 태양광발전소",
    rn: 2193
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대호이스트태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.75,
    company: "동남태양광발전(주)",
    rn: 2194
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코아텍시스템즈태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.67,
    company: "동남태양광발전(주)",
    rn: 2195
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한영산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.88,
    company: "동남태양광발전(주)",
    rn: 2196
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파워풀글로벌코리아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.565,
    company: "동남태양광발전(주)",
    rn: 2197
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이스타태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "동남태양광발전(주)",
    rn: 2198
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치와이중공업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "동남태양광발전(주)",
    rn: 2199
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "한솔홈데코바이오매스#2",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.5,
    company: "(주)한솔홈데코",
    rn: 2200
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금호1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "부창솔라(주)",
    rn: 2201
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양지에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "양지에너지(주)",
    rn: 2202
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.345,
    company: "수정태양광발전소",
    rn: 2203
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "환경과에너지 제2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.335,
    company: "환경과에너지제2호태양광발전소",
    rn: 2204
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태광2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "태광2태양광발전소(주)",
    rn: 2205
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "양산시소각여열발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1,
    company: "양산시청",
    rn: 2206
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "신창바이오에너지발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 1.08,
    company: "농업회사법인 바이오에너지팜아산주식회사",
    rn: 2207
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천우쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "천우쏠라태양광발전소",
    rn: 2208
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "새만금가력도풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "한국농어촌공사 새만금사업단",
    rn: 2209
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "진주산업소각로열병합b",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.247,
    company: "(주)클렌코",
    rn: 2210
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.288,
    company: "빛에너지",
    rn: 2211
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안신온리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.473,
    company: "주식회사 태안발전에너지",
    rn: 2212
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "제주토평바이오매스발전기",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 2.95,
    company: "(주)이웰",
    rn: 2213
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우람2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "우람2호태양광발전소",
    rn: 2214
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "추풍령저수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "(주)그린솔라에너지",
    rn: 2215
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛가람태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 빛가람태양광발전소",
    rn: 2216
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "윤영태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "윤영태양광발전소",
    rn: 2217
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영서화1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영서화 발전소1호",
    rn: 2218
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "섬진강댐소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.65,
    company: "한국수자원공사",
    rn: 2219
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고아정공경주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "동남태양광발전(주)",
    rn: 2220
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "트윈시스템태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.52,
    company: "동남태양광발전(주)",
    rn: 2221
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "용담고산소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.098,
    company: "한국수자원공사",
    rn: 2222
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성정공1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.39,
    company: "(주)삼성정공",
    rn: 2223
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "버섯나무 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "버섯나무태양광발전소",
    rn: 2224
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우주최강태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "우주최강태양광발전소",
    rn: 2225
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)해동이엔씨6호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주) 해동이엔씨 제6호",
    rn: 2226
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양3공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.323,
    company: "동양산업 주식회사",
    rn: 2227
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양4공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "동양산업 주식회사",
    rn: 2228
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천하수처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "에스케이디앤디(주) 순천하수처리장태양광발전소",
    rn: 2229
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화천1호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 천혜발전소",
    rn: 2230
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화천2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 천혜발전소",
    rn: 2231
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스이에스덕남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.585,
    company: "에스이에스 주식회사",
    rn: 2232
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인지솔라1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.087,
    company: "인지솔라1호기보스틸태양광발전소",
    rn: 2233
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화천3호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 천혜발전소",
    rn: 2234
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정민태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정민태양광발전소",
    rn: 2235
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포천파워소내태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.664,
    company: "포천파워(주)",
    rn: 2236
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛고을신재생제1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.524,
    company: "주식회사 빛고을신재생발전",
    rn: 2237
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛고을신재생2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.299,
    company: "주식회사 빛고을신재생발전",
    rn: 2238
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우산태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "우산태양광발전소",
    rn: 2239
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "큰산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "주식회사 창우",
    rn: 2240
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한동햇빛3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한동햇빛3호태양광발전소",
    rn: 2241
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대전통영선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.78,
    company: "해사랑태양광 주식회사",
    rn: 2242
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양제6태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "주식회사동양제6태양광발전소",
    rn: 2243
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "금성축산영농조합법인",
    rn: 2244
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동부죽포1 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "동부죽포1 태양광발전소",
    rn: 2245
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제일종축태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.221,
    company: "농업회사법인(주)선진한마을제일종축",
    rn: 2246
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "봉천태양광발전소",
    rn: 2247
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "환경과에너지7호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "환경과에너지제7호태양광발전소",
    rn: 2248
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강석1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강석1호태양광발전소",
    rn: 2249
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양지솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "(주)양지솔라",
    rn: 2250
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "한라산바이오발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.5,
    company: "농업회사법인 주식회사 한라산바이오",
    rn: 2251
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한라1호태양광발전소",
    rn: 2252
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디아이테크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.96,
    company: "주식회사 디아이테크",
    rn: 2253
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신화테크2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.942,
    company: "영암테크노태양광(주) #1",
    rn: 2254
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "GS당진바이오매스",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 100,
    company: "GS EPS(주)",
    rn: 2255
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "경산에코에너지#1",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.875,
    company: "경산에코에너지 주식회사",
    rn: 2256
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령해냄태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.892,
    company: "(유)보령해냄",
    rn: 2257
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승문태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "테슬라발전 주식회사",
    rn: 2258
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이엠에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.298,
    company: "주식회사 케이엠에너지",
    rn: 2259
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의정부행복태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.241,
    company: "주식회사 의정부행복태양광발전소",
    rn: 2260
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남정수상장성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.283,
    company: "(주)남정수상태양광",
    rn: 2261
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나눔태양광(장성)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.648,
    company: "(주)나눔장학회",
    rn: 2262
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "월정풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "월정풍력발전 주식회사",
    rn: 2263
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "BBTS태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "BBTS태양광발전소",
    rn: 2264
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑인프라영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "주식회사 항촌태양광",
    rn: 2265
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호남축산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.83,
    company: "호남축산영농조합법인 관산지점",
    rn: 2266
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.79,
    company: "농업회사법인 주식회사 정도",
    rn: 2267
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라뱅크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "솔라뱅크 유한회사",
    rn: 2268
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "GMK창원공장3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.002,
    company: "케이에스솔라2호(주)",
    rn: 2269
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제1야수교태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.349,
    company: "주식회사 부흥솔라",
    rn: 2270
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피비태선오계1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 피비태선",
    rn: 2271
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "춘천정비부대태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.418,
    company: "해사랑태양광 주식회사",
    rn: 2272
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사천미래태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.869,
    company: "사천미래태양광발전",
    rn: 2273
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암군대불하수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.432,
    company: "영암군수도사업소",
    rn: 2274
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JS태양전력2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주식회사 서진인쇄지기",
    rn: 2275
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월햇빛1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명월햇빛1호태양광발전소",
    rn: 2276
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광백수풍력발전",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 40,
    company: "영광백수풍력발전주식회사",
    rn: 2277
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산남주민태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.18,
    company: "산남주민지원협의체",
    rn: 2278
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구좌읍상도리1095태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "구좌읍상도리1095발전소",
    rn: 2279
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상명1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상명1호태양광발전소",
    rn: 2280
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "고원풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 18,
    company: "주식회사 고원풍력발전",
    rn: 2281
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.469,
    company: "(주)디더블유이엔지",
    rn: 2282
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.469,
    company: "주식회사 디제이이엔지",
    rn: 2283
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송주솔텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "송주솔텍",
    rn: 2284
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서민행복7호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "서민행복7호태양광발전소",
    rn: 2285
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "린컴퍼니태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.402,
    company: "주식회사 엠제이에너지",
    rn: 2286
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강빛태양광발전",
    rn: 2287
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신제태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신제태양광",
    rn: 2288
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상익1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.075,
    company: "상익1호태양광발전소",
    rn: 2289
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청명태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "청명태양광발전소",
    rn: 2290
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "HLS태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "HLS태양광발전소",
    rn: 2291
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.864,
    company: "주식회사 태원",
    rn: 2292
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.532,
    company: "은성기업(주)",
    rn: 2293
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.694,
    company: "은성기업(주)",
    rn: 2294
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남순태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.04,
    company: "남순태양광발전소",
    rn: 2295
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진희1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.078,
    company: "진희1호 태양광발전소",
    rn: 2296
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.43,
    company: "유한회사 에코쏠라",
    rn: 2297
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "Gridsol태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.041,
    company: "엘에스산전(주)",
    rn: 2298
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "동두천바이오가스발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.995,
    company: "(주)지엔씨에너지",
    rn: 2299
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "군위 화산풍력 풍력발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 3.2,
    company: "한국남동발전(주)",
    rn: 2300
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와우조천1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "와우텍 주식회사",
    rn: 2301
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와우조천2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "와우텍 주식회사",
    rn: 2302
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.185,
    company: "강진에너지",
    rn: 2303
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "GS영양풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 59.4,
    company: "(주)GS영양풍력발전",
    rn: 2304
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이화곤지암 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "이화전기공업 (주)",
    rn: 2305
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수식리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.302,
    company: "썬힐에너지 주식회사",
    rn: 2306
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한철강태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.371,
    company: "(주)대한철강",
    rn: 2307
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.642,
    company: "산호태양광발전소",
    rn: 2308
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "슬찬에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.995,
    company: "농업회사법인유한회사슬찬에너지",
    rn: 2309
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.306,
    company: "대산태양광발전소",
    rn: 2310
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.903,
    company: "영농조합법인 태인",
    rn: 2311
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무안솔라파크 ESS #1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "주식회사 무안솔라파크",
    rn: 2312
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.903,
    company: "영농조합법인 태인",
    rn: 2313
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.919,
    company: "영농조합법인 태인",
    rn: 2314
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동아영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.662,
    company: "동아자원개발 주식회사",
    rn: 2315
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무안솔라파크 ESS #2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "주식회사 무안솔라파크",
    rn: 2316
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼당에코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "농업회사법인(유)삼당에코",
    rn: 2317
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무안솔라파크 ESS #3",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "주식회사 무안솔라파크",
    rn: 2318
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보성리581-1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대정읍 보성리 581-1발전소",
    rn: 2319
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명문에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.417,
    company: "명문에너지 주식회사",
    rn: 2320
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희망태양광발전소",
    rn: 2321
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동캐1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동캐1호태양광발전소",
    rn: 2322
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용흥마을태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(유)용흥마을태양광발전소",
    rn: 2323
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대정읍보성리1503-1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대정읍보성리1503발전소",
    rn: 2324
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑인프라창기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.914,
    company: "주식회사탑인프라창기태양광",
    rn: 2325
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농업현성기업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.66,
    company: "농업회사법인 현성기업(주)",
    rn: 2326
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천혜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "천혜태양광발전소",
    rn: 2327
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보성리581태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "대정읍보성리581발전소",
    rn: 2328
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "이건에너지바이오",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 3.36,
    company: "이건에너지(주)",
    rn: 2329
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "동화정수장소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.12,
    company: "한국수자원공사",
    rn: 2330
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동두천드림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.591,
    company: "동두천드림파워(주)",
    rn: 2331
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "동화1소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.6,
    company: "한국농어촌공사 남원지사",
    rn: 2332
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성읍리태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.787,
    company: "(주)다쓰테크",
    rn: 2333
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오박태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.078,
    company: "오박태양광발전소",
    rn: 2334
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.694,
    company: "보광태양광",
    rn: 2335
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무안솔라파크 ESS #4",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "주식회사 무안솔라파크",
    rn: 2336
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.646,
    company: "현성에너지(주)",
    rn: 2337
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SF태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "SF태양광발전소",
    rn: 2338
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경완솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 경완솔라팜",
    rn: 2339
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래그린태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)미래그린",
    rn: 2340
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현성산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.67,
    company: "현성산업(주)",
    rn: 2341
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코빌리지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 에코빌리지",
    rn: 2342
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용성솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6.669,
    company: "용성솔라에너지 주식회사",
    rn: 2343
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해비치태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.629,
    company: "주식회사 해비치태양광발전소",
    rn: 2344
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "제주상명풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 21,
    company: "한국중부발전(주)",
    rn: 2345
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "준성태양광발전소",
    rn: 2346
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉중앙태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "무릉중앙태양광발전소",
    rn: 2347
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해오름태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "해오름태양광발전소",
    rn: 2348
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유당솔라(주) ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 8,
    company: "유당솔라 주식회사",
    rn: 2349
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "광명전력태양광발전소",
    rn: 2350
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "오사설화3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "에스와이 태양광발전",
    rn: 2351
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "태양사태양광발전소",
    rn: 2352
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "대현태양광발전소",
    rn: 2353
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)지에스피브이앤이군산_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)지에스피브이앤이군산",
    rn: 2354
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이에프영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(유)에이에프영암",
    rn: 2355
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.701,
    company: "주식회사 대광태양광",
    rn: 2356
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "은영태양광발전소",
    rn: 2357
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보나태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.288,
    company: "보나태양광발전소",
    rn: 2358
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나할라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "나할라태양광발전소",
    rn: 2359
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "소현태양광발전소",
    rn: 2360
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동엽에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "동엽에너지",
    rn: 2361
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승엽에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "승엽에너지",
    rn: 2362
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "주식회사 정선솔라팜",
    rn: 2363
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "양지태양광발전소",
    rn: 2364
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에이티태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "에스에이티태양광발전소",
    rn: 2365
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "원선태양광발전소",
    rn: 2366
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인성리마을회태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.059,
    company: "인성리마을회",
    rn: 2367
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "삼양사여열회수발전",
    cent: "비중앙",
    fuel: "기타",
    pcap: 1.72,
    company: "(주)삼양사 인천1공장",
    rn: 2368
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔에스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "엔에스태양광발전소",
    rn: 2369
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "코오롱플라스틱김천발전소",
    cent: "비중앙",
    fuel: "기타",
    pcap: 3.09,
    company: "코오롱플라스틱(주)",
    rn: 2370
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#1 영광군서",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 2371
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.4,
    company: "(주)오운태양광",
    rn: 2372
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동은태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동은태양광발전소",
    rn: 2373
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "하산태양광발전소",
    rn: 2374
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수완태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "수완태양광발전소",
    rn: 2375
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장가네태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "장가네태양광발전 주식회사",
    rn: 2376
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광전사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "주식회사 광전사",
    rn: 2377
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스영광솔라파크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "(주)제이에스영광솔라파크",
    rn: 2378
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호 대한태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "케이솔라이호 주식회사",
    rn: 2379
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.066,
    company: "주식회사 해발전소",
    rn: 2380
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 디에스쏠라",
    rn: 2381
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)진도그린에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "(유)진도그린에너지",
    rn: 2382
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기은에코에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.301,
    company: "유한회사 기은에코에너지",
    rn: 2383
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "축복2호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "축복2호 태양광발전소",
    rn: 2384
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉룡 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "봉룡태양광발전소",
    rn: 2385
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "도일태양광발전소",
    rn: 2386
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보능 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "보능태양광발전소",
    rn: 2387
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정옥햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.298,
    company: "주식회사 정옥햇빛",
    rn: 2388
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이원에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "주식회사 이원에너지",
    rn: 2389
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬위즈군위태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.43,
    company: "(주)썬위즈",
    rn: 2390
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희윤2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희윤2태양광발전소",
    rn: 2391
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쌍백태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "쌍백태양광발전소",
    rn: 2392
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하초5호태양광 ESS발전기",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "하초5호 태양광발전소",
    rn: 2393
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉황태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 빛에너지",
    rn: 2394
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다옴1태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "다옴1태양광발전소",
    rn: 2395
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동부죽포2 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동부죽포2태양광발전소",
    rn: 2396
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "심천",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "유한회사 송산",
    rn: 2397
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "소연에너지",
    rn: 2398
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JH태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제이에이치(JH) 태양광발전소",
    rn: 2399
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "형보 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "형보태양광발전소",
    rn: 2400
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대광태양광",
    rn: 2401
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일광태양광c",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "일광태양광발전소",
    rn: 2402
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태근태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "태근 태양광발전소",
    rn: 2403
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "양순태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "양순 태양광발전소",
    rn: 2404
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한라2호태양광발전소",
    rn: 2405
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "섭지코지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "섭지코지태양광발전소",
    rn: 2406
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "(주)원호태양광발전소",
    rn: 2407
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "(주)원호태양광발전소",
    rn: 2408
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "(주)원호태양광발전소",
    rn: 2409
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "(주)원호태양광발전소",
    rn: 2410
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "(주)원호태양광발전소",
    rn: 2411
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "(주)원호태양광발전소",
    rn: 2412
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "(주)원호태양광발전소",
    rn: 2413
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라캠퍼스군산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.988,
    company: "솔라캠퍼스군산발전소 주식회사",
    rn: 2414
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수지1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "수지1태양광발전소",
    rn: 2415
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수지4태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "(유)수지4태양광발전소",
    rn: 2416
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "선태양광발전소",
    rn: 2417
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한전가상발전기#2 고흥코센",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 2418
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보배태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "보배태양광발전소",
    rn: 2419
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "회광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "회광태양광발전소",
    rn: 2420
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "화선태양광발전소",
    rn: 2421
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "대호발전소(주)",
    rn: 2422
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KEPCO-11-G13-은성",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "켑코솔라 주식회사",
    rn: 2423
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KEPCO-11-G14-다스",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "켑코솔라 주식회사",
    rn: 2424
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "필정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "필정태양광발전소",
    rn: 2425
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "호성에너지태양광발전소",
    rn: 2426
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "카이로스에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 8.894,
    company: "카이로스에너지 주식회사",
    rn: 2427
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "LH본사신사옥태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.04,
    company: "한국토지주택공사",
    rn: 2428
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#3 동복풍력",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 2429
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "(주)유진산업",
    rn: 2430
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서혜원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "서혜원태양광발전",
    rn: 2431
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선우태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.352,
    company: "주식회사 선우그래픽",
    rn: 2432
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서지원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "서지원 태양광발전소",
    rn: 2433
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "행복태양광발전소",
    rn: 2434
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서득원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "서득원태양광발전소",
    rn: 2435
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "준태양광발전소",
    rn: 2436
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해광2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해광2호태양광발전소",
    rn: 2437
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "선태양광발전소",
    rn: 2438
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "공공하수처리시설소화가스발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.5,
    company: "의정부시(하수처리과)",
    rn: 2439
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서장석2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "서장석2호태양광발전소",
    rn: 2440
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "진남발전소(주)",
    rn: 2441
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "태광발전소(주)",
    rn: 2442
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서장석1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "서장석1호태양광발전소",
    rn: 2443
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오태2수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.088,
    company: "상주수상태양광 주식회사",
    rn: 2444
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지평2수상",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.088,
    company: "상주수상태양광 주식회사",
    rn: 2445
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.286,
    company: "장곡태양광발전소 주식회사",
    rn: 2446
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현석태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "현석태양광발전소",
    rn: 2447
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창하태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "창하태양광발전소",
    rn: 2448
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "평창풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 14,
    company: "평창풍력발전주식회사",
    rn: 2449
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "선영에너지",
    rn: 2450
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황금태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "황금에너지",
    rn: 2451
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "임금태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "임금에너지",
    rn: 2452
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서현에너지",
    rn: 2453
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소3호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.98,
    company: "코리아에너지발전소(주)",
    rn: 2454
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.997,
    company: "(주)우진태양광발전소",
    rn: 2455
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도우농산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.954,
    company: "농업회사법인 도우농산 유한회사",
    rn: 2456
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은하수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "은하수태양광발전소",
    rn: 2457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "환경과에너지4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "환경과에너지4호태양광발전소",
    rn: 2458
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서산쏠라랜드1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "서산쏠라랜드 주식회사",
    rn: 2459
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엠에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "(주)에스엠에너지",
    rn: 2460
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영동공수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.758,
    company: "미래에코에너지주식회사",
    rn: 2461
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동서에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.28,
    company: "동서에너지",
    rn: 2462
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강용구1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "강용구1호 태양광발전소",
    rn: 2463
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "거창풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 14,
    company: "거창풍력발전 주식회사",
    rn: 2464
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.494,
    company: "농업회사법인(주)화성",
    rn: 2465
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스마트솔라1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "주식회사 스마트솔라",
    rn: 2466
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "3군사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.464,
    company: "주식회사그린나래에너지",
    rn: 2467
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "종군교태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.531,
    company: "주식회사그린나래에너지",
    rn: 2468
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대륜발전3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.564,
    company: "(주)대륜발전",
    rn: 2469
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "춘천정비부대태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.501,
    company: "해사랑태양광 주식회사",
    rn: 2470
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피비태선오계2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "농업회사법인알곡주식회사",
    rn: 2471
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "춘천MBC태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.494,
    company: "춘천문화방송(주)",
    rn: 2472
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.064,
    company: "미래솔라에너지 주식회사",
    rn: 2473
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유진에너지 태양광발전소",
    rn: 2474
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "개화차량기지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "서울에너지공사",
    rn: 2475
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고덕차량기지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.612,
    company: "서울에너지공사",
    rn: 2476
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지축차량기지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.992,
    company: "서울에너지공사",
    rn: 2477
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도봉차량기지태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.648,
    company: "서울에너지공사",
    rn: 2478
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.743,
    company: "고흥솔라파크 주식회사",
    rn: 2479
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.777,
    company: "고흥솔라파크 주식회사",
    rn: 2480
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.458,
    company: "고흥솔라파크 주식회사",
    rn: 2481
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "고흥솔라파크 주식회사",
    rn: 2482
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.462,
    company: "고흥솔라파크 주식회사",
    rn: 2483
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크6호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.685,
    company: "고흥솔라파크 주식회사",
    rn: 2484
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크7호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "고흥솔라파크 주식회사",
    rn: 2485
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크8호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.291,
    company: "고흥솔라파크 주식회사",
    rn: 2486
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크9호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.307,
    company: "고흥솔라파크 주식회사",
    rn: 2487
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크10호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.615,
    company: "고흥솔라파크 주식회사",
    rn: 2488
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크11호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.69,
    company: "고흥솔라파크 주식회사",
    rn: 2489
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥만 고흥솔라파크12호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "고흥솔라파크 주식회사",
    rn: 2490
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼구태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.948,
    company: "주식회사 우리에너지",
    rn: 2491
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.345,
    company: "주식회사 한빛에너지",
    rn: 2492
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해미드미태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "해미드미태양광발전소",
    rn: 2493
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "춘규태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "춘규태양광발전소",
    rn: 2494
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장성복합물류태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.854,
    company: "(주)장성솔라",
    rn: 2495
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한솔테크닉스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.506,
    company: "한솔테크닉스(주)",
    rn: 2496
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "음암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.176,
    company: "주식회사 에스아이",
    rn: 2497
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다니엘영동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "주식회사 다니엘영동태양광",
    rn: 2498
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.003,
    company: "주식회사 남계태양광발전소",
    rn: 2499
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.234,
    company: "문정태양광발전소",
    rn: 2500
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이엠태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "제이엠태양광발전소",
    rn: 2501
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "엔이티발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.8,
    company: "주식회사 엔이티",
    rn: 2502
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진환태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진환태양광발전소",
    rn: 2503
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일성전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.042,
    company: "일성전력산업 주식회사",
    rn: 2504
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자연5태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.795,
    company: "자연발전",
    rn: 2505
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천북승민행복나눔태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.584,
    company: "농업회사법인유한회사승민행복나눔",
    rn: 2506
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린솔라에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.098,
    company: "주식회사 그린솔라에너지",
    rn: 2507
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "능성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "주식회사 능성발전",
    rn: 2508
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북리태양광발전소3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "현대건재(북리태양광발전소)",
    rn: 2509
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래에스코덕천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.075,
    company: "미래에스코",
    rn: 2510
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해바라기영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사해바라기영암",
    rn: 2511
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "믿음태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "믿음태양광발전소",
    rn: 2512
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛나리1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "빛나리1호태양광발전소",
    rn: 2513
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛나리2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇살가득 태양광발전소",
    rn: 2514
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "풍기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.198,
    company: "풍기태양광발전소",
    rn: 2515
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에이치태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "농업회사법인디에이치에너지주식회사",
    rn: 2516
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스제이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "에스제이에너지주식회사",
    rn: 2517
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대창기계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대창기계에너지태양광발전소",
    rn: 2518
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "군산산단풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 4.95,
    company: "군산풍력발전(주) (지점1)",
    rn: 2519
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미연쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "미연쏠라태양광발전소",
    rn: 2520
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성원쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성원쏠라태양광발전소",
    rn: 2521
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "빛태양광발전소",
    rn: 2522
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진주정씨충장공파종중",
    rn: 2523
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.347,
    company: "주식회사행복솔라",
    rn: 2524
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해피에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.04,
    company: "해피에너지태양광발전소",
    rn: 2525
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "늘품두드림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "늘품두드림태양광발전소",
    rn: 2526
    },
    {
    area: "비수도권",
    genFom: "차압",
    genSrc: "화력",
    genNm: "세종공장기력발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.45,
    company: "아세아제지(주)세종공장",
    rn: 2527
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수열태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "수열태양광발전소",
    rn: 2528
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "케이에이치파워연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 1,
    company: "케이에이치파워 주식회사",
    rn: 2529
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "고산1호태양광발전소",
    rn: 2530
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "고산2호태양광발전소",
    rn: 2531
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "고산3호태양광발전소",
    rn: 2532
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "에코에너지태양광발전사업",
    rn: 2533
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고성조절지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.312,
    company: "한국수자원공사",
    rn: 2534
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "손영란1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "손영란1호 태양광발전소",
    rn: 2535
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.056,
    company: "해사랑태양광 주식회사",
    rn: 2536
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지에스6호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지에스(GS)6호 태양광",
    rn: 2537
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영태양광3호",
    rn: 2538
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하초4호태양광 ESS발전기",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "하초4호태양광발전소",
    rn: 2539
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대박태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대박 태양광발전소",
    rn: 2540
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신호태양광발전소",
    rn: 2541
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "초원 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "초원 태양광발전소",
    rn: 2542
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상록태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상록태양광발전소",
    rn: 2543
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선일발전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 선일발전",
    rn: 2544
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "화창태양광발전소",
    rn: 2545
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고기압태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "고기압 태양광발전소",
    rn: 2546
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양재태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.089,
    company: "한화솔루션 주식회사 ",
    rn: 2547
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "봉성발전소",
    rn: 2548
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해바라기 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해바라기 태양광발전소",
    rn: 2549
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지경태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지경태양광발전소",
    rn: 2550
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조일에너지4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조일에너지4호",
    rn: 2551
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.59,
    company: "수산태양광발전소",
    rn: 2552
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중부솔라발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.975,
    company: "중부솔라발전 주식회사",
    rn: 2553
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용강동마을회태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "용강동마을회태양광발전소250kw",
    rn: 2554
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동캐2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동캐2호태양광발전소",
    rn: 2555
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)에너지팜 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.975,
    company: "(유)에너지팜",
    rn: 2556
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도전에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "유한회사 도전에너지",
    rn: 2557
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "용담고산소수력#1",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.8,
    company: "한국수자원공사",
    rn: 2558
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "순옥태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "순옥 태양광발전소",
    rn: 2559
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명석태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "명석 태양광발전소",
    rn: 2560
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "죽포태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "죽포태양광발전소",
    rn: 2561
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "논산2호 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "논산 태양광발전소",
    rn: 2562
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지산태양광발전소1호기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "논산 태양광발전소",
    rn: 2563
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산청태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.352,
    company: "유한회사 흥일에너지",
    rn: 2564
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용마태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "용마태양광발전소",
    rn: 2565
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유준태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유준태양광발전",
    rn: 2566
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스에너지5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.747,
    company: "(주)티에스에너지5호",
    rn: 2567
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지산태양광발전소2호기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "논산 태양광발전소",
    rn: 2568
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새별오름태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "새별오름태양광발전소",
    rn: 2569
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이제주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "하이제주태양광발전소",
    rn: 2570
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해안마을태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해안마을태양광발전소",
    rn: 2571
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행종이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행종이태양광발전소",
    rn: 2572
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "씨지앤율촌 연료전지#3",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5,
    company: "씨지앤율촌전력(주)",
    rn: 2573
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태승 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "태승 태양광 발전소",
    rn: 2574
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송대상평6호 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "송대상평6호 태양광발전소",
    rn: 2575
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강릉제일솔라5호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "강릉제일솔라5호",
    rn: 2576
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "증류탑폐열활용발전기",
    cent: "비중앙",
    fuel: "기타",
    pcap: 16.4,
    company: "주식회사 케이디파워텍",
    rn: 2577
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "아라태양광발전소",
    rn: 2578
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제요동현ess",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "제요동현태양광발전소",
    rn: 2579
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제요리후 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "제요리후 태양광발전소",
    rn: 2580
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태두태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "태두태양광발전소",
    rn: 2581
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파워에코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 파워에코",
    rn: 2582
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백학태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "화성엔지니어링(주)",
    rn: 2583
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주아이엠1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제주아이엠1호태양광발전소",
    rn: 2584
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "후태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "후태양광발전소",
    rn: 2585
    },
    {
    area: "제주",
    genFom: "해양에너지",
    genSrc: "신재생에너지",
    genNm: "인진연안파력발전",
    cent: "비중앙",
    fuel: "해양에너지",
    pcap: 0.05,
    company: "주식회사 인진",
    rn: 2586
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)사천태양광발전소",
    rn: 2587
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)신기태양광발전소",
    rn: 2588
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동올레태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.083,
    company: "동올레태양광발전소",
    rn: 2589
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "서광산업신재생발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 2.4,
    company: "서광산업주식회사",
    rn: 2590
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디유태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.898,
    company: "키움 태양광발전소",
    rn: 2591
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.54,
    company: "석현태양광발전소",
    rn: 2592
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어음리2686태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "애월읍어음리2686발전소",
    rn: 2593
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "도경태양광발전소",
    rn: 2594
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영동#1",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 125,
    company: "한국남동발전(주)",
    rn: 2595
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "영동#2",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 200,
    company: "한국남동발전(주)",
    rn: 2596
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호 달마쏠라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "케이솔라이호 주식회사",
    rn: 2597
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두산엔진 MG태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.077,
    company: "한국남동발전(주)",
    rn: 2598
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "두산엔진 MG태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "한국남동발전(주)",
    rn: 2599
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산쏠라4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(유)자연과사람",
    rn: 2600
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산쏠라7호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(유)자연과사람",
    rn: 2601
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영월#1",
    cent: "중앙",
    fuel: "무연탄",
    pcap: 50,
    company: "한국남부발전(주)",
    rn: 2602
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영월#2",
    cent: "중앙",
    fuel: "무연탄",
    pcap: 50,
    company: "한국남부발전(주)",
    rn: 2603
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정현에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(유)정현에너지",
    rn: 2604
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오솔라에너지발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 오솔라에너지",
    rn: 2605
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고연저수지수상태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.934,
    company: "주식회사 워터솔라",
    rn: 2606
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진SH태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.48,
    company: "진양쏠라 주식회사",
    rn: 2607
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "동해#1",
    cent: "중앙",
    fuel: "무연탄",
    pcap: 200,
    company: "한국동서발전(주)",
    rn: 2608
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "동해#2",
    cent: "중앙",
    fuel: "무연탄",
    pcap: 200,
    company: "한국동서발전(주)",
    rn: 2609
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "화순풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 16,
    company: "한국서부발전(주)",
    rn: 2610
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "서천#1",
    cent: "중앙",
    fuel: "무연탄",
    pcap: 200,
    company: "한국중부발전(주)",
    rn: 2611
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "서천#2",
    cent: "중앙",
    fuel: "무연탄",
    pcap: 200,
    company: "한국중부발전(주)",
    rn: 2612
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "군산#1",
    cent: "중앙",
    fuel: "무연탄",
    pcap: 66,
    company: "한국서부발전(주)",
    rn: 2613
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "북평화력#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 595,
    company: "(주)지에스동해전력",
    rn: 2614
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "북평화력#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 595,
    company: "(주)지에스동해전력",
    rn: 2615
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주)지에스동해전력",
    rn: 2616
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "주식회사 한태연1호",
    rn: 2617
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금학일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "주식회사금학전기",
    rn: 2618
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "영선태양광발전소",
    rn: 2619
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "605수송대대태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.518,
    company: "주식회사그린나래에너지",
    rn: 2620
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너제이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 에너제이",
    rn: 2621
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일광2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "(주)일광태양광발전소",
    rn: 2622
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도윤태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "도윤태양광발전소",
    rn: 2623
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진모태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "진모태양광발전소",
    rn: 2624
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성수1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성수1태양광발전소",
    rn: 2625
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미정수장태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.521,
    company: "한국수자원공사",
    rn: 2626
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시드바이오테크 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.988,
    company: "농업회사법인 시드바이오테크 주식회사",
    rn: 2627
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영선태양광b 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.06,
    company: "영선태양광발전소",
    rn: 2628
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "우성태양광발전소",
    rn: 2629
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선영태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "선영태양광발전소",
    rn: 2630
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영선태양광b 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영선태양광발전소",
    rn: 2631
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서회천마을태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.062,
    company: "서회천마을회",
    rn: 2632
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부지외태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.139,
    company: "동두천드림파워(주)",
    rn: 2633
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부지외태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.132,
    company: "동두천드림파워(주)",
    rn: 2634
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라팜아산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)쏠라팜",
    rn: 2635
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현마산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사현마산업",
    rn: 2636
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성애태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "성애태양광발전소",
    rn: 2637
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "고산태양광발전소",
    rn: 2638
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부경태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 부경태양광발전소1호",
    rn: 2639
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신보령화력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.9,
    company: "한국중부발전(주)",
    rn: 2640
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유림솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 유림솔라에너지",
    rn: 2641
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "주식회사 사리태양광발전소",
    rn: 2642
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.809,
    company: "솔라파워(주)",
    rn: 2643
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디씨에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.49,
    company: "주식회사 디씨에너지",
    rn: 2644
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "은현태양광발전소",
    rn: 2645
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청산에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.336,
    company: "주식회사 청산에너지",
    rn: 2646
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문경솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.082,
    company: "주식회사 문경솔라팜",
    rn: 2647
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "BC옥천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "주식회사 조이솔라",
    rn: 2648
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "보청소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.35,
    company: "엠케이에이치피 주식회사(MKHP)",
    rn: 2649
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "용연정수장소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.76,
    company: "지에스파워(주)",
    rn: 2650
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "의령풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 18.75,
    company: "의령풍력발전(주)",
    rn: 2651
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "석문발전소#1",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 38.9,
    company: "(주)석문에너지",
    rn: 2652
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "부산그린연료전지#1",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 15.4,
    company: "부산그린에너지(주)",
    rn: 2653
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.402,
    company: "유한회사 대명에너지 ",
    rn: 2654
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "형진쏠라12호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.299,
    company: "형진쏠라주식회사",
    rn: 2655
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "친환경단구리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "형진쏠라주식회사",
    rn: 2656
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양지태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.652,
    company: "케이솔라일호 주식회사",
    rn: 2657
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "안성바이오가스발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 1.05,
    company: "(주)바이오신트로",
    rn: 2658
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상곤태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "주식회사 정연",
    rn: 2659
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부용태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "부용태양광발전소",
    rn: 2660
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성자태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "성자태양광발전소",
    rn: 2661
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주혁3태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.402,
    company: "농업회사법인(유)주혁",
    rn: 2662
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금학2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.868,
    company: "주식회사금학전기",
    rn: 2663
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천지에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 천지에너지",
    rn: 2664
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "AJ태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.185,
    company: "AJ렌터카(AJ태양광발전소)",
    rn: 2665
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "도원태양광발전소",
    rn: 2666
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명도암마을회태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.137,
    company: "명도암마을회태양광발전소",
    rn: 2667
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용성2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(주)용성2호발전소",
    rn: 2668
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "익성솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(주)익성솔라",
    rn: 2669
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승현태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "승현태양광발전소",
    rn: 2670
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안9·10호기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.836,
    company: "한국서부발전(주)",
    rn: 2671
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "금성풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3.05,
    company: "주식회사 아이오",
    rn: 2672
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금산목소리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.201,
    company: "(주)세종에너지",
    rn: 2673
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.986,
    company: "(주)오성발전",
    rn: 2674
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "우리솔라파크(주)",
    rn: 2675
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대원그린폐기물발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.9,
    company: "대원그린에너지 주식회사",
    rn: 2676
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진양17호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.911,
    company: "진양쏠라 주식회사",
    rn: 2677
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진양23호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.933,
    company: "진양쏠라 주식회사",
    rn: 2678
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진양25호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.503,
    company: "진양쏠라 주식회사",
    rn: 2679
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백광영월신재생",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)백광에너지",
    rn: 2680
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백광영월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)백광에너지",
    rn: 2681
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래영월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)미래",
    rn: 2682
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진영월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)우진",
    rn: 2683
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕원영월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)덕원",
    rn: 2684
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "분당연료전지#3",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.72,
    company: "한국남동발전(주)",
    rn: 2685
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에스쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.822,
    company: "(주)케이에스쏠라",
    rn: 2686
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장성영지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 영지",
    rn: 2687
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)삼계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 삼계태양광발전소",
    rn: 2688
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "배내상주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)배내태양광",
    rn: 2689
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우도상주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)우도에너지",
    rn: 2690
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암군대불하수태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.239,
    company: "영암군수도사업소",
    rn: 2691
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#4 태안154",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 2692
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "신보령소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 2693
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "토산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.744,
    company: "주식회사 토산태양광",
    rn: 2694
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무량태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.304,
    company: "주식회사 무량에너지",
    rn: 2695
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.962,
    company: "인화태양광발전소",
    rn: 2696
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "근흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.956,
    company: "근흥태양광발전소",
    rn: 2697
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내외진1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.801,
    company: "주식회사 내외진태양광",
    rn: 2698
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내외진2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.947,
    company: "주식회사 내외진태양광",
    rn: 2699
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성파워1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.496,
    company: "삼성솔라파워주식회사",
    rn: 2700
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "자원화바이오가스발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 1.75,
    company: "전주리싸이클링에너지(주)",
    rn: 2701
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "감량화바이오가스발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.7,
    company: "전주리싸이클링에너지(주)",
    rn: 2702
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "초포솔라1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.92,
    company: "주식회사 초포솔라",
    rn: 2703
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "초포솔라2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.738,
    company: "주식회사 초포솔라",
    rn: 2704
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우실1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.957,
    company: "주식회사 우실태양광",
    rn: 2705
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우실2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.895,
    company: "주식회사 우실태양광",
    rn: 2706
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "금오에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.839,
    company: "(주)금오에너지",
    rn: 2707
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금오에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.564,
    company: "(주)금오에너지",
    rn: 2708
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대구폐기물에너지화시설",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3,
    company: "대구그린에너지센터 주식회사",
    rn: 2709
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이더블유에너지태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 12.232,
    company: "주식회사 제이더블유에너지",
    rn: 2710
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이더블유에너지 태양광발전소 #2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.588,
    company: "주식회사 제이더블유에너지",
    rn: 2711
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은영태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)은영태양광발전소",
    rn: 2712
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "홍성모산도풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "한국농어촌공사 천수만사업단",
    rn: 2713
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "농업회사법인 성안 주식회사",
    rn: 2714
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강고태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "강고태양광발전소",
    rn: 2715
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.009,
    company: "(주)제이파워",
    rn: 2716
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고라햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.117,
    company: "(주)윤파워에너지",
    rn: 2717
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "약수풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 19.8,
    company: "영광약수풍력발전(주)",
    rn: 2718
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동신이엔텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.108,
    company: "주식회사 타이멕",
    rn: 2719
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛고을메트로태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.399,
    company: "주식회사 빛고을메트로",
    rn: 2720
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛고을메트로태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.668,
    company: "주식회사 빛고을메트로",
    rn: 2721
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛고을메트로태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 빛고을메트로",
    rn: 2722
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "박달하수처리장가스터빈발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 2.664,
    company: "안양시 상하수도사업소",
    rn: 2723
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소4호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "코리아에너지발전소(주)",
    rn: 2724
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소5호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.091,
    company: "코리아에너지발전소(주)",
    rn: 2725
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소6호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.502,
    company: "코리아에너지발전소(주)",
    rn: 2726
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "탐라해상풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 18,
    company: "탐라해상풍력발전(주)",
    rn: 2727
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "탐라해상풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 12,
    company: "탐라해상풍력발전(주)",
    rn: 2728
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "어곡풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.08,
    company: "한진에너지 주식회사",
    rn: 2729
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)현도",
    rn: 2730
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)송림태양광",
    rn: 2731
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "노을그린연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 20,
    company: "노을그린에너지(주)",
    rn: 2732
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유원미래태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.477,
    company: "유원미래태양광(주)",
    rn: 2733
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유원성주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.962,
    company: "(주)유원 성주공장",
    rn: 2734
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.99,
    company: "(주)한국태양광발전소",
    rn: 2735
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "풍력시스템평가센터발전기#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "(재)전남테크노파크",
    rn: 2736
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단비신장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.192,
    company: "주식회사 에스아이",
    rn: 2737
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "천북풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 7.05,
    company: "지에스파워(주)",
    rn: 2738
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신광2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.663,
    company: "신광2호태양광발전소",
    rn: 2739
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진플라임1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.372,
    company: "우진에너지일호(주)",
    rn: 2740
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매포태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.975,
    company: "메가솔라하나(주)",
    rn: 2741
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수한태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.797,
    company: "메가솔라제이(주)",
    rn: 2742
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영희2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.775,
    company: "영희태양광발전소",
    rn: 2743
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.95,
    company: "(주)우진태양광발전소",
    rn: 2744
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우도경주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.974,
    company: "(주)우도에너지",
    rn: 2745
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진플라임2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.68,
    company: "우진에너지이호(주)",
    rn: 2746
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철도솔라4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.34,
    company: "철도솔라 주식회사",
    rn: 2747
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철도솔라5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "철도솔라 주식회사",
    rn: 2748
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "강릉대기리풍력 1회선",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 14,
    company: "효성윈드파워홀딩스주식회사",
    rn: 2749
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑인프라영암태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9.956,
    company: "주식회사 탑인프라 영암태양광발전소",
    rn: 2750
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주종합경기장 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "제주에너지공사",
    rn: 2751
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "위미태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.703,
    company: "위미태양광발전소",
    rn: 2752
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예산증곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.041,
    company: "예산증곡태양광발전소",
    rn: 2753
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "핼리오그린솔라11호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)핼리오그린솔라",
    rn: 2754
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "핼리오그린솔라12호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "(주)핼리오그린솔라",
    rn: 2755
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "핼리오그린솔라20호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)핼리오그린솔라",
    rn: 2756
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "야룡1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.251,
    company: "주식회사엔더블유에너지",
    rn: 2757
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "강동풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 7.05,
    company: "경주산업단지풍력발전(주)",
    rn: 2758
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스에너지28호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.703,
    company: "주식회사 티에스에너지28호",
    rn: 2759
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단비덕실태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.964,
    company: "솔라도시 주식회사",
    rn: 2760
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성남리1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "솔라도시 주식회사",
    rn: 2761
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성남리2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "솔라도시 주식회사",
    rn: 2762
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신광1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.697,
    company: "신광1호태양광발전소",
    rn: 2763
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아침태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주)아침태양광",
    rn: 2764
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.999,
    company: "(주)광일태양광발전소",
    rn: 2765
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이원태양발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.603,
    company: "(주)케이에너지",
    rn: 2766
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "전주파워바이오매스STG#2",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 32.36,
    company: "(주)전주파워",
    rn: 2767
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.848,
    company: "부성태양광발전소",
    rn: 2768
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "울산SRF차압발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.8,
    company: "(주)에코비트에너지울산",
    rn: 2769
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "하장2풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "하장2풍력발전(주)",
    rn: 2770
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "추계리태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "미래에코에너지주식회사",
    rn: 2771
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영동공수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.757,
    company: "미래에코에너지주식회사",
    rn: 2772
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포곡55사단태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)포곡그린에너지",
    rn: 2773
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KCTC태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.572,
    company: "(주)포곡그린에너지",
    rn: 2774
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다니엘윈체태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.484,
    company: "(주)회명에너지",
    rn: 2775
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.196,
    company: "(주)안덕태양광",
    rn: 2776
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지상상예태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "지상상예태양광발전소",
    rn: 2777
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "럭스솔라1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.376,
    company: "(주)선데이",
    rn: 2778
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주해오름태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)해성프라임",
    rn: 2779
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호 태양이앤지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.616,
    company: "케이솔라이호 주식회사",
    rn: 2780
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진환태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진환태양광발전소",
    rn: 2781
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준규솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.004,
    company: "주식회사준규솔라태양광발전소",
    rn: 2782
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광령태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.292,
    company: "광령태양광발전소",
    rn: 2783
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "CB상주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.456,
    company: "주식회사 케이앤알",
    rn: 2784
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "선창바이오매스발전",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 7.9,
    company: "선창산업(주)",
    rn: 2785
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광태양광c",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "(유)율현",
    rn: 2786
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금산단양1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.705,
    company: "(주)금산솔라1",
    rn: 2787
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "유기성폐기물연소발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 1.05,
    company: "영천바이오에너지(주)",
    rn: 2788
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이호동오도마을회3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "이호동오도마을회",
    rn: 2789
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해모아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.115,
    company: "주식회사 부성",
    rn: 2790
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대웅에너지태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.669,
    company: "대웅에너지태양광발전소2호",
    rn: 2791
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "동우에너지소재열병합",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.1,
    company: "(주)동우에너지소재",
    rn: 2792
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "따라비태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "따라비태양광발전소",
    rn: 2793
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시화방아머리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.04,
    company: "한국수자원공사",
    rn: 2794
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영흥 태양광 3단지-1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.332,
    company: "한국남동발전(주)",
    rn: 2795
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영흥 태양광 3단지-2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.283,
    company: "한국남동발전(주)",
    rn: 2796
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영흥 태양광 3단지-3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.538,
    company: "한국남동발전(주)",
    rn: 2797
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "비노텍신재생발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.565,
    company: "비노텍 주식회사",
    rn: 2798
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다모아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.875,
    company: "유한회사 다모아",
    rn: 2799
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래개발태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "미래개발주식회사",
    rn: 2800
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동구태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동구태양광발전소",
    rn: 2801
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "양주권자원회수시설",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3.2,
    company: "양주시청",
    rn: 2802
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서산덕지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.986,
    company: "(유)세사람에너지",
    rn: 2803
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛하만리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.797,
    company: "주식회사 한빛하만리태양광발전소",
    rn: 2804
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소리솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.202,
    company: "(주)소리솔라",
    rn: 2805
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소리솔라2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.301,
    company: "(주)소리솔라",
    rn: 2806
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.629,
    company: "(주)지에스솔라",
    rn: 2807
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.629,
    company: "(주)지에스솔라",
    rn: 2808
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.595,
    company: "(주)지에스솔라",
    rn: 2809
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황산태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.595,
    company: "(주)황산솔라파크",
    rn: 2810
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황산태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.578,
    company: "(주)황산솔라파크",
    rn: 2811
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황산태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.624,
    company: "(주)황산솔라파크",
    rn: 2812
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황산태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.516,
    company: "(주)황산솔라파크",
    rn: 2813
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황산태양광#5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.629,
    company: "(주)황산솔라파크",
    rn: 2814
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.391,
    company: "(주)원호솔라파크",
    rn: 2815
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.607,
    company: "(주)원호솔라파크",
    rn: 2816
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원호태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.59,
    company: "(주)원호솔라파크",
    rn: 2817
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "에이티아이텍(주) 제주지점",
    rn: 2818
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진양쏠라15호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.064,
    company: "진양쏠라 주식회사",
    rn: 2819
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진양쏠라20호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.893,
    company: "진양쏠라 주식회사",
    rn: 2820
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진양12호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.974,
    company: "진양쏠라 주식회사",
    rn: 2821
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "관대리습지형수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.111,
    company: "한국수자원공사",
    rn: 2822
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엘피양지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.851,
    company: "솔라원일호 주식회사",
    rn: 2823
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스앤이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.074,
    company: "에스앤이 주식회사",
    rn: 2824
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "유한회사 주덕태양광",
    rn: 2825
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어음태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.425,
    company: "(주)어음태양광발전소",
    rn: 2826
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이그린에너지(주)",
    rn: 2827
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "진로발효바이오발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 2.9,
    company: "(주)진로발효",
    rn: 2828
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이레태양광c",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.954,
    company: "(주)이레태양광발전소",
    rn: 2829
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천항햇빛발전소9",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.434,
    company: "인천항만쏠라(주)",
    rn: 2830
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KCC울산물류센터 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.862,
    company: "(주)케이씨씨 본부영업소",
    rn: 2831
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "카피어솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "카피어솔라(주)",
    rn: 2832
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신평가덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.868,
    company: "주식회사 대광에너지",
    rn: 2833
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정읍태곡1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 녹색에너지",
    rn: 2834
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석매1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 위저드",
    rn: 2835
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에스이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.093,
    company: "주식회사 케이에스이",
    rn: 2836
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금능2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "금능2호태양광발전소",
    rn: 2837
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금능1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "금능1호 태양광발전소",
    rn: 2838
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)에스솔라",
    rn: 2839
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.994,
    company: "(유)구곡2호에너지",
    rn: 2840
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한율1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한율1호태양광발전소",
    rn: 2841
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한율2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한율1호태양광발전소",
    rn: 2842
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천혜태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "천혜 태양광발전소",
    rn: 2843
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "드림태양광발전소",
    rn: 2844
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양전기종묘태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태양전기",
    rn: 2845
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "부산그린연료전지#2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 15.4,
    company: "부산그린에너지(주)",
    rn: 2846
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "표선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "(주)표선태양광",
    rn: 2847
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠아이씨태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "(주)엠아이씨전기",
    rn: 2848
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "종현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "종현태양광발전소",
    rn: 2849
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "제이태양광발전소",
    rn: 2850
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한화화약 보은 태양광설비",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "한반도태양광(주)",
    rn: 2851
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한화기계창원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.627,
    company: "한반도태양광(주)",
    rn: 2852
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이뱅크9호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.749,
    company: "디와이뱅크(주)",
    rn: 2853
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)회명에너지",
    rn: 2854
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다니엘두하태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.966,
    company: "(주)회명에너지",
    rn: 2855
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국탄소배출권거래소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "한국탄소배출권거래소(주)",
    rn: 2856
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한솔태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "(주)한솔태양광",
    rn: 2857
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선보전기 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.803,
    company: "(주)선보전기",
    rn: 2858
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상명2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상명2호 태양광발전소",
    rn: 2859
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포태양광#5-1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.94,
    company: "한국남동발전(주)",
    rn: 2860
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포태양광#5-2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6.647,
    company: "한국남동발전(주)",
    rn: 2861
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세일솔라덕산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.001,
    company: "세일솔라 주식회사",
    rn: 2862
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상은태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "(주)상은태양광",
    rn: 2863
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래에너지제주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "(주)미래에너지제주",
    rn: 2864
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강석2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강석2호태양광발전소",
    rn: 2865
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#5 금오에너지",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 2866
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보현산댐태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.402,
    company: "태양광발전소 화북영농조합법인",
    rn: 2867
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가시리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.501,
    company: "(주)가시리태양광",
    rn: 2868
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "정우 태양광발전소",
    rn: 2869
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린에스앤이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "그린에스앤이",
    rn: 2870
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이더블유솔라파크 태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 12.213,
    company: "주식회사 제이더블유솔라파크",
    rn: 2871
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이더블유솔라파크태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9.566,
    company: "주식회사 제이더블유솔라파크",
    rn: 2872
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)한국태양광발전3호",
    rn: 2873
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제삼경인고속월곶태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)한국태양광발전3호",
    rn: 2874
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제삼경인고속연성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.844,
    company: "(주)한국태양광발전3호",
    rn: 2875
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이스에너지도개 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "주식회사 에이스에너지도개",
    rn: 2876
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망빛부북위양 제3발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.658,
    company: "희망빛발전주식회사",
    rn: 2877
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평지저수지수상태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.706,
    company: "주식회사 워터솔라",
    rn: 2878
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "강릉대기리풍력 2회선",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 12,
    company: "효성윈드파워홀딩스주식회사",
    rn: 2879
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SUPEX3A태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.597,
    company: "전남도시가스(주)",
    rn: 2880
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SUPEX3B태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.193,
    company: "전남도시가스(주)",
    rn: 2881
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SUPEX3C태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "전남도시가스(주)",
    rn: 2882
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제2수송단태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.091,
    company: "(주)나전솔라",
    rn: 2883
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고경태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.286,
    company: "이레에너지 주식회사",
    rn: 2884
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "위천2호햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "주식회사 강천에너지",
    rn: 2885
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승문태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.423,
    company: "장수갈산태양광발전소 주식회사",
    rn: 2886
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성진A 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "성진A 태양광발전소",
    rn: 2887
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시흥정수장태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.311,
    company: "한국수자원공사",
    rn: 2888
    },
    {
    area: "수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "일산정수장소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.25,
    company: "한국수자원공사",
    rn: 2889
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진플라임3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.152,
    company: "우진에너지삼호(주)",
    rn: 2890
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바른빛에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "바른빛에너지",
    rn: 2891
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "성림에너지 발전1호기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 9.1,
    company: "성림에너지 주식회사",
    rn: 2892
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔트웰2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.696,
    company: "(주)엔트웰(남원지점)",
    rn: 2893
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(유)성산에너파크",
    rn: 2894
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주마을태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.079,
    company: "제주마을태양광발전소",
    rn: 2895
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "간촌리 지선 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.656,
    company: "산야썬 주식회사",
    rn: 2896
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산테크팩태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.633,
    company: "동원솔라에너지(주)",
    rn: 2897
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동해1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "농업회사법인 주식회사 부송농원 해남지점",
    rn: 2898
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.987,
    company: "(유)구곡1호에너지",
    rn: 2899
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.695,
    company: "(주)여광",
    rn: 2900
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명전설태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "(주)광현",
    rn: 2901
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월곡선창태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "월곡선창발전소",
    rn: 2902
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "대기제1풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.35,
    company: "대기풍력발전(주)",
    rn: 2903
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "안성태양광발전소",
    rn: 2904
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김포공급센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "서울도시가스(주)",
    rn: 2905
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘농장풍양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.503,
    company: "하늘농장주식회사",
    rn: 2906
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘농장6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.503,
    company: "하늘농장주식회사",
    rn: 2907
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘농장15호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.503,
    company: "하늘농장주식회사",
    rn: 2908
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이엔솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "와이엔솔라주식회사",
    rn: 2909
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월곡남양발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.991,
    company: "월곡남양발전소",
    rn: 2910
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구례에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.097,
    company: "주식회사구례에너지",
    rn: 2911
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.003,
    company: "(주)미래",
    rn: 2912
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "춘천쏠라테크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.003,
    company: "(주)춘천쏠라테크",
    rn: 2913
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선도리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.391,
    company: "(주)경인솔라시스템",
    rn: 2914
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율문쏠라테크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "(주)율문쏠라테크",
    rn: 2915
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구례에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.097,
    company: "주식회사구례에너지1호",
    rn: 2916
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구례삼산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.097,
    company: "주식회사구례삼산발전소",
    rn: 2917
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "대청댐소수력#2",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.9,
    company: "한국수자원공사",
    rn: 2918
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "시흥배곧가압장연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 6,
    company: "주식회사 페트로코너지",
    rn: 2919
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대동수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.041,
    company: "(주)대동수상태양광발전소",
    rn: 2920
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.82,
    company: "(주)두온이앤에스",
    rn: 2921
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남그린에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9.498,
    company: "(주)해남그린에너지",
    rn: 2922
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.997,
    company: "마정에너지 주식회사",
    rn: 2923
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼아에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "(주)삼아에너지",
    rn: 2924
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.36,
    company: "유한회사 이지테크윈",
    rn: 2925
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자운에스지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "자운에스지(주)",
    rn: 2926
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린6태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "그린6태양광발전소",
    rn: 2927
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천북공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.345,
    company: "현대힘스(주) 천북공장",
    rn: 2928
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수월봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "수월봉태양광발전소",
    rn: 2929
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "죽성솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "(주)죽성솔라",
    rn: 2930
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진태양광c",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우진태양광발전소",
    rn: 2931
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고리본부태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.146,
    company: "한국수력원자력(주)",
    rn: 2932
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "[평택]발전부지내 태양광 1단계",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.22,
    company: "한국서부발전(주)",
    rn: 2933
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "[서인천]발전부지내 태양광 1단계",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.1,
    company: "한국서부발전(주)",
    rn: 2934
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "김해바이오가스1발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.99,
    company: "(주)지엔씨에너지",
    rn: 2935
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한자1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.611,
    company: "(주)한자태양광",
    rn: 2936
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한자2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.011,
    company: "(주)한자태양광",
    rn: 2937
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대야에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "(주)대야에너지",
    rn: 2938
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨그마렌텍2영월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.767,
    company: "씨그마렌텍2영월태양광 주식회사",
    rn: 2939
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산소1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.57,
    company: "(주)티에스에너지7호",
    rn: 2940
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산소2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.611,
    company: "(주)티에스에너지7호",
    rn: 2941
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산소솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.287,
    company: "(주)산소솔라",
    rn: 2942
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KEPCO-13-G06-재환솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "켑코솔라 주식회사",
    rn: 2943
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신광솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 신광솔라",
    rn: 2944
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "제주축산바이오가스발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.099,
    company: "농업회사법인(주)제주축산바이오가스발전소",
    rn: 2945
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안발전부지태양광1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.884,
    company: "한국서부발전(주)",
    rn: 2946
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "개화솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.636,
    company: "(주)탑선태양광2호",
    rn: 2947
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지석리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.561,
    company: "우리환경에너지(주)",
    rn: 2948
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팡돌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "팡돌태양광발전소",
    rn: 2949
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.604,
    company: "(주)그린영암에너지",
    rn: 2950
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가온길에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.82,
    company: "(유)가온길에너지",
    rn: 2951
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.477,
    company: "(주)지원쏠라",
    rn: 2952
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "럭키쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.214,
    company: "럭키쏠라 주식회사",
    rn: 2953
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "대기제2풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.35,
    company: "대기풍력발전(주)",
    rn: 2954
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대죽1공장원료창고태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.905,
    company: "(주)케이씨씨 본부영업소",
    rn: 2955
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이씨씨여주제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.428,
    company: "주식회사 케이씨씨글라스 여주공장",
    rn: 2956
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신호솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "신호솔라에너지",
    rn: 2957
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스플래닛십호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.199,
    company: "에스플래닛십호(주)",
    rn: 2958
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래본드뱅크2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.655,
    company: "미래본드뱅크유한회사",
    rn: 2959
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다니엘태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "다니엘태양광발전소",
    rn: 2960
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명대태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.751,
    company: "주식회사 명대태양광발전",
    rn: 2961
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건국대학교제4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "건국대학교",
    rn: 2962
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해찬1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해찬1호 태양광발전소",
    rn: 2963
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "양구리풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 75.9,
    company: "(주)영양에코파워",
    rn: 2964
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산정관에너지 #1 GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 26.65,
    company: "부산정관에너지(발전)",
    rn: 2965
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청록담1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "(주)청록담",
    rn: 2966
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라에스엠태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "주식회사 솔라에스엠",
    rn: 2967
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "방제에너텍 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.003,
    company: "주식회사 방제에너텍",
    rn: 2968
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "경수태양광발전소",
    rn: 2969
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구례1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.097,
    company: "(주)구례1호태양광발전소",
    rn: 2970
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘땅태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.694,
    company: "주식회사하늘땅",
    rn: 2971
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승현태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "승현태양광발전소",
    rn: 2972
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.188,
    company: "주식회사 미래솔라",
    rn: 2973
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬플러스 7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "썬플러스에너지 7호 주식회사",
    rn: 2974
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창표태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "파워엠앤씨 주식회사",
    rn: 2975
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JH에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "JH에너지태양광",
    rn: 2976
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "누림에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "누림에너지태양광발전소",
    rn: 2977
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다온에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "다온에너지태양광발전소",
    rn: 2978
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "온새미로에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.449,
    company: "(유)온새미로에너지",
    rn: 2979
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "동복리 마을풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "동복리",
    rn: 2980
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치케이에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.911,
    company: "에이치케이에너지 주식회사",
    rn: 2981
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성보태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.484,
    company: "성보태양광",
    rn: 2982
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "화력",
    genNm: "이에스세종발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.8,
    company: "주식회사 이에스세종",
    rn: 2983
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JJHAE태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "JJHAE태양광발전소",
    rn: 2984
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에스평동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.746,
    company: "(주)케이에스에너지",
    rn: 2985
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청수리1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.495,
    company: "(주)엔트웰",
    rn: 2986
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산정관에너지 #1 CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 45.836,
    company: "부산정관에너지(발전)",
    rn: 2987
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "(주)예진태양광발전소",
    rn: 2988
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "삼성태양광발전소",
    rn: 2989
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "혜성태양광발전소",
    rn: 2990
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대양에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "대양에너지태양광발전소",
    rn: 2991
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "MJ태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "MJ태양광발전소",
    rn: 2992
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해양에너지태양광발전소",
    rn: 2993
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "하장3풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 4.6,
    company: "하장3풍력발전 주식회사",
    rn: 2994
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "우진스틸(주)",
    rn: 2995
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월리발전소2호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "명월리발전소2호기",
    rn: 2996
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산정관에너지 #1 ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 19.186,
    company: "부산정관에너지(발전)",
    rn: 2997
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린게이블태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "그린게이블태양광발전소",
    rn: 2998
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일출솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.923,
    company: "주식회사 일출솔라에너지",
    rn: 2999
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.203,
    company: "삼척태양광발전(주)",
    rn: 3000
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤서태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "윤서태양광발전소",
    rn: 3001
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "방동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.792,
    company: "유한회사 방동태양광",
    rn: 3002
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보은1호 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "중부도시가스(주)(발전)",
    rn: 3003
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.741,
    company: "(주)광명4호태양광발전소",
    rn: 3004
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영양무창풍력발전소",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 24.15,
    company: "(주)GS영양풍력발전",
    rn: 3005
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금전햇빛1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "주식회사 맥원에너지",
    rn: 3006
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.706,
    company: "주식회사 탑솔라",
    rn: 3007
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금빛에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.58,
    company: "금빛에너지",
    rn: 3008
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더원산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 더원산업",
    rn: 3009
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대17호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.798,
    company: "팔선 태양광발전소",
    rn: 3010
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오창저수지 수상2호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.506,
    company: "주식회사 테크윈",
    rn: 3011
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "효정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "효정태양광발전소",
    rn: 3012
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호원하남공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.629,
    company: "(주)호원소촌공장",
    rn: 3013
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)네모8태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.517,
    company: "(유)네모8태양광발전소",
    rn: 3014
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이씨씨글라스 울산물류센터 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.881,
    company: "주식회사 케이씨씨글라스 울산물류센터",
    rn: 3015
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금빛에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "금빛에너지",
    rn: 3016
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이이디태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 케이이디",
    rn: 3017
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신광태양광발전소",
    rn: 3018
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "광명태양광발전소",
    rn: 3019
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "인성태양광발전소",
    rn: 3020
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거창미래태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "마산미래솔라 주식회사",
    rn: 3021
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화서휴게소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.43,
    company: "우람태양광강진발전소(주)",
    rn: 3022
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하늘빛태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 에코쏠라",
    rn: 3023
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "명사태양광발전소",
    rn: 3024
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한세태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "한세태양광발전소",
    rn: 3025
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목사동에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.975,
    company: "주식회사 서정에너지",
    rn: 3026
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)디에스피이에스1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.504,
    company: "(주)디에스피이에스",
    rn: 3027
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DSP2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.45,
    company: "(주)디에스피이엔지",
    rn: 3028
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DSP4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.81,
    company: "(주)디에스피이엔지",
    rn: 3029
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JH에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 제이에이치에너지",
    rn: 3030
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두산중공업 창원러닝센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "두산중공업(주)",
    rn: 3031
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해비치태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.402,
    company: "좋은산업(주)",
    rn: 3032
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한동2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한동2호 태양광발전소",
    rn: 3033
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승연태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "승연태양광발전소",
    rn: 3034
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한동1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한동1호 태양광발전소",
    rn: 3035
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.808,
    company: "(주)성산",
    rn: 3036
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자연애1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "자연애1호태양광발전소",
    rn: 3037
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자연애2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "자연애2호태양광발전소",
    rn: 3038
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승모 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "승모태양광발전소",
    rn: 3039
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "MC태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "MC태양광발전소",
    rn: 3040
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광전명도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 광전",
    rn: 3041
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승민태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "주식회사 승민태양광",
    rn: 3042
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율이리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.039,
    company: "(주)율이리태양광발전소",
    rn: 3043
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피에이치씨 제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "주식회사 피에이치씨",
    rn: 3044
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "김해바이오가스2발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.495,
    company: "(주)지엔씨에너지",
    rn: 3045
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운수태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "운수태양광발전소",
    rn: 3046
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스트태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "베스트태양광발전소",
    rn: 3047
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세정태양광발전소",
    rn: 3048
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상명3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상명3호태양광발전소",
    rn: 3049
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대림1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대림1호태양광발전소",
    rn: 3050
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율리2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "율리2태양광발전소",
    rn: 3051
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.469,
    company: "주식회사 광호",
    rn: 3052
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주빛나라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "(주)지상",
    rn: 3053
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성곡2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "(주)GS E&R",
    rn: 3054
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주해맑음태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.989,
    company: "제주해맑음 태양광발전소",
    rn: 3055
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계룡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "계룡태양광발전소",
    rn: 3056
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "루맷태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 루맷",
    rn: 3057
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "멱우수상회전식태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주)솔키스아일랜드멱덕우",
    rn: 3058
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕우수상회전식태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.656,
    company: "(주)솔키스아일랜드멱덕우",
    rn: 3059
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "은광태양광발전소",
    rn: 3060
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신산1호태양광발전소",
    rn: 3061
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "넥스트에너지폐기물유류화발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.95,
    company: "(주)넥스트에너지",
    rn: 3062
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.643,
    company: "주식회사 에스지에너지",
    rn: 3063
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사곡태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.106,
    company: "광명태양광 주식회사",
    rn: 3064
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서초정리1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.615,
    company: "광명태양광 주식회사",
    rn: 3065
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도마리1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.106,
    company: "광명태양광 주식회사",
    rn: 3066
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "멱곡1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.79,
    company: "광명태양광 주식회사",
    rn: 3067
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.75,
    company: "광명태양광 주식회사",
    rn: 3068
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "섬광에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.495,
    company: "(주)섬광에너지",
    rn: 3069
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "열린에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.495,
    company: "주식회사열린에너지",
    rn: 3070
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함케1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "함케1 태양광발전소",
    rn: 3071
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함케2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "함케2 태양광발전소",
    rn: 3072
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해찬태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.15,
    company: "해찬태양광발전소",
    rn: 3073
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서영1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "주식회사 서영",
    rn: 3074
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서영2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "주식회사 서영",
    rn: 3075
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남동정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.937,
    company: "(주)알지비남동정수장태양광발전소",
    rn: 3076
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가곡솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)가곡솔라",
    rn: 3077
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양항 세방 지붕형 태양광 #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.097,
    company: "한국남동발전(주)",
    rn: 3078
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서진9호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서진9호태양광발전소",
    rn: 3079
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청마10호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "청마10호태양광발전소",
    rn: 3080
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청마11호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "청마1호태양광",
    rn: 3081
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "풍경태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "(유) 일도엔지니어링",
    rn: 3082
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의성율현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "주식회사 대정물산",
    rn: 3083
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "광명그린연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.64,
    company: "(주)삼천리(발전)",
    rn: 3084
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠제이에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.994,
    company: "(주)엠제이에너지",
    rn: 3085
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천안쏠라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 천안쏠라에너지",
    rn: 3086
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남양쏠라에너지영덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 남양쏠라에너지",
    rn: 3087
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.999,
    company: "(주)한빛솔라",
    rn: 3088
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소현에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "소현에너지",
    rn: 3089
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "패스오버태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.638,
    company: "유한회사 패스오버",
    rn: 3090
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내의2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "그린허브(주)",
    rn: 3091
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내의1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "주식회사 에너지뱅크",
    rn: 3092
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스트에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "(주)넥스트에너지",
    rn: 3093
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.577,
    company: "주식회사 에스원지이",
    rn: 3094
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.297,
    company: "주식회사 이앤에스",
    rn: 3095
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#6 행원풍력",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 3096
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "(주)한빛솔라",
    rn: 3097
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "주식회사 현지솔라",
    rn: 3098
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석정태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "한국서부발전(주)",
    rn: 3099
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보현에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사보현에너지",
    rn: 3100
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동인태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주)동인태양광발전소",
    rn: 3101
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사선경태양광발전소",
    rn: 3102
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 준에너지",
    rn: 3103
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사금성에너지",
    rn: 3104
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "제주축산바이오가스2호",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.19,
    company: "농업회사법인(주)제주축산바이오가스발전소",
    rn: 3105
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수망햇빛1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "수망햇빛1호태양광발전소",
    rn: 3106
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.18,
    company: "수경태양광발전소",
    rn: 3107
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세양2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.478,
    company: "(주)세양",
    rn: 3108
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정율1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "정율1호태양광발전소",
    rn: 3109
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운지솔라 북삼1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.091,
    company: "운지솔라파크 주식회사",
    rn: 3110
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송인태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.479,
    company: "송인태양광발전소",
    rn: 3111
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에버그린태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.479,
    company: "주식회사 에버그린",
    rn: 3112
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "터와울태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "터와울태양광발전소",
    rn: 3113
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금오름1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "금오름1호태양광발전소",
    rn: 3114
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구명물1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "구명물1호태양광발전소",
    rn: 3115
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국일본통운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.256,
    company: "주식회사 에이비엠솔라",
    rn: 3116
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대죽석고보드 1/2호기 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.983,
    company: "(주)케이씨씨 본부영업소",
    rn: 3117
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔아이씨안동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.979,
    company: "주식회사 엔아이씨 코리아",
    rn: 3118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JK태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "농업회사법인 주식회사 베스코농원",
    rn: 3119
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "귀덕태양광1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "귀덕태양광발전소1호기",
    rn: 3120
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월리발전소1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "명월리발전소1호기",
    rn: 3121
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라22호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.442,
    company: "은성쏠라 주식회사",
    rn: 3122
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신정솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 신정솔라",
    rn: 3123
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스에너지외입태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.756,
    company: "주식회사 티에스에너지26호",
    rn: 3124
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외입1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 외입태양광",
    rn: 3125
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송고태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.587,
    company: "주식회사 송고에너지",
    rn: 3126
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.528,
    company: "주식회사 장지솔라",
    rn: 3127
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보대태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.511,
    company: "주식회사 보대에너지",
    rn: 3128
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산나리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.705,
    company: "주식회사 산나리솔라",
    rn: 3129
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청천지구수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "한국농어촌공사 보령지사",
    rn: 3130
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에프앤씨소학태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.097,
    company: "주식회사 에프앤씨",
    rn: 3131
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산정수장 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "제2대구솔라에너지 주식회사",
    rn: 3132
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대구국가산단배수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "제2대구솔라에너지 주식회사",
    rn: 3133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문산정수장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.92,
    company: "제2대구솔라에너지 주식회사",
    rn: 3134
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옥포배수지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.426,
    company: "제2대구솔라에너지 주식회사",
    rn: 3135
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경림1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 경림",
    rn: 3136
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두량저수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "한국농어촌공사 사천지사",
    rn: 3137
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.539,
    company: "은성산업(주)",
    rn: 3138
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팔도에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "주식회사 팔도에너지",
    rn: 3139
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.659,
    company: "주식회사 구일태양광",
    rn: 3140
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "열린에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.659,
    company: "주식회사 열린에너지",
    rn: 3141
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.659,
    company: "주식회사 삼성태양광",
    rn: 3142
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "칠성솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.503,
    company: "주식회사 칠성솔라",
    rn: 3143
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "난산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "난산태양광발전소",
    rn: 3144
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.202,
    company: "영동태양광발전소",
    rn: 3145
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일광에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "주식회사 일광에너지",
    rn: 3146
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이엔지솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.028,
    company: "주식회사 이엔지솔라",
    rn: 3147
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.129,
    company: "한국서부발전(주)",
    rn: 3148
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "지산태양광발전소",
    rn: 3149
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산복합2단계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.686,
    company: "한국서부발전(주)",
    rn: 3150
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 은진태양광",
    rn: 3151
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이더블유에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.695,
    company: "(주)케이더블유에너지",
    rn: 3152
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.061,
    company: "금성태양광발전소2호",
    rn: 3153
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월곶2차태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.741,
    company: "(주)한국태양광발전3호",
    rn: 3154
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "낙안솔라8호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "낙안솔라8호 태양광발전소",
    rn: 3155
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "JS에너지 태양광발전소",
    rn: 3156
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성남 태양광발전소",
    rn: 3157
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양주솔라2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "양주솔라2호 태양광발전소",
    rn: 3158
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서광 태양광발전소",
    rn: 3159
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명쏠라1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.323,
    company: "광명쏠라1호 태양광발전소",
    rn: 3160
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라21호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.272,
    company: "은성쏠라 주식회사",
    rn: 3161
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘케이에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "주식회사 엘케이에너지",
    rn: 3162
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해온태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.355,
    company: "해온태양광발전소",
    rn: 3163
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "도음산풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 19.2,
    company: "도음산풍력발전(주)",
    rn: 3164
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "웅태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "웅태양광발전소",
    rn: 3165
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창금3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "창금3호태양광발전소",
    rn: 3166
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미주1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "미주1호태양광발전소",
    rn: 3167
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구명물2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.083,
    company: "구명물2호태양광발전소",
    rn: 3168
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구명물3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "구명물3호태양광발전소",
    rn: 3169
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조운1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조운1호 태양광발전소",
    rn: 3170
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "K1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "K1태양광발전소",
    rn: 3171
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.5,
    company: "주식회사 케이에스에너지삼호",
    rn: 3172
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원주메탈1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 라미솔라",
    rn: 3173
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산L&S1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "유한회사 라미솔라",
    rn: 3174
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광주FS1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.618,
    company: "유한회사 라미솔라",
    rn: 3175
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진L&S1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.98,
    company: "유한회사 라미솔라",
    rn: 3176
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "충주특수강1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.01,
    company: "유한회사 라미솔라",
    rn: 3177
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "충주특수강2기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.004,
    company: "유한회사 라미솔라",
    rn: 3178
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창원특수강1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.794,
    company: "유한회사 라미솔라",
    rn: 3179
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "[서인천]발전부지내 태양광 2단계",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "한국서부발전(주)",
    rn: 3180
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.252,
    company: "한일태양광발전소",
    rn: 3181
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쉬영목태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "쉬영목태양광발전소",
    rn: 3182
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "한라태양광발전소",
    rn: 3183
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "최강일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "주식회사 최강일",
    rn: 3184
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스파워1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "(주)제이에스파워",
    rn: 3185
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국야스나가태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.359,
    company: "전북에너지서비스주식회사",
    rn: 3186
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린쏠라에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.456,
    company: "그린쏠라에너지",
    rn: 3187
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "Gridsol화명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "엘에스산전(주)",
    rn: 3188
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소8호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.063,
    company: "코리아에너지발전소(주)",
    rn: 3189
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성에너지태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "은성에너지(주)",
    rn: 3190
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스제이에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(유)에스제이에너지",
    rn: 3191
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아로드태양광3호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.703,
    company: "코리아로드태양광발전소 주식회사",
    rn: 3192
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "종훈쏠라 유한회사",
    rn: 3193
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해토태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.999,
    company: "주식회사 에스아이지문경",
    rn: 3194
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DSP6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.032,
    company: "주식회사 디에스피에너지3호",
    rn: 3195
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태성2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "태성2호태양광발전소",
    rn: 3196
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월곡1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(유)월곡가에너지",
    rn: 3197
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "자연태양광발전소",
    rn: 3198
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.697,
    company: "대명주식회사",
    rn: 3199
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일신산업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "(주)일신산업",
    rn: 3200
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.745,
    company: "세명에스앤지(주)",
    rn: 3201
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)성운",
    rn: 3202
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이비솔라에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 케이비솔라에너지",
    rn: 3203
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "Gridsol부산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.912,
    company: "엘에스산전(주)",
    rn: 3204
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지오에너지와우1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "지오에너지 와우",
    rn: 3205
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동인에너지와우1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "동인에너지 와우",
    rn: 3206
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨그마렌텍3원주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.387,
    company: "씨그마렌텍3원주태양광 주식회사",
    rn: 3207
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.501,
    company: "주식회사 대창발전소",
    rn: 3208
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상화태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 상화발전소",
    rn: 3209
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "기타",
    genNm: "정진1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "(주)제이엠파워",
    rn: 3210
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더썬태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.224,
    company: "더썬태양광발전소",
    rn: 3211
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "포승바이오발전",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 43.2,
    company: "포승그린파워(주)",
    rn: 3212
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.016,
    company: "(유) 광일산업",
    rn: 3213
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신인천전망대법사면태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.742,
    company: "한국남부발전(주)",
    rn: 3214
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동해시하수종말처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.417,
    company: "한국동서발전(주)",
    rn: 3215
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청풍호 수상태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "한국수자원공사",
    rn: 3216
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명관수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.212,
    company: "한국농어촌공사 함안지사",
    rn: 3217
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "두산풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "(주)노랑에너지",
    rn: 3218
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자혜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)자혜태양광",
    rn: 3219
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내일에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(유)내일에너지",
    rn: 3220
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금산전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "금산전력 주식회사",
    rn: 3221
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.495,
    company: "은성쏠라 주식회사",
    rn: 3222
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스와이솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "주식회사 에스와이솔라",
    rn: 3223
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "개천수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.048,
    company: "의성수상태양광주식회사",
    rn: 3224
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조성수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.713,
    company: "의성수상태양광주식회사",
    rn: 3225
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SD에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "케이솔라이호 주식회사",
    rn: 3226
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.151,
    company: "다성태양광발전소",
    rn: 3227
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경희솔라제1발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.35,
    company: "주식회사 경희솔라",
    rn: 3228
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경희솔라제2발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.35,
    company: "주식회사 경희솔라제2발전소",
    rn: 3229
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금산1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "금산1호태양광발전소",
    rn: 3230
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "유한회사 장수태양광발전소",
    rn: 3231
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주2호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "유한회사 장수태양광발전소",
    rn: 3232
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주3호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "유한회사 장수태양광발전소",
    rn: 3233
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "미래태양광발전소",
    rn: 3234
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 신흥그린에너지",
    rn: 3235
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내린천4태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.085,
    company: "(주)브이엘코리아",
    rn: 3236
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만운저수지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "한국농어촌공사 안동지사",
    rn: 3237
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤서2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "윤서2태양광발전소",
    rn: 3238
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤서3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "윤서3태양광발전소",
    rn: 3239
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤서4태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "윤서4태양광발전소",
    rn: 3240
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이저수지수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.519,
    company: "주식회사 이에프리뉴어블스수상태양광발전소제일호",
    rn: 3241
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "공주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.921,
    company: "주식회사 공주태양광발전소",
    rn: 3242
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.495,
    company: "광진쏠라 주식회사",
    rn: 3243
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉성리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.618,
    company: "에너지사랑 주식회사",
    rn: 3244
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전주MBC이서태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.062,
    company: "전주문화방송(주)",
    rn: 3245
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥태양발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신흥태양광발전소",
    rn: 3246
    },
    {
    area: "제주",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "대산소수력발전기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.019,
    company: "대산소수력발전 주식회사",
    rn: 3247
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인포시티1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "인포시티 유한회사",
    rn: 3248
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.84,
    company: "피브이플랜트이호 주식회사",
    rn: 3249
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "피브이플랜트삼호 주식회사",
    rn: 3250
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.959,
    company: "피브이플랜트사호 주식회사",
    rn: 3251
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강경솔라팜원삼척1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "주식회사 강경솔라팜원",
    rn: 3252
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강경솔라팜원삼척5태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.269,
    company: "주식회사 강경솔라팜원",
    rn: 3253
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서호1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(유)서호바에너지",
    rn: 3254
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "재원태양광발전소",
    rn: 3255
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척GES태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "삼척지이에스 주식회사",
    rn: 3256
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코원이에스햇빛발전기(군산)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.756,
    company: "코원에너지서비스(주)",
    rn: 3257
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도개솔라파크2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "도개솔라파크2",
    rn: 3258
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도개솔라파크8",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "도개솔라파크8",
    rn: 3259
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이제이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "제이제이 태양광발전소",
    rn: 3260
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내린천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.417,
    company: "(주)브이엘코리아",
    rn: 3261
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이티화성송신소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.985,
    company: "주식회사 케이티",
    rn: 3262
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)금강에너지",
    rn: 3263
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "낙동에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(주)낙동에너지",
    rn: 3264
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "W-SCOPE태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.62,
    company: "충청에너지서비스(주)",
    rn: 3265
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지티씨솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.995,
    company: "주식회사 지티씨솔라2호",
    rn: 3266
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안부지 태양광 2단계(대지)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 8.267,
    company: "한국서부발전(주)",
    rn: 3267
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안부지 태양광 2단계(건축물)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.721,
    company: "한국서부발전(주)",
    rn: 3268
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "판곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "판곡태양광발전소",
    rn: 3269
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산발전본부1400kWp발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.4,
    company: "한국남부발전(주)",
    rn: 3270
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명도햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "명도햇빛발전소",
    rn: 3271
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정진햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "정진햇빛발전주식회사",
    rn: 3272
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청명해태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.891,
    company: "주식회사 청명해",
    rn: 3273
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대리은하1호_태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대리은하1호2호태양광발전소",
    rn: 3274
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호(주)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "케이솔라일호 주식회사",
    rn: 3275
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미화에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "케이솔라이호 주식회사",
    rn: 3276
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "주식회사 윤성에너지",
    rn: 3277
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내동태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.894,
    company: "내동태양광발전소",
    rn: 3278
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송옥순태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "송옥순태양광발전소",
    rn: 3279
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해성1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해성1호태양광발전소",
    rn: 3280
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해성2호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해성2호태양광발전소",
    rn: 3281
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "한빛태양광발전소",
    rn: 3282
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지효1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지효1호태양광발전소",
    rn: 3283
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "세계태양광발전소",
    rn: 3284
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산수처리건물태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.111,
    company: "한국남부발전(주)",
    rn: 3285
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "울산#4CC건물옥상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.404,
    company: "한국동서발전(주)",
    rn: 3286
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "금강태양광발전소",
    rn: 3287
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천만ECO태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.611,
    company: "순천시청",
    rn: 3288
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금한1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "금한1호 태양광발전소",
    rn: 3289
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영화에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "영화에너지",
    rn: 3290
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성솔라ENS",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "삼성솔라이엔에스주식회사",
    rn: 3291
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희망태양광발전소",
    rn: 3292
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "순천매립가스발전소 가스엔진발전기",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.3,
    company: "(주)지엔씨에너지",
    rn: 3293
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산하태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "산하 태양광발전소",
    rn: 3294
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "정암풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 32.2,
    company: "정암풍력발전주식회사",
    rn: 3295
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보성파워텍1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.778,
    company: "보성파워텍(주)",
    rn: 3296
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "고수소수력수차발전기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.099,
    company: "한국농어촌공사 고창지사",
    rn: 3297
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승일음성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.886,
    company: "충청에너지서비스(주)",
    rn: 3298
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오메가태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "오메가태양광발전소",
    rn: 3299
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청솔태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.903,
    company: "청솔태양광발전소",
    rn: 3300
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕암쏠라파크발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(유)덕암쏠라파크",
    rn: 3301
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리에너지(주)영동공수태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "우리에너지 주식회사",
    rn: 3302
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유수암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.253,
    company: "유수암태양광발전소",
    rn: 3303
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "써니파크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.27,
    company: "써니파크승마장",
    rn: 3304
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정유원에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정유원에너지",
    rn: 3305
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영월 철도부지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.191,
    company: "한국남부발전(주)",
    rn: 3306
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YES동부울산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.12,
    company: "영남에너지서비스(주)",
    rn: 3307
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "데이원에너지(주)",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 48.4,
    company: "현대에너지주식회사",
    rn: 3308
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "김천열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 59,
    company: "김천에너지",
    rn: 3309
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "고성 1호기",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1040,
    company: "고성그린파워 주식회사",
    rn: 3310
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "고성 2호기",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1040,
    company: "고성그린파워 주식회사",
    rn: 3311
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#9",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1020,
    company: "한국동서발전(주)",
    rn: 3312
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#10",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1020,
    company: "한국동서발전(주)",
    rn: 3313
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국중부발전(주)",
    rn: 3314
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국중부발전(주)",
    rn: 3315
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#3",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 550,
    company: "한국중부발전(주)",
    rn: 3316
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#4",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국중부발전(주)",
    rn: 3317
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#5",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국중부발전(주)",
    rn: 3318
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#6",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국중부발전(주)",
    rn: 3319
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#7",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국중부발전(주)",
    rn: 3320
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "보령#8",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국중부발전(주)",
    rn: 3321
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3322
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3323
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#3",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3324
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#4",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3325
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#5",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3326
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#6",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3327
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#7",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3328
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#8",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국서부발전(주)",
    rn: 3329
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3330
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3331
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#3",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3332
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#4",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3333
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#5",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3334
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#6",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3335
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#7",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3336
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "하동#8",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남부발전(주)",
    rn: 3337
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼천포#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 560,
    company: "한국남동발전(주)",
    rn: 3338
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼천포#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 560,
    company: "한국남동발전(주)",
    rn: 3339
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼천포#3",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 560,
    company: "한국남동발전(주)",
    rn: 3340
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼천포#4",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 560,
    company: "한국남동발전(주)",
    rn: 3341
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼천포#5",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남동발전(주)",
    rn: 3342
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "삼천포#6",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국남동발전(주)",
    rn: 3343
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3344
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3345
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#3",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3346
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#4",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3347
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#5",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3348
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#6",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3349
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#7",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3350
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "당진#8",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 500,
    company: "한국동서발전(주)",
    rn: 3351
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "호남#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한국동서발전(주)",
    rn: 3352
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "호남#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한국동서발전(주)",
    rn: 3353
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영흥#1",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 800,
    company: "한국남동발전(주)",
    rn: 3354
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영흥#2",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 800,
    company: "한국남동발전(주)",
    rn: 3355
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영흥#3",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 870,
    company: "한국남동발전(주)",
    rn: 3356
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영흥#4",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 870,
    company: "한국남동발전(주)",
    rn: 3357
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영흥#5",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 870,
    company: "한국남동발전(주)",
    rn: 3358
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "영흥#6",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 870,
    company: "한국남동발전(주)",
    rn: 3359
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음발전1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "한마음발전1(주)",
    rn: 3360
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음발전2(주)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.989,
    company: "신생발전소 주식회사",
    rn: 3361
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "통일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "통일 태양광발전소",
    rn: 3362
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창수발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "(주)창수발전소",
    rn: 3363
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해운광명에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.407,
    company: "주식회사 해운광명에너지",
    rn: 3364
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스지이엠태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.137,
    company: "(주)에스지이엠",
    rn: 3365
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.101,
    company: "청아태양광발전소",
    rn: 3366
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소현에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.422,
    company: "소현에너지2호",
    rn: 3367
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아리랑태양광발전소 주식회사",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "아리랑태양광발전소 주식회사",
    rn: 3368
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연주 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "주식회사 솔라드림",
    rn: 3369
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의성솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "주식회사 가온에너지",
    rn: 3370
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현경태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "현경태양광발전소",
    rn: 3371
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가산수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "한국농어촌공사 밀양지사",
    rn: 3372
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성보태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "성보태양광발전소",
    rn: 3373
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서산2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서산태양광발전소",
    rn: 3374
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라팩토리3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.302,
    company: "주식회사 솔라팩토리 ",
    rn: 3375
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "화력",
    genNm: "대전환경에너지종합타운발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 4,
    company: "대전그린에너지센터 주식회사",
    rn: 3376
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "억새미발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.051,
    company: "억새미발전소",
    rn: 3377
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "SK가스연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 0.44,
    company: "에스케이가스(주)",
    rn: 3378
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바우네태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "바우네 태양광발전소",
    rn: 3379
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태광대독발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.407,
    company: "주식회사태광통신",
    rn: 3380
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창희1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "창희1호태양광발전소",
    rn: 3381
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창희2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "창희2호태양광발전소",
    rn: 3382
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제석태양광발전소01호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "제석 태양광발전소 01호",
    rn: 3383
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성광대독발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.425,
    company: "주식회사 성광드림",
    rn: 3384
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨지에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.38,
    company: "JCG솔라",
    rn: 3385
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨지에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.192,
    company: "JCG솔라",
    rn: 3386
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨지에너지3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "JCG솔라",
    rn: 3387
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양천안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "충청에너지서비스(주)",
    rn: 3388
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "늘푸른하늘태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.894,
    company: "푸른하늘태양광발전소(주)",
    rn: 3389
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "늘푸른하늘태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.703,
    company: "푸른하늘태양광발전소(주)",
    rn: 3390
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "늘푸른하늘태양광3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "푸른하늘태양광발전소(주)",
    rn: 3391
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중봉SG태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "새금풍력태양광1호주식회사",
    rn: 3392
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유천SG태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.032,
    company: "새금풍력태양광1호주식회사",
    rn: 3393
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태성1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태성1호태양광발전소",
    rn: 3394
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대리은하2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대리은하1호2호태양광발전소",
    rn: 3395
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윈체태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.508,
    company: "에너지사랑 주식회사",
    rn: 3396
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오스템세종1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.556,
    company: "(주)오스템",
    rn: 3397
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "초곡태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.492,
    company: "(주)초곡태양광발전소",
    rn: 3398
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대일화학",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.495,
    company: "대일화학",
    rn: 3399
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백송태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "백송태양광발전소",
    rn: 3400
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동광성에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "하동광성에너지(주)",
    rn: 3401
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마루왓3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "마루왓3호태양광발전소",
    rn: 3402
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지희태양광발전소",
    rn: 3403
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동희태양광발전소",
    rn: 3404
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남포전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "남포전력태양광발전소",
    rn: 3405
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주천행복태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주천행복발전소",
    rn: 3406
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정승기1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정승기1호태양광발전소",
    rn: 3407
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠제이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "엠제이태양광발전소",
    rn: 3408
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성두태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "성두태양광발전소",
    rn: 3409
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "효일1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "효일1호태양광발전소",
    rn: 3410
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이솔라1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "와이솔라1호태양광발전소",
    rn: 3411
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이솔라2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "와이솔라2호태양광발전소",
    rn: 3412
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유니온쏠라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.392,
    company: "유니온쏠라에너지(주) ",
    rn: 3413
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마루왓4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "마루왓4호태양광발전소",
    rn: 3414
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마루왓1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "마루왓1호태양광발전소",
    rn: 3415
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "일산연료전지#4",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.28,
    company: "한국동서발전(주)",
    rn: 3416
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스플랜트6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.675,
    company: "다이아몬드에너지 주식회사",
    rn: 3417
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나래에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "나래에너지 태양광발전소",
    rn: 3418
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영동에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영동에너지 태양광발전소",
    rn: 3419
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동강태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동강태양광발전소",
    rn: 3420
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "한빛에너지",
    rn: 3421
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유강플랜트태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "유강플랜트 주식회사",
    rn: 3422
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유강에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.946,
    company: "유강에너지 주식회사",
    rn: 3423
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영광송림리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "송림리 태양광발전소",
    rn: 3424
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.297,
    company: "은성쏠라 주식회사",
    rn: 3425
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단비발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "단비농원",
    rn: 3426
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥스에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 맥스에너지2호",
    rn: 3427
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보국전자태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.245,
    company: "보국전자(주)",
    rn: 3428
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나이스쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "나이스쏠라태양광발전소",
    rn: 3429
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "교성리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "와이즈솔라 주식회사",
    rn: 3430
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사담리태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "에코솔라에너지 주식회사",
    rn: 3431
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사담리태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "환경사랑 주식회사",
    rn: 3432
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔와이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "엔와이태양광발전소",
    rn: 3433
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테크노태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.197,
    company: "테크노에너지(주)",
    rn: 3434
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SUPEXF태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.826,
    company: "전남도시가스(주)",
    rn: 3435
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "SGC에너지 #1",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 54.529,
    company: "에스지씨에너지(주)군산사업부문",
    rn: 3436
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "SGC에너지 #2",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 30.54,
    company: "에스지씨에너지(주)군산사업부문",
    rn: 3437
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중덕태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "중덕태양광발전소",
    rn: 3438
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "행복태양광발전소",
    rn: 3439
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암KIC태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.998,
    company: "전남개발공사",
    rn: 3440
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강릉1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.45,
    company: "해런솔라주식회사",
    rn: 3441
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강릉2호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "해런솔라주식회사",
    rn: 3442
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성동8호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성동8호 태양광발전소",
    rn: 3443
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성동13호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성동13호 태양광발전소",
    rn: 3444
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서봉에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.912,
    company: "에스에너지(주)",
    rn: 3445
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜진1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "혜진1호태양광발전소",
    rn: 3446
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜진2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "혜진2호태양광발전소",
    rn: 3447
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신정솔라2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)티에스에너지19호",
    rn: 3448
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대명태양광발전소",
    rn: 3449
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강천1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "주식회사 강천1호",
    rn: 3450
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강천2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "주식회사 강천2호 ",
    rn: 3451
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "위천솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "주식회사 위천솔라",
    rn: 3452
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아쏠라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.445,
    company: "주식회사 코리아쏠라에너지",
    rn: 3453
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이피피농원에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "와이피피쏠라(주)",
    rn: 3454
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "온더로드태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "온더로드 태양광발전소",
    rn: 3455
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상천태양광발전소",
    rn: 3456
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하찬캄태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "주식회사 하찬캄태양광발전소",
    rn: 3457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거석태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.316,
    company: "거석태양광발전소",
    rn: 3458
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "홍성바이오가스발전",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 1,
    company: "주식회사 기반",
    rn: 3459
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산하영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "산하영태양광발전소",
    rn: 3460
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산하환태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "산하환태양광발전소",
    rn: 3461
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척소내태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "한국남부발전(주)",
    rn: 3462
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "신인천연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 78.96,
    company: "한국남부발전(주)",
    rn: 3463
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상생에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.995,
    company: "(주)다산5",
    rn: 3464
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주농산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "제주농산(주)",
    rn: 3465
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청명5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.748,
    company: "주식회사 청명",
    rn: 3466
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이엠성수1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.792,
    company: "아이엠성수1호태양광발전소",
    rn: 3467
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강정태양광발전소",
    rn: 3468
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에스파워1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.748,
    company: "주식회사 이에스파워",
    rn: 3469
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "레일에너지태양광6",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.707,
    company: "레일에너지(주)",
    rn: 3470
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세실쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세실쏠라 태양광 발전소",
    rn: 3471
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스태양광3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "(주)디에스태양광발전소3호",
    rn: 3472
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스태양광5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "주식회사 디에스태양광발전소5호",
    rn: 3473
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지피원오호리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.193,
    company: "주식회사 지피원",
    rn: 3474
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두강봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "두강봉태양광발전소",
    rn: 3475
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "HD태양광발전기1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.178,
    company: "주식회사 현대에너지",
    rn: 3476
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오성태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 오성태양광발전소",
    rn: 3477
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호원테크공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.218,
    company: "주식회사 호원테크",
    rn: 3478
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.997,
    company: "유한회사 신자연쏠라",
    rn: 3479
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "뮤즈태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "뮤즈태양광발전소",
    rn: 3480
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해비치3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해비치3호태양광발전소",
    rn: 3481
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼백쏠라랜드태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.565,
    company: "영농조합법인 내일",
    rn: 3482
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고래태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "고래태양광발전소",
    rn: 3483
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥리태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)에너윈드",
    rn: 3484
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.27,
    company: "선우태양광발전소",
    rn: 3485
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "찬들태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 찬들태양광",
    rn: 3486
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "동양태양광",
    rn: 3487
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남전1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "주식회사 남전에너지",
    rn: 3488
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남전2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 개령에너지",
    rn: 3489
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스플랜트11호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "에스플래닛이십구호 주식회사",
    rn: 3490
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오뚜기태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "오뚜기 태양광발전소",
    rn: 3491
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JCY태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.751,
    company: "주식회사 나린태양광",
    rn: 3492
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS1SSPP태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 3493
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.436,
    company: "유한회사 사선",
    rn: 3494
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "영호태양광발전소",
    rn: 3495
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의수사랑에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 의수사랑에너지",
    rn: 3496
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천리광명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.173,
    company: "(주)삼천리(발전)",
    rn: 3497
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "천사태양광발전소",
    rn: 3498
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.312,
    company: "주식회사 유송태양광",
    rn: 3499
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)하늘에너지",
    rn: 3500
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조문국태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조문국 태양광발전소",
    rn: 3501
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇볕1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇볕1호 태양광발전소",
    rn: 3502
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해든에너지1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "해든에너지1",
    rn: 3503
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건국대학교경영관태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "건국대학교",
    rn: 3504
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치더블류에스지소촌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.724,
    company: "(주)H.K",
    rn: 3505
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장은에너지 제2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "장은에너지 주식회사",
    rn: 3506
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "박스뱅크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "박스뱅크주식회사",
    rn: 3507
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강석3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강석3호태양광발전소",
    rn: 3508
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준영 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 서영",
    rn: 3509
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송림태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "송림태양광발전소",
    rn: 3510
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지케이엠알오 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.479,
    company: "주식회사 지케이엠알오 (G. K)",
    rn: 3511
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨에스쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.478,
    company: "씨에스쏠라",
    rn: 3512
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청정태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "청정 태양광발전소",
    rn: 3513
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.929,
    company: "우주태양광발전소",
    rn: 3514
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "온스타1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "온스타1호 태양광발전소",
    rn: 3515
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "궁촌1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "궁촌1 태양광발전소",
    rn: 3516
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "궁촌2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "궁촌2 태양광발전소",
    rn: 3517
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해떠태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국산업전력공사",
    rn: 3518
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이디시에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.343,
    company: "(유)제이디시에너지",
    rn: 3519
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소민태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.311,
    company: "소민태양광발전소",
    rn: 3520
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "공암저수지수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 워터솔라",
    rn: 3521
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "근영산업1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)근영산업",
    rn: 3522
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "근영산업3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)근영산업",
    rn: 3523
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "한남태양광발전소",
    rn: 3524
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KCC중앙연구소 종합연구동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.134,
    company: "(주)케이씨씨 본부영업소",
    rn: 3525
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스비솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.451,
    company: "(주)에스비솔라",
    rn: 3526
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "금호여수열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 119.13,
    company: "금호석유화학(주)",
    rn: 3527
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "금호여수열병합#2",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 144.97,
    company: "금호석유화학(주)",
    rn: 3528
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우인에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "주식회사 우인에너지",
    rn: 3529
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음발전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "한마음발전주식회사",
    rn: 3530
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김태욱태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "태욱태양광발전소",
    rn: 3531
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보생발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.746,
    company: "보생 발전소",
    rn: 3532
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "켄스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "켄스태양광발전소",
    rn: 3533
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김택동.송명희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "김택동.송명희태양광발전소",
    rn: 3534
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "센도리2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.079,
    company: "센도리 제2태양광발전소",
    rn: 3535
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조양5호 태양발전소",
    rn: 3536
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조양6호태양광발전소",
    rn: 3537
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양10호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.694,
    company: "조양10호태양광발전소",
    rn: 3538
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양11호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조양11호태양발전소",
    rn: 3539
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양12호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조양12호태양광발전소",
    rn: 3540
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.434,
    company: "태성태양광발전소",
    rn: 3541
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상림 태양광발전소",
    rn: 3542
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "MG정읍태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "정읍새마을금고",
    rn: 3543
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "MG정읍태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.327,
    company: "정읍새마을금고(MG정읍푸드)",
    rn: 3544
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨앤피태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "씨앤피(C&P)태양광발전",
    rn: 3545
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양정태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "양정태양광발전",
    rn: 3546
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대지산업태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "대지산업(주)",
    rn: 3547
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문정석태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "문정석태양광발전소",
    rn: 3548
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성푸드태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 은성푸드",
    rn: 3549
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "365일에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.472,
    company: "365일에너지태양광발전소",
    rn: 3550
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.056,
    company: "승영태양광발전소",
    rn: 3551
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "여수바이오가스",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.306,
    company: "(주)지엔씨에너지",
    rn: 3552
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영순태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "영순태양광발전소",
    rn: 3553
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청풍호수상태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.202,
    company: "한국수자원공사",
    rn: 3554
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안저탄장태양광1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.81,
    company: "한국서부발전(주)",
    rn: 3555
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "정수발전소",
    rn: 3556
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양8호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "조양8호태양광발전소",
    rn: 3557
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양7호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "조양7호 태양광발전소",
    rn: 3558
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산제강3기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.544,
    company: "유한회사 라미솔라",
    rn: 3559
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸4기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.424,
    company: "유한회사 라미솔라",
    rn: 3560
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸5기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.647,
    company: "유한회사 라미솔라",
    rn: 3561
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸6기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.115,
    company: "(주)세아네트웍스",
    rn: 3562
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸7기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.249,
    company: "(주)세아네트웍스",
    rn: 3563
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성제침1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "(주)에스에스솔라파크",
    rn: 3564
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성제침2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.366,
    company: "(주)에스에스솔라파크",
    rn: 3565
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주덕대곡발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "(주)주덕대곡발전소",
    rn: 3566
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "(주)신영태양광발전소",
    rn: 3567
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "평화태양광발전소",
    rn: 3568
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다솔태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다솔태양광발전소",
    rn: 3569
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와룡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "와룡태양광발전소",
    rn: 3570
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비토태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "비토태양광발전소",
    rn: 3571
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "봉명태양광발전소",
    rn: 3572
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라파크원주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.918,
    company: "솔라에퀘티원주026 유한회사",
    rn: 3573
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남강태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "(유)남강에너지",
    rn: 3574
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양9호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.549,
    company: "조양9호 태양광발전소",
    rn: 3575
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림솔라1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "드림솔라1 태양광발전소",
    rn: 3576
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대룡1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 대룡기업",
    rn: 3577
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지앤비1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.26,
    company: "지앤비1호태양광발전",
    rn: 3578
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "은주태양광발전소",
    rn: 3579
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.702,
    company: "한원태양광",
    rn: 3580
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.479,
    company: "원희 태양광 발전소",
    rn: 3581
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진도성환2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한누리 태양광발전소",
    rn: 3582
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성덕태양광발전소",
    rn: 3583
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "삼천포풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 0.75,
    company: "한국남동발전(주)",
    rn: 3584
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.834,
    company: "주식회사한솔에너지",
    rn: 3585
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부귀리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.061,
    company: "부귀리 태양광발전소",
    rn: 3586
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영창이앤이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.434,
    company: "주식회사 영창이앤이",
    rn: 3587
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현우다인태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.038,
    company: "현우",
    rn: 3588
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.953,
    company: "그린존",
    rn: 3589
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금운1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "금운1태양광발전소",
    rn: 3590
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금운2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "금운2태양광발전소",
    rn: 3591
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SH화전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 에스에이치화전태양광",
    rn: 3592
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JL화전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "주식회사 제이엘화전태양광",
    rn: 3593
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "울진현종산풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 53.4,
    company: "울진풍력 주식회사",
    rn: 3594
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "섬진2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.479,
    company: "섬진2호 태양광발전소",
    rn: 3595
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대정2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대정2태양광발전소",
    rn: 3596
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광석에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "광석에너지",
    rn: 3597
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화선태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "화선태양광발전소",
    rn: 3598
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경민태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "경민태양광발전소",
    rn: 3599
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "하나태양광발전소",
    rn: 3600
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "광명태양광발전소",
    rn: 3601
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제일태양광발전소",
    rn: 3602
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.956,
    company: "유한회사 천일에너지",
    rn: 3603
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인7호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "유한회사 천일에너지",
    rn: 3604
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "녹산표준형공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "(주)부산도시가스",
    rn: 3605
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "경천태양광발전소",
    rn: 3606
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진에너지",
    rn: 3607
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한율용기태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "유한회사 한율",
    rn: 3608
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "익산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(유)금성썬에너지",
    rn: 3609
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양지동연태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "양지태양광발전소 유한회사",
    rn: 3610
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "보령에너지 태양광발전소",
    rn: 3611
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에너지태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "제이에너지 태양광발전소",
    rn: 3612
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보은2호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.194,
    company: "중부도시가스(주)(발전)",
    rn: 3613
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이씨씨글라스 전의공장 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.704,
    company: "주식회사 케이씨씨글라스 전의공장",
    rn: 3614
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복태양광발전소_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 더블넥서스",
    rn: 3615
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태웅태양광발전소_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "미래크린에너지 주식회사",
    rn: 3616
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청정태양광발전소_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 에너지니어스태양광1호",
    rn: 3617
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디엔피아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.497,
    company: "주식회사 디엔피아",
    rn: 3618
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원석1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.588,
    company: "유한회사 황금에너지",
    rn: 3619
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영화태양광발전소_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 풍영에너지",
    rn: 3620
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금학삼호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.485,
    company: "주식회사금학전기",
    rn: 3621
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금학사호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.599,
    company: "주식회사금학전기",
    rn: 3622
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수열병합발전기",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한화에너지(주) 여수공장",
    rn: 3623
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수열병합발전(구매)",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한화에너지(주) 여수공장(구매)",
    rn: 3624
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수열병합발전소#1(시운전)",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 125,
    company: "여수열병합발전(주)",
    rn: 3625
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수열병합발전소#2(시운전)",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 125,
    company: "여수열병합발전(주)",
    rn: 3626
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "군장열병합#2(구매)",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 99,
    company: "한화에너지(주) 군산공장(구매)",
    rn: 3627
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "군장열병합#2",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 99,
    company: "한화에너지(주)군산공장(구역)",
    rn: 3628
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "군장열병합#1(구매)",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 122.9,
    company: "한화에너지(주) 군산공장(구매)",
    rn: 3629
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에이치글로벌첨단공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.144,
    company: "(주)디에이치글로벌 첨단공장 ",
    rn: 3630
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디엔아이장교리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(주)디엔아이코퍼레이션(지점)",
    rn: 3631
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "SGC에너지 #3",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 60,
    company: "에스지씨에너지(주)군산사업부문",
    rn: 3632
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "SGC에너지 #4",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 250,
    company: "에스지씨에너지(주)군산사업부문",
    rn: 3633
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "SGC에너지 #5",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 28.5,
    company: "에스지씨에너지(주)군산사업부문",
    rn: 3634
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광풍력발전(육상)",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 45.1,
    company: "영광풍력발전 주식회사",
    rn: 3635
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광풍력발전(해상1단계)",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 16.1,
    company: "영광풍력발전 주식회사",
    rn: 3636
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광풍력발전(해상2단계)",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 18.4,
    company: "영광풍력발전 주식회사",
    rn: 3637
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성실5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.249,
    company: "성실에프앤에프 영농조합법인",
    rn: 3638
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성실3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "에스디 유한회사",
    rn: 3639
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다우장교리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.487,
    company: "(주)다우코퍼레이션(지점)",
    rn: 3640
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#9",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1050,
    company: "한국서부발전(주)",
    rn: 3641
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "태안#10",
    cent: "중앙",
    fuel: "유연탄",
    pcap: 1050,
    company: "한국서부발전(주)",
    rn: 3642
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스이씨에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.339,
    company: "SEC ENERGY 태양광발전소",
    rn: 3643
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 중앙시스템 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.87,
    company: "주식회사 중앙시스템",
    rn: 3644
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나무리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.966,
    company: "나무리태양광발전소 주식회사",
    rn: 3645
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순은태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "순은태양광발전소",
    rn: 3646
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사벌쏠라랜드태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "사벌쏠라랜드 주식회사",
    rn: 3647
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진쏠라랜드태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.68,
    company: "유진쏠라랜드 주식회사",
    rn: 3648
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "주식회사 태영태양광발전소",
    rn: 3649
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)소곡태양광발전소",
    rn: 3650
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단월리발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "충주브래드 11호",
    rn: 3651
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "득량태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "득량 태양광발전소",
    rn: 3652
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "근우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "SH에너지",
    rn: 3653
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.481,
    company: "(주)해바람",
    rn: 3654
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백운태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.472,
    company: "주식회사 동화",
    rn: 3655
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대곡태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "주식회사 대곡",
    rn: 3656
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송이용지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "유한회사 송이",
    rn: 3657
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가델에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "가델에너지태양광발전소",
    rn: 3658
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대현태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 대현태양광발전소",
    rn: 3659
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복태양광d",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "행복태양광발전소",
    rn: 3660
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한태연2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.506,
    company: "주식회사 한태연2호",
    rn: 3661
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보덕3호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "보덕3호태양광발전소",
    rn: 3662
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보덕4호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "보덕4호태양광발전소",
    rn: 3663
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보덕5호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.081,
    company: "보덕5호태양광발전소",
    rn: 3664
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어필태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "어필 태양광발전소",
    rn: 3665
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "샘골1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.829,
    company: "샘골1호태양광발전소",
    rn: 3666
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼이일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "삼이일태양광발전소",
    rn: 3667
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도개2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "에스아이텍 주식회사",
    rn: 3668
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로솔라5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.245,
    company: "아폴로솔라5호 주식회사",
    rn: 3669
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동연1호태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(유)태광에너지",
    rn: 3670
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "회천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "회천태양광발전소",
    rn: 3671
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "도림태양광발전소",
    rn: 3672
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명림태양광발전소",
    rn: 3673
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라14호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.095,
    company: "은성쏠라 주식회사",
    rn: 3674
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두산솔라파워1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "퓨처에너지 인프라 주식회사",
    rn: 3675
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두산솔라파워2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "퓨처에너지 인프라 주식회사",
    rn: 3676
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두산솔라파워6호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.028,
    company: "퓨처에너지 인프라 주식회사",
    rn: 3677
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "낙원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "대전리2호태양광발전소",
    rn: 3678
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대전리11호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "메가나인 주식회사",
    rn: 3679
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YES청도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.721,
    company: "영남에너지서비스(주)",
    rn: 3680
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은하산업 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.282,
    company: "(주)은하산업",
    rn: 3681
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이오태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "이오태양광",
    rn: 3682
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "엘림태양광",
    rn: 3683
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광민솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "유한회사 광민솔라",
    rn: 3684
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)디원태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주) 디원",
    rn: 3685
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "진산 태양광발전소",
    rn: 3686
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "두산 태양광발전소",
    rn: 3687
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부곡한양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)부곡한양태양광",
    rn: 3688
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승일천안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.902,
    company: "충청에너지서비스(주)",
    rn: 3689
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송덕1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "송덕1호 태양광발전소",
    rn: 3690
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송덕2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "송덕2호 태양광발전소",
    rn: 3691
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송덕3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "송덕3호 태양광발전소",
    rn: 3692
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송덕4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "송덕4호 태양광발전소",
    rn: 3693
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송덕5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "송덕5호 태양광발전소",
    rn: 3694
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오지쏠라에너지발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.432,
    company: "주식회사 오지쏠라에너지",
    rn: 3695
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대논공발전소 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)현대논공발전소",
    rn: 3696
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "패스오버에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.251,
    company: "패스오버에너지태양광발전소",
    rn: 3697
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가비태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.245,
    company: "가비태양광발전소",
    rn: 3698
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "늘푸른태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "늘푸른태양광발전소",
    rn: 3699
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.874,
    company: "오지 태양광발전소",
    rn: 3700
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "(유)천지기업",
    rn: 3701
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동화력태양광5호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국남부발전(주)",
    rn: 3702
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕담태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "덕담태양광발전소",
    rn: 3703
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이디솔라포천발전소1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 케이디솔라원",
    rn: 3704
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대하이텍태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.458,
    company: "(주)현대하이텍",
    rn: 3705
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고경3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 고경3호태양광",
    rn: 3706
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우산태양광발전소",
    rn: 3707
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양사태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "양사태양광발전소",
    rn: 3708
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "부일태양광발전소",
    rn: 3709
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "교래태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "제주에너지공사",
    rn: 3710
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해나눔태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.799,
    company: "(주)해나눔태양광발전소",
    rn: 3711
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티디씨에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 티디씨전력기술",
    rn: 3712
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신풍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "신풍태양광발전소",
    rn: 3713
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이티태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "제이티태양광발전소",
    rn: 3714
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수화에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.971,
    company: "수화에너지",
    rn: 3715
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호진중리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.463,
    company: "주식회사 호진솔라원",
    rn: 3716
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포인2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "포인2태양광발전소",
    rn: 3717
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정현태양광발전소",
    rn: 3718
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석민태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "석민태양광발전소",
    rn: 3719
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명문태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명문태양광발전소",
    rn: 3720
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금오태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "금오발전소(주)",
    rn: 3721
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "지우태양광발전소(주)",
    rn: 3722
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용권태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.643,
    company: "(주)용권태양광발전소",
    rn: 3723
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석그린태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.866,
    company: "(주)석그린에너지",
    rn: 3724
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민경1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "민경태양광발전소",
    rn: 3725
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "한국수력원자력(주)",
    rn: 3726
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다니엘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한국수력원자력(주)",
    rn: 3727
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은숙태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.078,
    company: "은숙태양광발전소",
    rn: 3728
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영일태양광발전소",
    rn: 3729
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영광태양광발전소",
    rn: 3730
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "화연태양광발전소",
    rn: 3731
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영숙태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영숙태양광발전소",
    rn: 3732
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정희태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정희태양광발전소",
    rn: 3733
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정희태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정희태양광발전소2호",
    rn: 3734
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "재진태양광발전소",
    rn: 3735
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지현태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.079,
    company: "지현태양광발전소",
    rn: 3736
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경민 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "경민 태양광발전소",
    rn: 3737
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라텍스에너지 천안발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.996,
    company: "주식회사 솔라텍스에너지",
    rn: 3738
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한자3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.667,
    company: "주식회사 티에스에너지22호",
    rn: 3739
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원진에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(유)원진에너지",
    rn: 3740
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "우진3호 태양광발전소",
    rn: 3741
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우진4호 태양광발전소",
    rn: 3742
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YNES삼원강재태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.143,
    company: "영남에너지서비스(주)",
    rn: 3743
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대박3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "대박3호태양광발전소",
    rn: 3744
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "마산태양광발전소",
    rn: 3745
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬그린 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "썬그린(주)",
    rn: 3746
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.446,
    company: "우리에너지(주)",
    rn: 3747
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에버그린에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.399,
    company: "에버그린에너지(주)",
    rn: 3748
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "애치광에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.599,
    company: "애치광에너지(주)",
    rn: 3749
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "리치1호 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.507,
    company: "(주)리치",
    rn: 3750
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림천안 에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.788,
    company: "주식회사 드림천안에너지",
    rn: 3751
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거슨새미 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "거슨새미 태양광발전소",
    rn: 3752
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선미태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "선미태양광발전소",
    rn: 3753
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상동태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상동태양광발전소",
    rn: 3754
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "골드파크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "(주)다쓰테크",
    rn: 3755
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "분당연료전지#6",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.1,
    company: "한국남동발전(주)",
    rn: 3756
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "순천매립가스발전기",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.3,
    company: "(주)지엔씨에너지",
    rn: 3757
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마루왓2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "마루왓2호태양광발전소",
    rn: 3758
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마북태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.016,
    company: "주식회사 휴먼솔라",
    rn: 3759
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진숙태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "진숙태양광발전소",
    rn: 3760
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남도에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 남도에너지",
    rn: 3761
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금오름태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "금오름태양광발전소",
    rn: 3762
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "타이거솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 타이거솔라",
    rn: 3763
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "프라임제3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "프라임 제3태양광발전소",
    rn: 3764
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬웨이태양광 교성1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.645,
    company: "주식회사 썬웨이",
    rn: 3765
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬웨이태양광 교성2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.58,
    company: "주식회사 썬웨이",
    rn: 3766
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정성태양광발전소",
    rn: 3767
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조은태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조은태양광발전소",
    rn: 3768
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신세계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.02,
    company: "(주)신세계태양광발전소",
    rn: 3769
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에너지개발태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.796,
    company: "제이에너지개발 주식회사",
    rn: 3770
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쥴태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)쥴",
    rn: 3771
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동원에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "동원에너지(주)",
    rn: 3772
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다인햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "주식회사 다인솔라",
    rn: 3773
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비에스케이햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.459,
    company: "(주)비에스케이",
    rn: 3774
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지동리중 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.28,
    company: "태봉리 주식회사",
    rn: 3775
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "명월태양광발전소",
    rn: 3776
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "게이트원햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "주식회사 게이트원",
    rn: 3777
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비에스케이여주햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(주)비에스케이그린",
    rn: 3778
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬웨이햇빛발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "썬웨이햇빛발전소 주식회사",
    rn: 3779
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어덕1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "어덕1태양광발전소",
    rn: 3780
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어덕2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "어덕2 태양광발전소",
    rn: 3781
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제일솔라1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "피앤제이솔라 주식회사",
    rn: 3782
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SU799태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.245,
    company: "SU799태양광발전소",
    rn: 3783
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.446,
    company: "진성에너지개발(주)",
    rn: 3784
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금악1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.288,
    company: "성현에너지개발 주식회사",
    rn: 3785
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상대1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "밝은누리에너지(주)",
    rn: 3786
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼지솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 삼지솔라",
    rn: 3787
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JJ태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.24,
    company: "태양광발전소(JJ)",
    rn: 3788
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송현 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "송현 태양광발전소",
    rn: 3789
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "은성태양광발전소",
    rn: 3790
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "연일태양광발전소",
    rn: 3791
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순자태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "순자태양광발전소",
    rn: 3792
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "가야에너지(주)",
    rn: 3793
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동해에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "동해에너지(주)",
    rn: 3794
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대운태양광발전소",
    rn: 3795
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대운2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대운2호태양광발전소",
    rn: 3796
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세현3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세현3호 태양광발전소",
    rn: 3797
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정가태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정가 태양광발전소",
    rn: 3798
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "줄포태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "줄포태양광발전소",
    rn: 3799
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참길자동차학원 태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(유)참길자동차학원",
    rn: 3800
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참길자동차학원 태양광발전소 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(유)참길자동차학원",
    rn: 3801
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참길자동차학원 태양광발전소 4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(유)참길자동차학원",
    rn: 3802
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참길자동차학원 태양광발전소 5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(유)참길자동차학원",
    rn: 3803
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조문국2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조문국2호태양광발전소",
    rn: 3804
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉춘2호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "봉춘2호에너지",
    rn: 3805
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세영에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세영에너지",
    rn: 3806
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재원1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)대한그린에너지",
    rn: 3807
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신라태양광발전소",
    rn: 3808
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현대태양광발전소",
    rn: 3809
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동국태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "동국태양광발전소",
    rn: 3810
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "그린솔라팜 주식회사",
    rn: 3811
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성8-b호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.466,
    company: "그린솔라팜 주식회사",
    rn: 3812
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "절이대첩(주)",
    rn: 3813
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성9-b호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "절이대첩(주)",
    rn: 3814
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송암2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 신안소순태양광발전소",
    rn: 3815
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송암1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 신안복지태양광발전소",
    rn: 3816
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(재)박기범재단",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.251,
    company: "재단법인 박기범재단",
    rn: 3817
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월승리2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(주)리치스톤",
    rn: 3818
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보현1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "보현1태양광발전소",
    rn: 3819
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보현2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "보현2태양광발전소",
    rn: 3820
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지동리북 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한종지동리 주식회사",
    rn: 3821
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "미소 주식회사",
    rn: 3822
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성8-a호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.454,
    company: "미소 주식회사",
    rn: 3823
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린환경건설태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.932,
    company: "(유)그린환경건설",
    rn: 3824
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원주그린솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.464,
    company: "명성파워그린 주식회사",
    rn: 3825
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "성산태양광발전소",
    rn: 3826
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세방부산신항물류센터 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "세방(주)",
    rn: 3827
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스이에너지1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.247,
    company: "파워텍",
    rn: 3828
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철원3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.374,
    company: "메가파이브(주)",
    rn: 3829
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉개동주민대책위원회1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "봉개동 쓰레기매립장 주민대책위원회",
    rn: 3830
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도덕태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "도덕태양광발전소",
    rn: 3831
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "kt원주연수원 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.322,
    company: "주식회사 케이티",
    rn: 3832
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소수 수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "한국농어촌괴산지사",
    rn: 3833
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청명해2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.749,
    company: "주식회사 청명해",
    rn: 3834
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지용태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.18,
    company: "지용태양광발전소",
    rn: 3835
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로솔라4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.275,
    company: "아폴로솔라4호 주식회사",
    rn: 3836
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.201,
    company: "주식회사 태양",
    rn: 3837
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세현태양광발전소",
    rn: 3838
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세현2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세현2호태양광발전소",
    rn: 3839
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 태곡태양광발전소",
    rn: 3840
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한교태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "(주)한교태양광발전소",
    rn: 3841
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현창이엔지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "(주)광명전기",
    rn: 3842
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수경1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "수경1호태양광발전소",
    rn: 3843
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수경2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "수경2호태양광발전소",
    rn: 3844
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수경3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "수경3호태양광발전소",
    rn: 3845
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다사랑태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.061,
    company: "다사랑태양광발전소",
    rn: 3846
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛모아 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 빛모아",
    rn: 3847
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤아에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 윤아에너지",
    rn: 3848
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일권태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "일권 태양광발전소",
    rn: 3849
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신재생에너지홍보관주차장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "제주에너지공사",
    rn: 3850
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인피니티태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.582,
    company: "인피니티태양광발전소",
    rn: 3851
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성산3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성산3호태양광발전소",
    rn: 3852
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "블루플래닛일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.506,
    company: "블루플래닛일호(주)",
    rn: 3853
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스태양광발전소7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.307,
    company: "(주)디에스태양광발전소7호",
    rn: 3854
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이플랜트십일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.371,
    company: "피브이플랜트십일호(주)",
    rn: 3855
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피앤디솔라 수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.688,
    company: "주식회사 피앤디솔라",
    rn: 3856
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강릉제일솔라3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "에이원솔라 주식회사",
    rn: 3857
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENL10호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.098,
    company: "에너리스(주)",
    rn: 3858
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문막포진리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.505,
    company: "제주발전에너지 주식회사",
    rn: 3859
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "(주)용도태양광",
    rn: 3860
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월림에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "월림에너지태양광발전소",
    rn: 3861
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시온유 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.363,
    company: "시온유 태양광발전소",
    rn: 3862
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구룡제2발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.93,
    company: "주식회사구룡제2발전소",
    rn: 3863
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영해태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "영해태양광발전 주식회사",
    rn: 3864
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영림3태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "영림3 주식회사",
    rn: 3865
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사광덕",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.391,
    company: "주식회사 광덕",
    rn: 3866
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망빛단장범도 제1발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "희망빛발전주식회사",
    rn: 3867
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경원에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.363,
    company: "경원에너지",
    rn: 3868
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "에코그린솔라 주식회사",
    rn: 3869
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계웅산업 태양광발전기1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 계웅산업",
    rn: 3870
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 쏠",
    rn: 3871
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구시털태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "구시털 태양광",
    rn: 3872
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "생물종다양성연구소 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "재단법인 제주테크노파크",
    rn: 3873
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대치에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대치에너지 태양광발전소",
    rn: 3874
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해든에너지3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해든에너지3",
    rn: 3875
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해든에너지4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해든에너지4",
    rn: 3876
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해든에너지5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해든에너지5",
    rn: 3877
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한울태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "농업회사법인(유)다온",
    rn: 3878
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "향상기업태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "향상기업주식회사",
    rn: 3879
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해오름태양광발전소a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "해오름태양광발전소",
    rn: 3880
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "대동 태양광발전소",
    rn: 3881
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.962,
    company: "유한회사 삼원태양광",
    rn: 3882
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "토성1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "토성1호태양광발전소",
    rn: 3883
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "토성2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "토성2호태양광발전소",
    rn: 3884
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수망햇빛2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "수망햇빛2호태양광발전소",
    rn: 3885
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조운2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조운2호 태양광발전소",
    rn: 3886
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나월태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "유한회사 나월",
    rn: 3887
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일신1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "일신1호태양광발전소",
    rn: 3888
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "몽치태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "몽치태양광발전소",
    rn: 3889
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군하산업 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.484,
    company: "군하산업(주)",
    rn: 3890
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신혜태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.031,
    company: "신혜 태양광발전소",
    rn: 3891
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "콘카그린쏠라1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.97,
    company: "(주)콘카그린쏠라",
    rn: 3892
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이엠8호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "아이엠8호태양광발전소",
    rn: 3893
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정선1호태양광발전소",
    rn: 3894
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백옥태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "백옥태양광발전소",
    rn: 3895
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쌍용전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.942,
    company: "쌍용전력(주)",
    rn: 3896
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "분당연료전지#6-2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 3.25,
    company: "한국남동발전(주)",
    rn: 3897
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대선태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.881,
    company: "대선태양광발전소",
    rn: 3898
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "루시디티2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 루시디티",
    rn: 3899
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청마8호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "청마8호태양광발전소",
    rn: 3900
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화광관춘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "전남도시가스(주)",
    rn: 3901
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주명진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "제주명진태양광발전소",
    rn: 3902
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘소태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "하늘소태양광발전소",
    rn: 3903
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새만금솔라에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5,
    company: "새만금솔라에너지 주식회사",
    rn: 3904
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "아폴로1호",
    rn: 3905
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "풍력시스템평가센터발전사업(1단계)",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 4.2,
    company: "(재)전남테크노파크",
    rn: 3906
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도건1호태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.474,
    company: "주식회사 도건시스템",
    rn: 3907
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자등1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 에스에이치화전태양광",
    rn: 3908
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자등2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 에스에이치화전태양광",
    rn: 3909
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태승가애태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "주식회사 태승가애",
    rn: 3910
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주왕1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)주왕산업",
    rn: 3911
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에덴에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "에덴에너지태양광발전소",
    rn: 3912
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영진 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.795,
    company: "영진 태양광발전소",
    rn: 3913
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "평창노동풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.3,
    company: "대관령풍력주식회사",
    rn: 3914
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(주)보림에너지",
    rn: 3915
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "레즐러 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.401,
    company: "주식회사 레즐러태양발전소",
    rn: 3916
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라제스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.037,
    company: "주식회사 크레티오",
    rn: 3917
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동신이엔텍태양광발전소제주",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.697,
    company: "주식회사 동신이엔텍",
    rn: 3918
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경영태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "경영태양광발전소",
    rn: 3919
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청수1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.698,
    company: "진성에너지개발(주)",
    rn: 3920
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "하장4풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.3,
    company: "주원에너지(주)",
    rn: 3921
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행정16호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "행정16호태양광발전소",
    rn: 3922
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "동탄 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 11.44,
    company: "한국지역난방공사",
    rn: 3923
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "탑솔라 주식회사",
    rn: 3924
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "MY태양광 제4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "MY태양광발전소",
    rn: 3925
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스코태양광 제4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "베스코태양광발전소 제4호",
    rn: 3926
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "이화산업전력 주식회사",
    rn: 3927
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신우솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.451,
    company: "주식회사 신우솔라",
    rn: 3928
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선(SUN)1에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "선(SUN)1에너지1호발전소",
    rn: 3929
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선(SUN)1에너지2호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "선(SUN)1에너지2호발전소",
    rn: 3930
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#7 영광풍력",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 3931
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼다도태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.269,
    company: "삼다도농장1호태양광발전소",
    rn: 3932
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼다도태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.756,
    company: "삼다도농장2호태양광발전소",
    rn: 3933
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.471,
    company: "유한회사 신흥태양광발전소",
    rn: 3934
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아주산업(주)아산공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.302,
    company: "아주산업(주)아산공장",
    rn: 3935
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼원썬텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 삼원썬텍",
    rn: 3936
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼원쏠라텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 삼원쏠라텍",
    rn: 3937
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진제2회처리장수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.501,
    company: "한국동서발전(주)",
    rn: 3938
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "(주)티에이치솔라팜",
    rn: 3939
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성9-a호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "(주)티에이치솔라팜",
    rn: 3940
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "씨지앤율촌 연료전지#4",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 10,
    company: "씨지앤율촌전력(주)",
    rn: 3941
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한철강(주) 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.334,
    company: "대한철강(주)",
    rn: 3942
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대광1호 태양광발전소",
    rn: 3943
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대광2호 태양광발전소",
    rn: 3944
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대융에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 대융에너지",
    rn: 3945
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디케이에너지1발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.051,
    company: "주식회사 디케이에너지",
    rn: 3946
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디케이에너지2발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.011,
    company: "주식회사 디케이에너지",
    rn: 3947
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림팜A태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.422,
    company: "농업회사법인 유한회사 드림팜",
    rn: 3948
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림팜B태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.477,
    company: "농업회사법인 유한회사 드림팜",
    rn: 3949
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림팜C태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.477,
    company: "농업회사법인 유한회사 드림팜",
    rn: 3950
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일광쏠라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 일광쏠라",
    rn: 3951
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "카본텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.214,
    company: "카본텍주식회사",
    rn: 3952
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해태크발전소충주4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)대통산업",
    rn: 3953
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지에스북발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.378,
    company: "지에스북 발전(주)",
    rn: 3954
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지에스중발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.571,
    company: "지에스중 발전(주)",
    rn: 3955
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "서인천연료전지2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 60.28,
    company: "한국서부발전(주)",
    rn: 3956
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현천리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.805,
    company: "강원도",
    rn: 3957
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성1호태양광발전소",
    rn: 3958
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성2호태양광발전소",
    rn: 3959
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성5호태양광발전소",
    rn: 3960
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성6호태양광발전소",
    rn: 3961
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성9호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성9호태양광발전소",
    rn: 3962
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성12호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성12호태양광발전소",
    rn: 3963
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "포항시 생활폐기물 에너지화시설",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 12.65,
    company: "포항이앤이 주식회사",
    rn: 3964
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "W-SCOPE 2공장 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.618,
    company: "충청에너지서비스(주)",
    rn: 3965
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "리라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.816,
    company: "리라태양광발전소",
    rn: 3966
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이앤씨 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.401,
    company: "주식회사 케이앤씨",
    rn: 3967
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에덴축산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "에덴축산1호 태양광발전소",
    rn: 3968
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피앤이솔루션발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.05,
    company: "(주)피앤이솔루션",
    rn: 3969
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현경13호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현경13호 태양광발전소",
    rn: 3970
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성15호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 제이디그린텍",
    rn: 3971
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성13호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제이디영(주)",
    rn: 3972
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성11호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)써니발전",
    rn: 3973
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "필솔라 주식회사",
    rn: 3974
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이제이에스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.482,
    company: "(주)아이제이에스",
    rn: 3975
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성4호태양광발전소",
    rn: 3976
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성7호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성7호태양광발전소",
    rn: 3977
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성8호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성8호태양광발전소",
    rn: 3978
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성10호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성10호태양광발전소",
    rn: 3979
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성14호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성14호태양광발전소",
    rn: 3980
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성16호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성16호태양광발전소",
    rn: 3981
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아성17호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아성17호태양광발전소",
    rn: 3982
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월미태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "지에스파워(주)",
    rn: 3983
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이원에너지1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "케이원에너지1호 태양광발전소",
    rn: 3984
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이원에너지2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "케이원에너지2호 태양광발전소",
    rn: 3985
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엘태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.902,
    company: "주식회사 에스엘태양광",
    rn: 3986
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스제이1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 에스제이쏠라",
    rn: 3987
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "황산1호태양광발전소",
    rn: 3988
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에이치태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.958,
    company: "켑코솔라 주식회사",
    rn: 3989
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "부성태양광발전소",
    rn: 3990
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제다 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)제다",
    rn: 3991
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.259,
    company: "지성태양광발전소",
    rn: 3992
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "클라우드앤1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.092,
    company: "(주)클라우드앤",
    rn: 3993
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "클라우드앤2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.092,
    company: "클라우드앤2호 태양광발전소",
    rn: 3994
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황산2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "황산2호태양광발전소",
    rn: 3995
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한메탈2발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.239,
    company: "대한메탈(주)",
    rn: 3996
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건국대학교 생명과학관 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "건국대학교",
    rn: 3997
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "두류발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.6,
    company: "정세환경기술 주식회사",
    rn: 3998
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스코태양광 제2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "베스코태양광발전소 제2호",
    rn: 3999
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서강3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 서강레미콘",
    rn: 4000
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서강2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "서강2호 태양광발전소",
    rn: 4001
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림라인쏠라파워2호 화순1지점",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "드림라인쏠라파워2호 주식회사",
    rn: 4002
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림라인쏠라파워2호 화순2지점",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.581,
    company: "드림라인쏠라파워2호 주식회사",
    rn: 4003
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "패밀리2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "패밀리태양광발전소",
    rn: 4004
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해승태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "해승태양광",
    rn: 4005
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이케이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "에이케이태양광",
    rn: 4006
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬하이텍태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)세종물산",
    rn: 4007
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내린천3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.501,
    company: "(주)브이엘코리아",
    rn: 4008
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "소향소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 3,
    company: "한국농어촌공사 전주완주임실지사",
    rn: 4009
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이안태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.14,
    company: "이안태양광발전소",
    rn: 4010
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕암 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "덕암 태양광발전소",
    rn: 4011
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "서남해해상풍력실증단지",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 60,
    company: "한국해상풍력 주식회사",
    rn: 4012
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엠이2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.997,
    company: "주식회사 에스엠이2호 태양광발전회사",
    rn: 4013
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계화2발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.953,
    company: "한국농어촌공사 부안지사",
    rn: 4014
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계화3발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.953,
    company: "한국농어촌공사 부안지사",
    rn: 4015
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계화4발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.953,
    company: "한국농어촌공사 부안지사",
    rn: 4016
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "행안태양광발전소",
    rn: 4017
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한메탈1발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.734,
    company: "대한메탈(주)",
    rn: 4018
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여수안포리태양광발전2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "여수안포리태양광발전2호<홍광발전>",
    rn: 4019
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대성1호 태양광발전소",
    rn: 4020
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 제이디그린텍",
    rn: 4021
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제이디영(주)",
    rn: 4022
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)써니발전",
    rn: 4023
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "필솔라 주식회사",
    rn: 4024
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해뜸태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.966,
    company: "주식회사 해뜸",
    rn: 4025
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "시에스에스에너지 주식회사",
    rn: 4026
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동그라미태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.987,
    company: "시에스에스에너지 주식회사",
    rn: 4027
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "네모이엔지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.736,
    company: "주식회사 네모이엔지",
    rn: 4028
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "태일태양광발전소",
    rn: 4029
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이에이치테크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.495,
    company: "주식회사 케이에이치테크",
    rn: 4030
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영락태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "영락 태양광발전소",
    rn: 4031
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정촌태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.404,
    company: "지에스파워(주)",
    rn: 4032
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스파워2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "(주)제이에스파워",
    rn: 4033
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SKY 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "Sky 태양광발전소",
    rn: 4034
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민솔라5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "민솔라5호태양광발전소",
    rn: 4035
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민솔라6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "민솔라6호태양광발전소",
    rn: 4036
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전3호 태양광발전소",
    rn: 4037
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전5호 태양광발전소",
    rn: 4038
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전7호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전7호태양광발전소",
    rn: 4039
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전8호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전8호태양광발전소",
    rn: 4040
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전9호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전9호태양광발전소",
    rn: 4041
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전10호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전10호태양광발전소",
    rn: 4042
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전11호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전11호태양광발전소",
    rn: 4043
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영전12호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영전12호태양광발전소",
    rn: 4044
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아산시 폐기물 매립장 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.045,
    company: "한국서부발전(주)",
    rn: 4045
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경철태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 경철태양광",
    rn: 4046
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현인태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 현인태양광",
    rn: 4047
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 하나에너지",
    rn: 4048
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "하영태양광발전소",
    rn: 4049
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "(주)정선 태양광발전소",
    rn: 4050
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무진장2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "무진장 2호 태양광발전",
    rn: 4051
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사옥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "사옥 태양광발전소",
    rn: 4052
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영일 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "영일엔지니어링 주식회사",
    rn: 4053
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이솔라3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "와이솔라3호태양광발전소",
    rn: 4054
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이솔라4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "와이솔라4호태양광발전소",
    rn: 4055
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스프링웨이브1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "스프링웨이브1호태양광발전소",
    rn: 4056
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백경에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.289,
    company: "백경에너지 태양광발전소",
    rn: 4057
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.484,
    company: "유한회사 에스원쏠라",
    rn: 4058
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.218,
    company: "유한회사 에스원쏠라",
    rn: 4059
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남해평산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.408,
    company: "유한회사 예담에너지",
    rn: 4060
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남해덕월태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.553,
    company: "유한회사 송촌",
    rn: 4061
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "정우태양광발전소",
    rn: 4062
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예다움태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.401,
    company: "주식회사 예다움태양광",
    rn: 4063
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "꽃초롱태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "주식회사 꽃초롱태양광",
    rn: 4064
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현아태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현아태양광발전소",
    rn: 4065
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나목도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "주식회사 나목도태양광",
    rn: 4066
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테크엔태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.449,
    company: "(주)테크엔",
    rn: 4067
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성민태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "성민 태양광발전소",
    rn: 4068
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 제이디그린텍",
    rn: 4069
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금10호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제이디영(주)",
    rn: 4070
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)써니발전",
    rn: 4071
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "필솔라 주식회사",
    rn: 4072
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠제이에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.36,
    company: "(주)엠제이에너지",
    rn: 4073
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해님2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해님2호 태양광발전소",
    rn: 4074
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "레드썬태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 레드썬",
    rn: 4075
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "블루썬태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "블루썬",
    rn: 4076
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "배두둑 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "배두둑 태양광발전소",
    rn: 4077
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진안황금9호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진안황금9호 태양광발전소",
    rn: 4078
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보담태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.97,
    company: "보담태양광발전소",
    rn: 4079
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선명태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "선명태양광발전소",
    rn: 4080
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스마트에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "스마트에너지 태양광발전소",
    rn: 4081
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해님1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해님1호 태양광발전소",
    rn: 4082
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가나안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "가나안 태양광발전소",
    rn: 4083
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 송촌에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.959,
    company: "주식회사송촌에너지",
    rn: 4084
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영조2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영조2호태양광발전소",
    rn: 4085
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오름태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "오름태양광발전소",
    rn: 4086
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세계태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "세계태양광발전소",
    rn: 4087
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정희2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정희2호태양광발전소",
    rn: 4088
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "행원2018 1호태양광발전소",
    rn: 4089
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.914,
    company: "호원태양광발전소",
    rn: 4090
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(유)아폴로에너지",
    rn: 4091
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬로드에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.989,
    company: "주식회사 썬로드에너지",
    rn: 4092
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "하늘태양광발전소",
    rn: 4093
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우빈태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우빈태양광발전소",
    rn: 4094
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현빈태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현빈태양광발전소",
    rn: 4095
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제건1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.356,
    company: "제건1호 태양광발전소",
    rn: 4096
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "노곡리1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "솔라스토리 제5호 주식회사",
    rn: 4097
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래발전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "미래발전",
    rn: 4098
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영일태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.093,
    company: "영일태양광발전소",
    rn: 4099
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현진태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현진태양광발전소",
    rn: 4100
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑솔라2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.243,
    company: "탑솔라 주식회사",
    rn: 4101
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가온태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "가온태양광발전소",
    rn: 4102
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유현태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유현 태양광발전소",
    rn: 4103
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하와이에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "하와이에너지 주식회사",
    rn: 4104
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.288,
    company: "성장태양광발전소",
    rn: 4105
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국그린2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.868,
    company: "코리아쏠라 주식회사",
    rn: 4106
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KTE태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.051,
    company: "(주)KTE",
    rn: 4107
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "원일산업 태양광발전소",
    rn: 4108
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제3대구솔라에너지 제1발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "제3대구솔라에너지 주식회사",
    rn: 4109
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유니온산업(주)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.256,
    company: "유니온산업(주)",
    rn: 4110
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새론태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "새론태양광발전소",
    rn: 4111
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정석태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "정석 태양광발전소",
    rn: 4112
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "갈말 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.016,
    company: "갈말태양광발전소 주식회사",
    rn: 4113
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티브이 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.273,
    company: "주식회사 티브이태양광발전소",
    rn: 4114
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선우 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.043,
    company: "선우태양광발전소 주식회사",
    rn: 4115
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "샛별에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.398,
    company: "유한회사 샛별에너지",
    rn: 4116
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "결실 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "결실태양광발전소",
    rn: 4117
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "창원에너텍 잉여증기발전시설",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.75,
    company: "(주)창원에너텍",
    rn: 4118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아시아2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.092,
    company: "주식회사 아시아",
    rn: 4119
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아시아3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "주식회사 아시아",
    rn: 4120
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.347,
    company: "코지 태양광발전소",
    rn: 4121
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛9태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.117,
    company: "한빛9태양광발전소",
    rn: 4122
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재성1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "재성1호 태양광발전소",
    rn: 4123
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무한에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 무한에너지",
    rn: 4124
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재성2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "재성2호 태양광발전소",
    rn: 4125
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쌍용전장(주)태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.342,
    company: "쌍용전장(주)",
    rn: 4126
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만주에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "만주에너지",
    rn: 4127
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "공혁태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "공혁태양광발전소",
    rn: 4128
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서강레미콘 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "주식회사 서강레미콘",
    rn: 4129
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하윤태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.246,
    company: "하윤태양광발전소",
    rn: 4130
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의성발전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "의성발전 주식회사",
    rn: 4131
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고성태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "주식회사 고성태양광발전",
    rn: 4132
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "갈마태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "갈마태양광발전소",
    rn: 4133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕암7호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "덕암7호태양광발전소",
    rn: 4134
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신도리2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신도리1. 2태양광발전소",
    rn: 4135
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "BEP민정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "브라이트민정1호 주식회사",
    rn: 4136
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스와이2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.938,
    company: "에스와이패널주식회사",
    rn: 4137
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백현수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.955,
    company: "한국농어촌공사 의성군위지사",
    rn: 4138
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고운에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "고운에너지 주식회사",
    rn: 4139
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "2.4MW 바이오가스 발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 2.4,
    company: "수도권매립지관리공사",
    rn: 4140
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옻골 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.259,
    company: "옻골 태양광발전소",
    rn: 4141
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래제이에스티솔라1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.948,
    company: "주식회사 미래제이에스티",
    rn: 4142
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래제주솔라1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.948,
    company: "주식회사 미래제주",
    rn: 4143
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "굿초이스솔라1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.461,
    company: "주식회사 굿초이스",
    rn: 4144
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)지후에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 지후에너지",
    rn: 4145
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)세이프티솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 세이프티솔라",
    rn: 4146
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "벧엘 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.064,
    company: "유한회사 벧엘태양광",
    rn: 4147
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신인천발전본부 해수취수구 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.907,
    company: "한국남부발전(주)",
    rn: 4148
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸8기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "(주)세아네트웍스",
    rn: 4149
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸9기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.92,
    company: "(주)세아네트웍스",
    rn: 4150
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸12기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.434,
    company: "유한회사 라미솔라",
    rn: 4151
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸14기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.629,
    company: "(주)세아네트웍스",
    rn: 4152
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸15기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.467,
    company: "(주)세아네트웍스",
    rn: 4153
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸17기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.622,
    company: "유한회사 라미솔라",
    rn: 4154
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "주원 태양광발전소",
    rn: 4155
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "고리#1",
    cent: "중앙",
    fuel: "원자력",
    pcap: 0,
    company: "한국수력원자력(주)",
    rn: 4156
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "고리#2",
    cent: "중앙",
    fuel: "원자력",
    pcap: 650,
    company: "한국수력원자력(주)",
    rn: 4157
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "고리#3",
    cent: "중앙",
    fuel: "원자력",
    pcap: 950,
    company: "한국수력원자력(주)",
    rn: 4158
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "고리#4",
    cent: "중앙",
    fuel: "원자력",
    pcap: 950,
    company: "한국수력원자력(주)",
    rn: 4159
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산베스틸18기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.162,
    company: "유한회사 라미솔라",
    rn: 4160
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라1호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.476,
    company: "주식회사 대명그린에너지",
    rn: 4161
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.712,
    company: "유한회사 대정태양광",
    rn: 4162
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승경2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "승경태양광발전소",
    rn: 4163
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신촌태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.377,
    company: "신촌태양광발전소",
    rn: 4164
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주영태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.234,
    company: "(주)주영",
    rn: 4165
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "신고리#1",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4166
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "신고리#2",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4167
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "신고리#3",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1400,
    company: "한국수력원자력(주)",
    rn: 4168
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "신고리#4",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1400,
    company: "한국수력원자력(주)",
    rn: 4169
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "어암태양광발전소",
    rn: 4170
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)썬에너팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 썬에너팜",
    rn: 4171
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "IPA북항햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.953,
    company: "인천항만공사",
    rn: 4172
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "골든태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "골든태양광",
    rn: 4173
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정성태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "주식회사 정성태양광발전소",
    rn: 4174
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "월성#1",
    cent: "중앙",
    fuel: "원자력",
    pcap: 678.683,
    company: "한국수력원자력(주)",
    rn: 4175
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "월성#2",
    cent: "중앙",
    fuel: "원자력",
    pcap: 700,
    company: "한국수력원자력(주)",
    rn: 4176
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "월성#3",
    cent: "중앙",
    fuel: "원자력",
    pcap: 700,
    company: "한국수력원자력(주)",
    rn: 4177
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "월성#4",
    cent: "중앙",
    fuel: "원자력",
    pcap: 700,
    company: "한국수력원자력(주)",
    rn: 4178
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "광진쏠라 주식회사",
    rn: 4179
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SH태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "SH태양광발전소",
    rn: 4180
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동아태양광에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "동아태양광에너지주식회사",
    rn: 4181
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제3대구솔라에너지제2발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.099,
    company: "제3대구솔라에너지 주식회사",
    rn: 4182
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강송제10태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.271,
    company: "주식회사 청운에너지",
    rn: 4183
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "신월성#1",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4184
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "신월성#2",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4185
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라필드제5차2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "솔라필드제일차 유한회사",
    rn: 4186
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#10 광백사(계송)",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 4187
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#11 광백사(입석)",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 4188
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#13 에스디엔",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 4189
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#14 무안솔라파크",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 4190
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강원에스티ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주식회사 강원에스티",
    rn: 4191
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거두해드림ESS발전소 ",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "춘천시청 (기후에너지과)",
    rn: 4192
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가리1호 태양광 ESS발전기",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 에스엘에너지",
    rn: 4193
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한빛#1",
    cent: "중앙",
    fuel: "원자력",
    pcap: 950,
    company: "한국수력원자력(주)",
    rn: 4194
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한빛#2",
    cent: "중앙",
    fuel: "원자력",
    pcap: 950,
    company: "한국수력원자력(주)",
    rn: 4195
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한빛#3",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4196
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한빛#4",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4197
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한빛#5",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4198
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한빛#6",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4199
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가리 2호 태양광 발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "서희발전에너지 주식회사",
    rn: 4200
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이에스태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "제이에스 태양광발전소",
    rn: 4201
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희성태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 희성",
    rn: 4202
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세도에너지21 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "세도에너지21 태양광발전소",
    rn: 4203
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사산에너지96 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "사산에너지96 태양광발전소",
    rn: 4204
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다경태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "신성테크 태양광발전소",
    rn: 4205
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "나포 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "나포 태양광발전소",
    rn: 4206
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "살강태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.09,
    company: "살강 태양광발전소",
    rn: 4207
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제동현ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "김제동현 태양광발전소",
    rn: 4208
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크 예천8호 태양광 발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "천광솔라",
    rn: 4209
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크 예천23호발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)와이앤케이",
    rn: 4210
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한울#1",
    cent: "중앙",
    fuel: "원자력",
    pcap: 950,
    company: "한국수력원자력(주)",
    rn: 4211
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한울#2",
    cent: "중앙",
    fuel: "원자력",
    pcap: 950,
    company: "한국수력원자력(주)",
    rn: 4212
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한울#3",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4213
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한울#4",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4214
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한울#5",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4215
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "원자력",
    genNm: "한울#6",
    cent: "중앙",
    fuel: "원자력",
    pcap: 1000,
    company: "한국수력원자력(주)",
    rn: 4216
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "용운태양광발전소",
    rn: 4217
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가나태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "가나태양광발전소",
    rn: 4218
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해피태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "메가나인 주식회사",
    rn: 4219
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해피2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해피2호 태양광 발전소",
    rn: 4220
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목곡2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "목곡2호태양광발전소",
    rn: 4221
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이즈솔라21호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "(주)와이즈솔라",
    rn: 4222
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진솔태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 진솔태양광",
    rn: 4223
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목포문화방송 삼호송신소 제3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "목포문화방송(주)",
    rn: 4224
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목포문화방송 삼호송신소 제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "목포문화방송(주)",
    rn: 4225
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목포문화방송 삼호송신소 제1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "목포문화방송(주)",
    rn: 4226
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "두남태양광발전소",
    rn: 4227
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "드림태양광발전소",
    rn: 4228
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜성태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "혜성태양광발전소",
    rn: 4229
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성심태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성심태양광발전소",
    rn: 4230
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "섬진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "섬진태양광발전소",
    rn: 4231
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한천2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "골드에스비건설 주식회사",
    rn: 4232
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한천3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "골드에스비건설 주식회사",
    rn: 4233
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화남제1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "주식회사 화남일",
    rn: 4234
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화남제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "주식회사 화남이",
    rn: 4235
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕암5호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "덕암5호 태양광발전소",
    rn: 4236
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목곡16호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "목곡16호태양광발전소",
    rn: 4237
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "효창아산 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 효창",
    rn: 4238
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목곡7호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "목곡7호태양광발전소",
    rn: 4239
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국수력원자력(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국수력원자력(주)",
    rn: 4240
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국남동발전(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국남동발전(주)",
    rn: 4241
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국중부발전(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국중부발전(주)",
    rn: 4242
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국서부발전(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국서부발전(주)",
    rn: 4243
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국남부발전(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국남부발전(주)",
    rn: 4244
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국동서발전(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국동서발전(주)",
    rn: 4245
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국지역난방공사(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국지역난방공사",
    rn: 4246
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "SK E&S(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "에스케이이엔에스(주)",
    rn: 4247
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "GS EPS(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "GS EPS(주)",
    rn: 4248
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "GS파워(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "지에스파워(주)",
    rn: 4249
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "포스코에너지(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "포스코에너지(주)",
    rn: 4250
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "씨지앤율촌전력(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "씨지앤율촌전력(주)",
    rn: 4251
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "한국수자원공사RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "한국수자원공사",
    rn: 4252
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "평택에너지서비스RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "평택에너지서비스(주)",
    rn: 4253
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "(주)에스파워RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "(주)에스파워",
    rn: 4254
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "(주)대륜발전RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "(주)대륜발전",
    rn: 4255
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "포천파워RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "포천파워(주)",
    rn: 4256
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "동두천드림파워(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "동두천드림파워(주)",
    rn: 4257
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "파주에너지서비스 주식회사 RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "파주에너지서비스 주식회사",
    rn: 4258
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "포천민자발전(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "포천민자발전(주)",
    rn: 4259
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "(주)지에스동해전력RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "(주)지에스동해전력",
    rn: 4260
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "신평택발전(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "신평택발전(주)",
    rn: 4261
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해솔2태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 해솔에너지",
    rn: 4262
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크충주11호 태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해테크충주11호",
    rn: 4263
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크충주12호 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해테크충주12호",
    rn: 4264
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해룡ECO태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "순천시청",
    rn: 4265
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "황룡1호 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "황룡1호 태양광발전소",
    rn: 4266
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대성태양에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "대성태양에너지",
    rn: 4267
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지안태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지안 태양광발전소",
    rn: 4268
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크안동1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "대성태양에너지a",
    rn: 4269
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광양25호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "광양25호태양광발전소",
    rn: 4270
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문정태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "문정 태양광발전소",
    rn: 4271
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "피앤씨테크(주)경주 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.101,
    company: "피앤씨테크주식회사",
    rn: 4272
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)광명전기 경주 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.101,
    company: "(주)광명전기",
    rn: 4273
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태화3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 양정태양광3호",
    rn: 4274
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이오투태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "이오투 태양광발전소",
    rn: 4275
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강성봉태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "강성봉태양광발전소",
    rn: 4276
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이오목태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "이오목태양광발전소",
    rn: 4277
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "OCI스페셜티 태양광ESS#2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "오씨아이파워주식회사",
    rn: 4278
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이오목3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "이오목3호태양광발전소",
    rn: 4279
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "손영란태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "손영란태양광발전소",
    rn: 4280
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강용구태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "강용구태양광발전소",
    rn: 4281
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이오순태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "이오순태양광발전소",
    rn: 4282
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "손불 태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 손불지우파워텍",
    rn: 4283
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "장수용월 태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 장수용월파워텍",
    rn: 4284
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현석용월 태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 현석용월파워텍",
    rn: 4285
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)엠케이2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 엠케이2호 태양광발전소",
    rn: 4286
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시문7태양광발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "시문7태양광발전소",
    rn: 4287
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에이치디에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "에이치디에너지",
    rn: 4288
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "포천알파에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "정선솔라 주식회사",
    rn: 4289
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌스마트팜앤쏠라씨티 ESS #1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 16,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 4290
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌스마트팜앤쏠라씨티 ESS #2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 16,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 4291
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌스마트팜앤쏠라씨티 ESS #3",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 4292
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌스마트팜앤쏠라씨티 ESS #4",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 4293
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌스마트팜앤쏠라씨티 ESS #5",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 18,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 4294
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌스마트팜앤쏠라씨티 ESS #6",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 6,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 4295
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서찬ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "서찬 태양광발전소",
    rn: 4296
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "S3목소리 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스3목소리발전",
    rn: 4297
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "S2목소리 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스2목소리발전",
    rn: 4298
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "함평1호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "정읍드림제2발전 주식회사",
    rn: 4299
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "함평2호 태양광발전 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "정읍드림제2발전 주식회사",
    rn: 4300
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "함평3호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "정읍드림제2발전 주식회사",
    rn: 4301
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주현태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주현 태양광발전소",
    rn: 4302
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창녕해안태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "창녕 해안 태양광발전소",
    rn: 4303
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "S9성곡리 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스9 성곡리발전",
    rn: 4304
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "S12 성곡리 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스12성곡리발전",
    rn: 4305
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "S7성곡리 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스7성곡리발전",
    rn: 4306
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "S5성곡리 태양광발전소",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스5성곡리발전",
    rn: 4307
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창기1호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "주식회사 디더블유에너지",
    rn: 4308
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창기4호 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "서부그린에너지 주식회사",
    rn: 4309
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창기3호 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "신안파워주식회사",
    rn: 4310
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창기2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "에스에이치파워 주식회사",
    rn: 4311
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "S8성곡리 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에스8성곡리발전",
    rn: 4312
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "SUNNY태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(주) 스프링웨이브",
    rn: 4313
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티디씨에너지2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주식회사 세이브에너지",
    rn: 4314
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼천포태양광#6_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국남동발전(주)",
    rn: 4315
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이레자연에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 이레자연에너지",
    rn: 4316
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "율린제3발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 오케이솔라",
    rn: 4317
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "율린제4발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 오케이솔라",
    rn: 4318
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "율린제5발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 보령에너지",
    rn: 4319
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "율린6발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 보령에너지",
    rn: 4320
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용담솔라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "용담솔라 주식회사",
    rn: 4321
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스비에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "에스비에너지(주)",
    rn: 4322
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "열방태양광발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "열방 태양광발전소",
    rn: 4323
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해테크여주11호 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)와이앤케이",
    rn: 4324
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백광영월 신재생태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)백광에너지",
    rn: 4325
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백광 영월태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)백광에너지",
    rn: 4326
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우진 영월태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)우진",
    rn: 4327
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕원 영월태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)덕원",
    rn: 4328
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림2발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "드림2발전소",
    rn: 4329
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엠에스솔라 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 엠에스솔라",
    rn: 4330
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호명솔라 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 호명솔라",
    rn: 4331
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이에스영광7 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 이에스영광7",
    rn: 4332
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신인천전망대법사면태양광 연계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "한국남부발전(주)",
    rn: 4333
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)진영태양광(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)진영태양광발전소",
    rn: 4334
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신안자라태양광발전기 ESS#1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 16,
    company: "빛솔라에너지주식회사",
    rn: 4335
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신안자라태양광발전기 ESS#2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 6,
    company: "빛솔라에너지주식회사",
    rn: 4336
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "좌포리용찬태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "좌포리용찬태양광발전소",
    rn: 4337
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "좌포5호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "좌포5호발전소",
    rn: 4338
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금빛세계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 금빛세계",
    rn: 4339
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영후 태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "영후 태양광발전소",
    rn: 4340
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "썬테크1호 태양광발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 썬테크 제1호",
    rn: 4341
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "푸른테크 태양광발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)푸른테크",
    rn: 4342
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신전태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "신전태양광발전소",
    rn: 4343
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉덕초 태양광발전소태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 인성티앤씨",
    rn: 4344
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "HS태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "에이치에스태양광발전소 주식회사",
    rn: 4345
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용암리2호 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 해테크예천26호",
    rn: 4346
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용암리1호태양광발전소ess",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 해테크횡성13호",
    rn: 4347
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "ICC EV충전스테이션 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "제주도청",
    rn: 4348
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "연당태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "썬라이프 유한회사",
    rn: 4349
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "죽장태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "경연태양광 주식회사",
    rn: 4350
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)에스에프태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)에스에프태양광발전소",
    rn: 4351
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JCG에너지4호태양광발전소 연계영 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "JCG솔라",
    rn: 4352
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "자연과태양에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 자연과태양에너지",
    rn: 4353
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "네오에코 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 네오에코에너지",
    rn: 4354
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영덕삼성 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "영덕 삼성 태양광발전소",
    rn: 4355
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "케프리 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "케프리태양광발전소",
    rn: 4356
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "글로리코리아낭산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "글로리코리아 낭산발전소",
    rn: 4357
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안지연1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "안지연1호 태양광",
    rn: 4358
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "용솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "용솔라 태양광",
    rn: 4359
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "연솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "연솔라 태양광",
    rn: 4360
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "희솔라 태양광",
    rn: 4361
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김희1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "김희1호 태양광",
    rn: 4362
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "박상영태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "박상영태양광",
    rn: 4363
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "처음세계 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 처음세계",
    rn: 4364
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영신발전소2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.01,
    company: "주식회사 지율에스피",
    rn: 4365
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영신발전소1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.01,
    company: "주식회사 영신에스피",
    rn: 4366
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "울산#4CC건물옥상태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.225,
    company: "한국동서발전(주)",
    rn: 4367
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이인리2호 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "이인리 2호 태양광발전소",
    rn: 4368
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "좌포리송근태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "좌포리송근태양광발전소",
    rn: 4369
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "좌포리현순태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "좌포리현순태양광발전소",
    rn: 4370
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "좌포순례태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "좌포리순례태양광발전소",
    rn: 4371
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "좌포리수정태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "좌포리수정태양광발전소",
    rn: 4372
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금송태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.01,
    company: "금송태양광발전소",
    rn: 4373
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주봉에너지1 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주봉에너지1",
    rn: 4374
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태후태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 해테크횡성13호",
    rn: 4375
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "빛나태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 해테크예천26호",
    rn: 4376
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)남경쏠라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.01,
    company: "(주)남경쏠라",
    rn: 4377
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "풍산용월태양광발전기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 풍산용월파워텍",
    rn: 4378
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "천지태양광발전소",
    rn: 4379
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래농산 5호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인 미래농산주식회사",
    rn: 4380
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소양해드림ESS발전소",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "춘천시청 (기후에너지과)",
    rn: 4381
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인투알 태양광발전기 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "주식회사 인투알",
    rn: 4382
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "로희 태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "로희 태양광발전소",
    rn: 4383
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신안자라_가상ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0,
    company: "빛솔라에너지주식회사",
    rn: 4384
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "용태양광발전소",
    rn: 4385
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "란 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "란 태양광발전소",
    rn: 4386
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이수 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "이수 태양광발전소",
    rn: 4387
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세을목 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.407,
    company: "세을목 태양광발전소",
    rn: 4388
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도송 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "도송태양광발전소",
    rn: 4389
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청풍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "청풍태양광발전소",
    rn: 4390
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "점리태양광발전소일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "나눔과사랑 주식회사",
    rn: 4391
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "점리태양광발전소이호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에너지나눔주식회사",
    rn: 4392
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.953,
    company: "태평태양광발전소",
    rn: 4393
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "굿파워아산 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.749,
    company: "유한회사 굿파워",
    rn: 4394
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한양1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "한양1발전소 주식회사",
    rn: 4395
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주늘해랑 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.197,
    company: "유한회사 제주늘해랑",
    rn: 4396
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "처용솔라2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.477,
    company: "처용솔라 주식회사",
    rn: 4397
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조현준1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "조현준1호 태양광발전소",
    rn: 4398
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "플라텔 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "플라텔(주)",
    rn: 4399
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일유태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 일유",
    rn: 4400
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장판11호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유한회사 제이에스에너지",
    rn: 4401
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제오태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "호황에너지 주식회사",
    rn: 4402
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진후문주차장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한국동서발전(주)",
    rn: 4403
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스태양광6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(주)디에스태양광발전소6호",
    rn: 4404
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이플랜트10호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "피브이플랜트십호 주식회사",
    rn: 4405
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "리차징팜 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "리차징팜 태양광발전소",
    rn: 4406
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유민1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유민1태양광발전소",
    rn: 4407
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유민2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유민2태양광발전소",
    rn: 4408
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정조 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "정조 태양광발전소",
    rn: 4409
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령선에너지1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 보령선에너지",
    rn: 4410
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호남1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "호남1호태양광발전소",
    rn: 4411
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호남2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "호남2호태양광발전소",
    rn: 4412
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태지태양광발전소",
    rn: 4413
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호재2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "호재2호태양광발전소",
    rn: 4414
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호재1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "호재1호태양광발전소",
    rn: 4415
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서산코넥 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.437,
    company: "솔라로드 주식회사",
    rn: 4416
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우영 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.064,
    company: "솔라로드 주식회사",
    rn: 4417
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신대륙물류 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.13,
    company: "솔라로드 주식회사",
    rn: 4418
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창해태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.828,
    company: "창해태양광발전소",
    rn: 4419
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "낙안솔라10호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "낙안솔라10호 태양광발전소",
    rn: 4420
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양주솔라3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "양주솔라3호 태양광발전소",
    rn: 4421
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠17호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "쏠17호 태양광발전소",
    rn: 4422
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령선광 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 선광",
    rn: 4423
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "점리태양광발전소삼호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "에코솔라파워 주식회사",
    rn: 4424
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우포태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.256,
    company: "우포태양광발전소",
    rn: 4425
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬플라워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 썬플라워",
    rn: 4426
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 진현",
    rn: 4427
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백마태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(유)백마",
    rn: 4428
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕수태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "덕수 태양광발전소",
    rn: 4429
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가산2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.895,
    company: "가산2태양광발전소",
    rn: 4430
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백화원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "백화원태양광발전소",
    rn: 4431
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대민1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "대민1호 태양광발전소",
    rn: 4432
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태공1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태공1호태양광발전소",
    rn: 4433
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "온새미로태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "온새미로 태양광발전소",
    rn: 4434
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경희태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "경희 태양광발전소",
    rn: 4435
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "형미태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "형미 태양광발전소",
    rn: 4436
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼달1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "삼달1호 태양광발전소",
    rn: 4437
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 제이엠피일렉트릭",
    rn: 4438
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "누리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.15,
    company: "누리태양광발전소",
    rn: 4439
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외입3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.696,
    company: "주식회사 소리도에너지",
    rn: 4440
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "삼광태양광발전소",
    rn: 4441
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "신산 태양광발전소",
    rn: 4442
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "유천태양광발전소",
    rn: 4443
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화남제3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 화남삼",
    rn: 4444
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지산3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "지산3호 태양광발전소",
    rn: 4445
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세나1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세나1호 태양광발전소",
    rn: 4446
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지혜태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지혜 태양광발전소",
    rn: 4447
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용정태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "용정태양광발전소1호",
    rn: 4448
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "류천에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.903,
    company: "류천에너지 주식회사",
    rn: 4449
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송암1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "송암1태양광",
    rn: 4450
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송암2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 송암에너지",
    rn: 4451
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용정태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "용정태양광발전소2호",
    rn: 4452
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용정태양광4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "용정태양광발전소4호",
    rn: 4453
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한들1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한들1호 태양광발전소",
    rn: 4454
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팔마ECO 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "순천시청",
    rn: 4455
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕천리771발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "덕천리771발전소",
    rn: 4456
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "슬기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "슬기태양광발전소",
    rn: 4457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해기태양광발전소",
    rn: 4458
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "인희태양광발전소",
    rn: 4459
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KH에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "KH에너지1호발전소",
    rn: 4460
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망태양광b",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희망 태양광발전소",
    rn: 4461
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "46호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.288,
    company: "(주)제주감귤태양광",
    rn: 4462
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "81호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "(주)제주감귤태양광",
    rn: 4463
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼랑진양수 태양광 #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.234,
    company: "한국수력원자력(주)",
    rn: 4464
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동현햇빛 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "동현햇빛 발전소",
    rn: 4465
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "북산태양광발전소",
    rn: 4466
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "네모1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(유)네모8태양광발전소",
    rn: 4467
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "네모2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(유)네모8태양광발전소",
    rn: 4468
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "네모4태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(유)네모8태양광발전소",
    rn: 4469
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "네모5태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "(유)네모8태양광발전소",
    rn: 4470
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청소 세기1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "청소 세기1호 태양광발전소",
    rn: 4471
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지산2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "지산2호 태양광발전소",
    rn: 4472
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "검디태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "검디태양발전소1호",
    rn: 4473
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "카리스1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.332,
    company: "카리스",
    rn: 4474
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유림이피씨 해상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 유림이피씨",
    rn: 4475
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "물류명가 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.279,
    company: "유한회사 군유솔라엔지",
    rn: 4476
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유마솔라발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)유마솔라",
    rn: 4477
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세창1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "세창물산 주식회사",
    rn: 4478
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천태양광2호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "한국중부발전(주)",
    rn: 4479
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령 #3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.938,
    company: "한국중부발전(주)",
    rn: 4480
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이앤더블유에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "주식회사 케이앤더블유에너지",
    rn: 4481
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성희태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "성희태양광발전소",
    rn: 4482
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태민태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "태민태양광발전소",
    rn: 4483
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정우태양광a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.726,
    company: "정우태양광발전소",
    rn: 4484
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태연3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "유한회사 태연",
    rn: 4485
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종태양광1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.327,
    company: "한국중부발전(주)",
    rn: 4486
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라필드제5차3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "솔라필드제이차 유한회사",
    rn: 4487
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라필드제5차1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "솔라필드제오차 유한회사",
    rn: 4488
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시영태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "시영 태양광발전소",
    rn: 4489
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우일솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 우일솔라",
    rn: 4490
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "주식회사 한라에너지",
    rn: 4491
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "후니3태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "후니3태양광발전소",
    rn: 4492
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용금1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "용금1호 태양광발전소",
    rn: 4493
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척장전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.453,
    company: "주식회사 삼척장전태양광발전소",
    rn: 4494
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척그린태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "주식회사 미란태양광발전소",
    rn: 4495
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제라1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제라1호 태양광발전소",
    rn: 4496
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태평1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태평1호 태양광발전소",
    rn: 4497
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태평2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태평2호 태양광발전소",
    rn: 4498
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세나2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "세나2호태양광발전소",
    rn: 4499
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광성물류센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.976,
    company: "주식회사 광성물류센터",
    rn: 4500
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "행복도시장군열병합",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 5,
    company: "세종그린파워 주식회사",
    rn: 4501
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담에스피에스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.688,
    company: "주식회사 다담에스피에스",
    rn: 4502
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "웅이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 웅이태양광발전소",
    rn: 4503
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이진혁1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "이진혁1호태양광발전소",
    rn: 4504
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리발전2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.697,
    company: "우리발전",
    rn: 4505
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티제이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "주식회사 티제이그린태양광발전소",
    rn: 4506
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.797,
    company: "(유)청운태양광",
    rn: 4507
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "왓츠1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 올가홈태양광발전소",
    rn: 4508
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준서 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.979,
    company: "주식회사 영도쏠라",
    rn: 4509
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이한산업제일차 태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.918,
    company: "주식회사 이한산업제일차",
    rn: 4510
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔텍솔라2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)엔텍솔라",
    rn: 4511
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔텍솔라4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.849,
    company: "(주)엔텍솔라",
    rn: 4512
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세이브태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "세이브에너지태양광발전소",
    rn: 4513
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소10호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.466,
    company: "코리아에너지발전소(주)",
    rn: 4514
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소7호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.485,
    company: "코리아에너지발전소(주)",
    rn: 4515
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경남2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.554,
    company: "유한회사 경남2호태양광발전소",
    rn: 4516
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "신승에너지 여열 회수 발전기",
    cent: "비중앙",
    fuel: "기타",
    pcap: 1.9,
    company: "주식회사 신승에너지",
    rn: 4517
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내담에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.398,
    company: "유한회사 내담에너지",
    rn: 4518
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해비치2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해비치2호태양광발전소",
    rn: 4519
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이한산업태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.906,
    company: "주식회사 이한산업",
    rn: 4520
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 다산태양광",
    rn: 4521
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "육일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "육일태양광발전소",
    rn: 4522
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무지개 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "주식회사 무지개태양광발전소",
    rn: 4523
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문보태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "문보태양광발전소",
    rn: 4524
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "첨단발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "첨단발전소",
    rn: 4525
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북평태양광 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.106,
    company: "(주)지에스동해전력",
    rn: 4526
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산복합태양광2단계",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.502,
    company: "한국남부발전(주)",
    rn: 4527
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다람태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.201,
    company: "다람태양광발전소",
    rn: 4528
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "럭키태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "럭키태양광발전소",
    rn: 4529
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용곡2태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 미원",
    rn: 4530
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안다미로제이",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.976,
    company: "주식회사 안다미로제이발전소",
    rn: 4531
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보담에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 보담에너지",
    rn: 4532
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.805,
    company: "주식회사 삼읍태양광발전소",
    rn: 4533
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준상태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "준상태양광발전소",
    rn: 4534
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용곡1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.016,
    company: "유한회사 네모1태양광발전소",
    rn: 4535
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령화력태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.427,
    company: "한국중부발전(주)",
    rn: 4536
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두모리 444 2호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "두모리444 2호 발전소",
    rn: 4537
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두모리 1885 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.093,
    company: "두모리 1885 발전소",
    rn: 4538
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두모리 444 1호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "두모리 444 1호 발전소",
    rn: 4539
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해마루3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.726,
    company: "유한회사해마루",
    rn: 4540
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주광 태양광발전소",
    rn: 4541
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세이브3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 엠디이엔지",
    rn: 4542
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화성태양광c",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "화성태양광발전소",
    rn: 4543
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "나리 태양광발전소",
    rn: 4544
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용곡3태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "세이프솔라 유한회사",
    rn: 4545
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솜티태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "솜티태양광발전소",
    rn: 4546
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용곡4태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 미래빛",
    rn: 4547
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디원2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "(주) 디원",
    rn: 4548
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "드림1호 태양광발전소",
    rn: 4549
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "갈화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.481,
    company: "갈화태양광발전소",
    rn: 4550
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "든솔에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.39,
    company: "유한회사 든솔에너지",
    rn: 4551
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백운개발5 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "백운개발5 태양광발전소",
    rn: 4552
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금란 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "금란 태양광발전소",
    rn: 4553
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건천 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.497,
    company: "주식회사 윌라스건천",
    rn: 4554
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일심태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "일심태양광발전소",
    rn: 4555
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가은태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.245,
    company: "가은 태양광발전소",
    rn: 4556
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인천태양광3호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.321,
    company: "한국중부발전(주)",
    rn: 4557
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.698,
    company: "경 태양광발전소",
    rn: 4558
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미르 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.345,
    company: "유한회사 미르태양광",
    rn: 4559
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서진13호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "서진13호 태양광발전소",
    rn: 4560
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "은성2호태양광발전소",
    rn: 4561
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거창온빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.598,
    company: "거창온빛 태양광발전소",
    rn: 4562
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#8 재원1태양광",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 4563
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한민2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한민2태양광발전소",
    rn: 4564
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한민3 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한민3태양광발전소",
    rn: 4565
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크여주1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "해테크여주1호",
    rn: 4566
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크여주4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "해테크여주4호",
    rn: 4567
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송덕1호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "송덕1호 태양광발전소",
    rn: 4568
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송덕2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "송덕2호 태양광발전소",
    rn: 4569
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송덕3호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "송덕3호 태양광발전소",
    rn: 4570
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송덕4호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "송덕4호 태양광발전소",
    rn: 4571
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송덕5호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "송덕5호 태양광발전소",
    rn: 4572
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현대논공발전소 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)현대논공발전소",
    rn: 4573
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가비태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "가비태양광발전소",
    rn: 4574
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "늘푸른태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "늘푸른태양광발전소",
    rn: 4575
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "패스오버에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "패스오버에너지태양광발전소",
    rn: 4576
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유성태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(유)천지기업",
    rn: 4577
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "부곡한양태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)부곡한양태양광",
    rn: 4578
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕담태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "덕담태양광발전소",
    rn: 4579
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "케이디솔라원발전소1 ESS ",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 케이디솔라원",
    rn: 4580
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고경3호태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 고경3호태양광",
    rn: 4581
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우산태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "우산태양광발전소",
    rn: 4582
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "양사태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "양사태양광발전소",
    rn: 4583
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "부일태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "부일태양광발전소",
    rn: 4584
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해나눔태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)해나눔태양광발전소",
    rn: 4585
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티디씨에너지1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주식회사 티디씨전력기술",
    rn: 4586
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신풍태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "신풍태양광발전소",
    rn: 4587
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이티태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "제이티태양광발전소",
    rn: 4588
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수화에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "수화에너지",
    rn: 4589
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "포인2태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "포인2태양광발전소",
    rn: 4590
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정현태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "정현태양광발전소",
    rn: 4591
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "석민태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "석민태양광발전소",
    rn: 4592
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명문태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "명문태양광발전소",
    rn: 4593
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림솔라1태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "드림솔라1 태양광발전소",
    rn: 4594
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청정태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "유한회사 에너지니어스태양광1호",
    rn: 4595
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영화태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "유한회사 풍영에너지",
    rn: 4596
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정진1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)제이엠파워",
    rn: 4597
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태웅태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "미래크린에너지 주식회사",
    rn: 4598
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행복태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 더블넥서스",
    rn: 4599
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시화방아머리태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "한국수자원공사",
    rn: 4600
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가온길에너지 태양광ESS발전소",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(유)가온길에너지",
    rn: 4601
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의령풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "의령풍력발전(주)",
    rn: 4602
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강고태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "강고태양광발전소",
    rn: 4603
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은숙태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "은숙태양광발전소",
    rn: 4604
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영일태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영일태양광발전소",
    rn: 4605
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영광태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영광태양광발전소",
    rn: 4606
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화연태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "화연태양광발전소",
    rn: 4607
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영숙태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영숙태양광발전소",
    rn: 4608
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정희태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "정희태양광발전소",
    rn: 4609
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정희태양광발전소2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "정희태양광발전소2호",
    rn: 4610
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "재진태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "재진태양광발전소",
    rn: 4611
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지현태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "지현태양광발전소",
    rn: 4612
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경민 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "경민 태양광발전소",
    rn: 4613
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우진3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "우진3호 태양광발전소",
    rn: 4614
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우진4호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "우진4호 태양광발전소",
    rn: 4615
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대박3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "대박3호태양광발전소",
    rn: 4616
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "마산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "마산태양광발전소",
    rn: 4617
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "썬그린 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "썬그린(주)",
    rn: 4618
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우리에너지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "우리에너지(주)",
    rn: 4619
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에버그린에너지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "에버그린에너지(주)",
    rn: 4620
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "애치광에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "애치광에너지(주)",
    rn: 4621
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대호발전소 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "대호발전소(주)",
    rn: 4622
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진남 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "진남발전소(주)",
    rn: 4623
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태광발전소 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "태광발전소(주)",
    rn: 4624
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태광2태양광발전소 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "태광2태양광발전소(주)",
    rn: 4625
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "선미태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "선미태양광발전소",
    rn: 4626
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "상동태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "상동태양광발전소",
    rn: 4627
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영광백수풍력 ESS 1",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 4,
    company: "영광백수풍력발전주식회사",
    rn: 4628
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영광백수풍력 ESS 2",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 4,
    company: "영광백수풍력발전주식회사",
    rn: 4629
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "근영산업1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "(주)근영산업",
    rn: 4630
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "근영산업3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "(주)근영산업",
    rn: 4631
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "골드파크태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "(주)다쓰테크",
    rn: 4632
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남도에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 남도에너지",
    rn: 4633
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이에너지개발태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "제이에너지개발 주식회사",
    rn: 4634
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쥴태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "(주)쥴",
    rn: 4635
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동원에너지태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "동원에너지(주)",
    rn: 4636
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호성에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "호성에너지태양광발전소",
    rn: 4637
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "어덕1태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "어덕1태양광발전소",
    rn: 4638
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "어덕2태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "어덕2 태양광발전소",
    rn: 4639
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제일솔라1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "피앤제이솔라 주식회사",
    rn: 4640
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "오지쏠라에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 오지쏠라에너지",
    rn: 4641
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "오지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "오지 태양광발전소",
    rn: 4642
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "마정태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "마정에너지 주식회사",
    rn: 4643
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "개화솔라팜 ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)탑선태양광2호",
    rn: 4644
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "개화솔라팜 ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)탑선태양광2호",
    rn: 4645
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보령솔라팜 ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)그린영암에너지",
    rn: 4646
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보령솔라팜 ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)그린영암에너지",
    rn: 4647
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "순자태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "순자태양광발전소",
    rn: 4648
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가야에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "가야에너지(주)",
    rn: 4649
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동해에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "동해에너지(주)",
    rn: 4650
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대운태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "대운태양광발전소",
    rn: 4651
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대운2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "대운2호태양광발전소",
    rn: 4652
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세현3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "세현3호 태양광발전소",
    rn: 4653
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정가태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "정가 태양광발전소",
    rn: 4654
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "줄포태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "줄포태양광발전소",
    rn: 4655
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참길자동차학원 1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(유)참길자동차학원",
    rn: 4656
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참길자동차학원 3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(유)참길자동차학원",
    rn: 4657
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참길자동차학원 4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(유)참길자동차학원",
    rn: 4658
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참길자동차학원 5호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(유)참길자동차학원",
    rn: 4659
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조문국2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "조문국2호태양광발전소",
    rn: 4660
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉춘2호에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "봉춘2호에너지",
    rn: 4661
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세영에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "세영에너지",
    rn: 4662
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "신라태양광발전소",
    rn: 4663
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현대태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "현대태양광발전소",
    rn: 4664
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동국태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "동국태양광발전소",
    rn: 4665
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(재)박기범재단 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "재단법인 박기범재단",
    rn: 4666
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해승태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해승태양광",
    rn: 4667
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "(구)남제주#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 10,
    company: "한국남부발전(주)",
    rn: 4668
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "(구)남제주#2",
    cent: "중앙",
    fuel: "중유",
    pcap: 10,
    company: "한국남부발전(주)",
    rn: 4669
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "남제주기력",
    cent: "중앙",
    fuel: "중유",
    pcap: 20,
    company: "한국남부발전(주)",
    rn: 4670
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "남제주#1",
    cent: "중앙",
    fuel: "바이오중유",
    pcap: 100,
    company: "한국남부발전(주)",
    rn: 4671
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "남제주#2",
    cent: "중앙",
    fuel: "바이오중유",
    pcap: 100,
    company: "한국남부발전(주)",
    rn: 4672
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이안태양광발전소ESS-1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "이안태양광발전소",
    rn: 4673
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대한메탈2발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "대한메탈(주)",
    rn: 4674
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대한메탈1발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "대한메탈(주)",
    rn: 4675
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세이브에너지2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 세이브에너지",
    rn: 4676
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "양산태양광(유산폐기물매립장)ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)양산햇빛발전소",
    rn: 4677
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "제주화력#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 10,
    company: "한국중부발전(주)",
    rn: 4678
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "제주화력#2",
    cent: "중앙",
    fuel: "바이오중유",
    pcap: 75,
    company: "한국중부발전(주)",
    rn: 4679
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "제주화력#3",
    cent: "중앙",
    fuel: "바이오중유",
    pcap: 75,
    company: "한국중부발전(주)",
    rn: 4680
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광천태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "호윤태양광발전소",
    rn: 4681
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사옥태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "사옥 태양광발전소",
    rn: 4682
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화순태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 신독",
    rn: 4683
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "드림태양광발전소",
    rn: 4684
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참진3태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "참진3태양광발전소",
    rn: 4685
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "제주LNG복합#1CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 114.367,
    company: "한국중부발전(주)",
    rn: 4686
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "제주LNG복합#2CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 114.367,
    company: "한국중부발전(주)",
    rn: 4687
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "제주LNG복합#1GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 71.893,
    company: "한국중부발전(주)",
    rn: 4688
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "제주LNG복합#2GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 71.893,
    company: "한국중부발전(주)",
    rn: 4689
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "제주LNG복합#1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 42.474,
    company: "한국중부발전(주)",
    rn: 4690
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "제주LNG복합#2ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 42.474,
    company: "한국중부발전(주)",
    rn: 4691
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일동3태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "일동3태양광발전소",
    rn: 4692
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이수백태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "이수백태양광발전소",
    rn: 4693
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "여주늘푸른태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "여주늘푸른태양광발전소",
    rn: 4694
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "선애태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "선애 태양광 발전소",
    rn: 4695
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "월승리2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)리치스톤",
    rn: 4696
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보현1태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "보현1태양광발전소",
    rn: 4697
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보현2태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "보현2태양광발전소",
    rn: 4698
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지동리북 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "한종지동리 주식회사",
    rn: 4699
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그린환경건설ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(유)그린환경건설",
    rn: 4700
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세방부산신항물류센터 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "세방(주)",
    rn: 4701
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "성산태양광발전소",
    rn: 4702
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지동리중ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "태봉리 주식회사",
    rn: 4703
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도덕태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "도덕태양광발전소",
    rn: 4704
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "kt원주연수원 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주식회사 케이티",
    rn: 4705
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고라햇빛발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.2,
    company: "(주)윤파워에너지",
    rn: 4706
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다인햇빛발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 다인솔라",
    rn: 4707
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "게이트원햇빛발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 게이트원",
    rn: 4708
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비에스케이여주햇빛발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)비에스케이그린",
    rn: 4709
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비에스케이햇빛발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)비에스케이",
    rn: 4710
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세현태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "세현태양광발전소",
    rn: 4711
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세현2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "세현2호태양광발전소",
    rn: 4712
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현창이엔지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(주)광명전기",
    rn: 4713
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일권태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "일권 태양광발전소",
    rn: 4714
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성광대독발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 성광드림",
    rn: 4715
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광나지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "광나진",
    rn: 4716
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행복태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "대랑원광 태양광발전소",
    rn: 4717
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원진에너지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)원진에너지",
    rn: 4718
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수경1호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "수경1호태양광발전소",
    rn: 4719
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수경2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "수경2호태양광발전소",
    rn: 4720
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수경3호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "수경3호태양광발전소",
    rn: 4721
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성산3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "성산3호태양광발전소",
    rn: 4722
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "수한태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "메가솔라제이(주)",
    rn: 4723
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광전사ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "주식회사 광전사",
    rn: 4724
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "나눔태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)나눔장학회",
    rn: 4725
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "피엠제이_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "주식회사 피엠제이",
    rn: 4726
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강릉제일솔라3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "에이원솔라 주식회사",
    rn: 4727
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "ENL10호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "에너리스(주)",
    rn: 4728
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "구룡제2발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사구룡제2발전소",
    rn: 4729
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영해태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "영해태양광발전 주식회사",
    rn: 4730
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영림3태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "영림3 주식회사",
    rn: 4731
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에너지팜ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)에너지팜",
    rn: 4732
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이에스영광솔라파크ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "(주)제이에스영광솔라파크",
    rn: 4733
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "복평태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인(유)복평",
    rn: 4734
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "계웅산업 ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 계웅산업",
    rn: 4735
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쏠태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 쏠",
    rn: 4736
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대치에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "대치에너지 태양광발전소",
    rn: 4737
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스지솔라태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 남부홀딩스 남부신안지점",
    rn: 4738
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "카이로스에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 9.6,
    company: "카이로스에너지 주식회사",
    rn: 4739
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우주태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "우주태양광발전소",
    rn: 4740
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한울ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "농업회사법인(유)다온",
    rn: 4741
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해오름ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "해오름태양광발전소",
    rn: 4742
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "향상기업ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "향상기업주식회사",
    rn: 4743
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대동태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "대동 태양광발전소",
    rn: 4744
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "토성1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "토성1호태양광발전소",
    rn: 4745
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "토성2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "토성2호태양광발전소",
    rn: 4746
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "빛모아 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 빛모아",
    rn: 4747
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "윤아에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 윤아에너지",
    rn: 4748
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "케이원태양발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)케이에너지",
    rn: 4749
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현단태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "현단태양광발전 주식회사",
    rn: 4750
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대양에너지태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "대양에너지태양광발전소",
    rn: 4751
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신혜태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.02,
    company: "신혜 태양광발전소",
    rn: 4752
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진도그린에너지ESS태양광",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)진도그린에너지",
    rn: 4753
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한백대구발전소연계ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 한백에어솔라",
    rn: 4754
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이에스파워1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 이에스파워",
    rn: 4755
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아이엠8호태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "아이엠8호태양광발전소",
    rn: 4756
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청명5호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 청명",
    rn: 4757
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송현태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "송현 태양광발전소",
    rn: 4758
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "프라임제3태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "프라임 제3태양광발전소",
    rn: 4759
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쌍용전력태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "쌍용전력(주)",
    rn: 4760
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고흥솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국수력원자력(주)",
    rn: 4761
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다니엘태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국수력원자력(주)",
    rn: 4762
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대선태양광연계ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "대선태양광발전소",
    rn: 4763
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진도솔라에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)진도솔라에너지",
    rn: 4764
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아폴로1호태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "아폴로1호",
    rn: 4765
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도건1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 도건시스템",
    rn: 4766
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주왕1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "(주)주왕산업",
    rn: 4767
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보림태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)보림에너지",
    rn: 4768
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "야룡1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.65,
    company: "주식회사엔더블유에너지",
    rn: 4769
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "연일태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "연일태양광발전소",
    rn: 4770
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경영태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "경영태양광발전소",
    rn: 4771
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "타이거솔라_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 타이거솔라",
    rn: 4772
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행정16호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "행정16호태양광발전소",
    rn: 4773
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탑솔라ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "탑솔라 주식회사",
    rn: 4774
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "MY태양광 제4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "MY태양광발전소",
    rn: 4775
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "베스코태양광 제4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "베스코태양광발전소 제4호",
    rn: 4776
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "선(SUN)1에너지1호발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "선(SUN)1에너지1호발전소",
    rn: 4777
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "선(SUN)1에너지2호발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "선(SUN)1에너지2호발전소",
    rn: 4778
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화광관춘태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "전남도시가스(주)",
    rn: 4779
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에너지랜드태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)에너지랜드",
    rn: 4780
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광일태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)광일태양광발전소",
    rn: 4781
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼원썬텍ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 삼원썬텍",
    rn: 4782
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼원쏠라텍ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 삼원쏠라텍",
    rn: 4783
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼원태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 삼원태양광",
    rn: 4784
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이파워태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)제이파워",
    rn: 4785
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림팜에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "농업회사법인(유)드림팜에너지",
    rn: 4786
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백옥태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "백옥태양광발전소",
    rn: 4787
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대광1호 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "대광1호 태양광발전소",
    rn: 4788
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대광2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "대광2호 태양광발전소",
    rn: 4789
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대융에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 대융에너지",
    rn: 4790
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해태크발전소충주4호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)대통산업",
    rn: 4791
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현천리태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "강원도",
    rn: 4792
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청정태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "청정 태양광발전소",
    rn: 4793
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성1호태양광발전소",
    rn: 4794
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성2호태양광발전소",
    rn: 4795
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성5호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성5호태양광발전소",
    rn: 4796
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성6호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성6호태양광발전소",
    rn: 4797
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성9호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성9호태양광발전소",
    rn: 4798
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성12호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성12호태양광발전소",
    rn: 4799
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광전명도태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 광전",
    rn: 4800
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "참솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)참솔라",
    rn: 4801
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼일방직ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "삼일방직(주)",
    rn: 4802
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거금솔라파크태양광#1_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 4,
    company: "(주)거금솔라파크",
    rn: 4803
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거금솔라파크태양광#2_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 4,
    company: "(주)거금솔라파크",
    rn: 4804
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거금솔라파크태양광#3_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "(주)거금솔라파크",
    rn: 4805
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거금솔라파크태양광#4_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "(주)거금솔라파크",
    rn: 4806
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거금솔라파크태양광#5_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)거금솔라파크",
    rn: 4807
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에덴축산1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "에덴축산1호 태양광발전소",
    rn: 4808
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "방동태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "유한회사 방동태양광",
    rn: 4809
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "피앤이솔루션ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "(주)피앤이솔루션",
    rn: 4810
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성15호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "주식회사 제이디그린텍",
    rn: 4811
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성13호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "제이디영(주)",
    rn: 4812
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성11호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "(주)써니발전",
    rn: 4813
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "필솔라 주식회사",
    rn: 4814
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현경13호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "현경13호 태양광발전소",
    rn: 4815
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성4호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성4호태양광발전소",
    rn: 4816
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성7호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성7호태양광발전소",
    rn: 4817
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성8호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성8호태양광발전소",
    rn: 4818
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성10호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성10호태양광발전소",
    rn: 4819
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성14호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성14호태양광발전소",
    rn: 4820
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성16호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성16호태양광발전소",
    rn: 4821
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아성17호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "아성17호태양광발전소",
    rn: 4822
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "부성태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "부성태양광발전소",
    rn: 4823
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "건국대학교 생명과학관 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "건국대학교",
    rn: 4824
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솔라에스엠태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 솔라에스엠",
    rn: 4825
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "베스코태양광 제2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "베스코태양광발전소 제2호",
    rn: 4826
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서강3호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 서강레미콘",
    rn: 4827
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서강2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "서강2호 태양광발전소",
    rn: 4828
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솔라에스엠태양광 ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 솔라에스엠",
    rn: 4829
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕암 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "덕암 태양광발전소",
    rn: 4830
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행안태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "행안태양광발전소",
    rn: 4831
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림라인솔라파워2호 화순1지점ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "드림라인쏠라파워2호 주식회사",
    rn: 4832
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "드림라인솔라파워2호 화순2지점ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "드림라인쏠라파워2호 주식회사",
    rn: 4833
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "여수안포리태양광발전2호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "여수안포리태양광발전2호<홍광발전>",
    rn: 4834
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대성1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "대성1호 태양광발전소",
    rn: 4835
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척소내태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "한국남부발전(주)",
    rn: 4836
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에이스에너지도개 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 에이스에너지도개",
    rn: 4837
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아이제이에스태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)아이제이에스",
    rn: 4838
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "케이앤씨 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 케이앤씨",
    rn: 4839
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전6호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "주식회사 제이디그린텍",
    rn: 4840
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전4호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "제이디영(주)",
    rn: 4841
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "(주)써니발전",
    rn: 4842
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "필솔라 주식회사",
    rn: 4843
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "시에스에스에너지 주식회사",
    rn: 4844
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동그라미태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "시에스에스에너지 주식회사",
    rn: 4845
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "네모이엔지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 네모이엔지",
    rn: 4846
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신재생에너지홍보관주차장ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "제주에너지공사",
    rn: 4847
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "SKY 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "Sky 태양광발전소",
    rn: 4848
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전3호 태양광발전소",
    rn: 4849
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전5호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전5호 태양광발전소",
    rn: 4850
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전7호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전7호태양광발전소",
    rn: 4851
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전8호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전8호태양광발전소",
    rn: 4852
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전9호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전9호태양광발전소",
    rn: 4853
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전10호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전10호태양광발전소",
    rn: 4854
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전11호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전11호태양광발전소",
    rn: 4855
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영전12호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "영전12호태양광발전소",
    rn: 4856
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하영태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "하영태양광발전소",
    rn: 4857
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "무진장2호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "무진장 2호 태양광발전",
    rn: 4858
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "클린에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "유한회사 클린에너지",
    rn: 4859
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)진도그린에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(유)진도그린에너지",
    rn: 4860
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "온새미로 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(유)온새미로에너지",
    rn: 4861
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "오운태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)오운태양광",
    rn: 4862
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스테루키2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "에스테루키2호태양광발전소",
    rn: 4863
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한림복합GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 70,
    company: "한국남부발전(주)",
    rn: 4864
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "스프링웨이브1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "스프링웨이브1호태양광발전소",
    rn: 4865
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "생물종다양성연구소 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "재단법인 제주테크노파크",
    rn: 4866
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일지테크경주공장태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)일지테크",
    rn: 4867
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "월승리1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)리치스톤",
    rn: 4868
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)네모8태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)네모8태양광발전소",
    rn: 4869
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이화태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "이화산업전력 주식회사",
    rn: 4870
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금2호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "진안황금2호태양광발전소",
    rn: 4871
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금5호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "진안황금5호태양광발전소",
    rn: 4872
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금6호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "진안황금6호태양광발전소",
    rn: 4873
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한림복합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 35,
    company: "한국남부발전(주)",
    rn: 4874
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한림복합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 35,
    company: "한국남부발전(주)",
    rn: 4875
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금7호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "진안황금7호태양광발전소",
    rn: 4876
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금8호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "진안황금8호 태양광발전소",
    rn: 4877
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제다태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)제다",
    rn: 4878
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태곡태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 태곡태양광발전소",
    rn: 4879
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "테크엔ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)테크엔",
    rn: 4880
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성민ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "성민 태양광발전소",
    rn: 4881
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금강1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사한솔에너지",
    rn: 4882
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에덴에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "에덴에너지태양광발전소",
    rn: 4883
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한림복합CC",
    cent: "중앙",
    fuel: "LNG",
    pcap: 105,
    company: "한국남부발전(주)",
    rn: 4884
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금4호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "주식회사 제이디그린텍",
    rn: 4885
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "(주)써니발전",
    rn: 4886
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금10호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "제이디영(주)",
    rn: 4887
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금3호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "필솔라 주식회사",
    rn: 4888
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해님2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "해님2호 태양광발전소",
    rn: 4889
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "배두둑 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "배두둑 태양광발전소",
    rn: 4890
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진안황금9호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "진안황금9호 태양광발전소",
    rn: 4891
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보담ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "보담태양광발전소",
    rn: 4892
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "선명ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "선명태양광발전소",
    rn: 4893
    },
    {
    area: "제주",
    genFom: "복합",
    genSrc: "화력",
    genNm: "한림복합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 35,
    company: "한국남부발전(주)",
    rn: 4894
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "스마트에너지 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "스마트에너지 태양광발전소",
    rn: 4895
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해님1호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "해님1호 태양광발전소",
    rn: 4896
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "썬로드에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 썬로드에너지",
    rn: 4897
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하늘태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "하늘태양광발전소",
    rn: 4898
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래발전ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "미래발전",
    rn: 4899
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영일태양광aESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "영일태양광발전소",
    rn: 4900
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현진태양광aESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "현진태양광발전소",
    rn: 4901
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가나안태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "가나안 태양광발전소",
    rn: 4902
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "기은에코에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 기은에코에너지",
    rn: 4903
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탑솔라2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "탑솔라 주식회사",
    rn: 4904
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "KTE태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "(주)KTE",
    rn: 4905
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원일태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "원일산업 태양광발전소",
    rn: 4906
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "샛별에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유한회사 샛별에너지",
    rn: 4907
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이에스파워2호태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)제이에스파워",
    rn: 4908
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "결실 태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "결실태양광발전소",
    rn: 4909
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쌍용전장(주)태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "쌍용전장(주)",
    rn: 4910
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이에쓰태양광ESS#1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 이에쓰",
    rn: 4911
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)디씨에너지(1-2)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3.661,
    company: "주식회사 디씨에너지",
    rn: 4912
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "매산1호태양광ESS 1-1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(주)두온이앤에스",
    rn: 4913
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그린6태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "그린6태양광발전소",
    rn: 4914
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼아에너지태양광ESS 1-1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)삼아에너지",
    rn: 4915
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "Gridsol태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "엘에스산전(주)",
    rn: 4916
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세종시폐기물매립장태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국서부발전(주)",
    rn: 4917
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "ESS_건국대제4호태양광",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "건국대학교",
    rn: 4918
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천북공장태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "현대힘스(주) 천북공장",
    rn: 4919
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우진태양광c_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "우진태양광발전소",
    rn: 4920
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "승현태양광b_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "승현태양광발전소",
    rn: 4921
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JJHAE태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "JJHAE태양광발전소",
    rn: 4922
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔비플러스태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 엔비플러스",
    rn: 4923
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에이치케이에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "에이치케이에너지 주식회사",
    rn: 4924
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JH에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "JH에너지태양광",
    rn: 4925
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시드바이오테크발전기_ESS-1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "농업회사법인 시드바이오테크 주식회사",
    rn: 4926
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥화력태양광#2_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국남동발전(주)",
    rn: 4927
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥 태양광 3단지-1_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "한국남동발전(주)",
    rn: 4928
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥 태양광 3단지-2_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국남동발전(주)",
    rn: 4929
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥 태양광 3단지-3_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "한국남동발전(주)",
    rn: 4930
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광명4호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "(주)광명4호태양광발전소",
    rn: 4931
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탑솔라태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 탑솔라",
    rn: 4932
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "효정태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "효정태양광발전소",
    rn: 4933
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시드바이오테크발전기_ESS-2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "농업회사법인 시드바이오테크 주식회사",
    rn: 4934
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태양전기종묘태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "태양전기",
    rn: 4935
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일출솔라에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 일출솔라에너지",
    rn: 4936
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한율1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "한율1호태양광발전소",
    rn: 4937
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한율2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "한율1호태양광발전소",
    rn: 4938
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "별량삼숙태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)삼숙",
    rn: 4939
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경주풍력1단계ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 4,
    company: "경주풍력발전(주)",
    rn: 4940
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정읍태곡1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 녹색에너지",
    rn: 4941
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신평가덕태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 대광에너지",
    rn: 4942
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "석매1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 위저드",
    rn: 4943
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대광태양광c_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "(유)율현",
    rn: 4944
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고경태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "이레에너지 주식회사",
    rn: 4945
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "케이이디태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 케이이디",
    rn: 4946
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화서휴게소태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "우람태양광강진발전소(주)",
    rn: 4947
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한세태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "한세태양광발전소",
    rn: 4948
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JH에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 제이에이치에너지",
    rn: 4949
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "두산 창원러닝센터태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "두산중공업(주)",
    rn: 4950
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청호전기태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "종로전기(주)",
    rn: 4951
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "농업현성기업태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "농업회사법인 현성기업(주)",
    rn: 4952
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은성3호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "은성기업(주)",
    rn: 4953
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은성4호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "은성기업(주)",
    rn: 4954
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "파워에코태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 파워에코",
    rn: 4955
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "루맷태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주식회사 루맷",
    rn: 4956
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "율리2태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "율리2태양광발전소",
    rn: 4957
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "목사동에너지2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 서정에너지",
    rn: 4958
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남정수상장성태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(주)남정수상태양광",
    rn: 4959
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "솔라뱅크태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "솔라뱅크 유한회사",
    rn: 4960
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창대글로리솔라연계_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "(주)창대글로리솔라",
    rn: 4961
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "풍경태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "풍경주식회사",
    rn: 4962
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동해1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "농업회사법인 주식회사 부송농원 해남지점",
    rn: 4963
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "패스오버태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 패스오버",
    rn: 4964
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "바른빛에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "바른빛에너지",
    rn: 4965
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그린에스앤이태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "그린에스앤이",
    rn: 4966
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "디에스피이에스1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "(주)디에스피이에스",
    rn: 4967
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현성에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "현성에너지(주)",
    rn: 4968
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은성산업태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "은성산업(주)",
    rn: 4969
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼천포태양광#4_ESS ",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "한국남동발전(주)",
    rn: 4970
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼천포태양광#5-1_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 4,
    company: "한국남동발전(주)",
    rn: 4971
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼천포태양광#5-2_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 6,
    company: "한국남동발전(주)",
    rn: 4972
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼천포태양광#2_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한국남동발전(주)",
    rn: 4973
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광명쏠라1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "광명쏠라1호 태양광발전소",
    rn: 4974
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "낙안솔라8호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "낙안솔라8호 태양광발전소",
    rn: 4975
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JS에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "JS에너지 태양광발전소",
    rn: 4976
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성남태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "성남 태양광발전소",
    rn: 4977
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "양주솔라2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "양주솔라2호 태양광발전소",
    rn: 4978
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서광태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "서광 태양광발전소",
    rn: 4979
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "DSP2호 태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "(주)디에스피이엔지",
    rn: 4980
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "DSP4호 태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)디에스피이엔지",
    rn: 4981
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "노포차량기지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "종로전기(주)",
    rn: 4982
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "그린쏠라에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "그린쏠라에너지",
    rn: 4983
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "Gridsol화명태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "엘에스산전(주)",
    rn: 4984
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현성산업태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "현성산업(주)",
    rn: 4985
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은성에너지태양광발전_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "은성에너지(주)",
    rn: 4986
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이에스파워1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)제이에스파워",
    rn: 4987
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하늘농장15호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "하늘농장주식회사",
    rn: 4988
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하늘농장풍양태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "하늘농장주식회사",
    rn: 4989
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하늘농장6호태양광 연계형 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "하늘농장주식회사",
    rn: 4990
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "자연태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "자연태양광발전소",
    rn: 4991
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "Gridsol부산태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "엘에스산전(주)",
    rn: 4992
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아침태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)아침태양광",
    rn: 4993
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일신산업태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(주)일신산업",
    rn: 4994
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "케이비솔라에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 케이비솔라에너지",
    rn: 4995
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성운태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)성운",
    rn: 4996
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금산전력태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "금산전력 주식회사",
    rn: 4997
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#1_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 4998
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "월곡1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)월곡가에너지",
    rn: 4999
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강동풍력_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "경주산업단지풍력발전(주)",
    rn: 5000
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "내일에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)내일에너지",
    rn: 5001
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지오에너지와우1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "지오에너지 와우",
    rn: 5002
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동인에너지와우1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "동인에너지 와우",
    rn: 5003
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "자혜태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)자혜태양광",
    rn: 5004
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인포시티1태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "인포시티 유한회사",
    rn: 5005
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동해시하수종말처리장태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "한국동서발전(주)",
    rn: 5006
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼척GES태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "삼척지이에스 주식회사",
    rn: 5007
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "새만금햇빛누리태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 6,
    company: "씨엔피브이파워코리아 주식회사",
    rn: 5008
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도개솔라파크2_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "도개솔라파크2",
    rn: 5009
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도개솔라파크8_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "도개솔라파크8",
    rn: 5010
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대명태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "대명주식회사",
    rn: 5011
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "세명태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "세명에스앤지(주)",
    rn: 5012
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "럭키쏠라_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "럭키쏠라 주식회사",
    rn: 5013
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해남그린에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 8,
    company: "(주)해남그린에너지",
    rn: 5014
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금강2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)금강에너지",
    rn: 5015
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "일광2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "(주)일광태양광발전소",
    rn: 5016
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에너지월드태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 에너지월드",
    rn: 5017
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "믿음태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "믿음태양광발전소",
    rn: 5018
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지산태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "지산태양광발전소",
    rn: 5019
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "낙동에너지2호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "(주)낙동에너지",
    rn: 5020
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다니엘영동태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 다니엘영동태양광",
    rn: 5021
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "판곡태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "판곡태양광발전소",
    rn: 5022
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼당에코태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인(유)삼당에코",
    rn: 5023
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "케이솔라일호(주)태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "케이솔라일호 주식회사",
    rn: 5024
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미화에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "케이솔라이호 주식회사",
    rn: 5025
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "윤성에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 윤성에너지",
    rn: 5026
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대리은하1호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "대리은하1호2호태양광발전소",
    rn: 5027
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#1_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5028
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#1_ESS3",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5029
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#1_ESS4",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5030
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#1_ESS5",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5031
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#1_ESS6",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5032
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이원에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 이원에너지",
    rn: 5033
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성곡2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)GS E&R",
    rn: 5034
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금강태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "금강태양광발전소",
    rn: 5035
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "순천만ECO태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "순천시청",
    rn: 5036
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "준규솔라태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2.5,
    company: "주식회사준규솔라태양광발전소",
    rn: 5037
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "내동태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "내동태양광발전소",
    rn: 5038
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송옥순태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "송옥순태양광발전소",
    rn: 5039
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해성1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해성1호태양광발전소",
    rn: 5040
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해성2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "해성2호태양광발전소",
    rn: 5041
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한빛태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "한빛태양광발전소",
    rn: 5042
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지효1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "지효1호태양광발전소",
    rn: 5043
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "희망태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "희망태양광발전소",
    rn: 5044
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금호1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "금호1호태양광발전소",
    rn: 5045
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금산1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "금산1호태양광발전소",
    rn: 5046
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래솔라태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "미래솔라에너지 주식회사",
    rn: 5047
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "능성태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 능성발전",
    rn: 5048
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "오메가태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "오메가태양광발전소",
    rn: 5049
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청솔태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "청솔태양광발전소",
    rn: 5050
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕암쏠라파크_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(유)덕암쏠라파크",
    rn: 5051
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "써니파크태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "써니파크승마장",
    rn: 5052
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정유원에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "정유원에너지",
    rn: 5053
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주GT",
    cent: "중앙",
    fuel: "중유",
    pcap: 55,
    company: "한국중부발전(주)",
    rn: 5054
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스지이엠태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)에스지이엠",
    rn: 5055
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청아태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "청아태양광발전소",
    rn: 5056
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성보태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "성보태양광발전소",
    rn: 5057
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우주1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "유한회사 장수태양광발전소",
    rn: 5058
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우주2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "유한회사 장수태양광발전소",
    rn: 5059
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대산쏠라7호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(유)자연과사람",
    rn: 5060
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "바우네태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "바우네 태양광발전소",
    rn: 5061
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태광대독발전_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사태광통신",
    rn: 5062
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제석태양광발전소01호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "제석 태양광발전소 01호",
    rn: 5063
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "오뚜기태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "오뚜기 태양광발전소",
    rn: 5064
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주GT#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 55,
    company: "한국중부발전(주)",
    rn: 5065
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주GT#2",
    cent: "중앙",
    fuel: "중유",
    pcap: 55,
    company: "한국중부발전(주)",
    rn: 5066
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "제주GT#3",
    cent: "중앙",
    fuel: "경유",
    pcap: 55,
    company: "한국중부발전(주)",
    rn: 5067
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금능1호태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "금능1호 태양광발전소",
    rn: 5068
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금능2호태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "금능2호태양광발전소",
    rn: 5069
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대리은하2호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "대리은하1호2호태양광발전소",
    rn: 5070
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광현2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "(주)광현",
    rn: 5071
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백송태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "백송태양광발전소",
    rn: 5072
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남포전력태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "남포전력태양광발전소",
    rn: 5073
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주천행복태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주천행복발전소",
    rn: 5074
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정승기1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "정승기1호태양광발전소",
    rn: 5075
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엠제이태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "엠제이태양광발전소",
    rn: 5076
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성두태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "성두태양광발전소",
    rn: 5077
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보성파워텍1호기ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "보성파워텍(주)",
    rn: 5078
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보국전자태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "보국전자(주)",
    rn: 5079
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엔와이태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "엔와이태양광발전소",
    rn: 5080
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영광송림리태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "송림리 태양광발전소",
    rn: 5081
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "나래에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "나래에너지 태양광발전소",
    rn: 5082
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스플랜트6호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "다이아몬드에너지 주식회사",
    rn: 5083
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "맥스에너지2호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "주식회사 맥스에너지2호",
    rn: 5084
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "단비발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "단비농원",
    rn: 5085
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "중덕태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "중덕태양광발전소",
    rn: 5086
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행복태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "행복태양광발전소",
    rn: 5087
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성동8호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "성동8호 태양광발전소",
    rn: 5088
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성동13호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "성동13호 태양광발전소",
    rn: 5089
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화성태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "농업회사법인(주)화성",
    rn: 5090
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서호1호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)서호바에너지",
    rn: 5091
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스알비#1_ESS1 ",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 에스알비태양광",
    rn: 5092
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스알비#1_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 에스알비태양광",
    rn: 5093
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "에스알비#2_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 에스알비태양광",
    rn: 5094
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "초포솔라#1_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 초포솔라",
    rn: 5095
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "초포솔라#1_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 초포솔라",
    rn: 5096
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "초포솔라#2_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 초포솔라",
    rn: 5097
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대명태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "대명태양광발전소",
    rn: 5098
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영동에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "영동에너지 태양광발전소",
    rn: 5099
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동강태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동강태양광발전소",
    rn: 5100
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "온더로드ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "온더로드 태양광발전소",
    rn: 5101
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(유)에너지팜 태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(유)에너지팜",
    rn: 5102
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "상천태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "상천태양광발전소",
    rn: 5103
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강천1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 강천1호",
    rn: 5104
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "강천2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 강천2호 ",
    rn: 5105
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "위천솔라태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 위천솔라",
    rn: 5106
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "와이엔솔라태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "와이엔솔라주식회사",
    rn: 5107
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "레일에너지태양광7_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "레일에너지(주)",
    rn: 5108
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "아이엠성수1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "아이엠성수1호태양광발전소",
    rn: 5109
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "레일에너지태양광6_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "레일에너지(주)",
    rn: 5110
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "HD태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "주식회사 현대에너지",
    rn: 5111
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "와이피피농원에너지2호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "와이피피쏠라(주)",
    rn: 5112
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도우농산태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "농업회사법인 도우농산 유한회사",
    rn: 5113
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "디에스에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(유)디에스에너지",
    rn: 5114
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유니온쏠라에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유니온쏠라에너지(주) ",
    rn: 5115
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "JS1SSPP태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 더이에스",
    rn: 5116
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사선태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "유한회사 사선",
    rn: 5117
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "영호태양광발전소",
    rn: 5118
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창수발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "(주)창수발전소",
    rn: 5119
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의수사랑에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "주식회사 의수사랑에너지",
    rn: 5120
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천사태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "천사태양광발전소",
    rn: 5121
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하늘에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)하늘에너지",
    rn: 5122
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동양태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "동양태양광",
    rn: 5123
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조문국태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "조문국 태양광발전소",
    rn: 5124
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "건국대학교경영관ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "건국대학교",
    rn: 5125
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "포에이스태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 포에이스",
    rn: 5126
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고래태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "고래태양광발전소",
    rn: 5127
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#2_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5128
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#2_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5129
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#2_ESS3",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5130
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#2_ESS4",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5131
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#2_ESS5",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5132
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유솔라파크#2_ESS6",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유솔라파크",
    rn: 5133
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "씨에스쏠라태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "씨에스쏠라",
    rn: 5134
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "궁촌1태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "궁촌1 태양광발전소",
    rn: 5135
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "궁촌2태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "궁촌2 태양광발전소",
    rn: 5136
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "박스뱅크태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "박스뱅크주식회사",
    rn: 5137
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#1 ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5138
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#1 ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5139
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#1 ESS3",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5140
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#1 ESS4",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5141
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#1 ESS5",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5142
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#1 ESS6",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5143
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#2 ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 제이더블유에너지",
    rn: 5144
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#2 ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 제이더블유에너지",
    rn: 5145
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#2 ESS3",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5146
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이더블유에너지#2 ESS4",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.75,
    company: "주식회사 제이더블유에너지",
    rn: 5147
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "센도리2태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "센도리 제2태양광발전소",
    rn: 5148
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양5호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "조양5호 태양발전소",
    rn: 5149
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양6호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "조양6호태양광발전소",
    rn: 5150
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양10호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "조양10호태양광발전소",
    rn: 5151
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양11호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "조양11호태양발전소",
    rn: 5152
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양12호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "조양12호태양광발전소",
    rn: 5153
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우인에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 우인에너지",
    rn: 5154
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "상림태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "상림 태양광발전소",
    rn: 5155
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서보의성태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)서보의성발전소",
    rn: 5156
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "MG정읍태양광발전소1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "정읍새마을금고",
    rn: 5157
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "MG정읍태양광발전소2호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "정읍새마을금고(MG정읍푸드)",
    rn: 5158
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금용태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "(주)금용태양광발전",
    rn: 5159
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은성푸드태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "주식회사 은성푸드",
    rn: 5160
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "문정석태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "문정석태양광발전소",
    rn: 5161
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서보의성태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)서보의성발전소",
    rn: 5162
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태안태양광2단계_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 8,
    company: "한국서부발전(주)",
    rn: 5163
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "남제주내연#1-1",
    cent: "중앙",
    fuel: "중유",
    pcap: 10,
    company: "한국남부발전(주)",
    rn: 5164
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "남제주내연#1-2",
    cent: "중앙",
    fuel: "중유",
    pcap: 10,
    company: "한국남부발전(주)",
    rn: 5165
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "남제주내연#2-1",
    cent: "중앙",
    fuel: "중유",
    pcap: 10,
    company: "한국남부발전(주)",
    rn: 5166
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "남제주내연#2-2",
    cent: "중앙",
    fuel: "중유",
    pcap: 10,
    company: "한국남부발전(주)",
    rn: 5167
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "남제주내연#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 20,
    company: "한국남부발전(주)",
    rn: 5168
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "남제주내연#2",
    cent: "중앙",
    fuel: "중유",
    pcap: 20,
    company: "한국남부발전(주)",
    rn: 5169
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양8호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "조양8호태양광발전소",
    rn: 5170
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양7호태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "조양7호 태양광발전소",
    rn: 5171
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해모아태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "주식회사 부성",
    rn: 5172
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영양무창풍력_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 8,
    company: "(주)GS풍력발전",
    rn: 5173
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하찬캄태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 하찬캄태양광발전소",
    rn: 5174
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주덕대곡발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)주덕대곡발전소",
    rn: 5175
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신영태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)신영태양광발전소",
    rn: 5176
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "평화태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "평화태양광발전소",
    rn: 5177
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다솔태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "다솔태양광발전소",
    rn: 5178
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "와룡태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "와룡태양광발전소",
    rn: 5179
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "비토태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "비토태양광발전소",
    rn: 5180
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "봉명태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "봉명태양광발전소",
    rn: 5181
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남강태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(유)남강에너지",
    rn: 5182
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연",
    cent: "중앙",
    fuel: "중유",
    pcap: 40,
    company: "한국중부발전(주)",
    rn: 5183
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "조양9호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "조양9호 태양광발전소",
    rn: 5184
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대룡1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 대룡기업",
    rn: 5185
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김태욱태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "태욱태양광발전소",
    rn: 5186
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "켄스태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "켄스태양광발전소",
    rn: 5187
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김택동.송명희태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "김택동.송명희태양광발전소",
    rn: 5188
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대지산업태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "대지산업(주)",
    rn: 5189
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "팔선태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "팔선 태양광발전소",
    rn: 5190
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은주태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "은주태양광발전소",
    rn: 5191
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우실1호태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 우실태양광",
    rn: 5192
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우실1호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.25,
    company: "주식회사 우실태양광",
    rn: 5193
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5194
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#2",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5195
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#3",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5196
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#4",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5197
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#5",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5198
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#6",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5199
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#7",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5200
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "북제주내연#8",
    cent: "중앙",
    fuel: "중유",
    pcap: 5,
    company: "한국중부발전(주)",
    rn: 5201
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 송촌에너지 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사송촌에너지",
    rn: 5202
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "제주내연#1",
    cent: "중앙",
    fuel: "중유",
    pcap: 40,
    company: "한국중부발전(주)",
    rn: 5203
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "화력",
    genNm: "제주내연#2",
    cent: "중앙",
    fuel: "중유",
    pcap: 40,
    company: "한국중부발전(주)",
    rn: 5204
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우실2호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사 우실태양광",
    rn: 5205
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금오에너지1호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)금오에너지",
    rn: 5206
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금오에너지1호태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)금오에너지",
    rn: 5207
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금오에너지2호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)금오에너지",
    rn: 5208
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스에너지28호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 티에스에너지28호",
    rn: 5209
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스에너지28호태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 티에스에너지28호",
    rn: 5210
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대산쏠라4호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)자연과사람",
    rn: 5211
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원희태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "원희 태양광 발전소",
    rn: 5212
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "산소솔라태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)산소솔라",
    rn: 5213
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "산소솔라태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "(주)산소솔라",
    rn: 5214
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "산소1호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)티에스에너지7호",
    rn: 5215
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "산소1호태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)티에스에너지7호",
    rn: 5216
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "산소2호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)티에스에너지7호",
    rn: 5217
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "산소2호태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)티에스에너지7호",
    rn: 5218
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한자1호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)한자태양광",
    rn: 5219
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한자1호태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "(주)한자태양광",
    rn: 5220
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한자2호태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.825,
    company: "(주)한자태양광",
    rn: 5221
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스에너지외입태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 티에스에너지26호",
    rn: 5222
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "티에스에너지외입태양광_ESS2",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.1,
    company: "주식회사 티에스에너지26호",
    rn: 5223
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신정솔라태양광_ESS1",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.825,
    company: "주식회사 신정솔라",
    rn: 5224
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성덕태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "성덕태양광발전소",
    rn: 5225
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "부귀리태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "부귀리 태양광발전소",
    rn: 5226
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현우다인태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "현우",
    rn: 5227
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "창성에너지태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "그린존",
    rn: 5228
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "베스트솔라에너지1호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "베스트솔라에너지(주)",
    rn: 5229
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "베스트솔라에너지2호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "베스트솔라에너지(주)",
    rn: 5230
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "베스트솔라에너지3호_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 5,
    company: "베스트솔라에너지(주)",
    rn: 5231
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "양정태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "양정태양광발전",
    rn: 5232
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "씨앤피태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "씨앤피(C&P)태양광발전",
    rn: 5233
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보생발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "보생 발전소",
    rn: 5234
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다성태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "다성태양광발전소",
    rn: 5235
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보나태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "보나태양광발전소",
    rn: 5236
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "나눔태양광(장성)_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)나눔장학회",
    rn: 5237
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주혁3태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "농업회사법인(유)주혁",
    rn: 5238
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "섬진2호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "섬진2호 태양광발전소",
    rn: 5239
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "현도태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)현도",
    rn: 5240
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송림태양광발전소(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "(주)송림태양광",
    rn: 5241
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "HVDC",
    cent: "중앙",
    fuel: "기타",
    pcap: 300,
    company: "한국전력공사",
    rn: 5242
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "HVDC#1 1POLE",
    cent: "중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 5243
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "HVDC#2 1POLE",
    cent: "중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 5244
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대정2태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "대정2태양광발전소",
    rn: 5245
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광석에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "광석에너지",
    rn: 5246
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화선태양광a_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "화선태양광발전소",
    rn: 5247
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경민태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "경민태양광발전소",
    rn: 5248
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "하나태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "하나태양광발전소",
    rn: 5249
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광명태양광a_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "광명태양광발전소",
    rn: 5250
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제일태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "제일태양광발전소",
    rn: 5251
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼백쏠라랜드_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "영농조합법인 내일",
    rn: 5252
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "대명지이씨풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "대명지이씨풍력발전주식회사",
    rn: 5253
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "티씨에스원연료전지#2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.6,
    company: "티씨에스원(주)연료전지",
    rn: 5254
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "인제용대풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "인제군",
    rn: 5255
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화남제1태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 화남일",
    rn: 5256
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "상공에너지열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 9.8,
    company: "상공에너지(주)",
    rn: 5257
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탕정공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "한국남동발전(주)",
    rn: 5258
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산신항태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.116,
    company: "한국남부발전(주)",
    rn: 5259
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "부산연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.6,
    company: "티피피(주)(발전)",
    rn: 5260
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원풍력#14",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 0.66,
    company: "제주에너지공사",
    rn: 5261
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "씨지앤율촌 연료전지#2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.6,
    company: "씨지앤율촌전력(주)",
    rn: 5262
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "가시리풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 15,
    company: "제주에너지공사",
    rn: 5263
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "합천댐수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국수자원공사",
    rn: 5264
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동변전소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.048,
    company: "한국남부발전(주)",
    rn: 5265
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "(주)디엠에스",
    rn: 5266
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "충북태양광16호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.464,
    company: "충북태양광발전(주)",
    rn: 5267
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "청주LFG발전기",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.8,
    company: "(주)지엔씨에너지",
    rn: 5268
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕암5호 태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "덕암5호 태양광발전소",
    rn: 5269
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "분당연료전지#4",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 16.72,
    company: "한국남동발전(주)",
    rn: 5270
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "분당연료전지#5",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.72,
    company: "한국남동발전(주)",
    rn: 5271
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화남제2태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "주식회사 화남이",
    rn: 5272
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "율문쏠라테크태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)율문쏠라테크",
    rn: 5273
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "미래태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "(주)미래",
    rn: 5274
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "단양태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)한국태양광발전소",
    rn: 5275
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대산쏠라에너지1호태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "대산농수산(주)",
    rn: 5276
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "춘천쏠라테크태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 3,
    company: "(주)춘천쏠라테크",
    rn: 5277
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "유한회사 천일에너지",
    rn: 5278
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "인7호태양광발전소_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "유한회사 천일에너지",
    rn: 5279
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "녹산표준형공장_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(주)부산도시가스",
    rn: 5280
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경천태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "경천태양광발전소",
    rn: 5281
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥화력풍력 ESS #1",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "한국남동발전(주)",
    rn: 5282
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥화력풍력 ESS #2",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "한국남동발전(주)",
    rn: 5283
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성산풍력#2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "한국남부발전(주)",
    rn: 5284
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "GS영양풍력#1 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 16.8,
    company: "(주)GS영양풍력발전",
    rn: 5285
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "화순풍력 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 1.25,
    company: "한국서부발전(주)",
    rn: 5286
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영암풍력ESS #1",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "영암풍력발전(주)",
    rn: 5287
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영암풍력ESS #2",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "영암풍력발전(주)",
    rn: 5288
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "군산산단풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 1.2,
    company: "군산풍력발전(주) (지점1)",
    rn: 5289
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "시화 방아머리풍력 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 0.1,
    company: "한국수자원공사",
    rn: 5290
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "상명풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "한국중부발전(주)",
    rn: 5291
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행원연안풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 0.5,
    company: "제주도청",
    rn: 5292
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천북풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 1,
    company: "지에스파워(주)",
    rn: 5293
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가시리풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 3,
    company: "제주에너지공사",
    rn: 5294
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동복풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 6,
    company: "제주에너지공사",
    rn: 5295
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "약수풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 4,
    company: "영광약수풍력발전(주)",
    rn: 5296
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "평창풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 4,
    company: "평창풍력발전주식회사",
    rn: 5297
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "평창풍력ESS#2",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "평창풍력발전주식회사",
    rn: 5298
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "거창풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 3,
    company: "거창풍력발전 주식회사",
    rn: 5299
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태백풍력ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 3,
    company: "태백풍력발전(주)",
    rn: 5300
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥화력풍력 ESS #1-1",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "한국남동발전(주)",
    rn: 5301
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영흥화력풍력 ESS #2-1",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 2,
    company: "한국남동발전(주)",
    rn: 5302
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서강레미콘 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "주식회사 서강레미콘",
    rn: 5303
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고성태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "주식회사 고성태양광발전",
    rn: 5304
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "갈마태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "갈마태양광발전소",
    rn: 5305
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "덕암7호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "덕암7호태양광발전소",
    rn: 5306
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "고운에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "고운에너지 주식회사",
    rn: 5307
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "벧엘 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.05,
    company: "유한회사 벧엘태양광",
    rn: 5308
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대명솔라1호에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "주식회사 대명그린에너지",
    rn: 5309
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이레태양광c ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "(주)이레태양광발전소",
    rn: 5310
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금산단양1호태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "(주)금산솔라1",
    rn: 5311
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주영태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "(주)주영",
    rn: 5312
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "목곡7호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "목곡7호태양광발전소",
    rn: 5313
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "목곡16호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "목곡16호태양광발전소",
    rn: 5314
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "진에너지",
    rn: 5315
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한율용기태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 한율",
    rn: 5316
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "익산태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(유)금성썬에너지",
    rn: 5317
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "양지동연태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "양지태양광발전소 유한회사",
    rn: 5318
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보령에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "보령에너지 태양광발전소",
    rn: 5319
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이에너지ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "제이에너지 태양광발전소",
    rn: 5320
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우주3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "유한회사 장수태양광발전소",
    rn: 5321
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "호남풍력발전ESS",
    cent: "비중앙",
    fuel: "전기저장장치(풍력)",
    pcap: 3,
    company: "호남풍력발전(주)",
    rn: 5322
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원석1호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 황금에너지",
    rn: 5323
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성실5호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "성실에프앤에프 영농조합법인",
    rn: 5324
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "성실3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "에스디 유한회사",
    rn: 5325
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대정상모태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)대정태양광발전(상모)",
    rn: 5326
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대정영락태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)대정태양광발전(영락)",
    rn: 5327
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "예빛태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "예빛태양광발전소",
    rn: 5328
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "썬태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "썬태양광발전소",
    rn: 5329
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남정수상상모#1_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "(주)남정수상태양광",
    rn: 5330
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "남정수상상모#2_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)남정수상태양광",
    rn: 5331
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "은혜태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "(주)은혜태양광발전",
    rn: 5332
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "영락태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "주식회사 영락태양광",
    rn: 5333
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백일에너지_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "백일에너지",
    rn: 5334
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "백일태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "백일태양광",
    rn: 5335
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "지영태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "지영태양광발전소",
    rn: 5336
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "쏠라텍태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 솔라텍코리아",
    rn: 5337
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "주식회사 중앙시스템 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 중앙시스템",
    rn: 5338
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "스포원태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "종로전기(주)",
    rn: 5339
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "해떠태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "한국산업전력공사",
    rn: 5340
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "순은태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "순은태양광발전소",
    rn: 5341
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "태영태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "주식회사 태영태양광발전소",
    rn: 5342
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "소곡태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)소곡태양광발전소",
    rn: 5343
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "단월리발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "충주브래드 11호",
    rn: 5344
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "득량태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "득량 태양광발전소",
    rn: 5345
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "근우태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.095,
    company: "SH에너지",
    rn: 5346
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대곡태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "주식회사 대곡",
    rn: 5347
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한양솔라_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "한양솔라(주)",
    rn: 5348
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "사벌쏠라랜드_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "사벌쏠라랜드 주식회사",
    rn: 5349
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "유진쏠라랜드_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.45,
    company: "유진쏠라랜드 주식회사",
    rn: 5350
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "우진태양광_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "우진스틸(주)",
    rn: 5351
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "송이용지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "유한회사 송이",
    rn: 5352
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "행복태양광dESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "행복태양광발전소",
    rn: 5353
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "가델에너지태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "가델에너지태양광발전소",
    rn: 5354
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대현태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "주식회사 대현태양광발전소",
    rn: 5355
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보덕3호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "보덕3호태양광발전소",
    rn: 5356
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보덕4호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "보덕4호태양광발전소",
    rn: 5357
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "보덕5호ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "보덕5호태양광발전소",
    rn: 5358
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "어필태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.1,
    company: "어필 태양광발전소",
    rn: 5359
    },
    {
    area: "수도권",
    genFom: "해양에너지",
    genSrc: "신재생에너지",
    genNm: "시화호조력",
    cent: "비중앙",
    fuel: "해양에너지",
    pcap: 254,
    company: "한국수자원공사",
    rn: 5360
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "샘골1호태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "샘골1호태양광발전소",
    rn: 5361
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "삼이일태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.08,
    company: "삼이일태양광발전소",
    rn: 5362
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이씨지에너지1호태양광(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.4,
    company: "JCG솔라",
    rn: 5363
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "제이씨지에너지2호태양광(ESS)",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.2,
    company: "JCG솔라",
    rn: 5364
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "연실태양광발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)연실태양광발전소",
    rn: 5365
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "온스타1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "온스타1호 태양광발전소",
    rn: 5366
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "동연1호태양광발전 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(유)태광에너지",
    rn: 5367
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "도림태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "도림태양광발전소",
    rn: 5368
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "명림태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.075,
    company: "명림태양광발전소",
    rn: 5369
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "디엔아이장교리ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)디엔아이코퍼레이션(지점)",
    rn: 5370
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "다우장교리ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)다우코퍼레이션(지점)",
    rn: 5371
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "정수발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.25,
    company: "정수발전소",
    rn: 5372
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원호태양광발전소1호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)원호태양광발전소",
    rn: 5373
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원호태양광발전소2호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)원호태양광발전소",
    rn: 5374
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원호태양광발전소3호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)원호태양광발전소",
    rn: 5375
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원호태양광발전소4호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)원호태양광발전소",
    rn: 5376
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원호태양광발전소5호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)원호태양광발전소",
    rn: 5377
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원호태양광발전소6호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)원호태양광발전소",
    rn: 5378
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "원호태양광발전소7호 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주)원호태양광발전소",
    rn: 5379
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "청정발전소 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.6,
    company: "주식회사 청정발전소",
    rn: 5380
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "두산솔라파워2호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "퓨처에너지 인프라 주식회사",
    rn: 5381
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "두산솔라파워6호 태양광 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1.5,
    company: "퓨처에너지 인프라 주식회사",
    rn: 5382
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "낙원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "대전리2호태양광발전소",
    rn: 5383
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "금우태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "메가나인 주식회사",
    rn: 5384
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "이오태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "이오태양광",
    rn: 5385
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엘림태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "엘림태양광",
    rn: 5386
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "광민솔라태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 광민솔라",
    rn: 5387
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "(주)디원태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "(주) 디원",
    rn: 5388
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "진산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "진산 태양광발전소",
    rn: 5389
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "두산태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.099,
    company: "두산 태양광발전소",
    rn: 5390
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "탑인프라영암태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 6,
    company: "주식회사 강진항촌태양광",
    rn: 5391
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "햇빛무릉_ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.8,
    company: "(주)햇빛태양광발전",
    rn: 5392
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "천지에너지태양광발전소ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "유한회사 천지에너지",
    rn: 5393
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "대명에너지태양광발전ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 1,
    company: "유한회사 대명에너지 ",
    rn: 5394
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "김제ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 5395
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "논공ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 36,
    company: "한국전력공사(송전)",
    rn: 5396
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "울산ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 16,
    company: "한국전력공사(송전)",
    rn: 5397
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "속초ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 5398
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신용인ESS#1",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 16,
    company: "한국전력공사(송전)",
    rn: 5399
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신용인ESS#2",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 8,
    company: "한국전력공사(송전)",
    rn: 5400
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서안성ESS#1",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 16,
    company: "한국전력공사(송전)",
    rn: 5401
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서안성ESS#2",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 12,
    company: "한국전력공사(송전)",
    rn: 5402
    },
    {
    area: "수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "경산ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 48,
    company: "한국전력공사(송전)",
    rn: 5403
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신충주ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 16,
    company: "한국전력공사(송전)",
    rn: 5404
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신화순ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 5405
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "의령ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 5406
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신계룡ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 5407
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해오름에너지장수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.87,
    company: "(주)해오름에너지",
    rn: 5408
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "에코에너지홀딩스마산LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.9,
    company: "(주)에코에너지홀딩스",
    rn: 5409
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이유그린신흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.384,
    company: "(주)탑인프라일렉트릭(신흥)",
    rn: 5410
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이유그린보안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.384,
    company: "(주)탑인프라일렉트릭(보안)",
    rn: 5411
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월출산영암태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "(주)월출산태양광",
    rn: 5412
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선곡군위태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.794,
    company: "솔라시티주식회사",
    rn: 5413
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포너스강릉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0,
    company: "포너스태양광발전소",
    rn: 5414
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉화발전법전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.915,
    company: "봉화발전소(주)",
    rn: 5415
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태광쏠라괴산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "태광쏠라파워(주)",
    rn: 5416
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옥광에너지영덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.968,
    company: "(주)옥광에너지",
    rn: 5417
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태화쏠라고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.737,
    company: "(유)태화쏠라텍",
    rn: 5418
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "렌텍태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "씨그마렌텍(주)",
    rn: 5419
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조양1호보령태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0,
    company: "조양1호태양광발전소",
    rn: 5420
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서연에너지무안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "서연에너지태양광발전소",
    rn: 5421
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천욱발전영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.988,
    company: "에스에너지제일호천욱태양광발전소(주)",
    rn: 5422
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고단태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.462,
    company: "고단태양광발전소",
    rn: 5423
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "울주ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 5424
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "신김제ESS",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 5425
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광석의성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(주)광석태양광발전소",
    rn: 5426
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천광산업영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.995,
    company: "(주)천광에너지",
    rn: 5427
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유성에너지수산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.483,
    company: "(유)유성에너지",
    rn: 5428
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가산태양광7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "가산태양광발전소(주)",
    rn: 5429
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도천솔라창녕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.793,
    company: "(주)도천솔라",
    rn: 5430
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이원에너지공주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "제이원에너지(주)",
    rn: 5431
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)업스톤해오름태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.194,
    company: "(주)업스톤해오름태양광발전소",
    rn: 5432
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라파크2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.389,
    company: "(유)대명솔라파크",
    rn: 5433
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유성에너지대성리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.977,
    company: "(유)유성에너지",
    rn: 5434
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남화원장춘태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "(주)반석파워",
    rn: 5435
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선창에너지(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "선창에너지(주)",
    rn: 5436
    },
    {
    area: "수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "누에섬풍력발전",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.25,
    company: "안산시",
    rn: 5437
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "(주)금성태양광발전소",
    rn: 5438
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산거림소각장",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.2,
    company: "(주)거림",
    rn: 5439
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "고양시소각장",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 5.3,
    company: "고양시",
    rn: 5440
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "강변연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 1.2,
    company: "벽산건설(주)",
    rn: 5441
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "제주시청폐기물발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.2,
    company: "제주시",
    rn: 5442
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "에코에너지홀딩스구미LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.45,
    company: "(주)에코에너지홀딩스",
    rn: 5443
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "광주양과LFG#1",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 1.06,
    company: "파낙스에너지(주)광주LFG발전소",
    rn: 5444
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "인제용대풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "인제군청",
    rn: 5445
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "한국남동발전(주)",
    rn: 5446
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "푸른빛태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.838,
    company: "푸른빛태양광(주)",
    rn: 5447
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "경천소수력제2발전기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.1,
    company: "(주)경천소수력제2발전소",
    rn: 5448
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "동대문환경바이오가스발전기",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 1.065,
    company: "동대문환경개발공사",
    rn: 5449
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "의정부시청폐기물발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.2,
    company: "의정부시",
    rn: 5450
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "금호태양광발전소",
    rn: 5451
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신생태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.838,
    company: "신발전소 주식회사",
    rn: 5452
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정포태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(주)정포태양광발전소",
    rn: 5453
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "현중풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 1.65,
    company: "현대중공업(주)",
    rn: 5454
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "김녕풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 0.75,
    company: "제주에너지공사",
    rn: 5455
    },
    {
    area: "수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "시화방아머리풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "한국수자원공사",
    rn: 5456
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "군위댐소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.5,
    company: "한국수자원공사",
    rn: 5457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태우당진태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "태우(주)",
    rn: 5458
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이화에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "이화에너지",
    rn: 5459
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한울에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "한울에너지",
    rn: 5460
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "안양연료전지발전기",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 4.8,
    company: "지에스파워(주)",
    rn: 5461
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "대성에너지_죽곡(발전)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 9,
    company: "대성에너지(주)(구역)",
    rn: 5462
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "대성에너지_죽곡(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 9,
    company: "대성에너지(주)세너지(구역)(구매)",
    rn: 5463
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "광양부생복합1호기",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 131,
    company: "포스코에너지(주)",
    rn: 5464
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "광양부생복합2호기",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 131,
    company: "포스코에너지(주)",
    rn: 5465
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 청수에너지#1",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 8.439,
    company: "제이비 주식회사 청수에너지",
    rn: 5466
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 청수에너지#2",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 8.439,
    company: "제이비 주식회사 청수에너지",
    rn: 5467
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 청수에너지#1(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "제이비 주식회사 청수에너지(구매)",
    rn: 5468
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 청수에너지#2(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "제이비 주식회사 청수에너지(구매)",
    rn: 5469
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "새만금풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "군산풍력발전(주) (지점2)",
    rn: 5470
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "구미시청자원회수시설발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3,
    company: "구미시청",
    rn: 5471
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산정관에너지(대표)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 50.15,
    company: "부산정관에너지(구역)",
    rn: 5472
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산정관에너지(구매)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 50.15,
    company: "부산정관에너지(구역)(구매)",
    rn: 5473
    },
    {
    area: "수도권",
    genFom: "해양에너지",
    genSrc: "신재생에너지",
    genNm: "시화호조력#1",
    cent: "비중앙",
    fuel: "해양에너지",
    pcap: 101.6,
    company: "한국수자원공사",
    rn: 5474
    },
    {
    area: "수도권",
    genFom: "해양에너지",
    genSrc: "신재생에너지",
    genNm: "시화호조력#2",
    cent: "비중앙",
    fuel: "해양에너지",
    pcap: 101.6,
    company: "한국수자원공사",
    rn: 5475
    },
    {
    area: "수도권",
    genFom: "해양에너지",
    genSrc: "신재생에너지",
    genNm: "시화호조력#3",
    cent: "비중앙",
    fuel: "해양에너지",
    pcap: 50.8,
    company: "한국수자원공사",
    rn: 5476
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리_광명#1(송전)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 23,
    company: "삼천리",
    rn: 5477
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리_광명#2(송전)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 23,
    company: "삼천리",
    rn: 5478
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리_광명#1(구매)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 0,
    company: "삼천리(구매)",
    rn: 5479
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리_광명#2(구매)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 0,
    company: "삼천리(구매)",
    rn: 5480
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완에너지#1",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 59,
    company: "수완에너지주식회사",
    rn: 5481
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완에너지#2",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 59,
    company: "수완에너지주식회사",
    rn: 5482
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완에너지#1(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "수완에너지주식회사(구매)",
    rn: 5483
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "수완에너지#2(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "수완에너지주식회사(구매)",
    rn: 5484
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "GS E&R 월령풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "(주)GS풍력발전",
    rn: 5485
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 탕정에너지(구역)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 7.3,
    company: "제이비 주식회사 탕정에너지(구역)",
    rn: 5486
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 탕정에너지(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 7.3,
    company: "제이비 주식회사 탕정에너지(구매)",
    rn: 5487
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "가락열병합발전소",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 1.082,
    company: "한국지역난방공사 남부지사(구역)",
    rn: 5488
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "가락열병합발전소(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국지역난방공사 강남지사(구역)(구매)",
    rn: 5489
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "상암열병합발전소",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 6,
    company: "한국지역난방공사 중앙지사(구역)",
    rn: 5490
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "상암열병합발전소(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국지역난방공사 중앙지사(구역)(구매)",
    rn: 5491
    },
    {
    area: "수도권",
    genFom: "가스터빈(단독)",
    genSrc: "화력",
    genNm: "동남권열병합#1",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 16,
    company: "한국지역난방공사 남부지사(구역)",
    rn: 5492
    },
    {
    area: "수도권",
    genFom: "가스터빈(단독)",
    genSrc: "화력",
    genNm: "동남권열병합#2",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 16,
    company: "한국지역난방공사 남부지사(구역)",
    rn: 5493
    },
    {
    area: "수도권",
    genFom: "가스터빈(단독)",
    genSrc: "화력",
    genNm: "동남권열병합#1(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국지역난방공사 강남지사(구역)(구매)",
    rn: 5494
    },
    {
    area: "수도권",
    genFom: "가스터빈(단독)",
    genSrc: "화력",
    genNm: "동남권열병합#2(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "한국지역난방공사 강남지사(구역)(구매)",
    rn: 5495
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "대성에너지_죽곡(구매2)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "대성에너지(주)(구역)(구매)",
    rn: 5496
    },
    {
    area: "수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영흥풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 22,
    company: "한국남동발전(주)",
    rn: 5497
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "군장열병합발전",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 122.9,
    company: "한화에너지(주)군산공장(구역)",
    rn: 5498
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "상암연료전지발전기",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "포스코에너지(주)",
    rn: 5499
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "대전서남부열병합",
    cent: "중앙",
    fuel: "LNG",
    pcap: 48.3,
    company: "한국토지주택공사 인천에너지사업단",
    rn: 5500
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해아름 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "해아름(주)",
    rn: 5501
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예천어림호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "한국남동발전(주)",
    rn: 5502
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "원통보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.2,
    company: "인제군",
    rn: 5503
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "티피피#1",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 10.5,
    company: "티피피(주)",
    rn: 5504
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "티피피(주)#1(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "티피피(주)(구매)",
    rn: 5505
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "티씨에스원 연료전지#1",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.6,
    company: "티씨에스원(주)연료전지",
    rn: 5506
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "인제서리보소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 1.5,
    company: "인제군",
    rn: 5507
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "티피피#2",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 10.5,
    company: "티피피(주)",
    rn: 5508
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "티피피(주)#2(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "티피피(주)(구매)",
    rn: 5509
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "마포자원회수시설발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 5,
    company: "서울특별시 마포",
    rn: 5510
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "대구우드칩발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 3,
    company: "한국지역난방공사",
    rn: 5511
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "디큐브시티가스발전기",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 9,
    company: "대성산업(주)(구역전기)",
    rn: 5512
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "신도림디큐브시티(구매)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 0,
    company: "대성산업(주)(구역전기)(구매)",
    rn: 5513
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "춘천소각로발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.9,
    company: "춘천시",
    rn: 5514
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "거제소각로발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3,
    company: "거제시",
    rn: 5515
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.003,
    company: "한국동서발전(주)",
    rn: 5516
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서울태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.301,
    company: "한국중부발전(주)",
    rn: 5517
    },
    {
    area: "제주",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "행원소수력발전소",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.06,
    company: "한국남부발전(주)",
    rn: 5518
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원소수력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.01,
    company: "한국남부발전(주)",
    rn: 5519
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령화력태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.525,
    company: "한국중부발전(주)",
    rn: 5520
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#1",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 5521
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#2",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 5522
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#3",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 5523
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대그린파워#4",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 100,
    company: "현대그린파워(주)",
    rn: 5524
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동화력태양광2호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.9,
    company: "한국남부발전(주)",
    rn: 5525
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동화력태양광3호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "한국남부발전(주)",
    rn: 5526
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "울산태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.509,
    company: "한국동서발전(주)",
    rn: 5527
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "포항제철",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 1007,
    company: "(주)포스코",
    rn: 5528
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "이천소각로열병합",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 8.2,
    company: "이천시",
    rn: 5529
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예천솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)예천솔라팜",
    rn: 5530
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천공항열병합b",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 127,
    company: "인천공항에너지(주)",
    rn: 5531
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "인천공항열병합",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 80,
    company: "인천공항에너지(주)",
    rn: 5532
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "파주소각로열병합",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.2,
    company: "파주시",
    rn: 5533
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "삼척그린파워 해양소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.75,
    company: "한국남부발전(주)",
    rn: 5534
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척그린파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한국남부발전(주)",
    rn: 5535
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스플랜트부안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.285,
    company: "(주)아이유에스앤비",
    rn: 5536
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연지장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "연지태양광발전㈜",
    rn: 5537
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "OCI열병합",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 19.2,
    company: "OCI(주)",
    rn: 5538
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.6,
    company: "태안솔라팜주식회사",
    rn: 5539
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지플러스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "유한회사 에너지플러스",
    rn: 5540
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성레포츠김제태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "(유)금성썬에너지",
    rn: 5541
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "광양제철#1",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 1964.5,
    company: "(주)포스코",
    rn: 5542
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "광양제철#2",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 0,
    company: "(주)포스코",
    rn: 5543
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산복합태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.392,
    company: "한국남부발전(주)",
    rn: 5544
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포에코예천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "포에코",
    rn: 5545
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경훈영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)경훈에너지",
    rn: 5546
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대구염색열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 72.9,
    company: "대구염색산업단지",
    rn: 5547
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "밀양얼음골태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "(주)밀양얼음골태양광발전소",
    rn: 5548
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포스코광양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)포스코",
    rn: 5549
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김해명동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.539,
    company: "김해명동태양광발전(주)",
    rn: 5550
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광에너지고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(유)서광에너지",
    rn: 5551
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "반월열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 83.455,
    company: "(주)GS E&R",
    rn: 5552
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우수영해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)우수영에너지",
    rn: 5553
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "(주)영광태양광발전소",
    rn: 5554
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대호신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.808,
    company: "대호에너지주식회사",
    rn: 5555
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린솔라장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)그린솔라",
    rn: 5556
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한미래보성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "대한미래쏠라(주)",
    rn: 5557
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔로몬영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "솔로몬솔라파워(주)",
    rn: 5558
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "토탈장수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.234,
    company: "(주)토탈에너지",
    rn: 5559
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선미래의성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.446,
    company: "(주)선미래에너지",
    rn: 5560
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에스의성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.489,
    company: "(주)에스에스솔라파크",
    rn: 5561
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정읍솔라태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.6,
    company: "(주)정읍솔라라이트",
    rn: 5562
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "구미열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 97.1,
    company: "STX에너지(주) 구미",
    rn: 5563
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬사랑진도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "(주)진슬태양광",
    rn: 5564
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신동진도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.399,
    company: "(주)진슬태양광",
    rn: 5565
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진도쏠라태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.399,
    company: "(주)진슬태양광",
    rn: 5566
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼영고성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)삼영에너지",
    rn: 5567
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산염색열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 19,
    company: "부산경남염색공업",
    rn: 5568
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아주나주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "에이제이에너지(주)",
    rn: 5569
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군위솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.987,
    company: "군위솔라테크주식회사",
    rn: 5570
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라파워영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "(주)솔라파워에너지",
    rn: 5571
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라테크예천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.378,
    company: "(주)솔라테크에너지(예천)",
    rn: 5572
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원광신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)동양에너지",
    rn: 5573
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동화장수태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)동화에너지",
    rn: 5574
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대호신안태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.913,
    company: "대호에너지주식회사",
    rn: 5575
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세진영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)세진쏠라",
    rn: 5576
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "LG석유여수",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 103.5,
    company: "(주)엘지화학",
    rn: 5577
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "LG석유여수(구매)",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 103.5,
    company: "(주)엘지화학(구매)",
    rn: 5578
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "풍기태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.27,
    company: "풍기제1태양광발전소",
    rn: 5579
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬아이강진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.487,
    company: "(주)썬아이태양광발전소",
    rn: 5580
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도촌봉화태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)도촌솔라테크",
    rn: 5581
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "풍기태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.28,
    company: "풍기제2태양광발전소",
    rn: 5582
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "전북집단에너지",
    cent: "비중앙",
    fuel: "중유",
    pcap: 19,
    company: "전북집단에너지(주)",
    rn: 5583
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "HS연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "(주)에이치에스이앤피",
    rn: 5584
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "신안풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "신안풍력발전주식회사",
    rn: 5585
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬볼트해남태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.08,
    company: "(주)썬볼트전력",
    rn: 5586
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대한유화열병합",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 16.18,
    company: "대한유화(주)",
    rn: 5587
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신양고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.65,
    company: "(유)신양에너지",
    rn: 5588
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "협성영천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "코리아종합건설(주)",
    rn: 5589
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고암창녕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "(주)고암태양광발전",
    rn: 5590
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포스포항태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)포스코",
    rn: 5591
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라영암태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.125,
    company: "(주)한국솔라파크",
    rn: 5592
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라영암태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.874,
    company: "(주)한국솔라파크",
    rn: 5593
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "합천솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "합천솔라파크(주)",
    rn: 5594
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "동해펄프열병합",
    cent: "비중앙",
    fuel: "중유",
    pcap: 36.4,
    company: "무림피앤피주식회사",
    rn: 5595
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태기산풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20,
    company: "태기산풍력(주)",
    rn: 5596
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태기산풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20,
    company: "태기산풍력(주)",
    rn: 5597
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "효성풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.75,
    company: "강원대기풍력발전소(효성)",
    rn: 5598
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "고리풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 0.75,
    company: "한국수력원자력(주)",
    rn: 5599
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "여수카본열병합",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 30.3,
    company: "오리온엔지니어드카본즈(주)",
    rn: 5600
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부평카본열병합",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 12.5,
    company: "오리온엔지니어드카본즈(주)",
    rn: 5601
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함평쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)함평쏠라",
    rn: 5602
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스피솔라영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.45,
    company: "(주)에스피솔라",
    rn: 5603
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천일부안태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.743,
    company: "(주)천일솔라파크",
    rn: 5604
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "이건에너지열병합",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 4.7,
    company: "이건에너지(주)",
    rn: 5605
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스피솔라장성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.305,
    company: "(주)에스피솔라",
    rn: 5606
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나쏠라익산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.247,
    company: "(주)하나쏠라파크",
    rn: 5607
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동일영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.697,
    company: "(주)동일태양광발전",
    rn: 5608
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영양풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 61.5,
    company: "(주)영양풍력발전공사",
    rn: 5609
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운성고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)운성",
    rn: 5610
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대전열병합",
    cent: "중앙",
    fuel: "LPG",
    pcap: 88,
    company: "대전열병합발전(주)",
    rn: 5611
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화산익산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "화산태양광발전(주)",
    rn: 5612
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(유)쏠라에너토피아",
    rn: 5613
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "우포월드창녕바이오",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 0.541,
    company: "(주)우포월드",
    rn: 5614
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼랑진태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "한국서부발전(주)",
    rn: 5615
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼랑진태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국서부발전(주)",
    rn: 5616
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동원강진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "동원산업(주)",
    rn: 5617
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광주에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.194,
    company: "(주)광주에너지",
    rn: 5618
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라테크상주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "(주)솔라테크에너지(상주)",
    rn: 5619
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래쏠라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "미래쏠라(주)",
    rn: 5620
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라파크태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "(주)에스앤피",
    rn: 5621
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "한국중부발전(주)",
    rn: 5622
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "미래(주)",
    rn: 5623
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "두산열병합",
    cent: "비중앙",
    fuel: "중유",
    pcap: 20,
    company: "두산산업개발(주)",
    rn: 5624
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정읍솔라고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.4,
    company: "(주)정읍솔라라이트",
    rn: 5625
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신북영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.538,
    company: "(주)신북태양광발전",
    rn: 5626
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청산장성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "(주)청산태양광발전",
    rn: 5627
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "명지소각장",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3,
    company: "부산광역시",
    rn: 5628
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로정읍태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.988,
    company: "에이케이태양광",
    rn: 5629
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠렉영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "썬앤정에너지(주)",
    rn: 5630
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "성남소각장",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 4.5,
    company: "성남시",
    rn: 5631
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨앤상주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)씨앤솔라",
    rn: 5632
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상주솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.492,
    company: "(주)상주솔라",
    rn: 5633
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인강진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.47,
    company: "(주)태인태양광발전소",
    rn: 5634
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.49,
    company: "(주)상주태양광발전소",
    rn: 5635
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "신동에너지열병합",
    cent: "비중앙",
    fuel: "중유",
    pcap: 42.6,
    company: "신동에너지(주)",
    rn: 5636
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛성산태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)한빛그린파워에너지",
    rn: 5637
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛성산태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "(주)한빛그린파워에너지",
    rn: 5638
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛덕암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.75,
    company: "(주)한빛그린파워에너지",
    rn: 5639
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛봉산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "(주)한빛그린파워에너지",
    rn: 5640
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛석남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)한빛그린파워에너지",
    rn: 5641
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛괴치태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "(주)한빛그린파워에너지",
    rn: 5642
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리블루영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "우리블루태양광발전(유)",
    rn: 5643
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "오산에너지",
    cent: "비중앙",
    fuel: "중유",
    pcap: 19.5,
    company: "(주)오산에너지",
    rn: 5644
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에프영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)이에프에너지",
    rn: 5645
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창환영덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "창환개발(주)",
    rn: 5646
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "동진소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.85,
    company: "에이뉴힐",
    rn: 5647
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암태양광발전#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "영암태양광발전(주)",
    rn: 5648
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "전북풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 7.9,
    company: "전라북도",
    rn: 5649
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린진도태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "그린태양광발전(유)",
    rn: 5650
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린진도태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "그린태양광발전(유)",
    rn: 5651
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장산신안태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)장산태양광발전소",
    rn: 5652
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장산신안태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)장산태양광발전소",
    rn: 5653
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "한경풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 4.5,
    company: "한국남부발전(주)",
    rn: 5654
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "한경풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 15,
    company: "한국남부발전(주)",
    rn: 5655
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양에너지신안태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 4.358,
    company: "(주)동양에너지",
    rn: 5656
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "GD해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "지디태양광 주식회사",
    rn: 5657
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송림영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "대화도장주식회사 지점",
    rn: 5658
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.053,
    company: "영동태양광발전",
    rn: 5659
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "포항풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 0.66,
    company: "경상북도",
    rn: 5660
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금산영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.446,
    company: "(주)신북금산태양광발전(금산태양광발전소)",
    rn: 5661
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에이의성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "(주)디에이디오",
    rn: 5662
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "파낙스에너지부산LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 3.174,
    company: "파낙스에너지(주) 부산LFG발전소",
    rn: 5663
    },
    {
    area: "제주",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "파낙스제주LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 1,
    company: "파낙스에너지(주)제주LFG발전소",
    rn: 5664
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "보령화력연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 0.3,
    company: "한국중부발전(주)",
    rn: 5665
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "광주운정LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 3.12,
    company: "(주)서희건설광주지점",
    rn: 5666
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "청주매립가스(LFG)발전소",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 1.058,
    company: "(주)지엔씨에너지",
    rn: 5667
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부남청송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.22,
    company: "부남발전",
    rn: 5668
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신태양영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "신태양발전주식회사",
    rn: 5669
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "상원이엔씨#1",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 9.88,
    company: "(주)상원이엔씨",
    rn: 5670
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "상원이엔씨#2",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 6.7,
    company: "(주)상원이엔씨",
    rn: 5671
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서해에너지임실태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "서해에너지(주)오수태양광",
    rn: 5672
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)신동양 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.284,
    company: "주식회사신동양",
    rn: 5673
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백한봉화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.807,
    company: "백한에너지주식회사",
    rn: 5674
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산청태양광에너지발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "(주)산청태양광에너지",
    rn: 5675
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로김제태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "(주)아폴로이엔시",
    rn: 5676
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라테크칠곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)썬왓",
    rn: 5677
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "버추얼군산연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "(주)버추얼텍",
    rn: 5678
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라젠나주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "(주)쏠라젠(B)",
    rn: 5679
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "대전금고동 LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 3.46,
    company: "(주)효성",
    rn: 5680
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이디에이영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)이디에이솔라",
    rn: 5681
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이디에이강진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)이디에이솔라",
    rn: 5682
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "써니정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "(주)써니발전",
    rn: 5683
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신천정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "(주)신천쏠라에너지",
    rn: 5684
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "대관령풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.64,
    company: "강원도",
    rn: 5685
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "대관령제1풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3.3,
    company: "강원도",
    rn: 5686
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "성산풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 12,
    company: "한국남부발전(주)",
    rn: 5687
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "성산풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 8,
    company: "한국남부발전(주)",
    rn: 5688
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "써니고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "써니고흥(주)",
    rn: 5689
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라그린정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.292,
    company: "(유)솔라그린파크",
    rn: 5690
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신태양에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "신태양에너지(주)",
    rn: 5691
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부여태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "(유)부여태양광발전소",
    rn: 5692
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이엔에스영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "(주)이엔에스솔라",
    rn: 5693
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금월해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.489,
    company: "금월전력(주)",
    rn: 5694
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주원영양태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "주원솔라주식회사",
    rn: 5695
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "부산 Bio Gas발전기",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 2.116,
    company: "(주)서희건설부산지점",
    rn: 5696
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주원영양태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "주원솔라주식회사",
    rn: 5697
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진의성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)유진에너지",
    rn: 5698
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대영해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "대영산업주식회사",
    rn: 5699
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백양보성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.989,
    company: "(주)백양솔라텍",
    rn: 5700
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "매봉산풍력9호기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2,
    company: "한국중부발전(주)",
    rn: 5701
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "매봉산풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 6.8,
    company: "한국중부발전(주)",
    rn: 5702
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이디앤디남원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.685,
    company: "한빛에스이 주식회사",
    rn: 5703
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산청태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "산청단성태양광",
    rn: 5704
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농어촌영암태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.492,
    company: "한국농어촌공사 영암지사",
    rn: 5705
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농촌공진도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "한국농어촌공사 진도지사",
    rn: 5706
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영덕풍력1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 19.8,
    company: "영덕풍력발전주식회사",
    rn: 5707
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영덕풍력2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 19.8,
    company: "영덕풍력발전주식회사",
    rn: 5708
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농어촌영암태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.492,
    company: "한국농어촌공사 영암지사",
    rn: 5709
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에버랜드김천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9.291,
    company: "삼성에버랜드(주)",
    rn: 5710
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김천에너빅스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9.106,
    company: "김천에너빅스(주)",
    rn: 5711
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리블루조광전기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.91,
    company: "고흥그린솔라타운(주)",
    rn: 5712
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리블루오마태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.478,
    company: "고흥그린솔라타운(주)",
    rn: 5713
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리블루고옥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.408,
    company: "고흥그린솔라타운(주)",
    rn: 5714
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리블루점암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.479,
    company: "고흥그린솔라타운(주)",
    rn: 5715
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리블루봉덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.47,
    company: "고흥그린솔라타운(주)",
    rn: 5716
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경현봉화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "경현솔라텍(주)",
    rn: 5717
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "청라소각장 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.8,
    company: "인천광역시",
    rn: 5718
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도촌봉화태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "(주)도촌솔라테크",
    rn: 5719
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서강봉화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)서강솔라테크",
    rn: 5720
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경휘울진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)경휘",
    rn: 5721
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남전인제태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "남전1리주민협의회 영농조합법인",
    rn: 5722
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "강남열병합#2",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 5.15,
    company: "한국지역난방공사",
    rn: 5723
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "강남열병합#1",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 13.3,
    company: "한국지역난방공사",
    rn: 5724
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "포항연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "포스코에너지(주)",
    rn: 5725
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태영에너지창녕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "태영에너지(주)",
    rn: 5726
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "히라이트영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.231,
    company: "(주)히라이트",
    rn: 5727
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라벤영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.231,
    company: "(주)솔라벤코퍼레이션",
    rn: 5728
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "관마진도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "(주)관마쏠라파크",
    rn: 5729
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이에스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)아이에스",
    rn: 5730
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이디앤디영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.44,
    company: "한빛에스이 주식회사",
    rn: 5731
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔에스피영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.468,
    company: "(주)엔에스피",
    rn: 5732
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광영천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.45,
    company: "(주)서광헬리오스",
    rn: 5733
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주고성리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "제주고성리태양광발전소",
    rn: 5734
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만준나주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.489,
    company: "(주)만준태양광",
    rn: 5735
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "목포대양LFG#2",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 1.058,
    company: "삼호테크 (유)",
    rn: 5736
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "목포대양LFG#1",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 1.058,
    company: "삼호테크 (유)",
    rn: 5737
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진도쏠라태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.924,
    company: "(주)진슬태양광",
    rn: 5738
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로정읍감곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "(주)아폴로태양광발전소",
    rn: 5739
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔에이치정읍태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.293,
    company: "주식회사 엔에이치인터내셔널",
    rn: 5740
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래에너지보성태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.951,
    company: "(주)미래에너지",
    rn: 5741
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라퓨처고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "쏠라퓨처태양광발전소",
    rn: 5742
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천일부안태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(주)천일솔라파크(3호기)",
    rn: 5743
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송산고성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.246,
    company: "(주)송산태양광발전소",
    rn: 5744
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송림에너텍영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "(유)송림에너텍",
    rn: 5745
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보성1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국태양보성1호발전소(주)",
    rn: 5746
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리그린고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "우리그린태양광발전유한회사",
    rn: 5747
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.75,
    company: "(주)미래태양광발전소",
    rn: 5748
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세보천안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)세보",
    rn: 5749
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동신에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)동남솔라",
    rn: 5750
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "익산시소각장 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 5,
    company: "익산시 신재생자원센터",
    rn: 5751
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원일에너지영덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.45,
    company: "원일에너지(주)",
    rn: 5752
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔싱크영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.966,
    company: "(주)솔싱크영주",
    rn: 5753
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "신재생에너지",
    genNm: "속초바이오GT",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 0.325,
    company: "속초시",
    rn: 5754
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "원에코소각로열병합",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.9,
    company: "원에코주식회사",
    rn: 5755
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(유)대신태양광발전소",
    rn: 5756
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "진주산업소각로열병합",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.247,
    company: "(주)클렌코",
    rn: 5757
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "청주소각로열병합",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.7,
    company: "청주시(청주시시설관리공단)",
    rn: 5758
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준에너지 태양광 #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.328,
    company: "(주)준에너지",
    rn: 5759
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준에너지태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.267,
    company: "(주)준에너지",
    rn: 5760
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "제주삼달풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 33,
    company: "한신에너지(주)",
    rn: 5761
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "카코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.999,
    company: "(주)광명에너지",
    rn: 5762
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다인의성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)다인태양광발전소",
    rn: 5763
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원효태양광고흥발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "원효태양광(주)",
    rn: 5764
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "광주소각로",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.8,
    company: "광주환경공단",
    rn: 5765
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "씨이브이LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.64,
    company: "씨이브이(주)",
    rn: 5766
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "한려에너지LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.923,
    company: "(주)한려에너지개발",
    rn: 5767
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "현대에너지(주)",
    rn: 5768
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주성산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "제주태양에너지발전(주)",
    rn: 5769
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "(주)대성에너지",
    rn: 5770
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "씨지앤율촌 연료전지#1",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 4.8,
    company: "씨지앤율촌전력(주)",
    rn: 5771
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리그린순천태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.15,
    company: "우리그린태양광발전유한회사",
    rn: 5772
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리그린순천태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "우리그린태양광발전유한회사",
    rn: 5773
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리그린고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "우리그린태양광발전유한회사",
    rn: 5774
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "GS당진연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "GS EPS(주)",
    rn: 5775
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "순천LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 1.85,
    company: "(주)지엔씨에너지",
    rn: 5776
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라예천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)솔라",
    rn: 5777
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스앤퓨처태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "주식회사 에스엔퓨처",
    rn: 5778
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진교태양광(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.956,
    company: "진교태양광 주식회사",
    rn: 5779
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진주태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)진주태양광발전소",
    rn: 5780
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.12,
    company: "한국서부발전(주)",
    rn: 5781
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세명태양광발전기#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 한태연3호",
    rn: 5782
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "백석소각장 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.8,
    company: "천안시청",
    rn: 5783
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동광하동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)동광솔라",
    rn: 5784
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진주햇빛 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.398,
    company: "(주)제이에이치앤에스에너지",
    rn: 5785
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)대명솔라파크 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.324,
    company: "(유)대명솔라파크",
    rn: 5786
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)한국태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "(주)한국태양광발전소",
    rn: 5787
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "한국남동발전(주)",
    rn: 5788
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화일솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.558,
    company: "(주)화일솔라",
    rn: 5789
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라팜장성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "쏠라팜태양광발전소",
    rn: 5790
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세명태양광발전기#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "주식회사 한태연3호",
    rn: 5791
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "화성그린환경센터",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 4.4,
    company: "화성시",
    rn: 5792
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "강원풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 98,
    company: "강원풍력발전(주)",
    rn: 5793
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "강원풍력발전기#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 70,
    company: "강원풍력발전(주)",
    rn: 5794
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광석고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)광석태양광발전소",
    rn: 5795
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진도선파크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.974,
    company: "(주)진도선파크",
    rn: 5796
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.422,
    company: "그린태양광발전소",
    rn: 5797
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "신창풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 1.7,
    company: "제주에너지공사",
    rn: 5798
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승덕군서태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.66,
    company: "실버태양광발전(유)",
    rn: 5799
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라솔라예천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)한라솔라파크",
    rn: 5800
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "뉴라이트태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "뉴라이트에너지(주)",
    rn: 5801
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "구리자원회수시설발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.8,
    company: "구리시",
    rn: 5802
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KSE 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.978,
    company: "(주)KSE",
    rn: 5803
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "포스코인천연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "포스코파워(주)",
    rn: 5804
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일광에너지안동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)일광태양광발전소",
    rn: 5805
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주원영양태양광#3 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "주원솔라주식회사",
    rn: 5806
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일산태양광발전기#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "일산태양광2호발전소",
    rn: 5807
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거창에코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)거창에코파워",
    rn: 5808
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치앤엘쏠라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "에이치앤엘쏠라(주)",
    rn: 5809
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "관촌에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.676,
    company: "(유)관촌에너지",
    rn: 5810
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이오스문경태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)이오스",
    rn: 5811
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.32,
    company: "(주)덕호태양광발전소",
    rn: 5812
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옥당태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.31,
    company: "(주)옥당태양광발전소",
    rn: 5813
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "KIER R&D 발전기",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 2.15,
    company: "한국에너지기술연구원",
    rn: 5814
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔쏠라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.659,
    company: "(주)엔쏠라",
    rn: 5815
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "제주월정풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 1.5,
    company: "한국에너지기술연구원",
    rn: 5816
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광벨리 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.277,
    company: "(주)광벨리",
    rn: 5817
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용화에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.868,
    company: "용화에너지주식회사",
    rn: 5818
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정읍솔라파크주산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "(주)정읍솔라파크",
    rn: 5819
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정읍솔라파크청량태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.398,
    company: "(주)정읍솔라파크",
    rn: 5820
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정읍솔라파크창동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "(주)정읍솔라파크",
    rn: 5821
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광영천북안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "서광솔라(주)",
    rn: 5822
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동해태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국동서발전(주)",
    rn: 5823
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "마산시 자원회수시설 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.9,
    company: "창원시 마산합포구",
    rn: 5824
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고려광칠곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "고려광발전(주)",
    rn: 5825
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지평선정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.399,
    company: "(주)지평선태양광발전소",
    rn: 5826
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이앤와이정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)제이앤와이에너지",
    rn: 5827
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "양양풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "한국중부발전(주)",
    rn: 5828
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신세계구성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.462,
    company: "(주)신세계",
    rn: 5829
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "노원연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "포스코파워(주)",
    rn: 5830
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엘영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "(주)에스엘",
    rn: 5831
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "창원소각장발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.1,
    company: "창원시 성산구",
    rn: 5832
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일산태양광발전기#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.739,
    company: "(주)일산태양광",
    rn: 5833
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산다대쓰레기소각장발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.7,
    company: "부산광역시",
    rn: 5834
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수성이앤씨신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)수성이앤씨",
    rn: 5835
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영진솔라테크태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.902,
    company: "영진솔라테크(주)",
    rn: 5836
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "분당연료전지#1",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 0.3,
    company: "한국남동발전(주)",
    rn: 5837
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "일산연료전지#2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.8,
    company: "한국동서발전(주)",
    rn: 5838
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "일산 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "한국동서발전(주)",
    rn: 5839
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대구성서소각로발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.7,
    company: "대구광역시환경시설공단",
    rn: 5840
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대음성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.531,
    company: "현대중공업 그린에너지(주)",
    rn: 5841
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엠강릉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "에스엠에스태양광발전소",
    rn: 5842
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영흥태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국남동발전(주)",
    rn: 5843
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동일신북영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.392,
    company: "(주)신북태양광",
    rn: 5844
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지산에너젠율산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "지산에너젠",
    rn: 5845
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농어촌괴산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.522,
    company: "한국농어촌괴산지사",
    rn: 5846
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한태연장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "(주)한국태양광발전연구소",
    rn: 5847
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "50MW 매립가스 발전기",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 50,
    company: "수도권매립지관리공사",
    rn: 5848
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한태연해창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.33,
    company: "(주)한국태양광발전연구소",
    rn: 5849
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파인태양광해남태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "파인태양광(주)",
    rn: 5850
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디엔광주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "서울마린주식회사",
    rn: 5851
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "전주시소각장발전기#1",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 8,
    company: "전주시",
    rn: 5852
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "전주시소각장발전기#2",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 4,
    company: "전주시",
    rn: 5853
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.328,
    company: "우주에너지(주)",
    rn: 5854
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영월접산풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 2.25,
    company: "강원도",
    rn: 5855
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고려태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.475,
    company: "광신발전(주)",
    rn: 5856
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나눔에너지 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)나눔에너지",
    rn: 5857
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)포에이스",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.468,
    company: "주식회사 포에이스",
    rn: 5858
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유일순창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.77,
    company: "제이에스에너지(주)",
    rn: 5859
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일광에너지영덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "(주)일광에너지영덕",
    rn: 5860
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영천화남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)영천태양광발전소",
    rn: 5861
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.416,
    company: "(주)광명3호태양광발전소",
    rn: 5862
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛에너지진도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)한빛에너지",
    rn: 5863
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼정태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.918,
    company: "(주)삼정태양광발전소",
    rn: 5864
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너파크태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)에너파크",
    rn: 5865
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.176,
    company: "한국수자원공사",
    rn: 5866
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "한국수자원공사",
    rn: 5867
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장흥솔라토피아",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.688,
    company: "장흥솔라토피아",
    rn: 5868
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "한마음에너지 태양광발전소",
    rn: 5869
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)탑인프라디벨로퍼건축사무소",
    rn: 5870
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신광나주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.29,
    company: "(주)신광발전소",
    rn: 5871
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "에스케이태양광발전(주)",
    rn: 5872
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남해강진태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "남해에너지발전(주)",
    rn: 5873
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남해강진제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "강진에너지(주)",
    rn: 5874
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안성환태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)신안성환에너지",
    rn: 5875
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진도성환태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "진도 태양광발전소",
    rn: 5876
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의성솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "의성솔라파크주식회사",
    rn: 5877
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한라전공태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)한라전공",
    rn: 5878
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디솔라나주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "디-솔라텍태양광발전소",
    rn: 5879
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬볼트해남태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)썬볼트전력",
    rn: 5880
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨케이해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)씨케이솔라",
    rn: 5881
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동진해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)동진쏠라에너지",
    rn: 5882
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김대중컨벤션센터 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "김대중컨벤션센터",
    rn: 5883
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남계고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "남계태양광발전소(주)",
    rn: 5884
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전주솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)전주솔라에너지",
    rn: 5885
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "후산부여태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.481,
    company: "더솔라파워주식회사",
    rn: 5886
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디엔순천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "에스디엔(주)",
    rn: 5887
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래에너지보성태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "(주)미래에너지",
    rn: 5888
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더솔라부여태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "더솔라파워주식회사",
    rn: 5889
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알에이보성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)알에이에너지",
    rn: 5890
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산해운대소각로발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.8,
    company: "부산광역시",
    rn: 5891
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고창솔라태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)고창솔라파크",
    rn: 5892
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고창솔라태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)고창솔라파크",
    rn: 5893
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고창솔라태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)고창솔라파크",
    rn: 5894
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고창솔라태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)고창솔라파크",
    rn: 5895
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고창솔라태양광#5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)고창솔라파크",
    rn: 5896
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동원정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.282,
    company: "동원태양광발전주식회사",
    rn: 5897
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문경SP태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.565,
    company: "(주)에스피에너지",
    rn: 5898
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬레이장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "썬레이에너지주식회사",
    rn: 5899
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문경SP태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.643,
    company: "(주)에스피에너지",
    rn: 5900
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "서대구에너지열병합#1",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.75,
    company: "(주)서대구에너지",
    rn: 5901
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여광강진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.463,
    company: "(주)여광에너지",
    rn: 5902
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린솔라함평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)그린솔라",
    rn: 5903
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태영정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.273,
    company: "태영광발전소",
    rn: 5904
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선우영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "선우에너지(주)",
    rn: 5905
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "서대구에너지열병합#2",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.75,
    company: "(주)서대구에너지",
    rn: 5906
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서해에너지군산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "서해에너지주식회사",
    rn: 5907
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "선경에너지",
    rn: 5908
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남정영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)남정에너지",
    rn: 5909
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛솔라파크#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.25,
    company: "한국수력원자력(주)",
    rn: 5910
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛솔라파크#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.75,
    company: "한국수력원자력(주)",
    rn: 5911
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)에스앤아이코퍼레이션",
    rn: 5912
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)에스앤아이코퍼레이션",
    rn: 5913
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)에스앤아이코퍼레이션",
    rn: 5914
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)에스앤아이코퍼레이션",
    rn: 5915
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안태양광#5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)에스앤아이코퍼레이션",
    rn: 5916
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무안태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)서울신문사",
    rn: 5917
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "포스콘연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 0.3,
    company: "포스코에너지(주)",
    rn: 5918
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백운함양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "백운세일에너지주식회사",
    rn: 5919
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔에이치고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.975,
    company: "(주)엔에이치",
    rn: 5920
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남부성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "해남부성태양광발전소",
    rn: 5921
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안증도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "한국지역난방공사",
    rn: 5922
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희재고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)희재태양광발전소",
    rn: 5923
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나에너지장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)하나에너지",
    rn: 5924
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나솔라텍장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.303,
    company: "(주)하나솔라텍",
    rn: 5925
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼우장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)삼우솔라텍",
    rn: 5926
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영주제1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "썬샤인에너지주식회사",
    rn: 5927
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문라이트태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.836,
    company: "(주)문라이트에너지",
    rn: 5928
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재희영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)재희태양광",
    rn: 5929
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다복태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "유한회사 다복태양광",
    rn: 5930
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영주제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.641,
    company: "썬샤인에너지주식회사",
    rn: 5931
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬샤인영주제3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.461,
    company: "썬샤인에너지주식회사",
    rn: 5932
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이비문경태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(주)제이비에너지",
    rn: 5933
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이비문경태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(주)제이비에너지",
    rn: 5934
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "헥스부안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.55,
    company: "헥스파워시스템(주)",
    rn: 5935
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "행원풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 5.64,
    company: "제주에너지공사",
    rn: 5936
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔알이부안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.433,
    company: "(주)엔알이",
    rn: 5937
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코너지해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.2,
    company: "(주)코너지",
    rn: 5938
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동화력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국남부발전(주)",
    rn: 5939
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동화력태양광4호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국남부발전(주)",
    rn: 5940
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송림에너지영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.22,
    company: "송림에너지주식회사",
    rn: 5941
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나전기장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "(주)하나전기",
    rn: 5942
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "HS화순태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)에이치에스솔라",
    rn: 5943
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천일부안태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.249,
    company: "(주)천일솔라파크(A)",
    rn: 5944
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유나인장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.31,
    company: "유나인에너지주식회사",
    rn: 5945
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한승장흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.31,
    company: "한승에너지주식회사",
    rn: 5946
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "김해소각로발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.45,
    company: "김해시",
    rn: 5947
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨그마영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.999,
    company: "(주)씨그마이티엔",
    rn: 5948
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼진영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)삼진태양광에너지",
    rn: 5949
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "태인쏠라(주)",
    rn: 5950
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지구정읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "(주)지구전력",
    rn: 5951
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산강진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "안산에너지개발(주)",
    rn: 5952
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)조일태양광발전소",
    rn: 5953
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청강영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "청강에너지(주)",
    rn: 5954
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "용인소각로발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3.3,
    company: "용인시 환경관리사업소",
    rn: 5955
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대현영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)대현에너지",
    rn: 5956
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화성태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "케이씨태양광발전(주)",
    rn: 5957
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용방해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "(주)용방에너지",
    rn: 5958
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일신영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "일신에너지주식회사",
    rn: 5959
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕암해남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주)덕암에너지",
    rn: 5960
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성무안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(유)금성에너지",
    rn: 5961
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.539,
    company: "(주)우리",
    rn: 5962
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "온누리나주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.8,
    company: "(주)온누리",
    rn: 5963
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라플러스함평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.393,
    company: "솔라플러스(주)",
    rn: 5964
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "지엔씨바이오#1",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.7,
    company: "(주)지엔씨에너지",
    rn: 5965
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜인해남태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주) 혜인",
    rn: 5966
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평산고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "(주)평산썬이앤지",
    rn: 5967
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로정읍태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "(주)아폴로이엔시",
    rn: 5968
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)광에너지",
    rn: 5969
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성부신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)동양에너지",
    rn: 5970
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대동부안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "(주)대동쏠라",
    rn: 5971
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한양고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.106,
    company: "(주)한양솔라파크",
    rn: 5972
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석포고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.732,
    company: "(주)석포발전",
    rn: 5973
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)동양에너지",
    rn: 5974
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이피피쏠라보성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.5,
    company: "와이피피쏠라(주)",
    rn: 5975
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)함평태양광발전소",
    rn: 5976
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서남순창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "서남태양광발전(주)",
    rn: 5977
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송평영암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.391,
    company: "(주)송평태양광발전",
    rn: 5978
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양고속신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)동양에너지",
    rn: 5979
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래영주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)미래에너지(B)",
    rn: 5980
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원일영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "(주)원일태양광발전소",
    rn: 5981
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이뻐영광태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "이뻐앤멋져(주)",
    rn: 5982
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이뻐영광태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "이뻐앤멋져(주)",
    rn: 5983
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양그린신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)동양에너지",
    rn: 5984
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS영광태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "(주)제이에스에너지",
    rn: 5985
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS영광태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.55,
    company: "(주)제이에스에너지",
    rn: 5986
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS영광태양광#3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.55,
    company: "(주)제이에스에너지",
    rn: 5987
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "흥양고흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(유)흥양고흥태양광에너지",
    rn: 5988
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양에너지신안태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "(주)동양에너지",
    rn: 5989
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이드김제태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)에이드쏠라태양광발전소",
    rn: 5990
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신재생나주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "한국신재생발전(주)",
    rn: 5991
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "헬리오장성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "(주)헬리오발전소",
    rn: 5992
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석창에너지태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.318,
    company: "석창에너지태양광발전소",
    rn: 5993
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "비중앙급전 발전기",
    cent: "중앙",
    fuel: "기타",
    pcap: 9999,
    company: "구입전력/비중앙급전",
    rn: 5994
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코오롱경주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)코오롱",
    rn: 5995
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양지고창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.392,
    company: "(주)양지태양광발전",
    rn: 5996
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디티이엔비 1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "주식회사 디티이엔비",
    rn: 5997
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "삼원태양광발전소",
    rn: 5998
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금산 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.391,
    company: "금산태양광발전소",
    rn: 5999
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두못개태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "두못개태양광발전소",
    rn: 6000
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정종솔라3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 정종솔라",
    rn: 6001
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신평태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "제이케이머트리얼즈(주)",
    rn: 6002
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태백7호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "주식회사 만만에너지",
    rn: 6003
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태백6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.853,
    company: "(주)다온에너지",
    rn: 6004
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태백3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "(주)아텍솔라파워",
    rn: 6005
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태백2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "주식회사 스카이에너지",
    rn: 6006
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이피안동1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.927,
    company: "주식회사 제이피에너지",
    rn: 6007
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "승리발전(주)",
    rn: 6008
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "경북북부권 자원회수 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 14.1,
    company: "경북그린에너지센터(주)",
    rn: 6009
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "서원 태양광발전소",
    rn: 6010
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "서광태양광발전소",
    rn: 6011
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건웅쏠라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "참나제주 태양광발전소",
    rn: 6012
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "휴먼솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "와이엔 태양광발전소",
    rn: 6013
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇빛누리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇빛누리 태양광발전소",
    rn: 6014
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구름태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "구름 태양광발전소",
    rn: 6015
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태백4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "(주)지에스에너지",
    rn: 6016
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "공혁태양광발전소 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.037,
    company: "공혁태양광발전소",
    rn: 6017
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신정솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "(주)신정솔라",
    rn: 6018
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월막1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)달천유니온솔라",
    rn: 6019
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)달천유니온솔라",
    rn: 6020
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인종2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 인종솔라",
    rn: 6021
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주영1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주영1호 태양광발전소",
    rn: 6022
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성요셉태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "성요셉태양광발전소",
    rn: 6023
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치에스",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "에이치에스 태양광발전소",
    rn: 6024
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이엠태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "케이엠 태양광발전소",
    rn: 6025
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원선2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.26,
    company: "원선2호 태양광발전소",
    rn: 6026
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전원솔라태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "농업회사법인(주)전원솔라",
    rn: 6027
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강산솔라태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "(주)강산솔라",
    rn: 6028
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해창솔라태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.993,
    company: "(주)해창솔라",
    rn: 6029
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해사랑태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해사랑 태양광발전소",
    rn: 6030
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세일철강(주)아산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.265,
    company: "세일철강(주)",
    rn: 6031
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "써니태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "써니 태양광발전소",
    rn: 6032
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주한 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "주한태양광발전소",
    rn: 6033
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청산햇빛태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "유한회사 청산햇빛태양광발전소",
    rn: 6034
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두모태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "두모태양광발전소",
    rn: 6035
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정종솔라2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 정종솔라",
    rn: 6036
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정진2호햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "정진2호햇빛발전소",
    rn: 6037
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오케이썬2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "오케이썬2호 태양광발전소",
    rn: 6038
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오케이썬1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "오케이썬 1호 태양광발전소",
    rn: 6039
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월햇빛2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명월햇빛2호태양광발전소",
    rn: 6040
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디파워태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "디파워태양광",
    rn: 6041
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디브태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "녹색 태양광발전소",
    rn: 6042
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현일태양광발전소",
    rn: 6043
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영월한마음태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "주식회사 그랜드디앤씨",
    rn: 6044
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "벽진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 벽진태양광발전소",
    rn: 6045
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마이솔에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "마이솔에너지(주)",
    rn: 6046
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송원발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "송원발전(주)",
    rn: 6047
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문수태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "문수태양광발전소",
    rn: 6048
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스플래닛 금산 일호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "에스플래닛일호 주식회사",
    rn: 6049
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해맑은태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "해맑은태양광발전소",
    rn: 6050
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "모든태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.394,
    company: "모든태양광발전소",
    rn: 6051
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음금산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 한마음에너지",
    rn: 6052
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광찬태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)베스컨에스",
    rn: 6053
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해찬태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)베스컨썬",
    rn: 6054
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이더블유제2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.964,
    company: "주식회사 제이더블유제2호발전소",
    rn: 6055
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "별량그린에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "별량그린 태양광발전소",
    rn: 6056
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "법영4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "법영4호 태양광발전소",
    rn: 6057
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "밝은태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "밝은 태양광발전소",
    rn: 6058
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송백태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "송백 태양광발전소",
    rn: 6059
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태임태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태임 태양광발전소",
    rn: 6060
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석범태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "석범 태양광발전소",
    rn: 6061
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "법영5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "법영5호 태양광발전소",
    rn: 6062
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "울트라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.07,
    company: "성산명진 태양광발전소",
    rn: 6063
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "매일 태양광발전소",
    rn: 6064
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혁정1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "혁정에너지(혁정1호태양광발전소)",
    rn: 6065
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승진에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.994,
    company: "(주)승진에너지",
    rn: 6066
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "균남1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "균남1호 태양광발전소",
    rn: 6067
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혁정2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "혁정2호 태양광발전소",
    rn: 6068
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아찬리 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아찬리 태양광발전소 1호",
    rn: 6069
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이에스1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "하이에스1호 태양광발전소",
    rn: 6070
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "균남2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "균남2호 태양광발전소",
    rn: 6071
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용한태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.393,
    company: "용한태양광발전소",
    rn: 6072
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "현대태양광발전소",
    rn: 6073
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화암태양광발전소 10호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "화암태양광발전소 제10호기",
    rn: 6074
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.204,
    company: "태원 태양광발전소",
    rn: 6075
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원주무장쏠라팜(주)1호태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "원주무장쏠라팜 주식회사",
    rn: 6076
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원주무장쏠라팜(주)3호 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "원주무장쏠라팜 주식회사",
    rn: 6077
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YB태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "YB태양광발전소",
    rn: 6078
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이에스2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "하이에스2호 태양광발전소",
    rn: 6079
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농업회사법인부경 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.976,
    company: "농업회사법인부경주식회사",
    rn: 6080
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "법영1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "법영1호 태양광발전소",
    rn: 6081
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선샤인태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "선샤인 태양광발전소",
    rn: 6082
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포도에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.006,
    company: "주식회사 포도에너지",
    rn: 6083
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스마트에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.776,
    company: "주식회사 스마트에너지",
    rn: 6084
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원더에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.006,
    company: "주시회사 원더에너지",
    rn: 6085
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파도에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 파도에너지",
    rn: 6086
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "식스에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 식스에너지",
    rn: 6087
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세계에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 세계에너지",
    rn: 6088
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 에스에너지",
    rn: 6089
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의령 고니 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "고니솔라파워(주)",
    rn: 6090
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의령 까치 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "까치(주)",
    rn: 6091
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의령 참새 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "참새(주)",
    rn: 6092
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한들2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "한들2호 태양광발전소",
    rn: 6093
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "주식회사 유진태양광",
    rn: 6094
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유진2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "주식회사 유진태양광",
    rn: 6095
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강릉제일솔라4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강릉제일솔라 4호",
    rn: 6096
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이더블유제3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.964,
    company: "주식회사 제이더블유제3호발전소",
    rn: 6097
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건미에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "건미에너지",
    rn: 6098
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건용에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "건용에너지",
    rn: 6099
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건영에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "건영에너지 태양광발전소",
    rn: 6100
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성현태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성현태양광발전소",
    rn: 6101
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠제이 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "엠제이 태양광 발전소",
    rn: 6102
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "왕성테크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.289,
    company: "주식회사왕성테크",
    rn: 6103
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 대신1호에너지",
    rn: 6104
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 대신2호에너지",
    rn: 6105
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "한화에너지 여수열병합",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한화에너지(주) 여수공장",
    rn: 6106
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "서광 태양광발전소",
    rn: 6107
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조은태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "조은태양광발전소",
    rn: 6108
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.442,
    company: "행원에너지 태양광발전소",
    rn: 6109
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스비솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.198,
    company: "농업회사법인 주식회사 에스비솔라",
    rn: 6110
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지아태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지아 태양광발전소",
    rn: 6111
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제라2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제라2호 태양광발전소",
    rn: 6112
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼호태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "(주)삼호태양광발전소",
    rn: 6113
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준태양광 왕징7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.818,
    company: "준태양광왕징7호 주식회사",
    rn: 6114
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KT부산물류센터 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.759,
    company: "주식회사 케이티",
    rn: 6115
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "괴산군조합공동법인 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.416,
    company: "주식회사 케이티",
    rn: 6116
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼랑진양수 태양광 #2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.539,
    company: "한국수력원자력(주)",
    rn: 6117
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만월태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 티에스에너지",
    rn: 6118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신이엔텍 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.166,
    company: "대신이엔텍 주식회사",
    rn: 6119
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광백수풍력발전 1-1호기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20,
    company: "영광백수풍력발전주식회사",
    rn: 6120
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "영광백수풍력발전 1-2호기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 20,
    company: "영광백수풍력발전주식회사",
    rn: 6121
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#9 백수풍력",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 6122
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대림쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "대림로지스 주식회사",
    rn: 6123
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이제이에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(주)제이제이에너지",
    rn: 6124
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이파워 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "제이파워 주식회사",
    rn: 6125
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠에스솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "엠에스솔라 주식회사",
    rn: 6126
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진말태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "진말태양광발전주식회사",
    rn: 6127
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영임태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "영임태양광 주식회사",
    rn: 6128
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의령솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "의령솔라 주식회사",
    rn: 6129
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "화력",
    genNm: "한화에너지 여수열병합발전기#2",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한화에너지(주) 여수공장",
    rn: 6130
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해오름에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.724,
    company: "주식회사 해오름에너지",
    rn: 6131
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "한화에너지 여수열병합발전(구매)#1",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한화에너지(주) 여수공장(구매)",
    rn: 6132
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "한화에너지 여수열병합발전(구매)#2",
    cent: "비중앙",
    fuel: "유연탄",
    pcap: 250,
    company: "한화에너지(주) 여수공장(구매)",
    rn: 6133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한길 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.809,
    company: "주식회사 한길태양광발전소",
    rn: 6134
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해바라기태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해바라기 태양광발전소",
    rn: 6135
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바인태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "바인 태양광발전소",
    rn: 6136
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우원솔라태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.73,
    company: "우원솔라 주식회사",
    rn: 6137
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용덕1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.181,
    company: "주식회사 용덕발전소",
    rn: 6138
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기성1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "기성1호태양광발전소",
    rn: 6139
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기성2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "기성2호태양광발전소",
    rn: 6140
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기성3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "기성3호태양광발전소",
    rn: 6141
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주용강1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.428,
    company: "제주용강1호태양광발전소",
    rn: 6142
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석종리태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "석종리 태양광발전소",
    rn: 6143
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이플랜트5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.899,
    company: "피브이플랜트오호 주식회사",
    rn: 6144
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이플랜트6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.899,
    company: "피브이플랜트육호 주식회사",
    rn: 6145
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이플랜트8호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.899,
    company: "피브이플랜트팔호 주식회사",
    rn: 6146
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정우엔지니어링태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "(주)정우엔지니어링",
    rn: 6147
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "제이에스태양광발전소",
    rn: 6148
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화산1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "화산1태양광발전소",
    rn: 6149
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스피햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.453,
    company: "주식회사에스피에너지",
    rn: 6150
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무성햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.453,
    company: "주식회사 무성에너지",
    rn: 6151
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스비햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.453,
    company: "주식회사 제이에스비영양",
    rn: 6152
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영양미래햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "영양미래솔라 주식회사",
    rn: 6153
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KEPCO-13-G03-의령태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "켑코솔라 주식회사",
    rn: 6154
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지티씨솔라6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "(주)지티씨솔라6호",
    rn: 6155
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛가람1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "유한회사 빛가람",
    rn: 6156
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛가람2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "유한회사 빛가람",
    rn: 6157
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대동솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "대동솔라태양광",
    rn: 6158
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)제일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.381,
    company: "(주) 제일",
    rn: 6159
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "따라비태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "따라비태양광발전소",
    rn: 6160
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송정제일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "송정제일태양광발전소",
    rn: 6161
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함박산햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.453,
    company: "(주)함박산에너지",
    rn: 6162
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "귀뚜라미 아산 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.909,
    company: "(주)귀뚜라미에너지",
    rn: 6163
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "귀뚜라미 아산 태양광발전소 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "(주)귀뚜라미에너지",
    rn: 6164
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "세종태양광발전소",
    rn: 6165
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "온누리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "온누리태양광발전소",
    rn: 6166
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영화태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영화태양광발전소",
    rn: 6167
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스마일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.328,
    company: "스마일 태양광발전소",
    rn: 6168
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성10호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.916,
    company: "에코플러스주식회사",
    rn: 6169
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성11호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.906,
    company: "동주태양광 주식회사",
    rn: 6170
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JCG솔라 2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.414,
    company: "JCG솔라",
    rn: 6171
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희창태양광발전소",
    rn: 6172
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "미래태양광발전소",
    rn: 6173
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순애태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "순애태양광발전소",
    rn: 6174
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거린내태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "거린내태양광발전소",
    rn: 6175
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명동정수장 침전지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "김해시",
    rn: 6176
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼계정수장 침전지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.756,
    company: "김해시",
    rn: 6177
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야테마파크주차장 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "김해시",
    rn: 6178
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "우리태양광발전소",
    rn: 6179
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하천리상동1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "주식회사 와이지에너지1호",
    rn: 6180
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하천리상동2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "주식회사 와이지에너지1호",
    rn: 6181
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)내흥태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.558,
    company: "(주)내흥태양광발전소",
    rn: 6182
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사옥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.131,
    company: "한국수자원공사",
    rn: 6183
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영양2태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "영양2발전㈜",
    rn: 6184
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.459,
    company: "신흥2호 태양광발전소",
    rn: 6185
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제라4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제라4호태양광발전소",
    rn: 6186
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송백태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "송백태양광발전소",
    rn: 6187
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제라3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제라3호태양광발전소",
    rn: 6188
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "신흥1호 태양광발전소",
    rn: 6189
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원골드2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "원골드2호태양광발전소",
    rn: 6190
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원골드1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "원골드1호태양광발전소",
    rn: 6191
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북평 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "북평 태양광발전소",
    rn: 6192
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해5호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)압해발전소5호",
    rn: 6193
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)압해발전소3호",
    rn: 6194
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "임광에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "임광에너지",
    rn: 6195
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부안상영태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "부안상영태양광발전소",
    rn: 6196
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이익산1공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.428,
    company: "전북에너지서비스주식회사",
    rn: 6197
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이익산2공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.503,
    company: "전북에너지서비스주식회사",
    rn: 6198
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청명해3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "주식회사 청명해",
    rn: 6199
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백현태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.892,
    company: "(주)자혜태양광",
    rn: 6200
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.905,
    company: "(주)자혜태양광",
    rn: 6201
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "OCI평택당진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.227,
    company: "OCI(주)",
    rn: 6202
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "삼원태양광발전소",
    rn: 6203
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남희 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "남희 태양광발전소",
    rn: 6204
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)슬아에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "(유)슬아에너지",
    rn: 6205
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남선못태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "남선못태양광발전소",
    rn: 6206
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)압해발전소4호",
    rn: 6207
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림라인쏠라파워5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.601,
    company: "해모둠태양광 주식회사",
    rn: 6208
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)한울에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(유)한울에너지",
    rn: 6209
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에프앤씨문백태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.832,
    company: "에프앤씨문백태양광발전소",
    rn: 6210
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진천삼채나라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.986,
    company: "진천삼채나라영농조합법인",
    rn: 6211
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장리1하늘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.905,
    company: "장리1하늘태양광발전소 주식회사",
    rn: 6212
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지혜1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "지혜태양광발전소 주식회사",
    rn: 6213
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지혜2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "지혜태양광발전소 주식회사",
    rn: 6214
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)이룸장원리",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.187,
    company: "(주)이룸장원리",
    rn: 6215
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선하늘2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.894,
    company: "정선하늘태양광발전소 주식회사",
    rn: 6216
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선하늘4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "정선하늘태양광발전소 주식회사",
    rn: 6217
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사북하늘2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 사북하늘태양광발전소",
    rn: 6218
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사북하늘3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 사북하늘태양광발전소",
    rn: 6219
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우보 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "우보태양광발전소",
    rn: 6220
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태두 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태두태양광발전소",
    rn: 6221
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지후 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "오솔레 태양광발전소",
    rn: 6222
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "선경태양광발전소",
    rn: 6223
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "정원 태양광발전소",
    rn: 6224
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KCC 대죽1공장 석고3 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 5.583,
    company: "(주)케이씨씨 본부영업소",
    rn: 6225
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KCC 대죽3공장 창고 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.913,
    company: "(주)케이씨씨 본부영업소",
    rn: 6226
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리 광명열병합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 17.3,
    company: "삼천리",
    rn: 6227
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리 광명열병합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 17.3,
    company: "삼천리",
    rn: 6228
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리 광명열병합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 11.4,
    company: "삼천리",
    rn: 6229
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동환태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.024,
    company: "동환 태양광발전소",
    rn: 6230
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "벽산홍성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "전북에너지서비스주식회사",
    rn: 6231
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우림1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "우림1태양광발전소",
    rn: 6232
    },
    {
    area: "제주",
    genFom: "기력",
    genSrc: "화력",
    genNm: "제주특별자치도 생활폐기물 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 19.2,
    company: "제주도청",
    rn: 6233
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "서산바이오가스발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.75,
    company: "(주)지엔씨에너지",
    rn: 6234
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "포항제철#1",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 1220.69,
    company: "(주)포스코",
    rn: 6235
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "엔프로텍 바이오발전소",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 5,
    company: "주식회사 엔프로텍",
    rn: 6236
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "yd솔라에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "yd솔라에너지",
    rn: 6237
    },
    {
    area: "제주",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "대정1수력발전기",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.05,
    company: "대산소수력발전 주식회사",
    rn: 6238
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)에스엠솔라텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)에스엠솔라텍",
    rn: 6239
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성주솔라팜",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.97,
    company: "주식회사 티에스에너지15호",
    rn: 6240
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "개화햇빛",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.745,
    company: "주식회사 개화햇빛",
    rn: 6241
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양사랑(주)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "태양사랑주식회사",
    rn: 6242
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "배명태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "배명태양광발전소",
    rn: 6243
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)은탄3솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사은탄3솔라",
    rn: 6244
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사랑에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 사랑에너지",
    rn: 6245
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지이케이 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.726,
    company: "지이케이 태양광발전소",
    rn: 6246
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "뚜르에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.437,
    company: "(주)뚜르에너지",
    rn: 6247
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문막포진리 태양광발전소 4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에디슨 환경에너지 주식회사",
    rn: 6248
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복용쏠라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "복용쏠라",
    rn: 6249
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화서 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "화서태양광발전소",
    rn: 6250
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)은탄1솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 은탄1솔라",
    rn: 6251
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)은탄2솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 은탄2솔라",
    rn: 6252
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성원2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "성원태양광발전소",
    rn: 6253
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성원 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.407,
    company: "성원태양발전소",
    rn: 6254
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "네모12태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.825,
    company: "(유)네모8태양광발전소",
    rn: 6255
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "양주신재생에너지발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 3,
    company: "주식회사 가나에너지",
    rn: 6256
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성옥태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "성옥태양광주식회사",
    rn: 6257
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼달2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "삼달2호 태양광발전소",
    rn: 6258
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "선창산업 MDF Bio-SRF",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 7.97,
    company: "선창산업(주)",
    rn: 6259
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "(주)그린환경기술발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.95,
    company: "(주)그린환경기술",
    rn: 6260
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와초리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.847,
    company: "제이에치비에너지주식회사",
    rn: 6261
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영승가애 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "주식회사 영승가애",
    rn: 6262
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "신지풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 17.325,
    company: "아일랜드발전투자(주)",
    rn: 6263
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)금일에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.404,
    company: "(유)금일에너지",
    rn: 6264
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티7호 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티7호 태양광발전소 ",
    rn: 6265
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조율태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.494,
    company: "라성모방 (주)",
    rn: 6266
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치알태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.073,
    company: "주식회사 에이치알에너지",
    rn: 6267
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티5호 태양광발전소",
    rn: 6268
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정앤수2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "정앤수2호 태양광발전소",
    rn: 6269
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정은태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "정은 태양광발전소",
    rn: 6270
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 라일태양광",
    rn: 6271
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티8호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "호티8호 태양광발전소",
    rn: 6272
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디해바라기 태양광발전소 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.298,
    company: "(주)에스디해바라기",
    rn: 6273
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정앤수1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "정앤수 솔라에너지",
    rn: 6274
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티10호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티10호 태양광발전소",
    rn: 6275
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티4호 태양광발전소 ",
    rn: 6276
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티1호 태양광발전소",
    rn: 6277
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티2호 태양광발전소",
    rn: 6278
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛궁포리발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.597,
    company: "(주)한빛궁포리발전소",
    rn: 6279
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티3호 태양광발전소",
    rn: 6280
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호티9호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "호티9호 태양광발전소",
    rn: 6281
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "난산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.899,
    company: "주식회사 경일솔라",
    rn: 6282
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서락 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.089,
    company: "서락 태양광발전소",
    rn: 6283
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)청광6호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 청광6태양광발전소",
    rn: 6284
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)봉남리솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 봉남리솔라",
    rn: 6285
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)라이프에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 남포에너지",
    rn: 6286
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼인태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.473,
    company: "삼인 태양광발전소",
    rn: 6287
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남도태양광제2기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "남도태양광제2발전소 주식회사",
    rn: 6288
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남도태양광제1기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "남도태양광제1발전소 주식회사",
    rn: 6289
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "범한산업 주식회사(대산공장 태양광)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.084,
    company: "범한산업주식회사",
    rn: 6290
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥스에너지월성 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.969,
    company: "주식회사 맥스에너지월성",
    rn: 6291
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "들꽃01",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.314,
    company: "들꽃01태양광발전소",
    rn: 6292
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(SUPEX 7W)성화산업#1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.124,
    company: "전남도시가스(주)",
    rn: 6293
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(SUPEX 7E)성화산업#1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.156,
    company: "전남도시가스(주)",
    rn: 6294
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(SUPEX 8E)성화산업#2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "전남도시가스(주)",
    rn: 6295
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(SUPEX 8W)성화산업#2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.234,
    company: "전남도시가스(주)",
    rn: 6296
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진락태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "우진락태양광발전소",
    rn: 6297
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)늘솔길에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유한회사 늘솔길에너지",
    rn: 6298
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)원더풀태양광발전소가시리1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.921,
    company: "(주)원더풀태양광발전소가시리1호",
    rn: 6299
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리_광명(대표)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 46,
    company: "삼천리",
    rn: 6300
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼천리_광명(구매대표)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 0,
    company: "삼천리(구매)",
    rn: 6301
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "51호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(주)제주감귤태양광",
    rn: 6302
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이씨씨 여주태양광 제4발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.11,
    company: "주식회사 케이씨씨글라스 여주공장",
    rn: 6303
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "들꽃 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "들꽃 태양광발전소",
    rn: 6304
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(주)월성에너지",
    rn: 6305
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나민영태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "나민영 태양광발전소",
    rn: 6306
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상용 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.403,
    company: "상용태양광발전소",
    rn: 6307
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스에너지호동",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "티에스에너지27호",
    rn: 6308
    },
    {
    area: "제주",
    genFom: "해양에너지",
    genSrc: "신재생에너지",
    genNm: "제주파력발전",
    cent: "비중앙",
    fuel: "해양에너지",
    pcap: 0.5,
    company: "한국해양과학기술원 부설 선박해양플랜트연구소",
    rn: 6309
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛사랑태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "빛사랑태양광발전소(주)",
    rn: 6310
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서대태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "서대태양광(주)",
    rn: 6311
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "천광태양광발전소(주)",
    rn: 6312
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한려태양광(주) 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "한려태양광(주)",
    rn: 6313
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한려태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "한려태양광(주)",
    rn: 6314
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다담3호 태양광발전소",
    rn: 6315
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "노을 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "노을 태양광발전소",
    rn: 6316
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서산 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 65,
    company: "현대에코에너지주식회사",
    rn: 6317
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "KT대덕2연구센터 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 0.9,
    company: "주식회사 케이티",
    rn: 6318
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "yk에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.334,
    company: "주식회사 와이케이에너지",
    rn: 6319
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "18호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "(주)제주감귤태양광",
    rn: 6320
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)예스에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(유)예스에너지",
    rn: 6321
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한려태양광(주) 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "한려태양광(주)",
    rn: 6322
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "행원 태양광발전소",
    rn: 6323
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이13호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.421,
    company: "피브이플랜트십삼호(주)",
    rn: 6324
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진JH태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.603,
    company: "은성쏠라 주식회사",
    rn: 6325
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "블루2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.201,
    company: "블루플래닛이호 주식회사",
    rn: 6326
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.827,
    company: "(주)디에스태양광발전소4호",
    rn: 6327
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)엑스파워태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주)엑스파워",
    rn: 6328
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬가든태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.204,
    company: "주식회사 썬가든",
    rn: 6329
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바름태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "바름태양광발전소",
    rn: 6330
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월림산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유한회사 광일에너지",
    rn: 6331
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SW1 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.294,
    company: "주식회사 승우에너지",
    rn: 6332
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나민영2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.092,
    company: "나민영2 태양광발전소",
    rn: 6333
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)성우에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.88,
    company: "유한회사 성우에너지",
    rn: 6334
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동고지솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.6,
    company: "주식회사 동고지솔라",
    rn: 6335
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이제이 솔라 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이제이 솔라 1호 태양광발전소",
    rn: 6336
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성운2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "(주)성운",
    rn: 6337
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.225,
    company: "씨앤아이레저산업(주)",
    rn: 6338
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "간성흘리찰리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)간성흘리찰리",
    rn: 6339
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)쏠라스토리포천1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.003,
    company: "주식회사 쏠라스토리",
    rn: 6340
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "대산그린에너지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 50.16,
    company: "대산그린에너지 주식회사",
    rn: 6341
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼마여주2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "주식회사 삼마",
    rn: 6342
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "간성흘리에코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.989,
    company: "(주)간성흘리에코",
    rn: 6343
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국서부발전(주)태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "한국서부발전(주)",
    rn: 6344
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "푸르미농산 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "푸르미농산 태양광발전소",
    rn: 6345
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)청운 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 청운",
    rn: 6346
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성원에너지(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9.968,
    company: "(주)성원에너지",
    rn: 6347
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월천에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.447,
    company: "월천에너지",
    rn: 6348
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)너울에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "유한회사 너울에너지",
    rn: 6349
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "관동대 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.963,
    company: "주식회사 내추럴에너지",
    rn: 6350
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문길태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.07,
    company: "문길태양광발전소",
    rn: 6351
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상현 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상현 태양광발전소",
    rn: 6352
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민승 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "민승 태양광발전소",
    rn: 6353
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천광 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "천광태양광발전소",
    rn: 6354
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)일조 솔라플랜트 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "주식회사 일조솔라플랜트",
    rn: 6355
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)금성광에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)금성광에너지",
    rn: 6356
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "남천태양광발전소",
    rn: 6357
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동건태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동건태양광발전소",
    rn: 6358
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건우1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "건우1호 태양광발전소",
    rn: 6359
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕은1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "솔라플러스 주식회사",
    rn: 6360
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "음성장수태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "음성장수태양광발전소",
    rn: 6361
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산디티에스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.732,
    company: "주식회사 디티에스",
    rn: 6362
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉1리 다목적회관 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.031,
    company: "무릉1리마을회",
    rn: 6363
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은송예빈태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "은송예빈태양광발전소",
    rn: 6364
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑프라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.117,
    company: "해왕솔라 주식회사",
    rn: 6365
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼척소내태양광#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.599,
    company: "한국남부발전(주)",
    rn: 6366
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바다태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.787,
    company: "주식회사 바다태양광",
    rn: 6367
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지영 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지영 태양광발전소",
    rn: 6368
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지티씨솔라7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.294,
    company: "주식회사 지티씨솔라7호",
    rn: 6369
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한영피엔에스3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.703,
    company: "(주)한영피엔에스",
    rn: 6370
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KCC대죽공장 154kV 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 8.5,
    company: "(주)케이씨씨 본부영업소",
    rn: 6371
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신세계2호태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "신세계2호 태양광발전소",
    rn: 6372
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신세계1호태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신세계1호 태양광발전소",
    rn: 6373
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "그린에너지주식회사",
    rn: 6374
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대풍태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "대풍 태양광발전소",
    rn: 6375
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더준태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.841,
    company: "주식회사더준",
    rn: 6376
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "더준태양광ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.75,
    company: "주식회사더준",
    rn: 6377
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장미태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "장미태양광발전소",
    rn: 6378
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "훈민2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.449,
    company: "훈민2태양광발전소",
    rn: 6379
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라테크에너지 창녕태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사솔라테크에너지",
    rn: 6380
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경철태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "경철 태양광발전소",
    rn: 6381
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주한림읍태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "제주한림태양광발전소",
    rn: 6382
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨지에너지4호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.76,
    company: "JCG솔라",
    rn: 6383
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해나태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.26,
    company: "해나태양광발전소",
    rn: 6384
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이디에스 주식회사(1호) 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.285,
    company: "이디에스 주식회사 나주지점",
    rn: 6385
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희윤태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희윤 희윤4 희윤5 태양광발전소",
    rn: 6386
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희윤4태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희윤 희윤4 희윤5 태양광발전소",
    rn: 6387
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희윤5태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희윤 희윤4 희윤5 태양광발전소",
    rn: 6388
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "갑부태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "갑부 태양광발전소",
    rn: 6389
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명환 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명환 태양광발전소",
    rn: 6390
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마고1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "마고1 태양광발전소",
    rn: 6391
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "용담3소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.13,
    company: "한국수자원공사",
    rn: 6392
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "26호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)제주감귤태양광",
    rn: 6393
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장신태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.984,
    company: "(주)금성광에너지",
    rn: 6394
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.221,
    company: "(주)원태양광",
    rn: 6395
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)한그린에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.011,
    company: "(주)한그린에너지",
    rn: 6396
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광령태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.091,
    company: "광령태양광발전소",
    rn: 6397
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "브래드태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "브래드태양광 주식회사",
    rn: 6398
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "란2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "란2호 태양광발전소",
    rn: 6399
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청보리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.194,
    company: "청보리태양광발전소",
    rn: 6400
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인팩태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.635,
    company: "(주)인팩",
    rn: 6401
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠엘에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "엠엘에너지",
    rn: 6402
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준성원에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "준성원에너지태양광발전소",
    rn: 6403
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)옥종에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 옥종에너지",
    rn: 6404
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반야태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "반야 태양광발전소",
    rn: 6405
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태원 태양광발전소",
    rn: 6406
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유철 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "유철 태양광 발전소",
    rn: 6407
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라온하제 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "라온하제 태양광발전소",
    rn: 6408
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원화 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "주식회사 원화태양광발전소",
    rn: 6409
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬플러스5호 두일리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.022,
    company: "썬플러스에너지5호 주식회사",
    rn: 6410
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "웅임태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "웅임태양광발전소",
    rn: 6411
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제1비케이에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.574,
    company: "비케이에너지(유)",
    rn: 6412
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제2비케이에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.554,
    company: "비케이에너지(유)",
    rn: 6413
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼익악기 음성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "(주)삼익악기",
    rn: 6414
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KT무릉송신소 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 케이티",
    rn: 6415
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보현태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "보현 태양광발전소",
    rn: 6416
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월암3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "주식회사월암3호발전소",
    rn: 6417
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월암2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "주식회사월암2호발전소",
    rn: 6418
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월암1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "주식회사월암1호발전소",
    rn: 6419
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "80호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.451,
    company: "(주)제주감귤태양광",
    rn: 6420
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한글라스태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.997,
    company: "주식회사 한글라스 태양광발전",
    rn: 6421
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼달태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "삼달 태양광발전소",
    rn: 6422
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구억태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "구억 태양광발전소",
    rn: 6423
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대동공업 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.999,
    company: "충청에너지서비스(주)",
    rn: 6424
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영파워 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.506,
    company: "주식회사 영파워",
    rn: 6425
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태건솔라1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)태건솔라",
    rn: 6426
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태건솔라2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)태건솔라",
    rn: 6427
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부메랑태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.692,
    company: "부메랑",
    rn: 6428
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "부메랑ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0.5,
    company: "부메랑",
    rn: 6429
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코원이에스햇빛송파",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.315,
    company: "코원에너지서비스(주)",
    rn: 6430
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "야광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "야광태양광발전소",
    rn: 6431
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청담쏠라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "청담쏠라 태양광발전소",
    rn: 6432
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "167호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.633,
    company: "(주)제주감귤태양광",
    rn: 6433
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "155호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.167,
    company: "(주)제주감귤태양광",
    rn: 6434
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "89호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.925,
    company: "(주)제주감귤태양광",
    rn: 6435
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "131호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.455,
    company: "(주)제주감귤태양광",
    rn: 6436
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월암4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "주식회사 월암4호발전소",
    rn: 6437
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)청향에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "유한회사 청향에너지",
    rn: 6438
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영락태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.327,
    company: "영락태양광발전소",
    rn: 6439
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수복 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "수복 태양광발전소",
    rn: 6440
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "하천태양광발전",
    rn: 6441
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신촌1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.092,
    company: "신촌태양광발전소",
    rn: 6442
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "애송태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "애송태양광발전소",
    rn: 6443
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "오라태양광발전소",
    rn: 6444
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청솔태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "청솔태양광발전소",
    rn: 6445
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명원정읍 태양광발전소(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "명원정읍태양광발전소 주식회사",
    rn: 6446
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스솔루션태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "농업회사법인 유한회사 티에스솔루션",
    rn: 6447
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서리태양광발전소",
    rn: 6448
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성용태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "성용 태양광 발전소",
    rn: 6449
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "첼시4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "주식회사 첼시4호태양광발전소",
    rn: 6450
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현수물산태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 현수물산",
    rn: 6451
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)자연에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.806,
    company: "주식회사 자연에너지",
    rn: 6452
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "후암1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아리산전",
    rn: 6453
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청파태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)청파물산",
    rn: 6454
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우재태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 우재물산",
    rn: 6455
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연화태양광1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.15,
    company: "연화태양광발전소",
    rn: 6456
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.394,
    company: "무릉태양광",
    rn: 6457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래-2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "미래-2 태양광발전소",
    rn: 6458
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "OCI스페셜티 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.727,
    company: "오씨아이파워주식회사",
    rn: 6459
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "굴왓 햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "굴왓 햇빛발전소",
    rn: 6460
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영관 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.402,
    company: "영관태양광발전소",
    rn: 6461
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디원3 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.496,
    company: "(주) 디원",
    rn: 6462
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동일리 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.263,
    company: "동일리 태양광발전소",
    rn: 6463
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)송전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 송전태양광",
    rn: 6464
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠에이2호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "엠에이2호에너지",
    rn: 6465
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주해냄태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "유한회사 제주해냄",
    rn: 6466
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕천교육원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.076,
    company: "덕천교육원태양광발전소",
    rn: 6467
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신와태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.167,
    company: "주식회사 신와태양광",
    rn: 6468
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거북태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "거북 태양광발전소",
    rn: 6469
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥스에너지안정",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.956,
    company: "주식회사 맥스에너지안정",
    rn: 6470
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "휴코에너지1호 당진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.299,
    company: "휴코에너지 주식회사",
    rn: 6471
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라시도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 98.397,
    company: "솔라시도 태양광발전 주식회사",
    rn: 6472
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래농산 4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.971,
    company: "농업회사법인 미래농산주식회사",
    rn: 6473
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)성광태양광발전소",
    rn: 6474
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가람태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.244,
    company: "가람태양광발전소",
    rn: 6475
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한정밀 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.252,
    company: "대한정밀공업(주)",
    rn: 6476
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내척 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "내척태양광발전소",
    rn: 6477
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주민참여1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "강포리주민참여태양광발전소1호(주)",
    rn: 6478
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주민참여2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "강포리주민참여태양광발전소 2호 주식회사",
    rn: 6479
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주민참여3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "강포리주민참여태양광발전소3호(주)",
    rn: 6480
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주민참여4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "강포리주민참여태양광발전소4호(주)",
    rn: 6481
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주민참여5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "강포리주민참여태양광발전소 5호 주식회사",
    rn: 6482
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주민참여6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.869,
    company: "강포리주민참여태양광발전소6호(주)",
    rn: 6483
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석문제1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "(주)지엔씨에너지",
    rn: 6484
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비즌비츠로2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.48,
    company: "주식회사 비즌비츠로2",
    rn: 6485
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민결 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.913,
    company: "주식회사 민결발전소",
    rn: 6486
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광자 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 광자태양광",
    rn: 6487
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동안이젠태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.87,
    company: "동안태양광 주식회사",
    rn: 6488
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉평이 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 루이젠",
    rn: 6489
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉평일 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 루이젠평창일",
    rn: 6490
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "주식회사 하늘태양광발전소",
    rn: 6491
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바다태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "주식회사 바다태양광발전소",
    rn: 6492
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠8호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "쏠8호 태양광발전소",
    rn: 6493
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠9호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "쏠9호 태양광발전소",
    rn: 6494
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대화태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.945,
    company: "대화 태양광발전소",
    rn: 6495
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "공산쏠라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "공산쏠라 태양광발전소",
    rn: 6496
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "공산쏠라2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "공산쏠라2 태양광발전소",
    rn: 6497
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "BJ SSP 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "비제이(BJ) SSP 태양광발전소",
    rn: 6498
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진에코태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 9.813,
    company: "당진에코파워 주식회사",
    rn: 6499
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "부천연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 10.56,
    company: "지에스파워(주)",
    rn: 6500
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "표선1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.962,
    company: "표선1호 태양광발전소",
    rn: 6501
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "표선2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "표선2호 태양광발전소",
    rn: 6502
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "삼수풍력 ",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 21,
    company: "주식회사 삼수풍력발전 ",
    rn: 6503
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "수망풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 14.4,
    company: "주식회사 수망풍력",
    rn: 6504
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시문5태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "시문5태양광발전소",
    rn: 6505
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 에스솔라",
    rn: 6506
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "낙안솔라9호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "낙안솔라9호 태양광발전소",
    rn: 6507
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한영피엔에스2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.693,
    company: "(주)한영피엔에스",
    rn: 6508
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한영피엔에스1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.299,
    company: "(주)한영피엔에스",
    rn: 6509
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)라이프에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "유한회사 라이프에너지",
    rn: 6510
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북촌 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "북촌 태양광발전소",
    rn: 6511
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이14호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "피브이플랜트십사호 주식회사",
    rn: 6512
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "블루4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "블루플래닛사호(주)",
    rn: 6513
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한솔클린에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한솔클린에너지",
    rn: 6514
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서광2태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "서광2 태양광발전소",
    rn: 6515
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에이치이엔씨10호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 에스에이치이엔씨",
    rn: 6516
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문지훈태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "문지훈 태양광발전소",
    rn: 6517
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)다원태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.271,
    company: "(주)다원태양광발전",
    rn: 6518
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "yk에너지9호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.192,
    company: "주식회사 와이케이에너지",
    rn: 6519
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바다해태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "바다해 태양광발전소",
    rn: 6520
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "혜성태양광발전소",
    rn: 6521
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보아스에너지(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.901,
    company: "보아스에너지(주)",
    rn: 6522
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미암우리 6호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 미암우리6호 태양광발전소",
    rn: 6523
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송시근태양광발전소PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 현대에너지",
    rn: 6524
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "푸른태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "푸른 태양광발전소",
    rn: 6525
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "에스에스태양광발전소",
    rn: 6526
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.453,
    company: "행원리태양광발전소",
    rn: 6527
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선화 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "주식회사 선화에너지",
    rn: 6528
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "수망풍력#2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 10.8,
    company: "주식회사 수망풍력",
    rn: 6529
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이원에너지3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.22,
    company: "케이원에너지3호 태양광발전소",
    rn: 6530
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주현2호 태양광발전소",
    rn: 6531
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양이엔지(주)태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "동양이엔지 주식회사",
    rn: 6532
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "굿파워예산3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유한회사 굿파워",
    rn: 6533
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)대연1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)대연1태양광발전소",
    rn: 6534
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)대연2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)대연2태양광발전소",
    rn: 6535
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일과태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "일과 태양광발전소",
    rn: 6536
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)양정태양광1호 태화1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "유한회사 양정태양광1호",
    rn: 6537
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다담4호 태양광발전소",
    rn: 6538
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)양정태양광2호 태화2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "유한회사 양정태양광2호",
    rn: 6539
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)양정태양광3호 태화3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "유한회사 양정태양광3호",
    rn: 6540
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가인리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "가인리태양광발전소",
    rn: 6541
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중현태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "중현태양광발전소",
    rn: 6542
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "길현태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "길현태양광발전소",
    rn: 6543
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이엔비태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.34,
    company: "유한회사 와이엔비",
    rn: 6544
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린19호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.349,
    company: "케이그린19호 태양광발전소",
    rn: 6545
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기산솔라4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "기산솔라4호",
    rn: 6546
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "써니에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.877,
    company: "써니에너지태양광발전소",
    rn: 6547
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청리에너지-2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.952,
    company: "(주)솔라시너지햇살3호",
    rn: 6548
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청리에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주)솔라시너지햇살4호",
    rn: 6549
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청리에너지-3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 씨엔이에이엠",
    rn: 6550
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합GT#1",
    cent: "중앙",
    fuel: "LNG",
    pcap: 25.4,
    company: "한국지역난방공사 삼송지사",
    rn: 6551
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합GT#2",
    cent: "중앙",
    fuel: "LNG",
    pcap: 25.4,
    company: "한국지역난방공사 삼송지사",
    rn: 6552
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "삼송열병합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 49.4,
    company: "한국지역난방공사 삼송지사",
    rn: 6553
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고도농원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.041,
    company: "농업회사법인 고도농원 주식회사",
    rn: 6554
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기산솔라3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "기산솔라3호",
    rn: 6555
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "창원시덕동매립가스발전",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.7,
    company: "엘에프에너지 (주)",
    rn: 6556
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘비에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "엘비에너지",
    rn: 6557
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "(주)세미컴엔지니어링",
    rn: 6558
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나목도 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.644,
    company: "나목도 태양광발전소",
    rn: 6559
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성심에너지PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "성심에너지",
    rn: 6560
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한솔테크닉스 오창2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.794,
    company: "한솔테크닉스(주)",
    rn: 6561
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이룡2호 태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.728,
    company: "주식회사 인종솔라",
    rn: 6562
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.281,
    company: "주식회사 화산지수상발전소",
    rn: 6563
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "청송노래산풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 19.2,
    company: "청송노래산풍력발전(주)",
    rn: 6564
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기산솔라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)삼흥홍성태양광발전소 (기산솔라1호)",
    rn: 6565
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대흘태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "대흘태양광발전소",
    rn: 6566
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "녹산1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "녹산태양광발전소",
    rn: 6567
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성진 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.413,
    company: "성진태양광발전소",
    rn: 6568
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금정태양광1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.168,
    company: "금정태양광발전소",
    rn: 6569
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "포항제철#2",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 0,
    company: "(주)포스코",
    rn: 6570
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티원 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "티원 태양광발전소",
    rn: 6571
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영운 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영운태양광발전소",
    rn: 6572
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구슬3 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "구슬3 태양광발전소",
    rn: 6573
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기산솔라2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.249,
    company: "주식회사 에이탑에너지",
    rn: 6574
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송백2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "송백2호 태양광발전소",
    rn: 6575
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "환영 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "환영 태양광발전소",
    rn: 6576
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신촌햇빛 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신촌햇빛 태양광발전소",
    rn: 6577
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양천햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.292,
    company: "양천햇빛",
    rn: 6578
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진영햇빛",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.285,
    company: "진영햇빛발전",
    rn: 6579
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광령 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "광령태양광발전소",
    rn: 6580
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "흰구름태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "흰구름 태양광발전소",
    rn: 6581
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)경호에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(유)경호에너지 태양광발전소",
    rn: 6582
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현진태양광333kw",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.333,
    company: "현진태양광발전소",
    rn: 6583
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태백귀네미풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 19.8,
    company: "태백귀네미풍력발전 주식회사",
    rn: 6584
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리에너지 13호 태양광1기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.987,
    company: "우리에너지13호 태양광발전소",
    rn: 6585
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문보2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "문보2호 태양광발전소",
    rn: 6586
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문보3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "문보3호 태양광발전소",
    rn: 6587
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.981,
    company: "유한회사 제이씨진상태양광",
    rn: 6588
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구하기솔라에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 구하기솔라에너지",
    rn: 6589
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산강에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 산강에너지",
    rn: 6590
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아마에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 아마에너지",
    rn: 6591
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "녹사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "녹사태양광발전 주식회사",
    rn: 6592
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송당리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "한국남부발전(주)",
    rn: 6593
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "위미2리제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "한국남부발전(주)",
    rn: 6594
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하가리1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.316,
    company: "하가리1호태양광발전소",
    rn: 6595
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문영2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.084,
    company: "문영2 태양광발전소",
    rn: 6596
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "천안청수연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.28,
    company: "한국서부발전(주)",
    rn: 6597
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이케아 고양점 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "이케아코리아 유한회사",
    rn: 6598
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 에스이태양광1호 발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.068,
    company: "주식회사 에스이태양광",
    rn: 6599
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 에스이태양광2호 발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.068,
    company: "주식회사 에스이태양광",
    rn: 6600
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 에스이태양광3호 발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.068,
    company: "주식회사 에스이태양광",
    rn: 6601
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼포태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "삼포태양광발전소",
    rn: 6602
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영정이엔지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "영정이엔지 태양광발전소",
    rn: 6603
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일신1호 SOLAR",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "일신1호태양광발전소 주식회사",
    rn: 6604
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가시태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.932,
    company: "주식회사 가시태양광발전",
    rn: 6605
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금제이노텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 금제이노텍",
    rn: 6606
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "입석태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "입석태양광발전 주식회사",
    rn: 6607
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "송림태양광발전 주식회사",
    rn: 6608
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광염에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 광염에너지",
    rn: 6609
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "우평태양광발전 주식회사",
    rn: 6610
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광백에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 광백에너지",
    rn: 6611
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "교촌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "교촌태양광발전 주식회사",
    rn: 6612
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무령태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "무령태양광발전 주식회사",
    rn: 6613
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "계송태양광발전 주식회사",
    rn: 6614
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래에너지영광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 미래에너지영광",
    rn: 6615
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하승서승에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 하승서승에너지",
    rn: 6616
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예아솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 예아솔라에너지",
    rn: 6617
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광백태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 광백태양광",
    rn: 6618
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코로나에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 코로나에너지",
    rn: 6619
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바다태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 바다태양광",
    rn: 6620
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마스터즈광백태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 마스터즈광백",
    rn: 6621
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산정관 #2 GT",
    cent: "중앙",
    fuel: "LNG",
    pcap: 29.48,
    company: "부산정관에너지(구역)",
    rn: 6622
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "부산정관 #2 ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 20.67,
    company: "부산정관에너지(구역)",
    rn: 6623
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "세종연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 5.28,
    company: "한국중부발전(주)",
    rn: 6624
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "인천연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 15.84,
    company: "한국중부발전(주)",
    rn: 6625
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양산업발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.302,
    company: "태양산업 주식회사",
    rn: 6626
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화성KC2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 4.176,
    company: "주식회사 케이씨솔팜1호",
    rn: 6627
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비에스에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "비에스에너지(유)",
    rn: 6628
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "필에너지 연천옥계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.725,
    company: "필에너지주식회사",
    rn: 6629
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)하랑여주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 하랑",
    rn: 6630
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "이천뉴파워 신재생에너지",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 7.3,
    company: "주식회사 이천뉴파워",
    rn: 6631
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이엠에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 와이엠에너지",
    rn: 6632
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "덕호태양광발전 주식회사",
    rn: 6633
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강토에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 강토에너지",
    rn: 6634
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라미에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 라미에너지",
    rn: 6635
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송하2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "송하2 태양광발전소유한회사",
    rn: 6636
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신하태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "신하태양광발전 주식회사",
    rn: 6637
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천일에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 천일에너지",
    rn: 6638
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이와이에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "제이와이에너지 주식회사",
    rn: 6639
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이제이케이에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "제이제이케이에너지 주식회사",
    rn: 6640
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 도영태양광",
    rn: 6641
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "신월태양광발전 주식회사",
    rn: 6642
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "단주태양광발전 주식회사",
    rn: 6643
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테크노파크 순천 본관동 1호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 서경에너지",
    rn: 6644
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테크노파크 순천 본관동 2호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 서경에너지",
    rn: 6645
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테크노파크 목포 본관동 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.451,
    company: "주식회사 서경에너지",
    rn: 6646
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테크노파크 목포 공장동 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.466,
    company: "주식회사 서경에너지",
    rn: 6647
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테크노파크 영암 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 서경에너지",
    rn: 6648
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지승태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 지승태양광",
    rn: 6649
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스와이에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "에스와이에너지 주식회사",
    rn: 6650
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.927,
    company: "거창태양광발전소",
    rn: 6651
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.259,
    company: "이안태양광발전소",
    rn: 6652
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)최강일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.988,
    company: "주식회사 엠알솔라에너지",
    rn: 6653
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이와트태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 피브이와트",
    rn: 6654
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미숙 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "미숙 태양광",
    rn: 6655
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "TY1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "TY1호 태양광발전소",
    rn: 6656
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "TY2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "TY2호 태양광발전소",
    rn: 6657
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해성 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "해성태양광발전소",
    rn: 6658
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "월평태양광발전 주식회사",
    rn: 6659
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "글로리어스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 글로리어스",
    rn: 6660
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강수에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 강수에너지",
    rn: 6661
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "갈바람에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 갈바람에너지",
    rn: 6662
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송영훈 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "유한회사 백석태양광발전소",
    rn: 6663
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대각에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "유한회사 대각에너지",
    rn: 6664
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한온시스템 평택공장 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.153,
    company: "한온시스템(주)",
    rn: 6665
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)한강에스 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)한강에스성환지점",
    rn: 6666
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코테크에너지3발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "주식회사 에코테크에너지",
    rn: 6667
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.397,
    company: "주식회사 녹색태양에너지",
    rn: 6668
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "본솔라2호 연천옥계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.725,
    company: "본솔라2호 주식회사",
    rn: 6669
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)삼남1호에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.982,
    company: "유한회사 삼남",
    rn: 6670
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)삼남2호에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.982,
    company: "유한회사 삼남",
    rn: 6671
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YFTZ관리원주차장 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "전남도시가스(주)",
    rn: 6672
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)대정신재생에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)대정신재생에너지",
    rn: 6673
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주자연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 제주자연에너지",
    rn: 6674
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주오름태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.393,
    company: "주식회사 제주오름에너지",
    rn: 6675
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송당태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "제이원솔라파워 주식회사",
    rn: 6676
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "남강수력#2",
    cent: "비중앙",
    fuel: "수력",
    pcap: 9,
    company: "한국수자원공사",
    rn: 6677
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맑은제주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "주식회사 맑은제주에너지",
    rn: 6678
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "북촌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.526,
    company: "주식회사 맑은제주에너지",
    rn: 6679
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한회사 빌드윈인동태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.497,
    company: "유한회사 빌드윈인동태양광발전",
    rn: 6680
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "뱀부 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "뱀부 주식회사",
    rn: 6681
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중부발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.886,
    company: "중부발전 주식회사",
    rn: 6682
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소명에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.59,
    company: "소명에너지 주식회사",
    rn: 6683
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.982,
    company: "주식회사 준에너지",
    rn: 6684
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "엠함안1호기 ",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 9.9,
    company: "엠함안 주식회사",
    rn: 6685
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "에스이화성연료전지#1",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.8,
    company: "(주) 에스이그린에너지",
    rn: 6686
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔텍솔라광치태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.909,
    company: "주식회사 엔텍솔라광치",
    rn: 6687
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔텍솔라광치태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.909,
    company: "주식회사 엔텍솔라광치",
    rn: 6688
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서오창1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.743,
    company: "한국동서발전(주)",
    rn: 6689
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서오창2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.772,
    company: "한국동서발전(주)",
    rn: 6690
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "헬파이어 태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "헬파이어 태양광발전소",
    rn: 6691
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕은3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.961,
    company: "은돌발전쏠라 주식회사",
    rn: 6692
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해담은와산1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "예인산업개발",
    rn: 6693
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "365일에너지2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "365일에너지2",
    rn: 6694
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "365일에너지3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.019,
    company: "365일에너지3",
    rn: 6695
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린이엔씨태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "그린이엔씨 태양광발전소",
    rn: 6696
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)제이씨태백1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "유)제이씨태백1호",
    rn: 6697
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)선그린태백5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.853,
    company: "유)선그린태백5호",
    rn: 6698
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨평창",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "(유)제이씨평창태양광",
    rn: 6699
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)쏠라파워 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)쏠라파워",
    rn: 6700
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)파워제네레이션 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)파워제네레이션",
    rn: 6701
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)쏠라뱅크 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)쏠라뱅크",
    rn: 6702
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해맑은2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "해맑은태양광발전소",
    rn: 6703
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평촌 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "(주)에스엠솔라",
    rn: 6704
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송죽에너지태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "유한회사 송죽에너지",
    rn: 6705
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하리 태양광발전소(pv)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.093,
    company: "하리 태양광발전소1호",
    rn: 6706
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "수원시 자원회수시설 소각열 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 7,
    company: "주식회사 수원뉴파워",
    rn: 6707
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SA2기 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "(주) 에스에이",
    rn: 6708
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비에스에스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.126,
    company: "비에스에스 주식회사",
    rn: 6709
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "밀정 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "밀정 태양광발전소",
    rn: 6710
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.255,
    company: "삼원 태양광발전소",
    rn: 6711
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미에스티태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.414,
    company: "(주) 에스티",
    rn: 6712
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동해바이오화력본부 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.992,
    company: "한국동서발전(주)",
    rn: 6713
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양동아스틸1기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.356,
    company: "유한회사 라미솔라",
    rn: 6714
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양동아스틸2기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.68,
    company: "유한회사 라미솔라",
    rn: 6715
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양동아스틸3기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.458,
    company: "유한회사 라미솔라",
    rn: 6716
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산씨엠1기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.411,
    company: "유한회사 라미솔라",
    rn: 6717
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원주메탈2기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 라미솔라",
    rn: 6718
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YSP A 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.634,
    company: "전남도시가스(주)",
    rn: 6719
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YSP B 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.861,
    company: "전남도시가스(주)",
    rn: 6720
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명품3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명품3호 태양광발전소",
    rn: 6721
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇빛봉남1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "햇빛봉남1호태양광발전소",
    rn: 6722
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해오름 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "해오름 태양광발전소",
    rn: 6723
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용산42호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "용산 42호 태양광발전소",
    rn: 6724
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이젯신재생태양광(영광)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "에이젯신재생에너지 (주)",
    rn: 6725
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이앤엘에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "(유) 케이앤엘에너지",
    rn: 6726
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파워신재생에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "파워신재생에너지 (주)",
    rn: 6727
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대리뉴7호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.897,
    company: "현대리뉴어블랩 주식회사",
    rn: 6728
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경훈태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "주식회사 경훈태양광발전",
    rn: 6729
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉산효교제1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "봉산효교 제1호 태양광발전소",
    rn: 6730
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉산효교제2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "예산조창기발전소",
    rn: 6731
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대천리1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 대천리1호 발전소",
    rn: 6732
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.25,
    company: "장산1호 태양광발전소",
    rn: 6733
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더함4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(유)더함4호태양광발전소",
    rn: 6734
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "둥지에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.35,
    company: "둥지에너지 태양광발전소",
    rn: 6735
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피에스솔라1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "피에스솔라1호 주식회사",
    rn: 6736
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다온에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "다온에너지 태양광발전소",
    rn: 6737
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강이준희태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.092,
    company: "강이준희태양광발전소",
    rn: 6738
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.142,
    company: "행복 태양광발전소",
    rn: 6739
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "생드르영농조합법인 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.059,
    company: "생드르영농조합법인",
    rn: 6740
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 성진2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.432,
    company: "주식회사 성진2호태양광발전소",
    rn: 6741
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창녕해안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "창녕 해안 태양광발전소",
    rn: 6742
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주미래2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.252,
    company: "제주미래2 태양광발전소",
    rn: 6743
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주미래3 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.155,
    company: "제주미래3 태양광발전소",
    rn: 6744
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JCG솔라 3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.292,
    company: "JCG솔라",
    rn: 6745
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주다올발전소 1호 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "제주다올 발전소",
    rn: 6746
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신산2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신산2호 태양광발전소",
    rn: 6747
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현태양광",
    rn: 6748
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희연태양광",
    rn: 6749
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희승태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희승태양광",
    rn: 6750
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희정태양광",
    rn: 6751
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희란태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희란태양광",
    rn: 6752
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군위태양광_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.441,
    company: "군위 태양광발전소",
    rn: 6753
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)방장 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 방장",
    rn: 6754
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)노령 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 노령",
    rn: 6755
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창금1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "창금1호 태양광발전소",
    rn: 6756
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리에너지(유)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.987,
    company: "우리에너지 (유)",
    rn: 6757
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지혁태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.594,
    company: "지혁태양광발전소",
    rn: 6758
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이젯신재생태양광(완도)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "에이젯신재생에너지 (주)",
    rn: 6759
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "소각열회수발전",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 4.84,
    company: "삼영이앤이(주)",
    rn: 6760
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안덕한빛나눔에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "안덕한빛나눔에너지 주식회사",
    rn: 6761
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성SUN태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)대성태양광발전소",
    rn: 6762
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SH 발전소 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "에스에이치(SH) 발전소",
    rn: 6763
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "문수태양광발전소",
    rn: 6764
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한영정공1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(주) 한영정공",
    rn: 6765
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한영정공2호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(주) 한영정공",
    rn: 6766
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래나노텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.658,
    company: "충청에너지서비스(주)",
    rn: 6767
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평천태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "평천 태양광발전소",
    rn: 6768
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬샤인태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "썬샤인 태양광발전소",
    rn: 6769
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무위용 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 무위용",
    rn: 6770
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신평택태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.272,
    company: "신평택발전(주)",
    rn: 6771
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다림에너지 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "다림에너지",
    rn: 6772
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "BEP가실리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.178,
    company: "브라이트에너지파트너스 주식회사",
    rn: 6773
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천외서면발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 순천외서면발전소",
    rn: 6774
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천외서면발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 순천외서면발전소",
    rn: 6775
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬모아에너지(주) 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.302,
    company: "썬모아에너지 주식회사",
    rn: 6776
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대인구리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.017,
    company: "(주)부국에너지",
    rn: 6777
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파란하늘 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "파란하늘 태양광발전소",
    rn: 6778
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파란하늘2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.703,
    company: "파란하늘2호 태양광발전소",
    rn: 6779
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라36호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "은성쏠라 주식회사",
    rn: 6780
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라10호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "은성쏠라 주식회사",
    rn: 6781
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라12호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "은성쏠라 주식회사",
    rn: 6782
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라16호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "은성쏠라 주식회사",
    rn: 6783
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라40호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "은성쏠라 주식회사",
    rn: 6784
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라37호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "은성쏠라 주식회사",
    rn: 6785
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라41호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "은성쏠라 주식회사",
    rn: 6786
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일포리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "주식회사 일포리태양광",
    rn: 6787
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적산 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "적산태양광발전소",
    rn: 6788
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼일 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "삼일전설 (주)",
    rn: 6789
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명품5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명품5호 태양광발전소",
    rn: 6790
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라22호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "광진쏠라 주식회사",
    rn: 6791
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라5호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "광진쏠라 주식회사",
    rn: 6792
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라7호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "광진쏠라 주식회사",
    rn: 6793
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라25호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "광진쏠라 주식회사",
    rn: 6794
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라23호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "광진쏠라 주식회사",
    rn: 6795
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라28호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "광진쏠라 주식회사",
    rn: 6796
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘도라도 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "유한회사 엘도라도",
    rn: 6797
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명품4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명품4호 태양광발전소",
    rn: 6798
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "치웅 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "치웅태양광발전소",
    rn: 6799
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이엠에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이엠에너지",
    rn: 6800
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금애 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "금애태양광발전소",
    rn: 6801
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신덕 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "신덕태양광발전소",
    rn: 6802
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SOLVIC2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.726,
    company: "세방(주)",
    rn: 6803
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼보판지익산공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.413,
    company: "전북에너지서비스주식회사",
    rn: 6804
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세훈태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "세훈 태양광발전소",
    rn: 6805
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)여주케이원솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 여주케이원솔라",
    rn: 6806
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태백 금봉풍력1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 16,
    company: "그린에너지발전 주식회사",
    rn: 6807
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태백 금봉풍력2",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 12,
    company: "그린에너지발전 주식회사",
    rn: 6808
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제일산기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(주) 제일산기",
    rn: 6809
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현진태양광발전소",
    rn: 6810
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "명선태양광",
    rn: 6811
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강천게이트볼장태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.048,
    company: "여주에너지협동조합",
    rn: 6812
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당산1리공동저온저장고태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.052,
    company: "여주에너지협동조합",
    rn: 6813
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신전천후게이트볼장태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.052,
    company: "여주에너지협동조합",
    rn: 6814
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여주추모공원주차장태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.074,
    company: "여주에너지협동조합",
    rn: 6815
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼교동게이트볼장태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "여주에너지협동조합",
    rn: 6816
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)연실2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)연실태양광발전소",
    rn: 6817
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리에너지11호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.494,
    company: "우리에너지11호 태양광발전소",
    rn: 6818
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두리그린에너지 제2호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사두리그린에너지",
    rn: 6819
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파워그린에너지 제1호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사파워그린에너지",
    rn: 6820
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼삼태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "삼삼태양광발전소",
    rn: 6821
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성진 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "범우솔라 주식회사",
    rn: 6822
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준행복밸리발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.092,
    company: "준행복밸리발전소",
    rn: 6823
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕은2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.954,
    company: "검단태양광 주식회사",
    rn: 6824
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼달3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "삼달3호 태양광발전소",
    rn: 6825
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월평태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.553,
    company: "한국수력원자력(주)",
    rn: 6826
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주탑태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.803,
    company: "한국수력원자력(주)",
    rn: 6827
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지타운 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.237,
    company: "안산도시개발(주)",
    rn: 6828
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연봉에너지1호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.431,
    company: "주식회사 연봉에너지",
    rn: 6829
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연봉에너지2호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.431,
    company: "주식회사 연봉에너지",
    rn: 6830
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연봉에너지3호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.431,
    company: "주식회사 연봉에너지",
    rn: 6831
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연봉에너지4호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.387,
    company: "주식회사 연봉에너지",
    rn: 6832
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연봉에너지5호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.103,
    company: "주식회사 연봉에너지",
    rn: 6833
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한회사 사전태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 사전태양광발전소",
    rn: 6834
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소사4호 태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "소사4호 태양광발전소",
    rn: 6835
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소사10호 태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "소사10호 태양광발전소",
    rn: 6836
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)태안남산2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)태안태양광발전소",
    rn: 6837
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남양태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)남양태양광",
    rn: 6838
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.392,
    company: "주식회사 대광쏠라",
    rn: 6839
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대광태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.382,
    company: "대광태양광발전소2호",
    rn: 6840
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화원태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "화원태양광발전소",
    rn: 6841
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)봉암태양광",
    rn: 6842
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해바라기5호발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해바라기발전소5호",
    rn: 6843
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동인태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "동인태양광발전소",
    rn: 6844
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 이엠씨산업",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.65,
    company: "주식회사 이엠씨산업",
    rn: 6845
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경주동산태양광발전PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.971,
    company: "경주동산태양광발전 주식회사",
    rn: 6846
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "메건태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "메건태양광발전소",
    rn: 6847
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주명태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주명 태양광발전소",
    rn: 6848
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디이에스에스1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디엔 이에스에스 주식회사",
    rn: 6849
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디엔이엔지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디엔 엔지니어링 주식회사",
    rn: 6850
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디바이오1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디 바이오 주식회사",
    rn: 6851
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디솔루션1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디 솔루션 주식회사",
    rn: 6852
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디솔루션2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디 솔루션 주식회사",
    rn: 6853
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디솔루션3 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디 솔루션 주식회사",
    rn: 6854
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더블유솔라(W-solar)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "더블유솔라(W-solar)태양광발전소",
    rn: 6855
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디피브이1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디 피브이 주식회사",
    rn: 6856
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디피브이2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디 피브이 주식회사",
    rn: 6857
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디피브이3 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디 피브이 주식회사",
    rn: 6858
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "서정 태양광발전소",
    rn: 6859
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "울트라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.796,
    company: "주식회사울트라",
    rn: 6860
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디엔(주)강진1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디엔(주)",
    rn: 6861
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디엔(주)강진2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스디엔(주)",
    rn: 6862
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성천안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "유한회사 대성천안태양광발전소",
    rn: 6863
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨문곡태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.979,
    company: "유한회사 제이씨문곡태양광",
    rn: 6864
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음천안",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.201,
    company: "(유)한마음천안태양광",
    rn: 6865
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엠솔라텍 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.269,
    company: "(주)에스엠솔라텍",
    rn: 6866
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이광쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 이광쏠라",
    rn: 6867
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "TFOI태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "티에프오인더스트리(주)",
    rn: 6868
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신광쏠라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 신광쏠라",
    rn: 6869
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수정발전소1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "수정발전소1",
    rn: 6870
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신풍리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "한국남부발전(주)",
    rn: 6871
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수정발전소2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "수정발전소2",
    rn: 6872
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수정발전소3 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "수정발전소3",
    rn: 6873
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가람태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "가람태양광발전소",
    rn: 6874
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아토태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "아토태양광발전소",
    rn: 6875
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이산태양광발전소",
    rn: 6876
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "미래 태양광 주식회사",
    rn: 6877
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현광1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현광1호 태양광발전소",
    rn: 6878
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "교래리 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "교래리 태양광발전소",
    rn: 6879
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여주태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "유한회사 여주대신태양광",
    rn: 6880
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 여주대신태양광",
    rn: 6881
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대흘노루왓1호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "대흘노루왓1호 발전소",
    rn: 6882
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "㈜호원오토 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 호원오토",
    rn: 6883
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉개동주민대책위원회2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.793,
    company: "봉개동 쓰레기매립장 주민대책위원회",
    rn: 6884
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 3호태양광발전소a",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원2018 3호태양광발전소",
    rn: 6885
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원2018 2호태양광발전소",
    rn: 6886
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라파워1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라일호 주식회사",
    rn: 6887
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이파워1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라일호 주식회사",
    rn: 6888
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지파워1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라일호 주식회사",
    rn: 6889
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑솔라에너지 (유) 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "탑솔라에너지(유)",
    rn: 6890
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)가림에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.953,
    company: "(주) 가림에너지",
    rn: 6891
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목사동에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "주식회사목사동에너지",
    rn: 6892
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로8 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.418,
    company: "주식회사 비즌비츠로8",
    rn: 6893
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로4 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.48,
    company: "주식회사 비즌비츠로4",
    rn: 6894
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로3 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.48,
    company: "주식회사 비즌비츠로3",
    rn: 6895
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로5 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.48,
    company: "주식회사 비즌비츠로5",
    rn: 6896
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로6 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.48,
    company: "주식회사 비즌비츠로6",
    rn: 6897
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로7 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.273,
    company: "주식회사 비즌비츠로7",
    rn: 6898
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로9 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.371,
    company: "주식회사 비즌비츠로9",
    rn: 6899
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로10 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.066,
    company: "주식회사 비즌비츠로10",
    rn: 6900
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로13 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.504,
    company: "주식회사 비즌비츠로13",
    rn: 6901
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사랑말태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "사랑말 태양광 발전소",
    rn: 6902
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에스에이치6태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 이에스에이치6",
    rn: 6903
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스오 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.987,
    company: "티에스오주식회사",
    rn: 6904
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스오2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.454,
    company: "티에스오주식회사",
    rn: 6905
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)비즌비츠로1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.48,
    company: "주식회사 비즌비츠로1",
    rn: 6906
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)아빈 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "주식회사 아빈",
    rn: 6907
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알티무안1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 알티태양광무안1호",
    rn: 6908
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알티무안2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 알티태양광무안2호",
    rn: 6909
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알티무안3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 알티태양광무안3호",
    rn: 6910
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알티무안4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 알티태양광무안4호",
    rn: 6911
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태광에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 태광에너지",
    rn: 6912
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서촌 성창태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.032,
    company: "서촌 성창태양광발전소",
    rn: 6913
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동서태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "동서태양광발전소",
    rn: 6914
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉영솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.799,
    company: "주식회사 봉영솔라",
    rn: 6915
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수망태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한국수력원자력(주)",
    rn: 6916
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평대태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.695,
    company: "한국수력원자력(주)",
    rn: 6917
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미리내태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.938,
    company: "한국수력원자력(주)",
    rn: 6918
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "표선태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.918,
    company: "한국수력원자력(주)",
    rn: 6919
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "갈랩1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "광진쏠라 주식회사",
    rn: 6920
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지엘1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.799,
    company: "은성쏠라 주식회사",
    rn: 6921
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한회사 민채하동1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 민채하동1호",
    rn: 6922
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "재호 태양광발전소",
    rn: 6923
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "광진 태양광발전소",
    rn: 6924
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민석2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "민석2태양광발전소",
    rn: 6925
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영란2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "영란2태양광발전소",
    rn: 6926
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승세2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "승세2태양광발전소",
    rn: 6927
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영희1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영희1태양광발전소",
    rn: 6928
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "아폴로2호",
    rn: 6929
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 케이솔라",
    rn: 6930
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)삼원에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 삼원에너지",
    rn: 6931
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼호솔라태양광발전기(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.693,
    company: "유한회사 삼호솔라",
    rn: 6932
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성&준태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "성&준태양광발전소",
    rn: 6933
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고려오트론(주) 1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.19,
    company: "고려오트론주식회사 장성지점",
    rn: 6934
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용금2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "용금2호 태양광발전소",
    rn: 6935
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사랑태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "사랑태양광발전소",
    rn: 6936
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이목 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.347,
    company: "이목 태양광발전소",
    rn: 6937
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참솔태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "참솔 태양광발전소",
    rn: 6938
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한몰드(주) 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.243,
    company: "대한몰드 주식회사",
    rn: 6939
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)태원에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "유한회사 태원에너지",
    rn: 6940
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬리치태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "썬리치태양광발전소",
    rn: 6941
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래농산 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.97,
    company: "농업회사법인 미래농산주식회사",
    rn: 6942
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래농산 5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.97,
    company: "농업회사법인 미래농산주식회사",
    rn: 6943
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진송2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "진송2 태양광발전소",
    rn: 6944
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디와이파워창원공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.606,
    company: "전북에너지서비스주식회사",
    rn: 6945
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "종원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "종원 태양광발전소",
    rn: 6946
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백석이엔지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "백석이엔지(주)",
    rn: 6947
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "설후이엔지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "주식회사 설후이엔지",
    rn: 6948
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬웨이브태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "(주)파사트",
    rn: 6949
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)안다미로제일발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.97,
    company: "(주)안다미로 제일발전소",
    rn: 6950
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑솔라여주1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.499,
    company: "주식회사 탑솔라여주1",
    rn: 6951
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광덕태양광1호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.382,
    company: "광덕태양광1호 발전소",
    rn: 6952
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 남정수상태양광운영 2호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.009,
    company: "주식회사 남정수상태양광운영",
    rn: 6953
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "돌모로 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.669,
    company: "주식회사 돌모로",
    rn: 6954
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신인천발전본부 주차장 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.603,
    company: "한국남부발전(주)",
    rn: 6955
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하가일공삼 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "하가일공삼 태양광발전소",
    rn: 6956
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포미 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.27,
    company: "포미 태양광발전소",
    rn: 6957
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "갈고개태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "갈고개 태양광발전소",
    rn: 6958
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옥산1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한국동서발전(주)",
    rn: 6959
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옥산3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "한국동서발전(주)",
    rn: 6960
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남그린1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 동해그린에너지",
    rn: 6961
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치엔씨에너지창평태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.848,
    company: "(주)에이치엔씨에너지창평",
    rn: 6962
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김제토탈11호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "김제토탈11호태양광",
    rn: 6963
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김제토탈12호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "김제토탈12호태양광",
    rn: 6964
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김제토탈16호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "김제토탈16호태양광",
    rn: 6965
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김제토탈17호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "김제토탈17호태양광",
    rn: 6966
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김제토탈13호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "김제토탈13호태양광",
    rn: 6967
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김제토탈10호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "김제토탈10호태양광",
    rn: 6968
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "토탈23호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "토탈23호태양광발전소",
    rn: 6969
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "방곡태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.593,
    company: "주식회사 방곡태양광발전소",
    rn: 6970
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)칠곡지천발전소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.856,
    company: "(주)칠곡지천발전소",
    rn: 6971
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태웅 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "태웅 태양광 발전소",
    rn: 6972
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강원에스티태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.137,
    company: "주식회사 강원에스티",
    rn: 6973
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)현민솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.099,
    company: "주식회사 현민솔라",
    rn: 6974
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은혜 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "은혜 태양광발전소",
    rn: 6975
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한회사 에스케이3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.948,
    company: "유한회사 에스케이3호태양광",
    rn: 6976
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)원더풀태양광발전소덕천리2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.72,
    company: "주식회사 원더풀태양광발전소덕천리2호",
    rn: 6977
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)피노 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "주식회사 피노",
    rn: 6978
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혁신 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "혁신태양광발전소",
    rn: 6979
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대창 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "상주황금소 영농조합법인",
    rn: 6980
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청담 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.218,
    company: "청담 태양광발전소",
    rn: 6981
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미카엘라협동조합",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "미카엘라협동조합",
    rn: 6982
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해마루7호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.928,
    company: "유한회사해마루",
    rn: 6983
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.18,
    company: "주식회사 하늘이엔지",
    rn: 6984
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영도 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.196,
    company: "영도 태양광발전소",
    rn: 6985
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다복태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "다복태양광발전소",
    rn: 6986
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미자태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "미자발전소",
    rn: 6987
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월림산전1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "월림산전1호 태양광발전소",
    rn: 6988
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월림산전2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "월림산전2호 태양광발전소",
    rn: 6989
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우리 태양광발전소",
    rn: 6990
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태백하늘빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 태백하늘빛",
    rn: 6991
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진그린3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 동해그린에너지",
    rn: 6992
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진그린2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 동해그린에너지",
    rn: 6993
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강진그린1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 동해그린에너지",
    rn: 6994
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 무진솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "주식회사 무진솔라",
    rn: 6995
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "(재)울산테크노파크 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 1.498,
    company: "(재)울산테크노파크",
    rn: 6996
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔뱅 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "솔뱅 태양광발전소",
    rn: 6997
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)원호라원에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "유한회사 원호",
    rn: 6998
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "운암에너지 발전소 1호",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.2,
    company: "서울N.E.T.(주)양주지점",
    rn: 6999
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "안정태양광발전소",
    rn: 7000
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "찬기태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "찬기 태양광발전소",
    rn: 7001
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.196,
    company: "미래 태양광발전소",
    rn: 7002
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SUPEX 9W 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.856,
    company: "전남도시가스(주)",
    rn: 7003
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "곱은달 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "곱은달태양광발전소",
    rn: 7004
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동부소라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "동부전기기술단 (주)",
    rn: 7005
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동부소라2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "동부소라2호 태양광발전소",
    rn: 7006
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재은소라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.196,
    company: "재은소라1호 태양광발전소",
    rn: 7007
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "소라1호 태양광발전소",
    rn: 7008
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복동태양광발전소 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.147,
    company: "복동태양광발전소",
    rn: 7009
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 신봉태양광발전소",
    rn: 7010
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조주암태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "조주암태양광발전소",
    rn: 7011
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세종 디엠솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.607,
    company: "주식회사 디엠솔라",
    rn: 7012
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "훈풍태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.849,
    company: "주식회사 훈풍일렉트릭",
    rn: 7013
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "훈풍태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.892,
    company: "주식회사 훈풍일렉트릭",
    rn: 7014
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "축 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "축 발전소",
    rn: 7015
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "썬 발전소",
    rn: 7016
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다인태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.828,
    company: "다인태양광발전소 (주)",
    rn: 7017
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "효성1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.003,
    company: "효성이노텍 주식회사",
    rn: 7018
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여주걸은1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.907,
    company: "여주걸은1호 태양광발전소",
    rn: 7019
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동송2호태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동송2호 태양광발전소",
    rn: 7020
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동송3호태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동송3호 태양광발전소",
    rn: 7021
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.526,
    company: "주식회사 안송전기",
    rn: 7022
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)탑에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.197,
    company: "주식회사 탑에너지",
    rn: 7023
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스트1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "주식회사 코윈파워",
    rn: 7024
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해안동1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.404,
    company: "주식회사 와이지에너지 2호",
    rn: 7025
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참다리발전소 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "참다리태양광발전소",
    rn: 7026
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홈다리발전소 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "홈다리태양광발전소",
    rn: 7027
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "그린태양광발전소",
    rn: 7028
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "한빛 태양광발전소",
    rn: 7029
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "박말정1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.468,
    company: "박말정1호태양광발전소",
    rn: 7030
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라에쿼티코리아005 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "솔라에쿼티코리아005 주식회사",
    rn: 7031
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영남 태양광발전소 4호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "솔라에쿼티정읍025 유한회사",
    rn: 7032
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "목포MBC태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "목포문화방송(주)",
    rn: 7033
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아산신재생복합발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6.929,
    company: "주식회사 아산신재생복합발전",
    rn: 7034
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무들태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 무들태양광",
    rn: 7035
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태창솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.214,
    company: "주식회사 태창솔라",
    rn: 7036
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태창솔라2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.177,
    company: "주식회사 태창솔라",
    rn: 7037
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일신2호 SOLAR",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "일신2호태양광발전소 주식회사",
    rn: 7038
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "Gridsol청주1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "엘에스산전(주)",
    rn: 7039
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백의리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.264,
    company: "주식회사 수성파워",
    rn: 7040
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "허브태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "허브태양광발전소",
    rn: 7041
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산리1호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "고산리1호에너지",
    rn: 7042
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고산리2호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "고산리2호에너지",
    rn: 7043
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더우진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "더우진태양광발전소",
    rn: 7044
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원옥곤태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "도원옥곤태양광발전소",
    rn: 7045
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더유건태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "유한회사 더바른",
    rn: 7046
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더규하태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "더규하태양광발전소",
    rn: 7047
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운두태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "운두 태양광발전소",
    rn: 7048
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복골태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "복골 태양광발전소",
    rn: 7049
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 문산솔라",
    rn: 7050
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛일렉트릭태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 한빛일렉트릭",
    rn: 7051
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)NKG인천제1태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.818,
    company: "(주) NKG",
    rn: 7052
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지원 태양광발전소",
    rn: 7053
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대덕3호발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(주)대덕3호태양광발전소",
    rn: 7054
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대덕4호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(주)대덕4호 태양광발전소",
    rn: 7055
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#12 비즌비츠로태양광1",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 7056
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YES BIDC 부산 메인동 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.984,
    company: "영남에너지서비스(주)",
    rn: 7057
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YES BIDC 부산 부속동 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.306,
    company: "영남에너지서비스(주)",
    rn: 7058
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코원이에스햇빛(창원)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.519,
    company: "코원에너지서비스(주)",
    rn: 7059
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계화5발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "한국농어촌공사 부안지사",
    rn: 7060
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계화6발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "한국농어촌공사 부안지사",
    rn: 7061
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래아이앤지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 미래아이앤지",
    rn: 7062
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스카이1000 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 스카이태양광",
    rn: 7063
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복안수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.51,
    company: "한국농어촌공사 울산지사",
    rn: 7064
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산성1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 산성1호",
    rn: 7065
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더존태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "유한회사 더존",
    rn: 7066
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코오롱 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.217,
    company: "코오롱글로텍 (주) 구미공장",
    rn: 7067
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명희태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "명희태양광발전소",
    rn: 7068
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석환2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "석환2호 태양광발전소",
    rn: 7069
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송란 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "송란 태양광발전소",
    rn: 7070
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영광 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영광 태양광발전소",
    rn: 7071
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "귀뚜라미 평택 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "(주)귀뚜라미에너지",
    rn: 7072
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "귀뚜라미 평택 태양광발전소 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)귀뚜라미에너지",
    rn: 7073
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)아성다이소 부산허브센터",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.81,
    company: "주식회사 아성다이소",
    rn: 7074
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "노원소각열발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 9.5,
    company: "(주) 노원뉴파워",
    rn: 7075
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)사나래 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.482,
    company: "(유) 사나래",
    rn: 7076
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이13호 영주",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.376,
    company: "피브이플랜트십삼호(주)",
    rn: 7077
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명도암마을회 2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명도암마을회태양광발전소",
    rn: 7078
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "북촌서모풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 3,
    company: "주식회사 북촌서모풍력",
    rn: 7079
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용당리사무소 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.021,
    company: "용당리마을회",
    rn: 7080
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔길태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 솔길태양광",
    rn: 7081
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥스에너지 안계 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 맥스에너지7호",
    rn: 7082
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용진 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 엠케이태양광",
    rn: 7083
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)남정수상태양광",
    rn: 7084
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해뜰날 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해뜰날솔라에너지",
    rn: 7085
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "길태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "길토백이",
    rn: 7086
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "6호광장 EV충전스테이션",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.085,
    company: "제주도청",
    rn: 7087
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정남염산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 정남염산태양광발전소",
    rn: 7088
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정숙염산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 정숙염산태양광발전소",
    rn: 7089
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성환염산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 성환염산태양광발전소",
    rn: 7090
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스와이에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "주식회사 에스와이에너지",
    rn: 7091
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "휴먼영광에너지3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "(주)휴먼영광에너지3호",
    rn: 7092
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "명성환경 소각 폐열 발전소",
    cent: "비중앙",
    fuel: "기타",
    pcap: 3.1,
    company: "주식회사 명성환경",
    rn: 7093
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)아나톨1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "유한회사 아나톨",
    rn: 7094
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)아나톨2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "유한회사 아나톨",
    rn: 7095
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "GSL태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.979,
    company: "GSL태양광발전소",
    rn: 7096
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용수리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.889,
    company: "한국남부발전(주)",
    rn: 7097
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만도익산공장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3,
    company: "전북에너지서비스주식회사",
    rn: 7098
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성수1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "주식회사 성수태양광발전소",
    rn: 7099
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오엠시(신안)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.44,
    company: "주식회사 오엠시솔라에너지",
    rn: 7100
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오성안동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.792,
    company: "오성신재생에너지 주식회사",
    rn: 7101
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상하태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "상하 태양광발전소",
    rn: 7102
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보람태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "보람 태양광발전소",
    rn: 7103
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금동태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.389,
    company: "금동 태양광발전소",
    rn: 7104
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "창원 연료전지 발전기",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "창원에너파크 주식회사",
    rn: 7105
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "구미 연료전지 발전기",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 2.4,
    company: "창원에너파크(주) 구미지점",
    rn: 7106
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해오름 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.121,
    company: "해오름 태양광발전소",
    rn: 7107
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.121,
    company: "태양1호 태양광발전소",
    rn: 7108
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)동호쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.801,
    company: "(주) 동호쏠라",
    rn: 7109
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)동성에너텍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.801,
    company: "주식회사 동성에너텍",
    rn: 7110
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)장쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.799,
    company: "주식회사 장쏠라",
    rn: 7111
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 서인",
    rn: 7112
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.925,
    company: "주식회사 진성개발",
    rn: 7113
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.048,
    company: "유한회사 한빛에너지",
    rn: 7114
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.797,
    company: "센트럴태양광발전소3호주식회사",
    rn: 7115
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "삼진 태양광발전소",
    rn: 7116
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스트태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "베스트 태양광발전소",
    rn: 7117
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아란태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 아란태양광",
    rn: 7118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.488,
    company: "주식회사 수리태양광",
    rn: 7119
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자등태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 자등태양광발전소",
    rn: 7120
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원리마을회 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원리마을회 태양광발전소",
    rn: 7121
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남천태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "남천태양광 1호",
    rn: 7122
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남천태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "남천태양광 2호",
    rn: 7123
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "왕진제2햇빛태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "농업회사법인 주식회사 왕진",
    rn: 7124
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "람세스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "람세스 태양광발전소",
    rn: 7125
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "BSP태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.434,
    company: "BSP태양광발전소",
    rn: 7126
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상주현대태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 상주현대태양광발전소",
    rn: 7127
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미르태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.174,
    company: "주식회사 미르태양광",
    rn: 7128
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소수태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.144,
    company: "주식회사 소수태양광",
    rn: 7129
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라온태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.712,
    company: "주식회사 라온태양광",
    rn: 7130
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마루태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.727,
    company: "주식회사 마루태양광",
    rn: 7131
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백야에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.397,
    company: "주식회사 백야에너지",
    rn: 7132
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이앤씨 옥스포드 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이앤씨옥스포드태양광(주)",
    rn: 7133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래와희망 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "미래와희망발전소 주식회사",
    rn: 7134
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "강동연료전지 #1",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.8,
    company: "파주에너지서비스 주식회사",
    rn: 7135
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태천에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.219,
    company: "주식회사 태천에너지",
    rn: 7136
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장감솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.81,
    company: "주식회사 장감솔라",
    rn: 7137
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "심포솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.723,
    company: "(주) 심포솔라",
    rn: 7138
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신추에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.3,
    company: "주식회사 신추에너지",
    rn: 7139
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성소솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.271,
    company: "주식회사 성소솔라",
    rn: 7140
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉리에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.671,
    company: "(주)봉리에너지",
    rn: 7141
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉동솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.81,
    company: "주식회사 봉동솔라",
    rn: 7142
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희정태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "희정태양광발전소",
    rn: 7143
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천외서면발전소3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 순천외서면발전소",
    rn: 7144
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진드르태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.166,
    company: "진드르 태양광발전소",
    rn: 7145
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(SUPEX12) 포스코인터네셔널#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "전남도시가스(주)",
    rn: 7146
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문무리 햇들원 시범단지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.136,
    company: "지에스건설 (주)",
    rn: 7147
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한에너지 제2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)대한에너지제2호",
    rn: 7148
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한에너지 8호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 대한에너지8호",
    rn: 7149
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.804,
    company: "주식회사 영우",
    rn: 7150
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 지에스피브이4호_태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 지에스피브이4호",
    rn: 7151
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(SUPEX11) 밸런스인더스트리",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "전남도시가스(주)",
    rn: 7152
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안심태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "안심태양광발전소",
    rn: 7153
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "태양3호 태양광발전소",
    rn: 7154
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태현전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "태현전력",
    rn: 7155
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)지에스피브이앤이군산_태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주)지에스피브이앤이군산",
    rn: 7156
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디바이오2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.949,
    company: "에스디바이오(주) 강진",
    rn: 7157
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(SUPEX13) 포스코인터내셔널#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.788,
    company: "전남도시가스(주)",
    rn: 7158
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코원이에스햇빛(대전)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.571,
    company: "코원에너지서비스(주)",
    rn: 7159
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남해에너지2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.754,
    company: "남해에너지2호 태양광발전소",
    rn: 7160
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남해에너지3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.606,
    company: "남해에너지3호 태양광발전소",
    rn: 7161
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 드림의령에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 드림의령에너지",
    rn: 7162
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 에스와이에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 에스와이에너지",
    rn: 7163
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 아띠솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 아띠솔라",
    rn: 7164
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 부원에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 부원에너지",
    rn: 7165
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 하리에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.005,
    company: "주식회사 하리에너지",
    rn: 7166
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 탕정에너지(구역)(예비)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 7.3,
    company: "제이비 주식회사 탕정에너지(구역)",
    rn: 7167
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우진 태양광발전소",
    rn: 7168
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정원 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정원태양광발전소",
    rn: 7169
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정선2호 태양광발전소",
    rn: 7170
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스포17-2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.401,
    company: "(주)넥스포 에너지",
    rn: 7171
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스포17-4태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.685,
    company: "(주)넥스포 에너지",
    rn: 7172
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)아리랑에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 아리랑에너지",
    rn: 7173
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)한빛에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 한빛에너지",
    rn: 7174
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)산다라에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 산다라에너지",
    rn: 7175
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)다정에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 다정에너지",
    rn: 7176
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)노들에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 노들에너지",
    rn: 7177
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)나비잠에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 나비잠에너지",
    rn: 7178
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)고은에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 고은에너지",
    rn: 7179
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)어진에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 어진에너지",
    rn: 7180
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)꽃잠에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 꽃잠에너지",
    rn: 7181
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)은가비에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 은가비에너지",
    rn: 7182
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)지니에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 지니에너지",
    rn: 7183
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)나무에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 나무에너지",
    rn: 7184
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)토리에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 토리에너지",
    rn: 7185
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홍성에그원 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "홍성에그원 태양광발전소",
    rn: 7186
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마거솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 마거솔라",
    rn: 7187
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예안 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "예안태양광발전소",
    rn: 7188
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반뎅이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "반뎅이 태양광발전소",
    rn: 7189
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스포17-1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.064,
    company: "(주)넥스포 쏠라",
    rn: 7190
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스포17-3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.853,
    company: "(주)넥스포 쏠라",
    rn: 7191
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대중공업그린에너지(주)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.455,
    company: "현대에너지솔루션(주)",
    rn: 7192
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "린주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "린주태양광발전소",
    rn: 7193
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정안 Roof-top 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.494,
    company: "정안에너지 주식회사",
    rn: 7194
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산정관 #2 (예비)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 50.15,
    company: "부산정관에너지(구역)",
    rn: 7195
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "부산정관 #2 (구매 예비)",
    cent: "중앙",
    fuel: "LNG",
    pcap: 50.15,
    company: "부산정관에너지(구역)(구매)",
    rn: 7196
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월림산전3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "월림산전3호 태양광발전소",
    rn: 7197
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "엠제이에너지2 ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 2,
    company: "(주)엠제이에너지",
    rn: 7198
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진윈텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.164,
    company: "부산산업태양광 주식회사",
    rn: 7199
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정석2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정석2호태양광발전소",
    rn: 7200
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동선2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동선2호 태양광발전소",
    rn: 7201
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "효성 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "효성 태양광발전소",
    rn: 7202
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오사설화3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.758,
    company: "에스와이 태양광발전",
    rn: 7203
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지암PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지암태양광발전소",
    rn: 7204
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼양 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.012,
    company: "삼양 태양광발전소",
    rn: 7205
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신도태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.018,
    company: "신도태양광발전소",
    rn: 7206
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "저지리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.03,
    company: "저지리 태양광발전소",
    rn: 7207
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서진1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 서진인쇄지기",
    rn: 7208
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "대산바이오매스발전소",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 100,
    company: "씨지앤대산전력(주)",
    rn: 7209
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 쏠라파크",
    rn: 7210
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 쏠라파크",
    rn: 7211
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 쏠라파크",
    rn: 7212
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 대명쏠라에너지",
    rn: 7213
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 대명쏠라에너지",
    rn: 7214
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 대명쏠라에너지",
    rn: 7215
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대명솔라8호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 대명썬에너지",
    rn: 7216
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지암8호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지암8호태양광발전소",
    rn: 7217
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 5호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원2018 5호 태양광발전소",
    rn: 7218
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대운 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대운 태양광발전소",
    rn: 7219
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보물 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "보물 태양광발전소",
    rn: 7220
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가암태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "가암 태양광 발전소",
    rn: 7221
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)무안햇빛발전소 PV [20MW]",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 19.956,
    company: "주식회사 무안햇빛발전소",
    rn: 7222
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)무안햇빛발전소 PV [13MW]",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 13.022,
    company: "주식회사 무안햇빛발전소",
    rn: 7223
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복사골발전소 1 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.435,
    company: "(주) 틸에너지",
    rn: 7224
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복사골발전소 2 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.435,
    company: "(주) 틸에너지",
    rn: 7225
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "칼트로지스 부산신항 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.737,
    company: "(주)부산도시가스",
    rn: 7226
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "골든1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "골든에너지",
    rn: 7227
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복도시발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.273,
    company: "행복도시발전소 주식회사",
    rn: 7228
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행복도시발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.102,
    company: "행복도시발전소 주식회사",
    rn: 7229
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "차탄리 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "차탄태양광발전소",
    rn: 7230
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선흘리 나눔에너지태양광 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.379,
    company: "주식회사 나눔에너지발전소",
    rn: 7231
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은성쏠라15호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 지오쏠라",
    rn: 7232
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "연수태양광발전소",
    rn: 7233
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무등산에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.149,
    company: "동광레저개발 (주)",
    rn: 7234
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "10호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주)제주감귤태양광",
    rn: 7235
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "54호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.248,
    company: "(주)제주감귤태양광",
    rn: 7236
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "55호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)제주감귤태양광",
    rn: 7237
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "70-1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주)제주감귤태양광",
    rn: 7238
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "70호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주)제주감귤태양광",
    rn: 7239
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "104호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)제주감귤태양광",
    rn: 7240
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "112-1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.786,
    company: "(주)제주감귤태양광",
    rn: 7241
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "112-2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.808,
    company: "(주)제주감귤태양광",
    rn: 7242
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "112-3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.735,
    company: "(주)제주감귤태양광",
    rn: 7243
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "113호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.83,
    company: "(주)제주감귤태양광",
    rn: 7244
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "131-1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "(주)제주감귤태양광",
    rn: 7245
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "162호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)제주감귤태양광",
    rn: 7246
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "193호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.357,
    company: "(주)제주감귤태양광",
    rn: 7247
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "194호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.67,
    company: "(주)제주감귤태양광",
    rn: 7248
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SA 3기 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.977,
    company: "영진철강 (주)",
    rn: 7249
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라26호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 와이쏠라",
    rn: 7250
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진양에스엔지4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 와이쏠라",
    rn: 7251
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광진쏠라11호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 시오쏠라",
    rn: 7252
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이와이에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 시오쏠라",
    rn: 7253
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가화태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(주)가화태양광발전",
    rn: 7254
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "충화태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(주)충화태양광발전",
    rn: 7255
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "한빛 태양광발전소",
    rn: 7256
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제우에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "제우에너지",
    rn: 7257
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신생썬 에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.294,
    company: "신생썬 에너지",
    rn: 7258
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부미태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.741,
    company: "부미태양광발전소",
    rn: 7259
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "일우태양광발전소",
    rn: 7260
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "우리발전소(주)",
    rn: 7261
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여명태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "여명태양광발전소(주)",
    rn: 7262
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 7263
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엔트로피발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 7264
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대영발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "대영발전소(주)",
    rn: 7265
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가나안 1호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "가나안1호 태양광발전소",
    rn: 7266
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가나안 2호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "가나안2호태양광발전소 ",
    rn: 7267
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가나안 3호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "가나안3호태양광발전소",
    rn: 7268
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지암9호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지암9호태양광발전소",
    rn: 7269
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참조은1호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "참조은1호 태양광발전소",
    rn: 7270
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참조은2호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "참조은2호태양광발전소",
    rn: 7271
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참조은3호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "참조은3호태양광발전소",
    rn: 7272
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현광2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "현광2호 태양광발전소",
    rn: 7273
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "화력",
    genNm: "JB(주) 탕정에너지(구매)(예비)",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 7.3,
    company: "제이비 주식회사 탕정에너지(구매)",
    rn: 7274
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "대산파워",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 24.9,
    company: "주식회사대산파워",
    rn: 7275
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주) 드림솔라",
    rn: 7276
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "신서천화력소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 2.586,
    company: "한국중부발전(주)",
    rn: 7277
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "효일2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "효일2호 태양광발전소",
    rn: 7278
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행운1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "행운1 태양광발전소",
    rn: 7279
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행운2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "행운2 태양광발전소",
    rn: 7280
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "무릉이태양광발전소",
    rn: 7281
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진신행정동옥상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.896,
    company: "한국동서발전(주)",
    rn: 7282
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진제1회처리장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 24.952,
    company: "한국동서발전(주)",
    rn: 7283
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산에너지2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "(유)안산에너지2호태양광발전소",
    rn: 7284
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안산에너지3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "(유)안산에너지3호태양광발전소",
    rn: 7285
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대정2PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대정2태양광발전소",
    rn: 7286
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼일제2호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "삼일제2호태양광발전소",
    rn: 7287
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.984,
    company: "주식회사 천지",
    rn: 7288
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "강변하수처리장 소화가스 발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 1.4,
    company: "주식회사 라온발전사",
    rn: 7289
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해6호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.664,
    company: "주식회사 압해발전소6호 ",
    rn: 7290
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "납읍햇빛에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.686,
    company: "유한회사 납읍햇빛에너지",
    rn: 7291
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스디이에스에스2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.977,
    company: "에스디엔이에스에스(주) 강진",
    rn: 7292
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨문곡2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.68,
    company: "(유)선그린태백태양광",
    rn: 7293
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양3호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.919,
    company: "(주) 우림",
    rn: 7294
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산성2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 산성2호",
    rn: 7295
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "나리 태양광발전소",
    rn: 7296
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "판리2호태양광_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유한회사 장판신에너지",
    rn: 7297
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "폐기물 에너지화시설 여열 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 9.9,
    company: "지에스건설 (주)",
    rn: 7298
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코솔라향남",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.473,
    company: "에코이앤오 주식회사",
    rn: 7299
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코솔라정남",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.473,
    company: "에코이앤오 주식회사",
    rn: 7300
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코솔라남양",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.367,
    company: "에코이앤오 주식회사",
    rn: 7301
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영암태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 94.082,
    company: "영암태양광발전 주식회사",
    rn: 7302
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.02,
    company: "기정 태양광발전소",
    rn: 7303
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조수태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.015,
    company: "조수태양광발전소",
    rn: 7304
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이앤씨태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.378,
    company: "삼환전기 (유)",
    rn: 7305
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화인9호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "(주)파인이엠텍",
    rn: 7306
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치아이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.635,
    company: "(주) 에이치아이에너지",
    rn: 7307
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라카우고창011 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "솔라카우고창011 유한회사",
    rn: 7308
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조은자동차병원",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.019,
    company: "조은자동차병원",
    rn: 7309
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인제두바이2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "인제두바이2 태양광발전소",
    rn: 7310
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홍익홍성",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "주식회사 홍익홍성발전소",
    rn: 7311
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연세발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 연세발전소",
    rn: 7312
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크예천21호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해테크발전소 충주1호",
    rn: 7313
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크 예천24호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해테크솔라클럽",
    rn: 7314
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크예천3호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "해테크예천3호",
    rn: 7315
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.664,
    company: "대장태양광 주식회사",
    rn: 7316
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가희산업 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.205,
    company: "가희산업 주식회사",
    rn: 7317
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "주식회사 태찬테크",
    rn: 7318
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "주식회사 태찬테크",
    rn: 7319
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "주식회사 태찬테크",
    rn: 7320
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.046,
    company: "주식회사 태찬테크",
    rn: 7321
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광덕태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.454,
    company: "(주)광덕태양광발전소",
    rn: 7322
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강정마을회 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "강정마을회 태양광발전소",
    rn: 7323
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "차탄태양광발전(주)차탄태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "차탄태양광발전 주식회사",
    rn: 7324
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해피태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.016,
    company: "해피태양광발전소",
    rn: 7325
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지민태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.208,
    company: "지민 태양광발전소",
    rn: 7326
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안 삼양태양광 발전사업",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 16.997,
    company: "삼양태양광 주식회사",
    rn: 7327
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "킴스1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.059,
    company: "킴스1호 태양광발전소",
    rn: 7328
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠에스1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.147,
    company: "엠에스파워 주식회사",
    rn: 7329
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "속초바이오가스발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.845,
    company: "(주)지엔씨에너지",
    rn: 7330
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "동해연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 15,
    company: "한국동서발전(주)",
    rn: 7331
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.659,
    company: "한국수력원자력(주)",
    rn: 7332
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.709,
    company: "한국수력원자력(주)",
    rn: 7333
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "애월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.65,
    company: "한국수력원자력(주)",
    rn: 7334
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.749,
    company: "한국수력원자력(주)",
    rn: 7335
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오름제주",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.539,
    company: "한국수력원자력(주)",
    rn: 7336
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탐라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.723,
    company: "한국수력원자력(주)",
    rn: 7337
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.894,
    company: "한국수력원자력(주)",
    rn: 7338
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이플",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "솔라원일호 주식회사",
    rn: 7339
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "에스텍 주식회사",
    rn: 7340
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철도솔라6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "철도솔라 주식회사",
    rn: 7341
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SOLVIC 1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.038,
    company: "세방(주)",
    rn: 7342
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SOLVIC 3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.538,
    company: "세방(주)",
    rn: 7343
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크예천29호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "해테크의성18호",
    rn: 7344
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동명리 제주나눔에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.287,
    company: "제주나눔에너지 주식회사",
    rn: 7345
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한회사 빌드윈문경태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "유한회사 빌드윈문경태양광발전",
    rn: 7346
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주봉3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.859,
    company: "주식회사 주봉솔라",
    rn: 7347
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에스에이치팜 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.39,
    company: "케이솔라이호 주식회사",
    rn: 7348
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원남5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.023,
    company: "주식회사 원남솔라",
    rn: 7349
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "블루2호 홍천",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.83,
    company: "블루플래닛이호 주식회사",
    rn: 7350
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소봉태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "(주) 소봉",
    rn: 7351
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원효2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.576,
    company: "주식회사 원효2태양광",
    rn: 7352
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탁1호_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "탁1호태양광발전소",
    rn: 7353
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탁2호_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "탁2호태양광발전소",
    rn: 7354
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황금6호_PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(유)황금6호태양광발전소",
    rn: 7355
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대우 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "대우 태양광 발전소",
    rn: 7356
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌1호태양광발전소",
    rn: 7357
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌2호태양광발전소",
    rn: 7358
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌5호태양광발전소",
    rn: 7359
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌6호태양광발전소",
    rn: 7360
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌7호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌7호태양광발전소",
    rn: 7361
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌11호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌11호태양광발전소",
    rn: 7362
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌12호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌12호태양광발전소",
    rn: 7363
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌14호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌14호태양광발전소",
    rn: 7364
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌15호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌15호태양광발전소",
    rn: 7365
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌16호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌16호태양광발전소",
    rn: 7366
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌17호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌17호태양광발전소",
    rn: 7367
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌18호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌18호태양광발전소",
    rn: 7368
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌13호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 안좌13호태양광발전소",
    rn: 7369
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제네시스파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.709,
    company: "유한회사제네시스파워",
    rn: 7370
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무한태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 무한태양광발전소",
    rn: 7371
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그리드신파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 그리드신파워태양광발전소",
    rn: 7372
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그리드썬파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 그리드썬파워태양광발전소",
    rn: 7373
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신양태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.283,
    company: "신양태양광발전소1호",
    rn: 7374
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영익태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.396,
    company: "주식회사 영익",
    rn: 7375
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)아시아에너지서산태양광발전소PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주) 아시아에너지",
    rn: 7376
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥스에너지 근영 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 맥스에너지근영",
    rn: 7377
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥스에너지 고은 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 맥스에너지고은",
    rn: 7378
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)토교태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)토교태양광발전소",
    rn: 7379
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구정1 수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "한국농어촌공사 무안신안지사",
    rn: 7380
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구정2 수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "한국농어촌공사 무안신안지사",
    rn: 7381
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)무안햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 32.977,
    company: "주식회사 무안햇빛발전소",
    rn: 7382
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "재단법인하이산업",
    rn: 7383
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "논산연무대 태양광1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.843,
    company: "(주)논산연무대태양광발전소제1차",
    rn: 7384
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "논산연무대 태양광2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.945,
    company: "(주)논산연무대태양광발전소제2차",
    rn: 7385
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "논산연무대 태양광3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.648,
    company: "(주)논산연무대태양광발전소제3차",
    rn: 7386
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동명리 제주나눔에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.304,
    company: "제주나눔에너지 주식회사",
    rn: 7387
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전곡한빛1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "유한회사 지송",
    rn: 7388
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전곡한빛2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 전곡한빛2호 태양광발전소",
    rn: 7389
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건영솔라PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "플러스타임",
    rn: 7390
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "행원 태양광발전소",
    rn: 7391
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "태양2호 태양광발전소",
    rn: 7392
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동영햇빛발전1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "동영햇빛발전1호 태양광발전소",
    rn: 7393
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동영햇빛발전3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "동영햇빛발전3호 태양광발전소",
    rn: 7394
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래동연 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "미래동연 태양광발전소",
    rn: 7395
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제요동현",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제요동현태양광발전소",
    rn: 7396
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유민태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "유민 태양광발전소",
    rn: 7397
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성용태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "성용태양광발전소",
    rn: 7398
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주강 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.39,
    company: "주강 태양광발전소",
    rn: 7399
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아솔라텍 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 코리아솔라텍",
    rn: 7400
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코썬 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(유) 에코태양광발전",
    rn: 7401
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남원농협2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.159,
    company: "남원농업협동조합",
    rn: 7402
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준그린태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.336,
    company: "(주)준그린에너지",
    rn: 7403
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천그린1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 동해그린에너지",
    rn: 7404
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소망태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "소망태양광발전소",
    rn: 7405
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사랑태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "사랑 태양광발전소",
    rn: 7406
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "믿음태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "믿음태양광발전소",
    rn: 7407
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "준태양광발전소",
    rn: 7408
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양봉강발전소1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "주식회사 광양봉강발전소1호",
    rn: 7409
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양봉강한태연발전소2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "주식회사 광양봉강한태연발전소2호",
    rn: 7410
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양봉당리발전소3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "주식회사 광양봉당리발전소3호",
    rn: 7411
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.392,
    company: "솔라태양광발전소",
    rn: 7412
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)모록밭 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.489,
    company: "주식회사 모록밭",
    rn: 7413
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영태양광3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영태양광3호",
    rn: 7414
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제요리후 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "제요리후 태양광발전소",
    rn: 7415
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천그린 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 동해그린에너지",
    rn: 7416
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천그린3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 동해그린에너지",
    rn: 7417
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천그린4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 동해그린에너지",
    rn: 7418
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천그린5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 동해그린에너지",
    rn: 7419
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천그린6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 동해그린에너지",
    rn: 7420
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순천그린7호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 동해그린에너지",
    rn: 7421
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "용 태양광발전소",
    rn: 7422
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "답곡1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "답곡1호 태양광발전소",
    rn: 7423
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용산1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "용산1.2태양광발전소",
    rn: 7424
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용산2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "용산1.2태양광발전소",
    rn: 7425
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더조은 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "더조은 태양광발전소",
    rn: 7426
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이화글로텍",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.964,
    company: "솔라로드3호 주식회사",
    rn: 7427
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "한려LFG",
    cent: "비중앙",
    fuel: "매립가스",
    pcap: 0.925,
    company: "(주) 한려에너지개발",
    rn: 7428
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고령한태연 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "주식회사 고령한태연",
    rn: 7429
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이티솔라2호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "(유)제이티에스앤씨",
    rn: 7430
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이티솔라3호PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 엠제이솔라",
    rn: 7431
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전진엔텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.109,
    company: "부산산업태양광 주식회사",
    rn: 7432
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천상에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.462,
    company: "주식회사 천상에너지",
    rn: 7433
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕년7호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.965,
    company: "주식회사 태려에너지",
    rn: 7434
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕년에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 덕년에너지",
    rn: 7435
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석성리제1태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "석성리제1태양광발전소",
    rn: 7436
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석성리제2태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "석성리제2호태양광발전소",
    rn: 7437
    },
    {
    area: "수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "화성동탄바이오가스발전기",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.495,
    company: "(주)지엔씨에너지",
    rn: 7438
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은미 태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "은미 태양광발전소",
    rn: 7439
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨제이 태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "씨제이 태양광발전소",
    rn: 7440
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "여수연료전지#1",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 9.68,
    company: "한국남동발전(주)",
    rn: 7441
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤영태양광발전소 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "윤영태양광발전소",
    rn: 7442
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영서화1호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "영서화 발전소1호",
    rn: 7443
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)스카이에너지 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.299,
    company: "(주)스카이에너지",
    rn: 7444
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "버섯나무태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "버섯나무태양광발전소",
    rn: 7445
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.594,
    company: "에스태양광발전소 주식회사",
    rn: 7446
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)일레에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "주식회사 일레에너지",
    rn: 7447
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)테엔에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 테엔에너지",
    rn: 7448
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)나인에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 나인에너지",
    rn: 7449
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)투혼에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 투혼에너지",
    rn: 7450
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주최강태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "우주최강태양광발전소",
    rn: 7451
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)NKG인천제2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.991,
    company: "(주) NKG",
    rn: 7452
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선원리3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.969,
    company: "(주) 해동이엔씨 제6호",
    rn: 7453
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화천1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 천혜발전소",
    rn: 7454
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화천2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.456,
    company: "주식회사 천혜발전소",
    rn: 7455
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화천3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.456,
    company: "주식회사 천혜발전소",
    rn: 7456
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우산태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우산태양광발전소",
    rn: 7457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태안2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 레아솔",
    rn: 7458
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서진에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 서진이엔지",
    rn: 7459
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "닥모루 2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.077,
    company: "닥모루2호태양광발전소",
    rn: 7460
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동부죽포1 태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "동부죽포1 태양광발전소",
    rn: 7461
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지에스6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "지에스(GS)6호 태양광",
    rn: 7462
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태근태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태근 태양광발전소",
    rn: 7463
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양순 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "양순 태양광발전소",
    rn: 7464
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서혜원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서혜원태양광발전",
    rn: 7465
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서지원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서지원 태양광발전소",
    rn: 7466
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서득원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서득원태양광발전소",
    rn: 7467
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서장석2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서장석2호태양광발전소",
    rn: 7468
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서장석1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "서장석1호태양광발전소",
    rn: 7469
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강용구1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강용구1호 태양광발전소",
    rn: 7470
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "손영란1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "손영란1호 태양광발전소",
    rn: 7471
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS태양전력2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "주식회사 서진인쇄지기",
    rn: 7472
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "군위 화산풍력 풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 11.55,
    company: "한국남동발전(주)",
    rn: 7473
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이화곤지암 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.481,
    company: "이화전기공업 (주)",
    rn: 7474
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안제4태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.903,
    company: "(주)신안제4태양광발전소",
    rn: 7475
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)경남1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(유)경남1호태양광발전소",
    rn: 7476
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 경도제1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.935,
    company: "주식회사 경도제1태양광발전소",
    rn: 7477
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 경도제2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.919,
    company: "주식회사 경도제2태양광발전소",
    rn: 7478
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 경도제3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.919,
    company: "주식회사 경도제3태양광발전소",
    rn: 7479
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무안솔라파크 PV #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 19.038,
    company: "주식회사 무안솔라파크",
    rn: 7480
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무안솔라파크 PV #2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.027,
    company: "주식회사 무안솔라파크",
    rn: 7481
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무안솔라파크 PV #3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.027,
    company: "주식회사 무안솔라파크",
    rn: 7482
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무안솔라파크 PV #4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.069,
    company: "주식회사 무안솔라파크",
    rn: 7483
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유당솔라(주) 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.989,
    company: "유당솔라 주식회사",
    rn: 7484
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "GS당진바이오매스 2호기",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 105,
    company: "GS EPS(주)",
    rn: 7485
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다옴1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "다옴1태양광발전소",
    rn: 7486
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치디에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에이치디에너지",
    rn: 7487
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감당1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.931,
    company: "(유)연우에너지3",
    rn: 7488
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "축복2호 태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "축복2호 태양광발전소",
    rn: 7489
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하초5호태양광 PV발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "하초5호 태양광발전소",
    rn: 7490
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동부죽포2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "동부죽포2태양광발전소",
    rn: 7491
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "군산그린 생활폐기물 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 6,
    company: "군산그린에너지센터(주)",
    rn: 7492
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하초4호태양광 PV발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "하초4호태양광발전소",
    rn: 7493
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순옥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "순옥 태양광발전소",
    rn: 7494
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명석태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "명석 태양광발전소",
    rn: 7495
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "인천연료전지 제1호기",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.8,
    company: "인천연료전지 주식회사",
    rn: 7496
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "죽포태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "죽포태양광발전소",
    rn: 7497
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명도에너지 각기태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "(주)명도에너지",
    rn: 7498
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛솔라파크#4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.687,
    company: "한국수력원자력(주)",
    rn: 7499
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월성본부태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.397,
    company: "한국수력원자력(주)",
    rn: 7500
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YNES대원강업태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.3,
    company: "영남에너지서비스(주)",
    rn: 7501
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "논산2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "논산 태양광발전소",
    rn: 7502
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지산태양광발전소1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "논산 태양광발전소",
    rn: 7503
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지산태양광발전소2호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "논산 태양광발전소",
    rn: 7504
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태승 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.149,
    company: "태승 태양광 발전소",
    rn: 7505
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송대상평6호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "송대상평6호 태양광발전소",
    rn: 7506
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감당2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "솔라에쿼티하동027 유한회사",
    rn: 7507
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감당3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "솔라에쿼티하동027 유한회사",
    rn: 7508
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강릉제일솔라5호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "강릉제일솔라5호",
    rn: 7509
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성도7호태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "성도7호태양광발전소",
    rn: 7510
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "명지 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 9.68,
    company: "(주)부산도시가스",
    rn: 7511
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태두태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "태두태양광발전소",
    rn: 7512
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태백가덕산풍력발전 1호기~6호기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 21.6,
    company: "태백가덕산풍력발전 주식회사",
    rn: 7513
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "도원1호태양광발전소",
    rn: 7514
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시땅 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "시땅 태양광발전소",
    rn: 7515
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)행암에너지일호 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.499,
    company: "(주)행암에너지일호",
    rn: 7516
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "고려아연복합가스터빈#1 ",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 85.5,
    company: "고려아연 (주) 온산제련소",
    rn: 7517
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "고려아연복합가스터빈#2",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 85.5,
    company: "고려아연 (주) 온산제련소",
    rn: 7518
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "고려아연복합스팀터빈#1",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 101.5,
    company: "고려아연 (주) 온산제련소",
    rn: 7519
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "길성태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "길성 태양광발전소",
    rn: 7520
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주양지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "제주양지태양광발전소",
    rn: 7521
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "초월태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 초월태양광",
    rn: 7522
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래에너지태천",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 티에스에너지25호",
    rn: 7523
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 티에스에너지25호",
    rn: 7524
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유니태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 티에스에너지25호",
    rn: 7525
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복사태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 복사태양광",
    rn: 7526
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "약조태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 약조태양광",
    rn: 7527
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 티에스에너지25호",
    rn: 7528
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양6호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "태양6호 태양광발전소",
    rn: 7529
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 티에스에너지25호",
    rn: 7530
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연화태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 연화태양광",
    rn: 7531
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 태이태양광",
    rn: 7532
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비파태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 비파태양광",
    rn: 7533
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지도태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 지도태양광",
    rn: 7534
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시문7태양광발전소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "시문7태양광발전소",
    rn: 7535
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "도내리1차",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 8.1,
    company: "파주에코에너지 (주)",
    rn: 7536
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 티에스에너지25호",
    rn: 7537
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 티에스에너지25호",
    rn: 7538
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한율태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 한율태양광",
    rn: 7539
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)한태연 제주2호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주) 한태연제주2호발전소",
    rn: 7540
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한태연 제주10호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.501,
    company: "주식회사 한태연제주10호발전소",
    rn: 7541
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한태연 제주8호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.501,
    company: "주식회사 한태연제주8호발전소",
    rn: 7542
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산하현 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "산하현 태양광발전소",
    rn: 7543
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티앤알1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "(주)티앤알코리아",
    rn: 7544
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티앤알2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.926,
    company: "티앤알2호 태양광발전소",
    rn: 7545
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거두해드림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.212,
    company: "춘천시청 (기후에너지과)",
    rn: 7546
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장감솔라4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "주식회사 장감솔라",
    rn: 7547
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.041,
    company: "신안태양광",
    rn: 7548
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에스10호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.908,
    company: "주식회사 디에스태양광발전소10호",
    rn: 7549
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스이1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.845,
    company: "주식회사 에스이플랜트일호",
    rn: 7550
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가리1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.386,
    company: "주식회사 에스엘에너지",
    rn: 7551
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가리 2호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.38,
    company: "서희발전에너지 주식회사",
    rn: 7552
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수망농림에너지원",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "수망농림에너지원",
    rn: 7553
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린11호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 리앤킴솔라",
    rn: 7554
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린10호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "초록빛에너지 유한회사",
    rn: 7555
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린9호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유한회사 승광쏠라",
    rn: 7556
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린8호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 고도에너지",
    rn: 7557
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린7호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유한회사 쏠라에너지",
    rn: 7558
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린6호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "유한회사 케이그린6호발전소",
    rn: 7559
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린5호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "케이그린5호 발전소",
    rn: 7560
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)더케이와이에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 더케이와이에너지",
    rn: 7561
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)더에이솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 더에이솔라",
    rn: 7562
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스 태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "제이에스 태양광발전소",
    rn: 7563
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)더파워에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 더파워에너지",
    rn: 7564
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)더에스엠에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 더에스엠에너지",
    rn: 7565
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "청산풍력발전기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 21.6,
    company: "청산풍력발전(주)",
    rn: 7566
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)태봉에너지 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 태봉에너지",
    rn: 7567
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)태봉에너지2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 태봉에너지",
    rn: 7568
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우성에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "우성에너지",
    rn: 7569
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)오케이에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 오케이에너지",
    rn: 7570
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)더케이에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 더케이에너지",
    rn: 7571
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)더썬에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 더썬에너지",
    rn: 7572
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)규은솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 규은솔라",
    rn: 7573
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)솔라테크 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 솔라테크",
    rn: 7574
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)미래로발전 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 미래로발전",
    rn: 7575
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)학장3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 학장3호태양광",
    rn: 7576
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)더골든에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 더골든에너지",
    rn: 7577
    },
    {
    area: "제주",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "어승생소수력발전소",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.287,
    company: "제주도청",
    rn: 7578
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "유한회사 금이태양광발전소",
    rn: 7579
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.03,
    company: "우주태양광발전소",
    rn: 7580
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지구에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.236,
    company: "주식회사 티에스에너지9호",
    rn: 7581
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스에너지9호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.998,
    company: "주식회사 티에스에너지9호",
    rn: 7582
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희성태양광PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 희성",
    rn: 7583
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월호솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.998,
    company: "(주)월호솔라",
    rn: 7584
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상정솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.794,
    company: "주식회사 상정솔라",
    rn: 7585
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감정솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.675,
    company: "주식회사 감정솔라",
    rn: 7586
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "태백가덕산풍력발전 7호기~12호기",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 21.6,
    company: "태백가덕산풍력발전 주식회사",
    rn: 7587
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘에스엠 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "엘에스엠 태양광발전소",
    rn: 7588
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.78,
    company: "햇살태양광발전소",
    rn: 7589
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연주태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "연주 태양광발전소",
    rn: 7590
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청운 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "청운태양광발전소",
    rn: 7591
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.243,
    company: "세림태양광발전소",
    rn: 7592
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지석태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.292,
    company: "지석태양광 발전소",
    rn: 7593
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해안동2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 와이지에너지 2호",
    rn: 7594
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용산2호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "용산2호태양광발전소",
    rn: 7595
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "분도솔라6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 분도",
    rn: 7596
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "울산 연료전지 2단계",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 4.2,
    company: "한국동서발전(주)",
    rn: 7597
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경일쏠라햇빛",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.48,
    company: "경일쏠라 주식회사",
    rn: 7598
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨평창2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 제이씨평창2호태양광",
    rn: 7599
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨평창3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 제이씨평창2호태양광",
    rn: 7600
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영제솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "주식회사 영제솔라",
    rn: 7601
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도민제1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.003,
    company: "도민제1호태양광발전소 주식회사",
    rn: 7602
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세도에너지21 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "세도에너지21 태양광발전소",
    rn: 7603
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사산에너지96 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "사산에너지96 태양광발전소",
    rn: 7604
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다리챈연천 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "주식회사 다리챈",
    rn: 7605
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주) 제이피 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.934,
    company: "주식회사 제이피태양광",
    rn: 7606
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부산역 선상주차장 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.834,
    company: "한국남부발전(주)",
    rn: 7607
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "KT대구물류센터 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 0.9,
    company: "주식회사 케이티",
    rn: 7608
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라필드제1차1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "솔라필드 병풍1호 유한회사",
    rn: 7609
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라필드제1차2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "솔라필드 병풍1호 유한회사",
    rn: 7610
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지산2리주민 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.181,
    company: "주식회사 금동에너지",
    rn: 7611
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "약수주민 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "약수주민발전 주식회사",
    rn: 7612
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반용1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 반용1호태양광발전소",
    rn: 7613
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반용2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 반용2리태양광발전소",
    rn: 7614
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 남정수상태양광운영 1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.034,
    company: "주식회사 남정수상태양광운영",
    rn: 7615
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주현1호 태양광발전소",
    rn: 7616
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농협 평택물류센터 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.998,
    company: "농협경제지주(주) 에너지사업부",
    rn: 7617
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)태암태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.98,
    company: "주식회사 태암에너지 발전소",
    rn: 7618
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)함안솔라시스템태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(주) 함안솔라시스템",
    rn: 7619
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)함안솔라파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "(주)함안솔라파워",
    rn: 7620
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주사랑태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.746,
    company: "유한회사 우주",
    rn: 7621
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "침법 수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.505,
    company: "한국농어촌공사 경산청도지사",
    rn: 7622
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "화성 남양 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.8,
    company: "한국서부발전(주)",
    rn: 7623
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신생태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "신생태양광 주식회사",
    rn: 7624
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주현3호 태양광발전소",
    rn: 7625
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전대결1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "전대결1호태양광발전소",
    rn: 7626
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철우 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "철우 태양광발전소",
    rn: 7627
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청명해8호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 청명해",
    rn: 7628
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상하사주민제1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "상하사주민발전 주식회사",
    rn: 7629
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상하사주민제2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "상하사주민발전 주식회사",
    rn: 7630
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영진1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.437,
    company: "영진1호 태양광발전소",
    rn: 7631
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "충주3수력",
    cent: "비중앙",
    fuel: "수력",
    pcap: 5,
    company: "한국수자원공사",
    rn: 7632
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "다운태양광발전소",
    rn: 7633
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "최정임 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "최정임태양광발전소",
    rn: 7634
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한영발전 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "주식회사 한영발전",
    rn: 7635
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황금솔라(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.896,
    company: "황금솔라 주식회사",
    rn: 7636
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린에너지센터태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사코쿤에너지",
    rn: 7637
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천우쏠라기완",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "천우쏠라기완태양광발전소",
    rn: 7638
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천우쏠라기명 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "천우쏠라기명 태양광발전소",
    rn: 7639
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다경태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "신성테크 태양광발전소",
    rn: 7640
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 이오스 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 이오스",
    rn: 7641
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세련태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 세련태양광",
    rn: 7642
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재성4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "재성4호 태양광발전소",
    rn: 7643
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동복리 1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동복리1호 태양광발전소",
    rn: 7644
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금악태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "한국수력원자력(주)",
    rn: 7645
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)세원에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 미주에너지",
    rn: 7646
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "서울연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 6,
    company: "한국중부발전(주)",
    rn: 7647
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "인천연료전지 제2호기",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.8,
    company: "인천연료전지 주식회사",
    rn: 7648
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시우 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "시우 태양광발전",
    rn: 7649
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 일신에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 일신에너지",
    rn: 7650
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)태안3호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.914,
    company: "(주)태안태양광발전소",
    rn: 7651
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철강1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.865,
    company: "부산산업태양광 주식회사",
    rn: 7652
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철강3 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.023,
    company: "부산산업태양광 주식회사",
    rn: 7653
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동복리2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동복리2호 태양광발전소",
    rn: 7654
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원촌리LSY에너지태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 엘에스와이에너지",
    rn: 7655
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피앤씨테크(주)경주태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "피앤씨테크주식회사",
    rn: 7656
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양25호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.083,
    company: "광양25호태양광발전소",
    rn: 7657
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)광명전기 경주 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "(주)광명전기",
    rn: 7658
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다온태양광발전소 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "다온태양광발전소",
    rn: 7659
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우정에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 우정에너지",
    rn: 7660
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감천이엔지3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 감천이엔지3호",
    rn: 7661
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동일에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "동일에너지",
    rn: 7662
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성림에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)성림에너지",
    rn: 7663
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태영에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)태영에너지",
    rn: 7664
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창수이엔지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "(주)창수이엔지",
    rn: 7665
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비에이치 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "비에이치태양광 주식회사",
    rn: 7666
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛길이엔지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "(주)빛길이엔지",
    rn: 7667
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "풍산에너지1호 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "풍산에너지 주식회사",
    rn: 7668
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "풍산에너지3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "풍산에너지 주식회사",
    rn: 7669
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진보에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 진보에너지",
    rn: 7670
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나포 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "나포 태양광발전소",
    rn: 7671
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "살강 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "살강 태양광발전소",
    rn: 7672
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "누리파워 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "주식회사 에스코퍼레이션",
    rn: 7673
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "제이에스태양광발전 주식회사",
    rn: 7674
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SKC1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "SKC태양광발전소",
    rn: 7675
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "야촌태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "야촌 태양광 발전소",
    rn: 7676
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농어촌율치태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.92,
    company: "한국농어촌공사 영암지사",
    rn: 7677
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신성주민 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "신성주민발전 주식회사",
    rn: 7678
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김제동현 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "김제동현 태양광발전소",
    rn: 7679
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청천태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.223,
    company: "청천태양광발전소",
    rn: 7680
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코스모신소재 태양광 #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.017,
    company: "코스모신소재(주)",
    rn: 7681
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안자라태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 24.199,
    company: "빛솔라에너지주식회사",
    rn: 7682
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영조3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "영조3호 태양광발전소",
    rn: 7683
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재성3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "재성3호 태양광발전소",
    rn: 7684
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크 예천8호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "천광솔라",
    rn: 7685
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "읍애소수력 발전소",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.177,
    company: "한국수자원공사",
    rn: 7686
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크 예천23호발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)와이앤케이",
    rn: 7687
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대제철 자가용 발전1",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 400,
    company: "현대제철주식회사",
    rn: 7688
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "현대제철 자가용 발전2",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 400,
    company: "현대제철주식회사",
    rn: 7689
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한창태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "한창태양광발전소",
    rn: 7690
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해솔2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 해솔에너지",
    rn: 7691
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금빛세계 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 금빛세계",
    rn: 7692
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스윗",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.98,
    company: "스윗에너지 주식회사",
    rn: 7693
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율린제5발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "주식회사 보령에너지",
    rn: 7694
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율린제6발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "주식회사 보령에너지",
    rn: 7695
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율린제3발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "주식회사 오케이솔라",
    rn: 7696
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율린제4발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.941,
    company: "주식회사 오케이솔라",
    rn: 7697
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크충주11호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "해테크충주11호",
    rn: 7698
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크충주12호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "해테크충주12호",
    rn: 7699
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이엘씨 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.361,
    company: "솔라로드2호 주식회사",
    rn: 7700
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "울산 수소연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 1,
    company: "한국동서발전(주)",
    rn: 7701
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "시흥소각열 발전소",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.4,
    company: "케이지이티에스 (주) 에너지공장",
    rn: 7702
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신전태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.441,
    company: "신전태양광발전소",
    rn: 7703
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "퐁낭태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "퐁낭 태양광발전소",
    rn: 7704
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛솔라파크#5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.244,
    company: "한국수력원자력(주)",
    rn: 7705
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "의리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 디제이파워",
    rn: 7706
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해룡ECO태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "순천시청",
    rn: 7707
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양항어울림태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.656,
    company: "한국동서발전(주)",
    rn: 7708
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금오름 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.428,
    company: "한국수력원자력(주)",
    rn: 7709
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황룡1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "황룡1호 태양광발전소",
    rn: 7710
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성태양에너지PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "대성태양에너지",
    rn: 7711
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌스마트팜앤쏠라씨티 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 96.034,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 7712
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "남성태양광발전소",
    rn: 7713
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지안태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "지안 태양광발전소",
    rn: 7714
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백학 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.903,
    company: "유비솔라 주식회사",
    rn: 7715
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.762,
    company: "주식회사 혜성파워",
    rn: 7716
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대장 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.088,
    company: "주식회사 디제이파워",
    rn: 7717
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대장 태양광발전소 2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.694,
    company: "주식회사 엠제이일호",
    rn: 7718
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 엠제이일호",
    rn: 7719
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대화리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.89,
    company: "주식회사 에스와이일호",
    rn: 7720
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미찬훈발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.015,
    company: "주식회사 구미찬훈발전소",
    rn: 7721
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미봉순발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.015,
    company: "주식회사 구미봉순발전소",
    rn: 7722
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크안동1호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "대성태양에너지a",
    rn: 7723
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유촌리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.512,
    company: "주식회사 혜성파워",
    rn: 7724
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘컴텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "청운솔라 주식회사",
    rn: 7725
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문정태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "문정 태양광발전소",
    rn: 7726
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "처음세계 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.877,
    company: "주식회사 처음세계",
    rn: 7727
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ICC EV충전스테이션",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.101,
    company: "제주도청",
    rn: 7728
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "양양양수 신소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 0.15,
    company: "한국수력원자력(주)",
    rn: 7729
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인말응1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "중앙개발 주식회사",
    rn: 7730
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)엠케이2호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.712,
    company: "주식회사 엠케이2호 태양광발전소",
    rn: 7731
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영동 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.828,
    company: "주식회사 영동",
    rn: 7732
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이오투태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이오투 태양광발전소",
    rn: 7733
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "무릉 태양광발전소",
    rn: 7734
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강용구태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강용구태양광발전소",
    rn: 7735
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강성봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "강성봉태양광발전소",
    rn: 7736
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이오목태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이오목태양광발전소",
    rn: 7737
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함평1호 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.549,
    company: "정읍드림제2발전 주식회사",
    rn: 7738
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함평2호 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "정읍드림제2발전 주식회사",
    rn: 7739
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함평3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.683,
    company: "정읍드림제2발전 주식회사",
    rn: 7740
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신인천발전본부 북측부지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.365,
    company: "한국남부발전(주)",
    rn: 7741
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신인천 1&2단계 주차장 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.296,
    company: "한국남부발전(주)",
    rn: 7742
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이오목3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이오목3호태양광발전소",
    rn: 7743
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "손영란태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "손영란태양광발전소",
    rn: 7744
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "L태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.097,
    company: "엘(L) 태양광발전소",
    rn: 7745
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S3목소리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 에스3목소리발전",
    rn: 7746
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S8성곡리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 에스8성곡리발전",
    rn: 7747
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S7성곡리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 에스7성곡리발전",
    rn: 7748
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S5성곡리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 에스5성곡리발전",
    rn: 7749
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S9성곡리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 에스9 성곡리발전",
    rn: 7750
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S12 성곡리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 에스12성곡리발전",
    rn: 7751
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)에스2 목소리발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 에스2목소리발전",
    rn: 7752
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이오순태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "이오순태양광발전소",
    rn: 7753
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금평수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.404,
    company: "한국농어촌공사 전주완주임실지사",
    rn: 7754
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "길탕2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "솔라원 주식회사",
    rn: 7755
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "길탕1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "솔라나인 주식회사",
    rn: 7756
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JB태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.47,
    company: "주식회사 제이비시스템",
    rn: 7757
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한전리 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "주식회사 토성파워",
    rn: 7758
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "손불 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 손불지우파워텍",
    rn: 7759
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장수용월 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 장수용월파워텍",
    rn: 7760
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현석용월 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 현석용월파워텍",
    rn: 7761
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SY1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 에스와이전기",
    rn: 7762
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌스마트팜앤쏠라시티 PV #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.006,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 7763
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌스마트팜앤쏠라씨티 PV #2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.006,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 7764
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌스마트팜앤쏠라씨티 PV #3",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.006,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 7765
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌스마트팜앤쏠라씨티 PV #4",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.006,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 7766
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌스마트팜앤쏠라씨티 PV #5",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18.006,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 7767
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안좌스마트팜앤쏠라씨티 PV #6",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6.002,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 7768
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대양특수강(1) 태양광 발전설비",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.19,
    company: "한국동서발전(주)",
    rn: 7769
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대양특수강(2) 태양광 발전설비",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.088,
    company: "한국동서발전(주)",
    rn: 7770
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성현산업 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.626,
    company: "한국동서발전(주)",
    rn: 7771
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오케이텍 태양광 발전설비",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.109,
    company: "한국동서발전(주)",
    rn: 7772
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이레테크 태양광 발전설비",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.802,
    company: "한국동서발전(주)",
    rn: 7773
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정일울산 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.408,
    company: "한국동서발전(주)",
    rn: 7774
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테라테크 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.605,
    company: "한국동서발전(주)",
    rn: 7775
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티에스엠텍 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.244,
    company: "한국동서발전(주)",
    rn: 7776
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한공건설 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.34,
    company: "한국동서발전(주)",
    rn: 7777
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국몰드(1) 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.374,
    company: "한국동서발전(주)",
    rn: 7778
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창신인터내셔날 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.326,
    company: "한국동서발전(주)",
    rn: 7779
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서찬",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "서찬 태양광발전소",
    rn: 7780
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "관우솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.419,
    company: "솔라원일호 주식회사",
    rn: 7781
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현태양광 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주현 태양광발전소",
    rn: 7782
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와초1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.189,
    company: "(주)케이케이솔라",
    rn: 7783
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태신에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "태신에너지 주식회사",
    rn: 7784
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "BEP안성태양",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.642,
    company: "브라이트안성1호 주식회사",
    rn: 7785
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창기1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.986,
    company: "주식회사 디더블유에너지",
    rn: 7786
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창기2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.986,
    company: "에스에이치파워 주식회사",
    rn: 7787
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창기3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.986,
    company: "신안파워주식회사",
    rn: 7788
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창기4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.986,
    company: "서부그린에너지 주식회사",
    rn: 7789
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중앙연구소 내화시험동",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.206,
    company: "(주)케이씨씨 본부영업소",
    rn: 7790
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨우정태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.965,
    company: "유한회사 제이씨우정태양광",
    rn: 7791
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨포천태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(유)제이씨포천태양광",
    rn: 7792
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담7호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다담7호 태양광발전소",
    rn: 7793
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담8호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다담8호태양광발전소",
    rn: 7794
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유촌솔라텍",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "유촌솔라텍",
    rn: 7795
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포천알파에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "정선솔라 주식회사",
    rn: 7796
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "성지태양광발전소",
    rn: 7797
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만운2수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.035,
    company: "한국농어촌공사 안동지사",
    rn: 7798
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선흘리 나눔에너지발전소(2호)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.329,
    company: "주식회사 나눔에너지발전소",
    rn: 7799
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 나눔에너지발전소(3호)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.368,
    company: "주식회사 나눔에너지발전소",
    rn: 7800
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 나눔에너지발전소(4호)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.253,
    company: "주식회사 나눔에너지발전소",
    rn: 7801
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "E1 인천기지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.504,
    company: "주식회사 E1",
    rn: 7802
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "당진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.742,
    company: "당진태양광발전소",
    rn: 7803
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SUNNY태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "(주) 스프링웨이브",
    rn: 7804
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정희1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "정희1호 태양광발전소",
    rn: 7805
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라문2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 쏠라문",
    rn: 7806
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "병점태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.824,
    company: "지에스파워(주)",
    rn: 7807
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#16 모하솔라2호",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 7808
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사북하늘1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.947,
    company: "주식회사 사북하늘태양광발전소",
    rn: 7809
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사북하늘4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.886,
    company: "주식회사 사북하늘태양광발전소",
    rn: 7810
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉리태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.809,
    company: "한국남부발전(주)",
    rn: 7811
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선하늘1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.949,
    company: "정선하늘태양광발전소 주식회사",
    rn: 7812
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선하늘3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "정선하늘태양광발전소 주식회사",
    rn: 7813
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용암리2호태양광발전소PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "주식회사 해테크예천26호",
    rn: 7814
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빛나태양광발전소PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 해테크예천26호",
    rn: 7815
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용암리1호태양광발전소PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "주식회사 해테크횡성13호",
    rn: 7816
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태후태양광발전소PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주식회사 해테크횡성13호",
    rn: 7817
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태경A 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "태경A 태양광발전소",
    rn: 7818
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감정솔라4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.374,
    company: "주식회사 감정솔라",
    rn: 7819
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "심포솔라5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.876,
    company: "(주) 심포솔라",
    rn: 7820
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티디씨에너지2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "주식회사 세이브에너지",
    rn: 7821
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포태양광#6",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.977,
    company: "한국남동발전(주)",
    rn: 7822
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼천포 신재생 154kV #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 13.414,
    company: "한국남동발전(주)",
    rn: 7823
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이레자연에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 이레자연에너지",
    rn: 7824
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연당태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "썬라이프 유한회사",
    rn: 7825
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영신발전소2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 지율에스피",
    rn: 7826
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용담솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "용담솔라 주식회사",
    rn: 7827
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스비에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "에스비에너지(주)",
    rn: 7828
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영신발전소1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.92,
    company: "주식회사 영신에스피",
    rn: 7829
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우리별태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.877,
    company: "한국수력원자력(주)",
    rn: 7830
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성읍태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.962,
    company: "한국수력원자력(주)",
    rn: 7831
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "한국수력원자력(주)",
    rn: 7832
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "열방태양광발전소태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "열방 태양광발전소",
    rn: 7833
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "성광발전소 주식회사",
    rn: 7834
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크여주11호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.094,
    company: "(주)와이앤케이",
    rn: 7835
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림2발전소 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.482,
    company: "드림2발전소",
    rn: 7836
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "만금에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.782,
    company: "만금에너지",
    rn: 7837
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠에스솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.944,
    company: "주식회사 엠에스솔라",
    rn: 7838
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산하훈 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "산하훈 태양광발전소",
    rn: 7839
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살가득 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇살가득태양광발전소",
    rn: 7840
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호명솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.944,
    company: "주식회사 호명솔라",
    rn: 7841
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에스영광7 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 이에스영광7",
    rn: 7842
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한울태양광발전소이호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "(유) 한울태양광",
    rn: 7843
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살가득2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇살가득2호 태양광발전소",
    rn: 7844
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살가득3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇살가득3호태양광발전소",
    rn: 7845
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살가득4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇살가득4호 태양광발전소",
    rn: 7846
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살가득5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "햇살가득5호태양광발전소",
    rn: 7847
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "횡간솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.828,
    company: "주식회사 횡간솔라",
    rn: 7848
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광정솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.82,
    company: "주식회사 광정솔라",
    rn: 7849
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한울태양광발전소삼호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(유) 한울태양광",
    rn: 7850
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)진영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "(주)진영태양광발전소",
    rn: 7851
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원신흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "도원신흥태양광",
    rn: 7852
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안자라태양광발전기#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 18,
    company: "빛솔라에너지주식회사",
    rn: 7853
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신안자라태양광발전기#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 6.199,
    company: "빛솔라에너지주식회사",
    rn: 7854
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "좌포리용찬태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "좌포리용찬태양광발전소",
    rn: 7855
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "좌포5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "좌포5호발전소",
    rn: 7856
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "네오에코 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.887,
    company: "주식회사 네오에코에너지",
    rn: 7857
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬테크1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 썬테크 제1호",
    rn: 7858
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "푸른테크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)푸른테크",
    rn: 7859
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성재태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.716,
    company: "(유)에스제이 에스피피",
    rn: 7860
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조민태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)조민태양광발전소",
    rn: 7861
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "HS태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.797,
    company: "에이치에스태양광발전소 주식회사",
    rn: 7862
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자연과태양에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 자연과태양에너지",
    rn: 7863
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시흥태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.793,
    company: "지에스파워(주)",
    rn: 7864
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세화리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국남부발전(주)",
    rn: 7865
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미(정) 제3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.011,
    company: "한국수자원공사",
    rn: 7866
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KH솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "주식회사 케이에이치솔라",
    rn: 7867
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기린봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "기린봉태양광발전소",
    rn: 7868
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상준태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "상준태양광발전소",
    rn: 7869
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명수태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "명수태양광발전소",
    rn: 7870
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금송태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "금송태양광발전소",
    rn: 7871
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영후 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.644,
    company: "영후 태양광발전소",
    rn: 7872
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미4단지태양광#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "한국남동발전(주)",
    rn: 7873
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상식태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "상식태양광발전소",
    rn: 7874
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)에이치더블유솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유한회사 에이치더블유솔라",
    rn: 7875
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)보광2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유한회사 보광2호햇빛발전소",
    rn: 7876
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)디케이솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유한회사 디케이솔라",
    rn: 7877
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼우플랜트(주) 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.153,
    company: "삼우플랜트(주)",
    rn: 7878
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이케이10호 곤지암",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 7.984,
    company: "주식회사 와이케이에너지2호",
    rn: 7879
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)장수파워텍 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 장수파워텍",
    rn: 7880
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉덕초 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.864,
    company: "주식회사 인성티앤씨",
    rn: 7881
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문광면영농조합태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.003,
    company: "문광면영농조합법인",
    rn: 7882
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)풍산파워텍삼호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 풍산파워텍삼호",
    rn: 7883
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)풍산파워텍사호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 풍산파워텍사호",
    rn: 7884
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)지우파워텍 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 지우파워텍",
    rn: 7885
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)이에스영광1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)이에스영광1",
    rn: 7886
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)풍산파워텍이호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 풍산파워텍이호",
    rn: 7887
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대양금속 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.701,
    company: "(주) 대양금속",
    rn: 7888
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)이에스영광2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)이에스영광2",
    rn: 7889
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)이에스영광3 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)이에스영광3",
    rn: 7890
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "죽장태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "경연태양광 주식회사",
    rn: 7891
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원태양광발전소",
    rn: 7892
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)행복에너지 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 행복에너지발전소",
    rn: 7893
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보담3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)보담",
    rn: 7894
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)에스에프태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)에스에프태양광발전소",
    rn: 7895
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명도암 마을회 3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.212,
    company: "명도암마을회태양광발전소",
    rn: 7896
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영덕삼성 태양광발전소 태양",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.415,
    company: "영덕 삼성 태양광발전소",
    rn: 7897
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "모두에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.779,
    company: "주식회사 모두에너지",
    rn: 7898
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케프리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.249,
    company: "케프리태양광발전소",
    rn: 7899
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "글로리코리아낭산태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "글로리코리아 낭산발전소",
    rn: 7900
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해사랑태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "해사랑태양광발전소",
    rn: 7901
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이인리2호 태양광발전소 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.356,
    company: "이인리 2호 태양광발전소",
    rn: 7902
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안지연1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "안지연1호 태양광",
    rn: 7903
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "용솔라 태양광",
    rn: 7904
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "연솔라 태양광",
    rn: 7905
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "희솔라 태양광",
    rn: 7906
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김희1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "김희1호 태양광",
    rn: 7907
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "박상영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "박상영태양광",
    rn: 7908
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금굴수상태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.961,
    company: "한국농어촌공사 군산지사",
    rn: 7909
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정인그린태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.043,
    company: "정인그린태양광발전소",
    rn: 7910
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "주광태양광발전소",
    rn: 7911
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안심2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.08,
    company: "안심2호태양광발전소",
    rn: 7912
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새솔1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.231,
    company: "새솔다이아몬드공업 (주)",
    rn: 7913
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "좌포리송근태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "좌포리송근태양광발전소",
    rn: 7914
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "좌포리현순태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "좌포리현순태양광발전소",
    rn: 7915
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "좌포리순례태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "좌포리순례태양광발전소",
    rn: 7916
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "방화햇빛",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.634,
    company: "서울에너지공사",
    rn: 7917
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국MCC로지스틱스 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.092,
    company: "(주)부산도시가스",
    rn: 7918
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방발전소3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.718,
    company: "주식회사 삼방태양광",
    rn: 7919
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방이피엠1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.981,
    company: "주식회사 삼방이피엠",
    rn: 7920
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방이피엠3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.321,
    company: "주식회사 삼방이피엠",
    rn: 7921
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 삼방발전소",
    rn: 7922
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방솔라1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.915,
    company: "주식회사 삼방솔라",
    rn: 7923
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "좌포리수정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "좌포리수정태양광발전소",
    rn: 7924
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.259,
    company: "신우 태양광발전소",
    rn: 7925
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨태천2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.674,
    company: "유한회사 신안태천태양광",
    rn: 7926
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨태천1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.517,
    company: "유한회사 제이씨신안태양광",
    rn: 7927
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "주식회사 삼방에너지",
    rn: 7928
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 삼방그린",
    rn: 7929
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼방에너지3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.278,
    company: "주식회사 삼방제이",
    rn: 7930
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "197호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.482,
    company: "(주)제주감귤태양광",
    rn: 7931
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "198호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.482,
    company: "(주)제주감귤태양광",
    rn: 7932
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라에쿼티코리아003 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "솔라에쿼티코리아003 주식회사",
    rn: 7933
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라에쿼티코리아004 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "솔라에쿼티코리아004 주식회사",
    rn: 7934
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순덕 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.976,
    company: "주식회사 패밀리에너지",
    rn: 7935
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나눔2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.66,
    company: "주식회사 나눔 리테일",
    rn: 7936
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나눔 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.987,
    company: "(주)나눔리얼티",
    rn: 7937
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새별 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.976,
    company: "주식회사 새별에너지",
    rn: 7938
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주봉에너지1 태양광발전기 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.096,
    company: "주봉에너지1",
    rn: 7939
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛솔라파크#6",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.486,
    company: "한국수력원자력(주)",
    rn: 7940
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "연료전지 발전기",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.8,
    company: "청주에코파크 주식회사",
    rn: 7941
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "터보1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "에스에너지 태양광 발전소 주식회사",
    rn: 7942
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비엔제이 에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.37,
    company: "비엔제이 에너지 태양광발전소",
    rn: 7943
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)김송하태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "주식회사 김송하",
    rn: 7944
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)남경쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)남경쏠라",
    rn: 7945
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "풍산용월태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 풍산용월파워텍",
    rn: 7946
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "희망발전소 주식회사",
    rn: 7947
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.644,
    company: "천지태양광발전소",
    rn: 7948
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소양해드림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "춘천시청 (기후에너지과)",
    rn: 7949
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하동화력태양광6호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국남부발전(주)",
    rn: 7950
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혁정3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "혁정3호 태양광발전소",
    rn: 7951
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인투알 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "주식회사 인투알",
    rn: 7952
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영진쏠라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.93,
    company: "(주) 영진쏠라",
    rn: 7953
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인성리태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.408,
    company: "인성리태양광발전소",
    rn: 7954
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이엘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.904,
    company: "주식회사 제이엘태양광발전소",
    rn: 7955
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사계리태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.306,
    company: "사계리태양광발전소",
    rn: 7956
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영흥 태양광 5단지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.504,
    company: "한국남동발전(주)",
    rn: 7957
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "로희 태양광발전소(PV)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "로희 태양광발전소",
    rn: 7958
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티호1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.719,
    company: "주식회사 티에스에너지25호",
    rn: 7959
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고리본부태양광#2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.709,
    company: "한국수력원자력(주)",
    rn: 7960
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오엠시(남원)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.901,
    company: "주식회사 오엠시솔라에너지",
    rn: 7961
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미호지 수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.007,
    company: "한국농어촌공사 청주지사",
    rn: 7962
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오창수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.02,
    company: "한국농어촌공사 청주지사",
    rn: 7963
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세영3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.728,
    company: "세영3호태양광발전소",
    rn: 7964
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세영4호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "세영4호태양광발전소",
    rn: 7965
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)솔라뱅크원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.952,
    company: "솔라뱅크원 주식회사",
    rn: 7966
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "형제솔라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "형제솔라1호 태양광발전소",
    rn: 7967
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "형제솔라2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "형제솔라2호 태양광발전소",
    rn: 7968
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신성1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.851,
    company: "한국지붕태양광 주식회사",
    rn: 7969
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신성2호태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.765,
    company: "한국지붕태양광 주식회사",
    rn: 7970
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국남부발전 이천 디 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국남부발전(주)",
    rn: 7971
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "골드에너지 영주태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "골드에너지 주식회사",
    rn: 7972
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이레테크(2) 태양광 발전설비",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.388,
    company: "한국동서발전(주)",
    rn: 7973
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태영솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "태영솔라주식회사",
    rn: 7974
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영선504태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.475,
    company: "영선504 태양광발전소",
    rn: 7975
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "모다태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 모다에너지",
    rn: 7976
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청송양수 수상태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 4.445,
    company: "한국수력원자력(주)",
    rn: 7977
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)솔라비전스타태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 솔라비젼스타",
    rn: 7978
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단전태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "단전 태양광발전소",
    rn: 7979
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운산1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.674,
    company: "운산1호 태양광발전소",
    rn: 7980
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운산3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.674,
    company: "운산3호 태양광발전소",
    rn: 7981
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운산2호태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.688,
    company: "운산2호 태양광발전소",
    rn: 7982
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연천마전발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.793,
    company: "연천마전발전소",
    rn: 7983
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)심동에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 심동에너지",
    rn: 7984
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)두리발전 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 두리발전",
    rn: 7985
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창해태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.802,
    company: "주식회사 창해에너지",
    rn: 7986
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윤경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.485,
    company: "윤경 태양광발전소",
    rn: 7987
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해선태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "해선 태양광발전소",
    rn: 7988
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신세계에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "신세계에너지",
    rn: 7989
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)썬광기태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 썬광기태양광발전소",
    rn: 7990
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "기타",
    genNm: "생곡사업소 자가용발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 0.495,
    company: "부산광역시",
    rn: 7991
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음발전3태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.947,
    company: "학산농장,한마음발전3태양광발전소",
    rn: 7992
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이앤비에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "제이앤비에너지",
    rn: 7993
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재미디엠태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.984,
    company: "재미디엠 태양광발전소",
    rn: 7994
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창림솔라에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "창림솔라에너지1호",
    rn: 7995
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창림솔라에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "창림솔라에너지2호",
    rn: 7996
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나래(교보드림)태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "(유) 교보드림",
    rn: 7997
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소호솔라에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "소호솔라에너지",
    rn: 7998
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)옐로우미르태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "주식회사 옐로우미르",
    rn: 7999
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)옐로우덕진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "주식회사 옐로우덕진",
    rn: 8000
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇님태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.456,
    company: "주식회사 햇님태양광발전소",
    rn: 8001
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)햇님제2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.487,
    company: "주식회사 햇님태양광발전소",
    rn: 8002
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)호산 태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.325,
    company: "주식회사호산",
    rn: 8003
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주영에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "주영에너지태양광발전소",
    rn: 8004
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승광에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "승광에너지 태양광발전소",
    rn: 8005
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)시지테크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.723,
    company: "(주) 시지테크",
    rn: 8006
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.973,
    company: "드림 태양광발전소",
    rn: 8007
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대현발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "대현발전소",
    rn: 8008
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대현쏠라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "대현쏠라",
    rn: 8009
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장성햇빛발전소10",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 영지",
    rn: 8010
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이앤솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.904,
    company: "제이앤솔라(주)",
    rn: 8011
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김해시민햇빛 제1발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "김해시민햇빛발전협동조합",
    rn: 8012
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스제이연제태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 에스제이이엔지",
    rn: 8013
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "완이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "완이태양광발전소",
    rn: 8014
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송오태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "송오 태양광발전소",
    rn: 8015
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아름다운동행",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "아름다운동행 주식회사",
    rn: 8016
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한양 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.478,
    company: "주식회사 한양",
    rn: 8017
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문경요성1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.525,
    company: "(주)일신솔라파워",
    rn: 8018
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)연옥파워텍 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 연옥파워텍",
    rn: 8019
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대청취수장 육상 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "한국수자원공사",
    rn: 8020
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)평택파워텍일호 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 평택파워텍일호",
    rn: 8021
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)중부드림에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "유한회사 중부드림에너지",
    rn: 8022
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 한양에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 한양에너지",
    rn: 8023
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜송 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "대양유통",
    rn: 8024
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부성 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.698,
    company: "부성 태양광발전소",
    rn: 8025
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한정태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한정 태양광발전소",
    rn: 8026
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "징광리4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "징광리4호 태양광발전소",
    rn: 8027
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에너지팜2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.698,
    company: "에너지팜2태양광발전소",
    rn: 8028
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "(주)인천도시철도그린에너지발전소",
    rn: 8029
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "귤현태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(주)인천도시철도그린에너지발전소",
    rn: 8030
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림선샤인에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "드림선샤인 주식회사",
    rn: 8031
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SW상주태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.819,
    company: "주식회사 반곡솔라파워",
    rn: 8032
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현율태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "현율태양광발전소",
    rn: 8033
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티엠솔라원 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "티엠솔라원 발전소",
    rn: 8034
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이디솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 와이디솔라",
    rn: 8035
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠에스 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "엠에스솔라(주)",
    rn: 8036
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명지솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "명지솔라 주식회사",
    rn: 8037
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명그린에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 광명그린에너지",
    rn: 8038
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에이치이에너지 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "주식회사 에스에이치이에너지",
    rn: 8039
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창녕남지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.776,
    company: "창녕남지태양광발전소",
    rn: 8040
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정율3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.704,
    company: "정율3호 태양광발전소",
    rn: 8041
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서영 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.166,
    company: "서부태양광발전(주)",
    rn: 8042
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신촌2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "(유)소연에너지",
    rn: 8043
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인암솔라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.977,
    company: "인암솔라1호 태양광발전소",
    rn: 8044
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬빌리지2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "썬빌리지2호태양광발전소",
    rn: 8045
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용눈이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "용눈이 태양광발전소",
    rn: 8046
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영아태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "영아 태양광발전소",
    rn: 8047
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "성우 태양광발전소",
    rn: 8048
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "신우태양광발전소",
    rn: 8049
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수경에너지1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 수경에너지",
    rn: 8050
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용담솔라텍 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "용담솔라텍 주식회사",
    rn: 8051
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해뜨는발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 해뜨는발전소",
    rn: 8052
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금능태양광 주식회사",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.718,
    company: "금능태양광 주식회사",
    rn: 8053
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "SGC그린파워 1호기 ",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 100,
    company: "에스지씨그린파워 주식회사",
    rn: 8054
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재경3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "재경3태양광발전소",
    rn: 8055
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금송태양광 주식회사",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.756,
    company: "금송태양광 주식회사",
    rn: 8056
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금한태양광 주식회사",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.711,
    company: "금한태양광 주식회사",
    rn: 8057
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강태양광주식회사",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.643,
    company: "금강태양광 주식회사",
    rn: 8058
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한우리태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "한우리 태양광발전소1호",
    rn: 8059
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)옐로우 한양 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "주식회사 옐로우한양",
    rn: 8060
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "권빈 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 권빈발전",
    rn: 8061
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨티 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 씨티테크놀로지",
    rn: 8062
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대형에너지3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "주식회사 대형에너지",
    rn: 8063
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "누리쏠라에너지발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.784,
    company: "누리쏠라에너지발전소1호",
    rn: 8064
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "누리쏠라에너지발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.489,
    company: "누리쏠라에너지발전소2호",
    rn: 8065
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더홈에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "더홈(THE HOME)",
    rn: 8066
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성평창태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.524,
    company: "주식회사 윤서에너지",
    rn: 8067
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다올2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다올2 태양광발전소",
    rn: 8068
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한회사 일흥태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "주식회사 와이제이태양광발전소",
    rn: 8069
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해종태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "해종태양광발전소",
    rn: 8070
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "둔암형제태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "둔암 형제 태양광발전소",
    rn: 8071
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "㈜세진광 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 세진광",
    rn: 8072
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린허브(왕산)태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)그린허브솔라",
    rn: 8073
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태윤(왕산)태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 태윤에너지",
    rn: 8074
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "에스엠지에너지 주식회사",
    rn: 8075
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태경(왕산)태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 태경태양광",
    rn: 8076
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬뱅크(왕산)태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)썬뱅크",
    rn: 8077
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "㈜우리파워3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주) 우리파워3호",
    rn: 8078
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "대정 태양광발전소",
    rn: 8079
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제니 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "제니태양광 주식회사",
    rn: 8080
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금평태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "금평태양광발전소",
    rn: 8081
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원섭태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "원섭태양광발전소",
    rn: 8082
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬앤준태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.645,
    company: "썬앤준태양광발전소",
    rn: 8083
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SG SUN 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 에스지썬",
    rn: 8084
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조은1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "조은1호태양광발전소",
    rn: 8085
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사담리 태양광발전소 4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.44,
    company: "에디슨 환경에너지 주식회사",
    rn: 8086
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사담리 태양광발전소 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "제주발전에너지 주식회사",
    rn: 8087
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대전리태양광발전소 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "에스태양광발전소 주식회사",
    rn: 8088
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대전리 태양광발전소 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "에스태양광발전소 주식회사",
    rn: 8089
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대전리 태양광발전소 4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "에스태양광발전소 주식회사",
    rn: 8090
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "(재)울산테크노파크 연료전지-2",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 0.94,
    company: "(재)울산테크노파크",
    rn: 8091
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고령백양발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.519,
    company: "주식회사 평창원길리태양광3호",
    rn: 8092
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적암 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.178,
    company: "주식회사 2018동부하이웨이솔라",
    rn: 8093
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.638,
    company: "고성그린파워 주식회사",
    rn: 8094
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세동2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "세동2태양광발전소",
    rn: 8095
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오름태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "오름태양광발전소",
    rn: 8096
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)해담3호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.936,
    company: "(유)해담3호태양광발전소",
    rn: 8097
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "더함6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.971,
    company: "유한회사 더함6호태양광발전소",
    rn: 8098
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해오름태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.936,
    company: "해오름태양광발전소",
    rn: 8099
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송백태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "송백 태양광발전소 1호",
    rn: 8100
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)준경썬5호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유한회사 준경썬5호태양광",
    rn: 8101
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SH태양광13호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "에스에이치(SH)태양광13호 태양광발전소",
    rn: 8102
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신화3호에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "신화3호에너지",
    rn: 8103
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부여거북4태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.453,
    company: "주식회사 부여거북4태양광발전소",
    rn: 8104
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이씨 사출공장 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.047,
    company: "주식회사에스케이씨",
    rn: 8105
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양광발전기 #1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.047,
    company: "(주)율촌",
    rn: 8106
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕더리굴태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.794,
    company: "덕더리굴태양광발전소",
    rn: 8107
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원더스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "유한회사 원더스",
    rn: 8108
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창매태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.673,
    company: "주식회사 창매태양광",
    rn: 8109
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도초솔라온",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 솔라온",
    rn: 8110
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "늘빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 늘빛",
    rn: 8111
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이피에프1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이피에프1호 태양광발전소",
    rn: 8112
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이피에프2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이피에프2호 태양광발전소",
    rn: 8113
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이피에프3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이피에프3호 태양광발전소",
    rn: 8114
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 신우에너지",
    rn: 8115
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "수력",
    genNm: "고성소수력",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "고성그린파워 주식회사",
    rn: 8116
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세원썬진전 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.759,
    company: "세원썬파워(주)",
    rn: 8117
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "이양 태양광발전소",
    rn: 8118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "전남햇빛발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 전남햇빛발전",
    rn: 8119
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부여거북1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.462,
    company: "(주)부여거북1 태양광발전소",
    rn: 8120
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서봉태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "서봉태양광발전소",
    rn: 8121
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자은동성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "자은동성 태양광발전소",
    rn: 8122
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에이치솔라 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 디에이치솔라",
    rn: 8123
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스카이로지텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.461,
    company: "솔라로드2호 주식회사",
    rn: 8124
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혁정4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "혁정4호 태양광발전소",
    rn: 8125
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스피 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 제이에스피",
    rn: 8126
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대산에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "대산에너지주식회사",
    rn: 8127
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석포하이존(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.7,
    company: "석포하이존 주식회사",
    rn: 8128
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석포신재생태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "석포신재생(주)",
    rn: 8129
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승부1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "승부태양광 (주)",
    rn: 8130
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혁정5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "혁정5호 태양광발전소",
    rn: 8131
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)이조쏠라에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 이조쏠라에너지",
    rn: 8132
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)사조태양에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)사조태양에너지",
    rn: 8133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "병일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.973,
    company: "병일태양광발전소",
    rn: 8134
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김미경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "김미경태양광발전소",
    rn: 8135
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "곡리농장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "곡리농장태양광발전소",
    rn: 8136
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕진1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "덕진1호태양광발전소",
    rn: 8137
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다이알월드1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.195,
    company: "다이알월드 (주)",
    rn: 8138
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다이알월드2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.195,
    company: "다이알월드 (주)",
    rn: 8139
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다이알월드3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.292,
    company: "다이알월드 (주)",
    rn: 8140
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세동태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "세동태양광발전소",
    rn: 8141
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬토피아2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 썬토피아 2호",
    rn: 8142
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬토피아1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 썬토피아 1호",
    rn: 8143
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동해1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "동해1호태양광발전소",
    rn: 8144
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "준4호태양광발전소",
    rn: 8145
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.689,
    company: "태양에너지 태양광발전소",
    rn: 8146
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "㈜건천 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 건천 정선지점",
    rn: 8147
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이알쏠라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 와이알에너지",
    rn: 8148
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비케이 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 비케이솔라",
    rn: 8149
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쿠미오리발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)매싼커뮤니케이션",
    rn: 8150
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 태백행복",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 태백행복",
    rn: 8151
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬토피아3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 썬토피아 3호",
    rn: 8152
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬토피아4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 썬토피아 4호",
    rn: 8153
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)에이스에너지1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 에이스에너지1",
    rn: 8154
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)에이스에너지2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사에이스에너지2",
    rn: 8155
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "대송태양광발전소",
    rn: 8156
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬텍공전1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "케이솔라이호 주식회사",
    rn: 8157
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일과 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "일과 태양광발전소",
    rn: 8158
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이경용태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.984,
    company: "이경용 태양광발전소",
    rn: 8159
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은하수태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "은하수 태양광발전소",
    rn: 8160
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)스카이에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(주)스카이에너지",
    rn: 8161
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수지솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "수지솔라태양광발전소",
    rn: 8162
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나인태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "나인태양광발전소",
    rn: 8163
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "문간공휘재도문중(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "문간공휘재도문중 주식회사",
    rn: 8164
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진수전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.495,
    company: "케이솔라이호 주식회사",
    rn: 8165
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "㈜카이솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.813,
    company: "솔라원일호 주식회사",
    rn: 8166
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이래에이치티태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.847,
    company: "(주)이래에이치티",
    rn: 8167
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이래에이치티태양광2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.768,
    company: "(주)이래에이치티",
    rn: 8168
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이래에이치티 태양광3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.837,
    company: "(주)이래에이치티",
    rn: 8169
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이래에이치티 태양광4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.351,
    company: "(주)이래에이치티",
    rn: 8170
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)청정그린태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "곡성그린제일호 주식회사",
    rn: 8171
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청정쏠라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "곡성그린제이호 주식회사",
    rn: 8172
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "메이드홀딩스",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.856,
    company: "(주)메이드홀딩스",
    rn: 8173
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디솔라파워 1-1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.324,
    company: "디솔라파워 주식회사",
    rn: 8174
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디솔라파워 1-2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.307,
    company: "디솔라파워 주식회사",
    rn: 8175
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디솔라파워 영주 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "디솔라파워 주식회사",
    rn: 8176
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디솔라파워 예천",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.335,
    company: "디솔라파워 주식회사",
    rn: 8177
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윈체 태양광발전소 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.828,
    company: "유제이솔라(주)",
    rn: 8178
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유현그린 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 유현그린",
    rn: 8179
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 경북솔라 태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "주식회사 경북솔라",
    rn: 8180
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 은우솔라 태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "주식회사 은우솔라",
    rn: 8181
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 강민솔라 태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 강민솔라",
    rn: 8182
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산한솔라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "유한회사 한솔라산업",
    rn: 8183
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준경썬2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 운전5호태양광",
    rn: 8184
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어전1호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.989,
    company: "(유)어전1호 에너지",
    rn: 8185
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "애월 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.91,
    company: "주식회사 제주마시",
    rn: 8186
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살찬태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "햇살찬 태양광발전소",
    rn: 8187
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다산쏠라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "유한회사 다산쏠라",
    rn: 8188
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "범성에너지태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.598,
    company: "(유) 범성에너지",
    rn: 8189
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)알프하임솔라 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.962,
    company: "(주)알프하임솔라",
    rn: 8190
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)감문태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 감문태양광발전소",
    rn: 8191
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수곡1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.708,
    company: "유한회사 성남제이씨에너지",
    rn: 8192
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 태성",
    rn: 8193
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "철원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "화성엔지니어링(주)",
    rn: 8194
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)에이원산업",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "(유)에이원산업",
    rn: 8195
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)패스오버전기통신",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.92,
    company: "(주)패스오버전기통신",
    rn: 8196
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태영태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "태영태양광발전소",
    rn: 8197
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태원한양태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.478,
    company: "태원한양태양광발전소",
    rn: 8198
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 주노프라퍼티",
    rn: 8199
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다울태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 주노프라퍼티",
    rn: 8200
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구미내예1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "서은에너지1호 주식회사",
    rn: 8201
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그리드위즈 도봉 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.168,
    company: "주식회사 그리드위즈",
    rn: 8202
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그리드위즈 도봉 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.036,
    company: "주식회사 그리드위즈",
    rn: 8203
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "채아솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "주식회사 채아솔라",
    rn: 8204
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신주태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "신주태양광발전소",
    rn: 8205
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명새마을회 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.994,
    company: "주식회사 광명새마을회",
    rn: 8206
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SOLVIC 6호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.808,
    company: "세방(주)",
    rn: 8207
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SOLVIC 4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.348,
    company: "세방(주)",
    rn: 8208
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SOLVIC 5호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.871,
    company: "세방(주)",
    rn: 8209
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수곡2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.801,
    company: "주식회사 선그린에너지",
    rn: 8210
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진보부송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 진보태양광",
    rn: 8211
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경남4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "유한회사 경남2호태양광발전소",
    rn: 8212
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남도부송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 남도태양광",
    rn: 8213
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두리부송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 두리에너지",
    rn: 8214
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승진부송태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "유한회사 승진태양광",
    rn: 8215
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운산솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.914,
    company: "엔솔 일렉트릭",
    rn: 8216
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이디태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "(주)와이디태양광발전소",
    rn: 8217
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이디동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "(주)와이디동태양광발전소",
    rn: 8218
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정미 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "정미 태양광발전소",
    rn: 8219
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석현에너지 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "(유)석현에너지",
    rn: 8220
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.901,
    company: "(주)우진태양광발전소",
    rn: 8221
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "함덕 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "함덕태양광발전소",
    rn: 8222
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "안좌스마트팜_가상ESS",
    cent: "비중앙",
    fuel: "전기저장장치(태양광)",
    pcap: 0,
    company: "안좌스마트팜앤쏠라시티 주식회사",
    rn: 8223
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 대한에너지",
    rn: 8224
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대연태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.733,
    company: "대연태양광발전소 주식회사",
    rn: 8225
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)대형에스엘부안태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)대형에스엘 부안태양광발전소",
    rn: 8226
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)대형에스엘김제태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)대형에스엘 김제지점",
    rn: 8227
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)성우햇빛발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "주식회사 성우햇빛발전",
    rn: 8228
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "신재생에너지",
    genNm: "솔라에쿼티고창016",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "솔라에쿼티고창016유한회사",
    rn: 8229
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "신재생에너지",
    genNm: "솔라에쿼티고창015",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "솔라에쿼티고창015유한회사",
    rn: 8230
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)아침태양광6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.604,
    company: "(주)아침태양광6호",
    rn: 8231
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이지오(AGO)예천 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "에이지오(AGO)예천 태양광발전소",
    rn: 8232
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "영월 연료전지 1단계",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 15,
    company: "한국남부발전(주)",
    rn: 8233
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비와이쏠라테크 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "비와이쏠라테크 주식회사 ",
    rn: 8234
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "섬진강 1호 태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 선인에너지(Sun in Energy)",
    rn: 8235
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수범 태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "수범태양광 주식회사 ",
    rn: 8236
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원 수상태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.04,
    company: "한국농어촌공사 영북지사",
    rn: 8237
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영양햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 영양에너지",
    rn: 8238
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원3호 솔라원",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "도원3호 솔라원",
    rn: 8239
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "강원 2호 솔라원",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "강원 2호 솔라원",
    rn: 8240
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창영2호 솔라원",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "창영 2호 솔라원",
    rn: 8241
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)한성솔라젠",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 한성솔라젠",
    rn: 8242
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)미래에너지산업",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "주식회사 미래에너지산업",
    rn: 8243
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)로이상주발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)로이상주발전소",
    rn: 8244
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "농협 밀양물류센터 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.229,
    company: "농협경제지주(주) 에너지사업부",
    rn: 8245
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "창신 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.454,
    company: "주식회사 창신태양광발전소",
    rn: 8246
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광양항광양냉장태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.693,
    company: "한국동서발전(주)",
    rn: 8247
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELX_논산1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8248
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELX_논산2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.429,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8249
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELX_창녕1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.301,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8250
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELX_천안1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8251
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELX_청양2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.339,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8252
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에넬엑스창원1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.72,
    company: "에넬엑스코리아 유한회사",
    rn: 8253
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에넬엑스창원2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.54,
    company: "에넬엑스코리아 유한회사",
    rn: 8254
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영양양지햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "영양양지 주식회사",
    rn: 8255
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "예성햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 예성에너지",
    rn: 8256
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비에스케이영양햇빛태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 비에스케이영양",
    rn: 8257
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 비안솔라발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.871,
    company: "주식회사 비안솔라",
    rn: 8258
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "와이앤케이에너지 1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.267,
    company: "주식회사 와이앤케이하우징(Y&K Housing.co. Ltd.)",
    rn: 8259
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이앤씨에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.267,
    company: "제이앤씨에너지(주)",
    rn: 8260
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아에너지발전소9호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.437,
    company: "코리아에너지발전소(주)",
    rn: 8261
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 우정",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 우정",
    rn: 8262
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 대경에스이발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.706,
    company: "주식회사 대경에스이",
    rn: 8263
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크진주1호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "유한회사 넷제로투자2호",
    rn: 8264
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송화개발 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "송화개발 주식회사",
    rn: 8265
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELX_청양1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.634,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8266
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화촌주민참여형솔라팜태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국남부발전(주)",
    rn: 8267
    },
    {
    area: "수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "의왕 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 9.9,
    company: "한국서부발전(주)",
    rn: 8268
    },
    {
    area: "비수도권",
    genFom: "수력",
    genSrc: "신재생에너지",
    genNm: "태안소수력#2",
    cent: "비중앙",
    fuel: "소수력",
    pcap: 5,
    company: "한국서부발전(주)",
    rn: 8269
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쏠라포스1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "쏠라포스1호태양광",
    rn: 8270
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "옥성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "옥성태양광발전소",
    rn: 8271
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "금성태양광발전소",
    rn: 8272
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "분무골에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.337,
    company: "주식회사 분무골에너지",
    rn: 8273
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "분무골에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.026,
    company: "주식회사 분무골에너지",
    rn: 8274
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스지합수 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.204,
    company: "주식회사 에스지",
    rn: 8275
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장고솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.145,
    company: "(주)장고솔라",
    rn: 8276
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도원2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.346,
    company: "도원2호 태양광발전소",
    rn: 8277
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신추에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.663,
    company: "주식회사 신추에너지",
    rn: 8278
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "(주)동양환경 자가용발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 4.8,
    company: "(주)동양환경",
    rn: 8279
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "HM에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "주식회사 현중에너지",
    rn: 8280
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해울솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "해울솔라",
    rn: 8281
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "블루3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "블루플래닛삼호(주)",
    rn: 8282
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그리드위즈 제천1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.001,
    company: "주식회사 그리드위즈제천발전",
    rn: 8283
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "모하솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "주식회사 모하솔라",
    rn: 8284
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동아ENG태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "한국지붕태양광 주식회사",
    rn: 8285
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성산업태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.186,
    company: "한국지붕태양광2 유한회사",
    rn: 8286
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천왕햇빛",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.612,
    company: "서울에너지공사",
    rn: 8287
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다건태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.952,
    company: "주식회사다건태양광발전소",
    rn: 8288
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.912,
    company: "주식회사 태인 태양광발전소",
    rn: 8289
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬플라워1호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 썬플라워1호태양광발전소",
    rn: 8290
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다온태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "다온태양광발전소",
    rn: 8291
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피브이플랜트칠호 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "피브이플랜트칠호 주식회사",
    rn: 8292
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반곡태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.819,
    company: "주식회사반곡태양광",
    rn: 8293
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)우영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.973,
    company: "주식회사 우영태양광발전소",
    rn: 8294
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)두성태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.973,
    company: "(주)두성태양광",
    rn: 8295
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)해광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.973,
    company: "주식회사 해광태양광발전소",
    rn: 8296
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)죽매태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.973,
    company: "(주)죽매태양광",
    rn: 8297
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "본사지붕 태양광발전소 1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.296,
    company: "한국수력원자력(주)",
    rn: 8298
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)휘페리온태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.456,
    company: "주식회사 휘페리온태양광1호",
    rn: 8299
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)헬리오스태양광1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.435,
    company: "주식회사 헬리오스태양광1호",
    rn: 8300
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "노아태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "노아 태양광발전소",
    rn: 8301
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해테크보성1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "(주)해테크그린에너지",
    rn: 8302
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "노아2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "노아2호",
    rn: 8303
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율극리 1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.871,
    company: "브라이트전국태양광1호 주식회사",
    rn: 8304
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "신재생에너지",
    genNm: "서해열병합발전",
    cent: "비중앙",
    fuel: "바이오매스",
    pcap: 24.9,
    company: "(주)서해열병합발전",
    rn: 8305
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보원엠앤피태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.923,
    company: "주식회사보원엠앤피",
    rn: 8306
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두리마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 두리마을에너지",
    rn: 8307
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "들마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 들마을에너지",
    rn: 8308
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금빛마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 금빛마을에너지",
    rn: 8309
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "냇마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 냇마을에너지",
    rn: 8310
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이례마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 이례마을에너지",
    rn: 8311
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 산마을에너지",
    rn: 8312
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유니마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 유니마을에너지",
    rn: 8313
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마을에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.992,
    company: "유한회사 한마을에너지",
    rn: 8314
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마을에너지2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "유한회사 한마을에너지",
    rn: 8315
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선하늘5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.904,
    company: "센트럴태양광발전소4호 주식회사",
    rn: 8316
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정선하늘6호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.918,
    company: "센트럴태양광발전소5호 주식회사",
    rn: 8317
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천호(자일리)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "(주)천호태양광",
    rn: 8318
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 미래전력",
    rn: 8319
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 한빛전력",
    rn: 8320
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대흥전력태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 대흥전력",
    rn: 8321
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화인통상 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.452,
    company: "해바라기발전 주식회사",
    rn: 8322
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)대불1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)유일",
    rn: 8323
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)대불3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)유일",
    rn: 8324
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시화 해상태양광 실증플랜트",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "한국수자원공사",
    rn: 8325
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산 나눔 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.909,
    company: "나래에너지서비스 주식회사",
    rn: 8326
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라온태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 솔라온",
    rn: 8327
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔리스 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "(주)솔리스",
    rn: 8328
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)단촌솔라발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "(주)단촌솔라",
    rn: 8329
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 대도에너지발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 대도에너지",
    rn: 8330
    },
    {
    area: "비수도권",
    genFom: "내연",
    genSrc: "신재생에너지",
    genNm: "우포월드 바이오가스",
    cent: "비중앙",
    fuel: "바이오가스",
    pcap: 0.6,
    company: "농업회사법인주식회사우포월드",
    rn: 8331
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광현 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.796,
    company: "주식회사 동강엔지니어링",
    rn: 8332
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "고려아연복합CC",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 272.5,
    company: "고려아연 (주) 온산제련소",
    rn: 8333
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "고려아연복합GT",
    cent: "비중앙",
    fuel: "LNG",
    pcap: 171,
    company: "고려아연 (주) 온산제련소",
    rn: 8334
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "염전마을주민 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.004,
    company: "염전마을주민발전 주식회사",
    rn: 8335
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)효성에너지태양광발전소유원1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.439,
    company: "주식회사 효성에너지태양광발전소유원",
    rn: 8336
    },
    {
    area: "수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "나래에너지서비스(주)RPS발전기",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "나래에너지서비스 주식회사",
    rn: 8337
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사계절발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라일호 주식회사",
    rn: 8338
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이오1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "케이솔라일호 주식회사",
    rn: 8339
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이오1호 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "케이솔라일호 주식회사",
    rn: 8340
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠호1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "케이솔라일호 주식회사",
    rn: 8341
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S4평온리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "케이솔라일호 주식회사",
    rn: 8342
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.748,
    company: "케이솔라일호 주식회사",
    rn: 8343
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월리1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.454,
    company: "케이솔라일호 주식회사",
    rn: 8344
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "횡성케이솔라3호 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8345
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월리2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.506,
    company: "케이솔라일호 주식회사",
    rn: 8346
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "횡성케이솔라1호 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8347
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월리3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.506,
    company: "케이솔라일호 주식회사",
    rn: 8348
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거창 케이솔라1호 햇빛발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8349
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홍익태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라일호 주식회사",
    rn: 8350
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 헤르멜에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "케이솔라일호 주식회사",
    rn: 8351
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 상진발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8352
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "케이솔라일호 주식회사",
    rn: 8353
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제니태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.482,
    company: "케이솔라일호 주식회사",
    rn: 8354
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은진",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라일호 주식회사",
    rn: 8355
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아름파워",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8356
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중촌파워",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8357
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용덕1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8358
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정원태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8359
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8360
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "설희 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "케이솔라일호 주식회사",
    rn: 8361
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호주식회사(헤레브호촌)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.778,
    company: "케이솔라일호 주식회사",
    rn: 8362
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)효성에너지태양광발전소유원2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.483,
    company: "주식회사 효성에너지태양광발전소유원",
    rn: 8363
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "횡성케이솔라4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8364
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가평현 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "케이솔라일호 주식회사",
    rn: 8365
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운사 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "케이솔라일호 주식회사",
    rn: 8366
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어신태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "어신태양광발전소",
    rn: 8367
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "항아에너지케이솔라일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "케이솔라일호 주식회사",
    rn: 8368
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 호수2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.46,
    company: "케이솔라일호 주식회사",
    rn: 8369
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.799,
    company: "케이솔라일호 주식회사",
    rn: 8370
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영완4 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8371
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성진 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라일호 주식회사",
    rn: 8372
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태인 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "케이솔라일호 주식회사",
    rn: 8373
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치원솔라십일호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "케이솔라일호 주식회사",
    rn: 8374
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호진도2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "케이솔라일호 주식회사",
    rn: 8375
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "리더스에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8376
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백두 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8377
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)오에스피 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라일호 주식회사",
    rn: 8378
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)케이에스피 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라일호 주식회사",
    rn: 8379
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용진태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8380
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용진태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8381
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용진태양광발전소3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.201,
    company: "케이솔라일호 주식회사",
    rn: 8382
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)가재파워 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8383
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 와수이글스 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.37,
    company: "케이솔라일호 주식회사",
    rn: 8384
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 철원이글스 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8385
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라드림",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라일호 주식회사",
    rn: 8386
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삽존리현대에코 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.761,
    company: "케이솔라일호 주식회사",
    rn: 8387
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 명성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.598,
    company: "케이솔라일호 주식회사",
    rn: 8388
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 솔라스트림진도 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8389
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 솔라레이진도 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라일호 주식회사",
    rn: 8390
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양지에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "케이솔라일호 주식회사",
    rn: 8391
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 경식투 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8392
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선종1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라일호 주식회사",
    rn: 8393
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호영수해바라기발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8394
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 다온1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라일호 주식회사",
    rn: 8395
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 다온2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라일호 주식회사",
    rn: 8396
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "달밭태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라일호 주식회사",
    rn: 8397
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호석정태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라일호 주식회사",
    rn: 8398
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대넥솔 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.75,
    company: "케이솔라일호 주식회사",
    rn: 8399
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대넥솔영동 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.804,
    company: "케이솔라일호 주식회사",
    rn: 8400
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 옥천1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라일호 주식회사",
    rn: 8401
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 수혁원에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.447,
    company: "케이솔라일호 주식회사",
    rn: 8402
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 썬빌리지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "케이솔라일호 주식회사",
    rn: 8403
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "김성용 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.398,
    company: "케이솔라일호 주식회사",
    rn: 8404
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매정썬토피아 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라일호 주식회사",
    rn: 8405
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매정지오솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라일호 주식회사",
    rn: 8406
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매정이노솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라일호 주식회사",
    rn: 8407
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단양에너지1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "케이솔라일호 주식회사",
    rn: 8408
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.695,
    company: "케이솔라일호 주식회사",
    rn: 8409
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다우5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라일호 주식회사",
    rn: 8410
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원현 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.989,
    company: "케이솔라일호 주식회사",
    rn: 8411
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "H30태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.954,
    company: "케이솔라일호 주식회사",
    rn: 8412
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘림3호 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라일호 주식회사",
    rn: 8413
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S1목소리발전 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "케이솔라일호 주식회사",
    rn: 8414
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고성루스 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.909,
    company: "케이솔라일호 주식회사",
    rn: 8415
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S6성곡리",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8416
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S10성곡리",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.745,
    company: "케이솔라일호 주식회사",
    rn: 8417
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백포리1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "케이솔라일호 주식회사",
    rn: 8418
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백포리2호 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "케이솔라일호 주식회사",
    rn: 8419
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백포리3호 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라일호 주식회사",
    rn: 8420
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용곡",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라일호 주식회사",
    rn: 8421
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한주실업",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라일호 주식회사",
    rn: 8422
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S3평온리",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "케이솔라일호 주식회사",
    rn: 8423
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S4목소리",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.279,
    company: "케이솔라일호 주식회사",
    rn: 8424
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KS2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.959,
    company: "케이솔라일호 주식회사",
    rn: 8425
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현성",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8426
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "통해제5태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.919,
    company: "케이솔라일호 주식회사",
    rn: 8427
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS태양",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "케이솔라일호 주식회사",
    rn: 8428
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해리1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라일호 주식회사",
    rn: 8429
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "호남연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 15,
    company: "한국동서발전(주)",
    rn: 8430
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화음리2호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.467,
    company: "케이솔라일호 주식회사",
    rn: 8431
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "횡성케이솔라2호 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.345,
    company: "케이솔라일호 주식회사",
    rn: 8432
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대에어썬텍금산6",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "케이솔라일호 주식회사",
    rn: 8433
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이탑파워1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.391,
    company: "케이솔라일호 주식회사",
    rn: 8434
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이탑플랜트1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.391,
    company: "케이솔라일호 주식회사",
    rn: 8435
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "올바름2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.195,
    company: "케이솔라일호 주식회사",
    rn: 8436
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.289,
    company: "케이솔라일호 주식회사",
    rn: 8437
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신교3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.449,
    company: "케이솔라일호 주식회사",
    rn: 8438
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신교12호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.478,
    company: "케이솔라일호 주식회사",
    rn: 8439
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 썬양천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "케이솔라일호 주식회사",
    rn: 8440
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼마에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라일호 주식회사",
    rn: 8441
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송강 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라일호 주식회사",
    rn: 8442
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광의",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "케이솔라일호 주식회사",
    rn: 8443
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여천에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.695,
    company: "케이솔라일호 주식회사",
    rn: 8444
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8445
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라이호 주식회사",
    rn: 8446
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "케이솔라이호 주식회사",
    rn: 8447
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경이앤에프태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.194,
    company: "케이솔라이호 주식회사",
    rn: 8448
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소병도1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8449
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홍성례1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8450
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호(순창양지)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라이호 주식회사",
    rn: 8451
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경희태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "케이솔라이호 주식회사",
    rn: 8452
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엘엘13호기태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.194,
    company: "케이솔라이호 주식회사",
    rn: 8453
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "웅2호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 8454
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이종석1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.965,
    company: "케이솔라이호 주식회사",
    rn: 8455
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희정태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "케이솔라이호 주식회사",
    rn: 8456
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "케이솔라이호 주식회사",
    rn: 8457
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "운사2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.791,
    company: "케이솔라이호 주식회사",
    rn: 8458
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.637,
    company: "케이솔라이호 주식회사",
    rn: 8459
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호성발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.637,
    company: "케이솔라이호 주식회사",
    rn: 8460
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼현에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.45,
    company: "케이솔라이호 주식회사",
    rn: 8461
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유림태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "케이솔라이호 주식회사",
    rn: 8462
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "굿태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "케이솔라이호 주식회사",
    rn: 8463
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "베스트1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "케이솔라이호 주식회사",
    rn: 8464
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서검발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "케이솔라일호 주식회사",
    rn: 8465
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스트 용하리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.596,
    company: "케이솔라일호 주식회사",
    rn: 8466
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화음리1호 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라일호 주식회사",
    rn: 8467
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아우라지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라일호 주식회사",
    rn: 8468
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "S12구억리",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.959,
    company: "케이솔라일호 주식회사",
    rn: 8469
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "온빛태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8470
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "플러스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.394,
    company: "케이솔라이호 주식회사",
    rn: 8471
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.09,
    company: "케이솔라이호 주식회사",
    rn: 8472
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원중솔라에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.401,
    company: "케이솔라이호 주식회사",
    rn: 8473
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용호1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8474
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코스모태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "케이솔라이호 주식회사",
    rn: 8475
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신교15호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 8476
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유정태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "케이솔라이호 주식회사",
    rn: 8477
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스마일11호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.896,
    company: "케이솔라이호 주식회사",
    rn: 8478
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬광명태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "케이솔라이호 주식회사",
    rn: 8479
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진에너지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8480
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜광태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "케이솔라이호 주식회사",
    rn: 8481
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "찬늘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 8482
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고은이엔지1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8483
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "찬동에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라이호 주식회사",
    rn: 8484
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은식1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 8485
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광성에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.524,
    company: "케이솔라이호 주식회사",
    rn: 8486
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자은1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.955,
    company: "케이솔라이호 주식회사",
    rn: 8487
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "병곡1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.697,
    company: "케이솔라이호 주식회사",
    rn: 8488
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "논골태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "케이솔라이호 주식회사",
    rn: 8489
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남산1솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "케이솔라이호 주식회사",
    rn: 8490
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남영솔라태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8491
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소망태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "케이솔라이호 주식회사",
    rn: 8492
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비월복주에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 8493
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "복주에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라이호 주식회사",
    rn: 8494
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "새싹태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.802,
    company: "케이솔라이호 주식회사",
    rn: 8495
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아란에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.473,
    company: "케이솔라이호 주식회사",
    rn: 8496
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태우태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.47,
    company: "케이솔라이호 주식회사",
    rn: 8497
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티앤테크정선1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 8498
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경천6호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.863,
    company: "케이솔라이호 주식회사",
    rn: 8499
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.395,
    company: "케이솔라이호 주식회사",
    rn: 8500
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유안에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.295,
    company: "케이솔라이호 주식회사",
    rn: 8501
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "합곡1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8502
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "희망태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라이호 주식회사",
    rn: 8503
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고센태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.194,
    company: "케이솔라이호 주식회사",
    rn: 8504
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적도5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라이호 주식회사",
    rn: 8505
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라이호 주식회사",
    rn: 8506
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진성태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.189,
    company: "케이솔라이호 주식회사",
    rn: 8507
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진성1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.289,
    company: "케이솔라이호 주식회사",
    rn: 8508
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬미래태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8509
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.462,
    company: "케이솔라이호 주식회사",
    rn: 8510
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감천대영태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.31,
    company: "케이솔라이호 주식회사",
    rn: 8511
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일백1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.471,
    company: "케이솔라이호 주식회사",
    rn: 8512
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일백2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.471,
    company: "케이솔라이호 주식회사",
    rn: 8513
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "감천준혁태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.193,
    company: "케이솔라이호 주식회사",
    rn: 8514
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재민태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8515
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상만해비치1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.19,
    company: "케이솔라이호 주식회사",
    rn: 8516
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은광1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8517
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경애 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.604,
    company: "케이솔라이호 주식회사",
    rn: 8518
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.394,
    company: "케이솔라이호 주식회사",
    rn: 8519
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대야도어촌계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "케이솔라이호 주식회사",
    rn: 8520
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "환성4호발전태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라이호 주식회사",
    rn: 8521
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아리랑 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8522
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사계절발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8523
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호 양덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.418,
    company: "케이솔라이호 주식회사",
    rn: 8524
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호 양양태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.398,
    company: "케이솔라이호 주식회사",
    rn: 8525
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호 JS태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라이호 주식회사",
    rn: 8526
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼농태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8527
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오석상회 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.867,
    company: "케이솔라이호 주식회사",
    rn: 8528
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은총 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "케이솔라이호 주식회사",
    rn: 8529
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨엔케이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8530
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가평정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8531
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지안파워텍 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라이호 주식회사",
    rn: 8532
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용산11호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8533
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이엠15호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "케이솔라이호 주식회사",
    rn: 8534
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이엠16호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "케이솔라이호 주식회사",
    rn: 8535
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대원에너지제1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라이호 주식회사",
    rn: 8536
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포항제강RD센터태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.029,
    company: "(주)세아네트웍스",
    rn: 8537
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "항아2호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.972,
    company: "케이솔라일호 주식회사",
    rn: 8538
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양보2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.956,
    company: "케이솔라일호 주식회사",
    rn: 8539
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대련3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "케이솔라일호 주식회사",
    rn: 8540
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다우6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라일호 주식회사",
    rn: 8541
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JS1발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.959,
    company: "케이솔라일호 주식회사",
    rn: 8542
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "헵시바2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라일호 주식회사",
    rn: 8543
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용호2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8544
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한욱1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8545
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "써니태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "케이솔라이호 주식회사",
    rn: 8546
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적도6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "케이솔라이호 주식회사",
    rn: 8547
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상만해비치9호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라이호 주식회사",
    rn: 8548
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에덴에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "케이솔라이호 주식회사",
    rn: 8549
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성민태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "케이솔라이호 주식회사",
    rn: 8550
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라움태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "케이솔라이호 주식회사",
    rn: 8551
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해비치태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.748,
    company: "케이솔라이호 주식회사",
    rn: 8552
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "큰빛태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "케이솔라이호 주식회사",
    rn: 8553
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아나4호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "케이솔라이호 주식회사",
    rn: 8554
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미루태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8555
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "박산(노령)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8556
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그랜드태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라이호 주식회사",
    rn: 8557
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양재태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "케이솔라이호 주식회사",
    rn: 8558
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주 성읍리 1단계 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국중부발전(주)",
    rn: 8559
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주성읍리2단계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "한국중부발전(주)",
    rn: 8560
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신탄진에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.886,
    company: "씨엔씨티쏠라넷에이치원(주)",
    rn: 8561
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장항에너지1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "씨엔씨티쏠라넷에이치원(주)",
    rn: 8562
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장항에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "씨엔씨티쏠라넷에이치투(주)",
    rn: 8563
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신탄진에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.886,
    company: "씨엔씨티쏠라넷에이치투(주)",
    rn: 8564
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)구례태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.998,
    company: "(주)구례태양광발전소 1호",
    rn: 8565
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해인일호(주) 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "해인일호태양광 주식회사",
    rn: 8566
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "음성군 감우리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.554,
    company: "한국남부발전(주)",
    rn: 8567
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "순수햇빛발전태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.999,
    company: "순수햇빛발전 주식회사",
    rn: 8568
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)태선에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.999,
    company: "주식회사 태선에너지",
    rn: 8569
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "대원그린에너지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 19.8,
    company: "주식회사 대원그린에너지",
    rn: 8570
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디에치에스피 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 디에치에스피",
    rn: 8571
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다빈팜태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.526,
    company: "농업회사법인 주식회사 다빈",
    rn: 8572
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KEPCO 부산크로스독 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.479,
    company: "켑코솔라 주식회사",
    rn: 8573
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이팜A태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.406,
    company: "농업회사법인 하이팜 주식회사",
    rn: 8574
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이팜B태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.477,
    company: "농업회사법인 하이팜 주식회사",
    rn: 8575
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이팜C 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.477,
    company: "농업회사법인 하이팜 주식회사",
    rn: 8576
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "합천댐 수상태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 41.472,
    company: "합천수상태양광 주식회사",
    rn: 8577
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이레자연솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "이레자연솔라 주식회사",
    rn: 8578
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "HVDC#1 2POLE",
    cent: "중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 8579
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "HVDC#2 2POLE",
    cent: "중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 8580
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명월2호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8581
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서제주ESS#1",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 24,
    company: "한국전력공사(송전)",
    rn: 8582
    },
    {
    area: "제주",
    genFom: "기타",
    genSrc: "기타",
    genNm: "서제주ESS#2",
    cent: "비중앙",
    fuel: "FR 전기저장장치(한전)",
    pcap: 16,
    company: "한국전력공사(송전)",
    rn: 8583
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일산일호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "일산일호 태양광발전소",
    rn: 8584
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선우 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.352,
    company: "케이솔라일호 주식회사",
    rn: 8585
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉산 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "케이솔라일호 주식회사",
    rn: 8586
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라일호 임계태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "케이솔라일호 주식회사",
    rn: 8587
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "장흥풍력",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 18,
    company: "한국서부발전(주)",
    rn: 8588
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주호태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 주호",
    rn: 8589
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석교쏠라랜드4 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "케이솔라이호 주식회사",
    rn: 8590
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성대골햇빛발전소 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.025,
    company: "성대골에너지협동조합",
    rn: 8591
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "여수 NO2 COMPLEX 자가용",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 84.02,
    company: "(주)엘지화학",
    rn: 8592
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대길태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "센트럴태양광발전소2호주식회사",
    rn: 8593
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대길2태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "센트럴태양광발전소2호주식회사",
    rn: 8594
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황제태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "센트럴태양광발전소1호 주식회사",
    rn: 8595
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황제1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "센트럴태양광발전소1호 주식회사",
    rn: 8596
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇빛찬소향태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "햇빛찬소향태양광",
    rn: 8597
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨에스티1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "씨.에스.티주식회사",
    rn: 8598
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨에스티2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "씨.에스.티주식회사",
    rn: 8599
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한빛용궁1호 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.959,
    company: "주식회사 한빛용궁1호",
    rn: 8600
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영산강사업단(나불1) 태양",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "한국농어촌공사 영산강사업단",
    rn: 8601
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영산강사업단(나불2) 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "한국농어촌공사 영산강사업단",
    rn: 8602
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼용2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "삼용2호태양광발전소",
    rn: 8603
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥스에너지승본 PV",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)맥스에너지 승본",
    rn: 8604
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진석태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8605
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가현1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.416,
    company: "케이솔라일호 주식회사",
    rn: 8606
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "거창케이솔라2호햇빛",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.346,
    company: "케이솔라일호 주식회사",
    rn: 8607
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라이호 헤라에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라이호 주식회사",
    rn: 8608
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나성6호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.999,
    company: "세종시민태양광발전 주식회사",
    rn: 8609
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SK신길동",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.026,
    company: "SK에너지 주식회사",
    rn: 8610
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SK은평뉴타운 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.021,
    company: "SK에너지 주식회사",
    rn: 8611
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SK신평 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.026,
    company: "SK에너지 주식회사",
    rn: 8612
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SK북부 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.018,
    company: "SK에너지 주식회사",
    rn: 8613
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SK박미 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.021,
    company: "SK에너지 주식회사",
    rn: 8614
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SK창동 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.018,
    company: "SK에너지 주식회사",
    rn: 8615
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SK신봉천 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.015,
    company: "SK에너지 주식회사",
    rn: 8616
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이엠아이 태양광 발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.102,
    company: "한국동서발전(주)",
    rn: 8617
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에프엠테크 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.204,
    company: "한국동서발전(주)",
    rn: 8618
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남광디씨텍 태양광 발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.592,
    company: "한국동서발전(주)",
    rn: 8619
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포스텍 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.095,
    company: "한국동서발전(주)",
    rn: 8620
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유원테크 태양광 발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.218,
    company: "한국동서발전(주)",
    rn: 8621
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디제이에프 태양광 발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.945,
    company: "한국동서발전(주)",
    rn: 8622
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성일터빈 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.673,
    company: "한국동서발전(주)",
    rn: 8623
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성일엔케어 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.938,
    company: "한국동서발전(주)",
    rn: 8624
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한울항공기계 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "한국동서발전(주)",
    rn: 8625
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "터보파워텍 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.231,
    company: "한국동서발전(주)",
    rn: 8626
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "터보링크 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.666,
    company: "한국동서발전(주)",
    rn: 8627
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주연태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.499,
    company: "(주)고령철석태양발전소",
    rn: 8628
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동산글로벌제2태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.481,
    company: "주식회사 동산글로벌",
    rn: 8629
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELX 경산1발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.799,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8630
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)두레전력발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2,
    company: "(주)두레전력발전소",
    rn: 8631
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하암태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 하암태양광발전소",
    rn: 8632
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유영 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.493,
    company: "주식회사 유영",
    rn: 8633
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일순금산",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.459,
    company: "주식회사 일순에너지",
    rn: 8634
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오덕솔라1호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "솔라원일호 주식회사",
    rn: 8635
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오덕솔라2호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "솔라원일호 주식회사",
    rn: 8636
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "오덕솔라3호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "솔라원일호 주식회사",
    rn: 8637
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨감정1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.248,
    company: "유한회사 제이씨신안태양광",
    rn: 8638
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이씨감정2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.272,
    company: "유한회사 신안태천태양광",
    rn: 8639
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두야태양광발전소 2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)두야태양광발전소",
    rn: 8640
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성소솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.929,
    company: "주식회사 성소솔라",
    rn: 8641
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지구에너지3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.864,
    company: "주식회사 티에스에너지9호",
    rn: 8642
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장고솔라3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.861,
    company: "(주)장고솔라",
    rn: 8643
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상정솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.704,
    company: "주식회사 상정솔라",
    rn: 8644
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월호솔라4호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.917,
    company: "(주)월호솔라",
    rn: 8645
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태창 제1호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.68,
    company: "태창금속(주)",
    rn: 8646
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이천시 백사면A 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.834,
    company: "한국남부발전(주)",
    rn: 8647
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월호솔라3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주)월호솔라",
    rn: 8648
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉동솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.431,
    company: "주식회사 봉동솔라",
    rn: 8649
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대원산업태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국지붕태양광2 유한회사",
    rn: 8650
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)해움태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.988,
    company: "유한회사 해움",
    rn: 8651
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천우에너지태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 천우에너지",
    rn: 8652
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대한에너지1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 대한에너지 1호",
    rn: 8653
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테라에너지사호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 테라에너지제사호",
    rn: 8654
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "퍼스트태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(유)퍼스트태양광",
    rn: 8655
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유민-3태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "유민-3태양광발전소",
    rn: 8656
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유민-4태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "유민-4태양광발전소",
    rn: 8657
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)신흥태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.896,
    company: "주식회사 신흥태양광 ",
    rn: 8658
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한마음계전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.575,
    company: "한마음 에너지 주식회사",
    rn: 8659
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소양태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "소양 태양광 발전소",
    rn: 8660
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)송현에너지 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 송현에너지",
    rn: 8661
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코리아나5호태양광 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "코리아나5호 태양광발전소",
    rn: 8662
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건창1호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "건창1호태양광발전소",
    rn: 8663
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건창2호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "건창2호태양광발전소 ",
    rn: 8664
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대율리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.351,
    company: "대율리 태양광 발전소",
    rn: 8665
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "테라에너지 삼5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.538,
    company: "주식회사 테라에너지제삼호",
    rn: 8666
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)새만금희망태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 99,
    company: "주식회사 새만금희망태양광",
    rn: 8667
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "시노솔라코리아1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 시노솔라코리아 1호",
    rn: 8668
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "천광에너지(유)태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.984,
    company: "천광에너지유한회사",
    rn: 8669
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "큰나무태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "큰나무 태양광발전소",
    rn: 8670
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반도크레인태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국지붕태양광2 유한회사",
    rn: 8671
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지구에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 티에스에너지9호",
    rn: 8672
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중야2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.472,
    company: "중야2호태양광발전소",
    rn: 8673
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "여수 NO2 COMPLEX 자가용(예비)",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 84.02,
    company: "(주)엘지화학",
    rn: 8674
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스플래닛제주1호태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.974,
    company: "에스플래닛삼십삼호 주식회사",
    rn: 8675
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 나경",
    rn: 8676
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "코원이에스햇빛(아산)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "코원에너지서비스(주)",
    rn: 8677
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영덕썬파워일태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.49,
    company: "케이솔라이호 주식회사",
    rn: 8678
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "안나태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "케이솔라이호 주식회사",
    rn: 8679
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서안태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.943,
    company: "케이솔라이호 주식회사",
    rn: 8680
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "넥스트솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "케이솔라이호 주식회사",
    rn: 8681
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "캘리포니아1호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라이호 주식회사",
    rn: 8682
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화천은혜태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.551,
    company: "케이솔라이호 주식회사",
    rn: 8683
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스카이솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "케이솔라이호 주식회사",
    rn: 8684
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외부9호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.2,
    company: "케이솔라이호 주식회사",
    rn: 8685
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외부10호태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "케이솔라이호 주식회사",
    rn: 8686
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동외쏠라랜드 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8687
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석교쏠라랜드2 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "케이솔라이호 주식회사",
    rn: 8688
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석교쏠라랜드4 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "케이솔라이호 주식회사",
    rn: 8689
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석교쏠라랜드6 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "케이솔라이호 주식회사",
    rn: 8690
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석교쏠라랜드8 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.347,
    company: "케이솔라이호 주식회사",
    rn: 8691
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금오솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.954,
    company: "케이솔라일호 주식회사",
    rn: 8692
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "빌드윈충주지앤피태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.787,
    company: "유한회사 빌드윈충주지앤피태양광발전",
    rn: 8693
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외부12호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8694
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외부18호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8695
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외부20호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라이호 주식회사",
    rn: 8696
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대곡1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8697
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대곡2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8698
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대곡3호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8699
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대곡4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8700
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석교쏠라랜드1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "케이솔라이호 주식회사",
    rn: 8701
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "케이솔라이호 주식회사",
    rn: 8702
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수분리1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "케이솔라일호 주식회사",
    rn: 8703
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉의리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.297,
    company: "봉의리태양광발전소",
    rn: 8704
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대선에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.968,
    company: "유한회사 대선에너지",
    rn: 8705
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성산파워태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "유한회사 성산파워",
    rn: 8706
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.168,
    company: "한국지붕태양광2 유한회사",
    rn: 8707
    },
    {
    area: "비수도권",
    genFom: "기력",
    genSrc: "기타",
    genNm: "태성",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 2.9,
    company: "주식회사 제스코 파워",
    rn: 8708
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쓰리에이 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.365,
    company: "준태양광자양3호(주)",
    rn: 8709
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스마트뷰 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "준태양광자양3호(주)",
    rn: 8710
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서림 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.391,
    company: "준태양광자양3호(주)",
    rn: 8711
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼흥리플러스 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.498,
    company: "(주)티지에너지",
    rn: 8712
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호권태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.336,
    company: "한국지붕태양광2 유한회사",
    rn: 8713
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서산 태양광 제2발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "유림쏠라 주식회사",
    rn: 8714
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송욱태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.914,
    company: "송욱태양광발전소",
    rn: 8715
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "승민태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.667,
    company: "테크메이트코리아대부(주) 충주",
    rn: 8716
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제주태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)제주태양광발전소",
    rn: 8717
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)금강1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 금강1태양광발전소",
    rn: 8718
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "여수 NO2 COMPLEX 자가용(대표)",
    cent: "비중앙",
    fuel: "부생가스",
    pcap: 84.02,
    company: "(주)엘지화학",
    rn: 8719
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "모국7태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "모국7태양광발전소",
    rn: 8720
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "SOLVIC 8호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "세방(주)",
    rn: 8721
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이평태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "유한회사 청광",
    rn: 8722
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "율포솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "유한회사 율포솔라",
    rn: 8723
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)아르케전기기술단",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.409,
    company: "주식회사 아르케전기기술단",
    rn: 8724
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "참에너지 주식회사",
    rn: 8725
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "모란햇빛",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.357,
    company: "서울에너지공사",
    rn: 8726
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우주1호 태양광발전소",
    rn: 8727
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "우주2호 태양광발전소",
    rn: 8728
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠제이파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "엠제이파워태양광발전소",
    rn: 8729
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황조1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.194,
    company: "태초에너지 주식회사",
    rn: 8730
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래전력에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(유)미래전력에너지",
    rn: 8731
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연화솔라에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 연화솔라에너지",
    rn: 8732
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상주1호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "골드에너지 주식회사",
    rn: 8733
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "원조태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(유)원조태양광",
    rn: 8734
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "연우에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(유)연우에너지",
    rn: 8735
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스파워태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.198,
    company: "제이에스파워 태양광발전소",
    rn: 8736
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선탑에너지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(유)선탑에너지",
    rn: 8737
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "영남태양광발전소",
    rn: 8738
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금강5태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "금강5 태양광발전소",
    rn: 8739
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상주2호태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "골드팔금8호 유한회사",
    rn: 8740
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라테크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.861,
    company: "켑코솔라 주식회사",
    rn: 8741
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이가네솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.951,
    company: "켑코솔라 주식회사",
    rn: 8742
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "말응 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.951,
    company: "켑코솔라 주식회사",
    rn: 8743
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성호리싸이클링 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.611,
    company: "켑코솔라 주식회사",
    rn: 8744
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성메탈 경주공장 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.718,
    company: "켑코솔라 주식회사",
    rn: 8745
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성호정공 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.806,
    company: "켑코솔라 주식회사",
    rn: 8746
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성호이앤티 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.556,
    company: "켑코솔라 주식회사",
    rn: 8747
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대창공업B 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.612,
    company: "켑코솔라 주식회사",
    rn: 8748
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성호금속 경주2공장 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.814,
    company: "켑코솔라 주식회사",
    rn: 8749
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이티에이치 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "켑코솔라 주식회사",
    rn: 8750
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경남스틸창원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.979,
    company: "켑코솔라 주식회사",
    rn: 8751
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정안그린 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "켑코솔라 주식회사",
    rn: 8752
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬플라워 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "켑코솔라 주식회사",
    rn: 8753
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬웨이햇빛1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "켑코솔라 주식회사",
    rn: 8754
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구례영천에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "켑코솔라 주식회사",
    rn: 8755
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "셀테크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.719,
    company: "켑코솔라 주식회사",
    rn: 8756
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홍앤박1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "켑코솔라 주식회사",
    rn: 8757
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대구대학교(경산) 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.6,
    company: "켑코솔라 주식회사",
    rn: 8758
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이든테크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.943,
    company: "켑코솔라 주식회사",
    rn: 8759
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이엔케이1호 켑코 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.894,
    company: "켑코솔라 주식회사",
    rn: 8760
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이엔케이2호 켑코 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.817,
    company: "켑코솔라 주식회사",
    rn: 8761
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이엔케이3호 켑코 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.627,
    company: "켑코솔라 주식회사",
    rn: 8762
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜성정밀 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "켑코솔라 주식회사",
    rn: 8763
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서정2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "서정2호 태양광발전소",
    rn: 8764
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "대전 학하 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 6.16,
    company: "한국서부발전(주)",
    rn: 8765
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "ENELXKOMIPO_사천 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.911,
    company: "에넬엑스중부태양광 유한회사",
    rn: 8766
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산육상태양광(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 98.994,
    company: "군산육상태양광 주식회사",
    rn: 8767
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠에이치태양광발전소(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "엠에이치태양광발전소주식회사",
    rn: 8768
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엠케이태양광발전소(주)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "엠케이태양광발전소 주식회사",
    rn: 8769
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "임마누엘 쏠라 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "임마누엘 쏠라 발전소",
    rn: 8770
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원2018 4호 태양광발전소",
    rn: 8771
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "춘송1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.703,
    company: "(주)서부하이웨이솔라",
    rn: 8772
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지제2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.882,
    company: "(주)서부하이웨이솔라",
    rn: 8773
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월평3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.697,
    company: "(주)서부하이웨이솔라",
    rn: 8774
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신계 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.562,
    company: "(주)서부하이웨이솔라",
    rn: 8775
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수곡 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.428,
    company: "(주)서부하이웨이솔라",
    rn: 8776
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서계양2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "(주)서부하이웨이솔라",
    rn: 8777
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서계양1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.588,
    company: "(주)서부하이웨이솔라",
    rn: 8778
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼계1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.377,
    company: "(주)서부하이웨이솔라",
    rn: 8779
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉안1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "(주)서부하이웨이솔라",
    rn: 8780
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "반월 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.415,
    company: "(주)서부하이웨이솔라",
    rn: 8781
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동산2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.722,
    company: "(주)서부하이웨이솔라",
    rn: 8782
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동산1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.486,
    company: "(주)서부하이웨이솔라",
    rn: 8783
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도계 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.383,
    company: "(주)서부하이웨이솔라",
    rn: 8784
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕정3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.62,
    company: "(주)서부하이웨이솔라",
    rn: 8785
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕정2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.633,
    company: "(주)서부하이웨이솔라",
    rn: 8786
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕정1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.364,
    company: "(주)서부하이웨이솔라",
    rn: 8787
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "구룡태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.978,
    company: "(주)서부하이웨이솔라",
    rn: 8788
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코에너지 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.597,
    company: "에코에너지원(주)",
    rn: 8789
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유한회사 대승 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 대승태양광발전소",
    rn: 8790
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영진화학 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "영진화학 주식회사",
    rn: 8791
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매곡태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.846,
    company: "매곡태양광발전소",
    rn: 8792
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상남3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.435,
    company: "(주)동부하이웨이솔라",
    rn: 8793
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평온3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.952,
    company: "(주)동부하이웨이솔라",
    rn: 8794
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평온2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)동부하이웨이솔라",
    rn: 8795
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평온1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)동부하이웨이솔라",
    rn: 8796
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영덕리3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.819,
    company: "(주)동부하이웨이솔라",
    rn: 8797
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영덕리2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.879,
    company: "(주)동부하이웨이솔라",
    rn: 8798
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영덕 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.473,
    company: "(주)동부하이웨이솔라",
    rn: 8799
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미잠태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.342,
    company: "(주)동부하이웨이솔라",
    rn: 8800
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양7호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "태양7호 태양광발전소",
    rn: 8801
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고잔8호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "(주)동부하이웨이솔라",
    rn: 8802
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고잔2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.828,
    company: "(주)동부하이웨이솔라",
    rn: 8803
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가흥2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.653,
    company: "(주)동부하이웨이솔라",
    rn: 8804
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가흥1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.48,
    company: "(주)동부하이웨이솔라",
    rn: 8805
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고흥율치1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "고흥율치1호 태양광발전소",
    rn: 8806
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신한산업사 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.47,
    company: "한국지붕태양광6 유한회사",
    rn: 8807
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "신재생에너지",
    genNm: "원동풍력#1",
    cent: "비중앙",
    fuel: "풍력",
    pcap: 18.8,
    company: "원동풍력 주식회사",
    rn: 8808
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담5호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다담5호 태양광발전소",
    rn: 8809
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산 어은리태양광1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 13.877,
    company: "유한회사 에너지코",
    rn: 8810
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "군산 어은리태양광2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 16.163,
    company: "유한회사 에너지코",
    rn: 8811
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대곡 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.629,
    company: "대곡 태양광발전소",
    rn: 8812
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라그린태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.968,
    company: "주식회사 솔라레드",
    rn: 8813
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우신솔라네이션 괴산1차1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.003,
    company: "엠아이에너지 주식회사",
    rn: 8814
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "푸른태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.877,
    company: "푸른태양광발전소",
    rn: 8815
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "동해 북평레포츠 연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 4.2,
    company: "한국동서발전(주)",
    rn: 8816
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.47,
    company: "주식회사 남경태양광발전",
    rn: 8817
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스알피태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.785,
    company: "유한회사 에스알피",
    rn: 8818
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다담6호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "다담6호태양광발전소",
    rn: 8819
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "산초태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.83,
    company: "유한회사 산초",
    rn: 8820
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "햇살 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 햇살태양광발전소",
    rn: 8821
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해담은 철원2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "주식회사 해담은 철원2호",
    rn: 8822
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해담은안평",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.477,
    company: "해담은안평태양광발전소",
    rn: 8823
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에코에너지태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "에코에너지태양광발전소",
    rn: 8824
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보은태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "보은태양광발전소",
    rn: 8825
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "영호태양광",
    rn: 8826
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "㈜디케이솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "㈜디케이솔라",
    rn: 8827
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "샤론2 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.365,
    company: "샤론2 태양광발전소",
    rn: 8828
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장재울발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.298,
    company: "장재울 태양광발전소",
    rn: 8829
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "골드태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "골드태양광발전소",
    rn: 8830
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아토쏠라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 아토쏠라",
    rn: 8831
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "하늘태양광발전소",
    rn: 8832
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DSR(주)부산 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.265,
    company: "디에스알(주)",
    rn: 8833
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉성2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "봉성2호 태양광발전소",
    rn: 8834
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하늘사랑태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "하늘사랑태양광발전소",
    rn: 8835
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스타트베이 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.875,
    company: "주식회사 쏠라디씨",
    rn: 8836
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비봉 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 비봉태양광발전소",
    rn: 8837
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "태일태양광발전소",
    rn: 8838
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바다태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.347,
    company: "바다태양광발전소",
    rn: 8839
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "프로발 태양광 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.102,
    company: "주식회사 프로발",
    rn: 8840
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼현이엔에스 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.465,
    company: "유한회사 삼환",
    rn: 8841
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대원총업 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.394,
    company: "한국지붕태양광6 유한회사",
    rn: 8842
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대신정공 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.171,
    company: "한국지붕태양광6 유한회사",
    rn: 8843
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선호NC태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.143,
    company: "한국지붕태양광6 유한회사",
    rn: 8844
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세아네트웍스 안산1기 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)세아네트웍스",
    rn: 8845
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정월2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "정월2호태양광발전소",
    rn: 8846
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해담은 철원1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "주식회사 해담은 철원1호",
    rn: 8847
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스제이태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "에스제이태양광발전소",
    rn: 8848
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신바트에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.915,
    company: "(주)신바트에너지",
    rn: 8849
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지니솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.91,
    company: "(주)지니솔라",
    rn: 8850
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알라딘솔라",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.915,
    company: "(주)알라딘솔라",
    rn: 8851
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자가용태양광발전기#1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.051,
    company: "(주)프론텍",
    rn: 8852
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티케이솔라태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 티케이솔라",
    rn: 8853
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불제이씨삼호1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.48,
    company: "대불제이씨3호 주식회사",
    rn: 8854
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신한일 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.867,
    company: "신한일태양광발전소",
    rn: 8855
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령3 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 피오쏠라",
    rn: 8856
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석성(정) 탄소중립 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.809,
    company: "한국수자원공사",
    rn: 8857
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보령1 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 쏠라테크",
    rn: 8858
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "칠량태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(유)칠량태양광",
    rn: 8859
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥팩 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.197,
    company: "한국지붕태양광2 유한회사",
    rn: 8860
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한국엠엘 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.133,
    company: "한국지붕태양광2 유한회사",
    rn: 8861
    },
    {
    area: "수도권",
    genFom: "기력",
    genSrc: "화력",
    genNm: "경인환경에너지 자가용 발전기",
    cent: "비중앙",
    fuel: "폐기물",
    pcap: 1.1,
    company: "경인환경에너지 주식회사",
    rn: 8862
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홍앤박3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "주식회사 해솔이앤시",
    rn: 8863
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "홍앤박4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "주식회사 해솔이앤시",
    rn: 8864
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이큐태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "주식회사 이큐에너지",
    rn: 8865
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조동종로에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.597,
    company: "주식회사 종로에너지",
    rn: 8866
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "YES BIDC 울산 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.964,
    company: "영남에너지서비스(주)",
    rn: 8867
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제2강릉 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국지역난방공사",
    rn: 8868
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제3강릉 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국지역난방공사",
    rn: 8869
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제4강릉 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "한국지역난방공사",
    rn: 8870
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘크루에너지용인 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.097,
    company: "엘크루에너지용인 주식회사",
    rn: 8871
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지원태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "지원태양광발전소",
    rn: 8872
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이피텍태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "한국지붕태양광2 유한회사",
    rn: 8873
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성전기통신태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.291,
    company: "한국지붕태양광2 유한회사",
    rn: 8874
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대성 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.218,
    company: "한국지붕태양광2 유한회사",
    rn: 8875
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금송태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.003,
    company: "주식회사 금송",
    rn: 8876
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우람 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.171,
    company: "한국지붕태양광6 유한회사",
    rn: 8877
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘앤조태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.774,
    company: "엘앤조태양광발전소",
    rn: 8878
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "땅이농장발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "땅이농장발전소",
    rn: 8879
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "종로태양광발전소태인지점",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "주식회사 종로에너지",
    rn: 8880
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "굿파워 아산3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.888,
    company: "유한회사 굿파워",
    rn: 8881
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "배재고등학교태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "서울에너지공사",
    rn: 8882
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.974,
    company: "케이솔라일호 주식회사",
    rn: 8883
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두일태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.397,
    company: "케이솔라일호 주식회사",
    rn: 8884
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보선1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "케이솔라일호 주식회사",
    rn: 8885
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유곡에너지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.466,
    company: "주식회사 유곡에너지",
    rn: 8886
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "페퍼 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "농업회사법인 주식회사 페퍼",
    rn: 8887
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신탁1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.736,
    company: "주식회사 2020서부하이웨이솔라",
    rn: 8888
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신탁2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.769,
    company: "주식회사 2020서부하이웨이솔라",
    rn: 8889
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신대",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.595,
    company: "주식회사 2020서부하이웨이솔라",
    rn: 8890
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "내지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.373,
    company: "주식회사 2020서부하이웨이솔라",
    rn: 8891
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "어울림 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8892
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "석현지혜 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8893
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "침곡3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "케이솔라이호 주식회사",
    rn: 8894
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청정에너지1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "케이솔라이호 주식회사",
    rn: 8895
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우면 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "케이솔라이호 주식회사",
    rn: 8896
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "기례1 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "케이솔라이호 주식회사",
    rn: 8897
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성명개발 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "케이솔라이호 주식회사",
    rn: 8898
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "씨와이스틸태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8899
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고덕파워태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.492,
    company: "케이솔라이호 주식회사",
    rn: 8900
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광격A태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.743,
    company: "케이솔라이호 주식회사",
    rn: 8901
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스타발전소 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.95,
    company: "주식회사 스타발전소",
    rn: 8902
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "겨루에너지 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "유한회사 겨루에너지",
    rn: 8903
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나봄에너지 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "유한회사 나봄에너지",
    rn: 8904
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "늘솔길에너지2 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "유한회사 늘솔길에너지2",
    rn: 8905
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 태영에스이디발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.691,
    company: "(주)태영에스이디",
    rn: 8906
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불제이씨일호1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.661,
    company: "대불제이씨 1호 주식회사",
    rn: 8907
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스제일차일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "솔라스페이스제일차 유한회사",
    rn: 8908
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지동1호에너지 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 지동1호에너지",
    rn: 8909
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주연태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주연태양광발전소",
    rn: 8910
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하강솔라 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 하강솔라",
    rn: 8911
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑행복한발전소 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 탑 행복한발전소",
    rn: 8912
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광명1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.768,
    company: "주식회사 광명태양광1호",
    rn: 8913
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "보석 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.96,
    company: "주식회사 보석태양광",
    rn: 8914
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.961,
    company: "주식회사 삼성태양광",
    rn: 8915
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티엠당진동우1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.94,
    company: "티엠솔라원 주식회사",
    rn: 8916
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.732,
    company: "주식회사 삼성태양광1호",
    rn: 8917
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼성2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 삼성태양광2호",
    rn: 8918
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성일에너지2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.804,
    company: "주식회사 성일에너지2호",
    rn: 8919
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태백 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 태백태양광",
    rn: 8920
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가은 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "가은 태양광발전소",
    rn: 8921
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불그린삼호1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "대불그린3호 주식회사",
    rn: 8922
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불그린삼호2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "대불그린3호 주식회사",
    rn: 8923
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대에너지2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.876,
    company: "주식회사 현대에너지2호",
    rn: 8924
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 현대에너지",
    rn: 8925
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적각1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.732,
    company: "주식회사 왕산대기발전소12호",
    rn: 8926
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적각5호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.792,
    company: "주식회사 왕산대기발전소",
    rn: 8927
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적각9호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.936,
    company: "주식회사 왕산대기발전소11호",
    rn: 8928
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적각10호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.42,
    company: "주식회사왕산대기발전소10호",
    rn: 8929
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적각24호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.876,
    company: "주식회사왕산대기발전소15호",
    rn: 8930
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "흥전리3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 대우태양광",
    rn: 8931
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적각2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 왕산대기발전소 2호",
    rn: 8932
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "일순1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 태백에너지산업",
    rn: 8933
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "부연솔라팜 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 부연솔라팜",
    rn: 8934
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "적각12호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 왕산대기발전소13호",
    rn: 8935
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대경 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "대경 태양광발전소",
    rn: 8936
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비케이그린1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.389,
    company: "주식회사 비케이그린",
    rn: 8937
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불그린오호1",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "대불그린5호(주)",
    rn: 8938
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세아네트웍스 인천1기 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.922,
    company: "(주)세아네트웍스",
    rn: 8939
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에이치태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 제이에이치에너지",
    rn: 8940
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성산지원 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "금성산지원태양광발전소",
    rn: 8941
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성솔라콤 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "금성솔라콤 태양광발전소",
    rn: 8942
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고상욱 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.33,
    company: "한국지붕태양광6 유한회사",
    rn: 8943
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광격1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.655,
    company: "케이솔라이호 주식회사",
    rn: 8944
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "재원유천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.196,
    company: "케이솔라이호 주식회사",
    rn: 8945
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한스훼밀리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "케이솔라이호 주식회사",
    rn: 8946
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주니스유천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.597,
    company: "케이솔라이호 주식회사",
    rn: 8947
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬광천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "케이솔라이호 주식회사",
    rn: 8948
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉동솔라3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.933,
    company: "주식회사 봉동솔라",
    rn: 8949
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티호5호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 티에스에너지25호",
    rn: 8950
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신추에너지3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.002,
    company: "주식회사 신추에너지",
    rn: 8951
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "봉리에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주)봉리에너지",
    rn: 8952
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "민현태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.384,
    company: "한국지붕태양광6 유한회사",
    rn: 8953
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에스지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.18,
    company: "한국지붕태양광2 유한회사",
    rn: 8954
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "KEPCO-16-F37 에스앤케이 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.159,
    company: "켑코솔라 주식회사",
    rn: 8955
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "심포솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "(주) 심포솔라",
    rn: 8956
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스제일차이호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "솔라스페이스제일차 유한회사",
    rn: 8957
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스제일차오호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.999,
    company: "솔라스페이스제일차 유한회사",
    rn: 8958
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스 제이차삼호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "솔라스페이스제이차(유)",
    rn: 8959
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스제사차이호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "솔라스페이스제사차 유한회사",
    rn: 8960
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불제이씨삼호2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.709,
    company: "대불제이씨3호 주식회사",
    rn: 8961
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불제이씨일호2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.976,
    company: "대불제이씨 1호 주식회사",
    rn: 8962
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장감솔라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 장감솔라",
    rn: 8963
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "굿파워 아산2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.913,
    company: "유한회사 굿파워",
    rn: 8964
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하대리1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "하대리주민참여태양광 주식회사",
    rn: 8965
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스원에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 에스원에너지",
    rn: 8966
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미항 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "유한회사 미항",
    rn: 8967
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신양랜드 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.428,
    company: "유한회사 신양랜드",
    rn: 8968
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태조솔라3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.837,
    company: "주식회사 태조솔라",
    rn: 8969
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태조솔라2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.419,
    company: "주식회사 태조솔라",
    rn: 8970
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "묘사 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.627,
    company: "주식회사 2016하이웨이솔라",
    rn: 8971
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼봉3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.782,
    company: "주식회사 2016하이웨이솔라",
    rn: 8972
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로에너지 창원1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.326,
    company: "아폴로에너지 (주)",
    rn: 8973
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아폴로에너지 창원2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.663,
    company: "아폴로에너지 (주)",
    rn: 8974
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외조 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.441,
    company: "아폴로에너지 (주)",
    rn: 8975
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청춘 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.621,
    company: "청춘 태양광발전소",
    rn: 8976
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라이언 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.602,
    company: "라이언 태양광발전소",
    rn: 8977
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스원 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.419,
    company: "에스원 태양광발전소",
    rn: 8978
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "썬 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.166,
    company: "썬 태양광발전소",
    rn: 8979
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "괴곡 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.558,
    company: "주식회사 파인데이",
    rn: 8980
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신흥 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.312,
    company: "주식회사 에스지솔라",
    rn: 8981
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스엠엔테크 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.597,
    company: "유한회사 티에스씨솔라6호",
    rn: 8982
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성현공업 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.419,
    company: "유한회사 티에스씨솔라6호",
    rn: 8983
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성협공업 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.896,
    company: "유한회사 티에스씨솔라6호",
    rn: 8984
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "다랑쉬 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.966,
    company: "주식회사 다랑쉬태양광",
    rn: 8985
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하나더태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "하나더 태양광발전소",
    rn: 8986
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티호3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 티에스에너지25호",
    rn: 8987
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "황풍태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "황풍 태양광발전소",
    rn: 8988
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대진A태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "한국지붕태양광6 유한회사",
    rn: 8989
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대진B 태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "한국지붕태양광6 유한회사",
    rn: 8990
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미호유천태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.196,
    company: "케이솔라이호 주식회사",
    rn: 8991
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성산대경 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "금성산대경태양광발전소",
    rn: 8992
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금성산주연 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "금성산주연 태양광발전소",
    rn: 8993
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "GS당진복합 3ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 139,
    company: "GS EPS(주)",
    rn: 8994
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "영남파워1ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 150.8,
    company: "코스포영남파워 주식회사",
    rn: 8995
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "위례열병합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 142.2,
    company: "나래에너지서비스 주식회사",
    rn: 8996
    },
    {
    area: "비수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "춘천열병합ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 143.4,
    company: "춘천에너지(주)",
    rn: 8997
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합7ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 139.7,
    company: "포스코에너지(주)",
    rn: 8998
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합8ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 139.7,
    company: "포스코에너지(주)",
    rn: 8999
    },
    {
    area: "수도권",
    genFom: "복합",
    genSrc: "화력",
    genNm: "포스코복합9ST",
    cent: "중앙",
    fuel: "LNG",
    pcap: 139.7,
    company: "포스코에너지(주)",
    rn: 9000
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불그린오호2",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "대불그린5호(주)",
    rn: 9001
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서천상 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.307,
    company: "주식회사 효종솔라",
    rn: 9002
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "휴먼4호에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.915,
    company: "주식회사 휴먼4호에너지",
    rn: 9003
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "매산1호 임시발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0,
    company: "매산1호 태양광발전소",
    rn: 9004
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "용운태양광_가상발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0,
    company: "용운태양광발전소",
    rn: 9005
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "참길자동차태양광1345호_임시발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0,
    company: "(유)참길자동차학원",
    rn: 9006
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금아스틸 태양광발전기(김해공장)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.188,
    company: "주식회사 금아스틸",
    rn: 9007
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스 제이차일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.996,
    company: "솔라스페이스제이차(유)",
    rn: 9008
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스 제이차이호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "솔라스페이스제이차(유)",
    rn: 9009
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라스페이스제사차일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "솔라스페이스제사차 유한회사",
    rn: 9010
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "솔라필드제육차일호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "솔라필드제육차 유한회사",
    rn: 9011
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "백야에너지2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.202,
    company: "주식회사 백야에너지",
    rn: 9012
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장지태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.784,
    company: "에코빛길솔라 주식회사",
    rn: 9013
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압구정태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.918,
    company: "압구정주식회사",
    rn: 9014
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이솔라4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "유한회사금빛에너지",
    rn: 9015
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남원위미태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.986,
    company: "주식회사 삼다에너지",
    rn: 9016
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정에너지 유한회사",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "정에너지 유한회사",
    rn: 9017
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)나월 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "유한회사 나월",
    rn: 9018
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동해그린수소 생산R&D실증단지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 3.247,
    company: "한국동서발전(주)",
    rn: 9019
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "린 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1,
    company: "유한회사 린에너지",
    rn: 9020
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "고성GES태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "고성지이에스(주)",
    rn: 9021
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아주쏠라제2발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.553,
    company: "아주스틸주식회사",
    rn: 9022
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "외부리1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "케이솔라이호 주식회사",
    rn: 9023
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남용암2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "케이솔라이호 주식회사",
    rn: 9024
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마현10차 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "케이지에너지 주식회사",
    rn: 9025
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "계당리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.992,
    company: "(주)조광쏠라",
    rn: 9026
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청화태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "청화 태양광발전소",
    rn: 9027
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림2태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "드림2태양광발전소",
    rn: 9028
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림태양광에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "드림태양광에너지",
    rn: 9029
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "드림3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "드림3태양광발전소",
    rn: 9030
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜진1태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "혜진1태양광발전소",
    rn: 9031
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜진2태양광에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "혜진2태양광에너지",
    rn: 9032
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜진3태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "혜진3태양광발전소",
    rn: 9033
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#19 장감솔라",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 9034
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 신우하이텍 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.251,
    company: "(주)솔라팩토리10호",
    rn: 9035
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.98,
    company: "제이태양광발전소",
    rn: 9036
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "파란에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.939,
    company: "(주)파란에너지",
    rn: 9037
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상덕태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.988,
    company: "상덕태양광발전소",
    rn: 9038
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "프로솔태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국지붕태양광2 유한회사",
    rn: 9039
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비엠테크태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "한국지붕태양광2 유한회사",
    rn: 9040
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕신하우징 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "(주)덕신하우징",
    rn: 9041
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미르태양광발전소1호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 미르테크",
    rn: 9042
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미르태양광발전소2호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 미르테크",
    rn: 9043
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미르태양광발전소3호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 미르테크",
    rn: 9044
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미르태양광발전소4호기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 미르테크",
    rn: 9045
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "모아 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "모아 태양광발전소",
    rn: 9046
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양산 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "양산 태양광발전소",
    rn: 9047
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신덕태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "신덕태양광발전소",
    rn: 9048
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "진평 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "진평 태양광발전소",
    rn: 9049
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은곡 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "은곡 태양광발전소",
    rn: 9050
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 5호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원2018 5호 태양광발전소",
    rn: 9051
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "행원2018 2호 태양광발전소",
    rn: 9052
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "행원2018 1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "행원2018 1호 태양광발전소",
    rn: 9053
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무릉리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.868,
    company: "(주)수영하이텍",
    rn: 9054
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "탑에너지태양광발전소1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.008,
    company: "탑에너지태양광발전소 주식회사",
    rn: 9055
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신도리 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.729,
    company: "(주)수영하이텍",
    rn: 9056
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "장성 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "장성 태양광발전소",
    rn: 9057
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불제이씨이호1 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.955,
    company: "대불제이씨 2호 주식회사",
    rn: 9058
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대불제이씨이호2 태양",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.104,
    company: "대불제이씨 2호 주식회사",
    rn: 9059
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "팬시 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "팬시 태양광발전소",
    rn: 9060
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)쏠라루트 비전의령14-1호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.998,
    company: "주식회사 쏠라루트",
    rn: 9061
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동진태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.985,
    company: "주식회사 동진태양광발전소",
    rn: 9062
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이앤씨헤르메스태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.679,
    company: "케이앤씨헤르메스태양광발전소 주식회사",
    rn: 9063
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준태양광왕징4호 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "준태양광왕징4호 주식회사",
    rn: 9064
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 성종사 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.205,
    company: "(주)솔라팩토리13호",
    rn: 9065
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 툴드림 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "(주)솔라팩토리13호",
    rn: 9066
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DSR(주)광양 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.373,
    company: "디에스알(주)",
    rn: 9067
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "익산(군산 1단계)연료전지",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 15,
    company: "한국서부발전(주)",
    rn: 9068
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태영성남리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(주)태영에너지",
    rn: 9069
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정종솔라1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.993,
    company: "주식회사 정종솔라",
    rn: 9070
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "하이돈푸드시스템 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.165,
    company: "(주)솔라팩토리12호",
    rn: 9071
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주연 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "주연 태양광발전소",
    rn: 9072
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스에스 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "에스에스 태양광발전소",
    rn: 9073
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대윤 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "대윤 태양광발전소",
    rn: 9074
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화촌솔라팜 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "화촌솔라팜 주식회사 ",
    rn: 9075
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "미래1호 태양광발전소",
    rn: 9076
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "미래2호 태양광발전소",
    rn: 9077
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘앤씨성남리 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "(유)엘앤씨태양광발전소",
    rn: 9078
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "엘앤씨선동태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.9,
    company: "(유)엘앤씨태양광발전소",
    rn: 9079
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성근 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "성근 태양광발전소",
    rn: 9080
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미션태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 미션",
    rn: 9081
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "금송태양광발전소2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.293,
    company: "금송태양광발전소",
    rn: 9082
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "남경쏠라2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.546,
    company: "(주)남경쏠라",
    rn: 9083
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신비호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "신비호태양광발전소",
    rn: 9084
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양산1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "양산1호 태양광발전소",
    rn: 9085
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미근 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "미근 태양광발전소",
    rn: 9086
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "양산2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "양산2호 태양광발전소",
    rn: 9087
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "소풍3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "소풍3호 태양광발전소",
    rn: 9088
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "청림3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "청림3호태양광발전소",
    rn: 9089
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "여명 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "여명 태양광발전소",
    rn: 9090
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "서정 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "서정 태양광발전소",
    rn: 9091
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알파24 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "알파24 태양광발전소",
    rn: 9092
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "동호태양광발전소",
    rn: 9093
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한성3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "한성3호 태양광발전소",
    rn: 9094
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성운태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.824,
    company: "주식회사 성운발전소",
    rn: 9095
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신인천 본관주차장 발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.208,
    company: "한국남부발전(주)",
    rn: 9096
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "명종2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.85,
    company: "(주)명종솔라",
    rn: 9097
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "죽현4호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.668,
    company: "주식회사성종솔라",
    rn: 9098
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야3호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.693,
    company: "주식회사성종솔라",
    rn: 9099
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "자영태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.496,
    company: "자영태양광발전소",
    rn: 9100
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "쓰리엠제이 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.939,
    company: "쓰리엠제이 태양광 발전소",
    rn: 9101
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "달천태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.97,
    company: "주식회사 예종솔라",
    rn: 9102
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "죽현1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.756,
    company: "주식회사 예종솔라",
    rn: 9103
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미곡 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "주식회사 예종솔라",
    rn: 9104
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "월막2호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.627,
    company: "주식회사 예종솔라",
    rn: 9105
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "평산 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.584,
    company: "주식회사 예종솔라",
    rn: 9106
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인성 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "인성 태양광발전소",
    rn: 9107
    },
    {
    area: "비수도권",
    genFom: "RPS",
    genSrc: "RPS",
    genNm: "고성그린파워(주) RPS발전기 ",
    cent: "비중앙",
    fuel: "RPS",
    pcap: 0,
    company: "고성그린파워 주식회사",
    rn: 9108
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "중석 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.422,
    company: "(주)진영솔라에너지",
    rn: 9109
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "초원 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "초원 태양광발전소",
    rn: 9110
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "O.K그린 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "오케이(O.K)그린 태양광발전소",
    rn: 9111
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동4호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동4호 태양광발전소",
    rn: 9112
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동1호 태양광발전소",
    rn: 9113
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동5호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동5호 태양광발전소",
    rn: 9114
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동3호 태양광발전소",
    rn: 9115
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "대판솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "대판솔라 태양광발전소",
    rn: 9116
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동호3호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동호3호 태양광발전소",
    rn: 9117
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "형근솔라 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "형근솔라 태양광발전소",
    rn: 9118
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동호2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동호2호 태양광발전소",
    rn: 9119
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태성메가솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.4,
    company: "태성메가솔라 태양광발전소",
    rn: 9120
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "MY 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.419,
    company: "엠와이 태양광발전소",
    rn: 9121
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경훈2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "경훈2호 태양광발전소",
    rn: 9122
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "두레태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 두레태양광",
    rn: 9123
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아이아이알 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.474,
    company: "아이아이알 태양광발전소",
    rn: 9124
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "조광태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "조광태양광발전소",
    rn: 9125
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동2호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동2호 태양광발전소",
    rn: 9126
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무주 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.539,
    company: "주식회사 무주",
    rn: 9127
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "무주에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.542,
    company: "주식회사 무주에너지",
    rn: 9128
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "선일이엔지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "한국지붕태양광6 유한회사",
    rn: 9129
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "추용 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.979,
    company: "케이솔라일호 주식회사",
    rn: 9130
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지품썬토피아 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 지품썬토피아",
    rn: 9131
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "윈즈태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.8,
    company: "주식회사 윈즈에너지",
    rn: 9132
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "해남용암4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "케이솔라이호 주식회사",
    rn: 9133
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태조솔라1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 태조솔라",
    rn: 9134
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "티호2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.995,
    company: "주식회사 티에스에너지25호",
    rn: 9135
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "맥사 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.658,
    company: "주식회사 효종솔라",
    rn: 9136
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.458,
    company: "주식회사 효종솔라",
    rn: 9137
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)십단태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 십단태양광",
    rn: 9138
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세나2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "세나2호 태양광발전소",
    rn: 9139
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "사반태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 사반태양광",
    rn: 9140
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "가야2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.422,
    company: "주식회사 효종솔라",
    rn: 9141
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "도담태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 도담태양광",
    rn: 9142
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정남진태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 정남진태양광",
    rn: 9143
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "호영솔라 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 호영솔라",
    rn: 9144
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "나래태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 나래태양광",
    rn: 9145
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "누리태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 누리태양광",
    rn: 9146
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "초롱태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "주식회사 초롱태양광",
    rn: 9147
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "라온태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.493,
    company: "주식회사 라온태양광",
    rn: 9148
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에스에이치8 1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.963,
    company: "주식회사 이에스에이치8",
    rn: 9149
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이에스에이치8 2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "주식회사 이에스에이치8",
    rn: 9150
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마루태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 마루태양광",
    rn: 9151
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "그린푸드텍태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.272,
    company: "한국지붕태양광2 유한회사",
    rn: 9152
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "준테크태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "한국지붕태양광2 유한회사",
    rn: 9153
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지호 태양광발전",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.546,
    company: "지호 태양광발전소",
    rn: 9154
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이룡1호 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.428,
    company: "주식회사 선조솔라",
    rn: 9155
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경북우리집RE100발전소 20호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.067,
    company: "경북우리집RE100협동조합",
    rn: 9156
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경북우리집RE100발전소 22호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "경북우리집RE100협동조합",
    rn: 9157
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경북우리집RE100발전소 23호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.062,
    company: "경북우리집RE100협동조합",
    rn: 9158
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경북우리집RE100발전소 24호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.098,
    company: "경북우리집RE100협동조합",
    rn: 9159
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "삼동호1호 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "삼동호1호 태양광발전소",
    rn: 9160
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "포천 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "포천 태양광발전소",
    rn: 9161
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜선 태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.588,
    company: "혜선 태양광발전소",
    rn: 9162
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "스타2호발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "스타2호발전소",
    rn: 9163
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이피에이 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이피에이",
    rn: 9164
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이피비1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이피비",
    rn: 9165
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지나썬칩 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "소망 주식회사",
    rn: 9166
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이피비2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이피비",
    rn: 9167
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이피씨3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이피씨",
    rn: 9168
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이피씨2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이피씨",
    rn: 9169
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이피씨1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이피씨",
    rn: 9170
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이피비3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이피비",
    rn: 9171
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "울산시민가상발전소 10호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "울산스마트에너지협동조합",
    rn: 9172
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정화태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "정화태양광발전소",
    rn: 9173
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수아이 공산발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.996,
    company: "주식회사 수아이",
    rn: 9174
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "우주에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.662,
    company: "주식회사 우주에너지",
    rn: 9175
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대공업매곡태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "(주)현대공업",
    rn: 9176
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태평태양광발전기",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.796,
    company: "태평태양광발전소",
    rn: 9177
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "마산3호 태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.494,
    company: "마산3호태양광발전소",
    rn: 9178
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비전에너지2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 비전에너지 2호",
    rn: 9179
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 솔라카운티강구16호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 솔라카운티강구16호",
    rn: 9180
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "성모 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "성모 태양광발전소",
    rn: 9181
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한양솔라제3 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "(주)한양에코텍",
    rn: 9182
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9183
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9184
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9185
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워4호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9186
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워5호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9187
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워6호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9188
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워7호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9189
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워8호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9190
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워9호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9191
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워10호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.841,
    company: "주식회사 에스케이파워",
    rn: 9192
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워11호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9193
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워12호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.979,
    company: "주식회사 에스케이파워",
    rn: 9194
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에스케이파워13호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 에스케이파워",
    rn: 9195
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대스틸산업천안 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.98,
    company: "현대스틸산업주식회사",
    rn: 9196
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현민 태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "케이솔라일호 주식회사",
    rn: 9197
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "에이치디충주태양광1호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.966,
    company: "에이치디충주태양광1호 주식회사",
    rn: 9198
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "JK은곡 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 제이케이에너지",
    rn: 9199
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현대비앤지스틸 당진공장 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.034,
    company: "현대비앤지스틸 주식회사",
    rn: 9200
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "바인 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "바인 태양광발전소",
    rn: 9201
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "피엘존 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.28,
    company: "주식회사 피엘존태양광발전소",
    rn: 9202
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "디케이이앤에스 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.954,
    company: "디케이이앤에스 주식회사",
    rn: 9203
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "DSR제강(주)율촌 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.949,
    company: "디에스알제강 주식회사",
    rn: 9204
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송정1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.491,
    company: "솔라스타 주식회사",
    rn: 9205
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세화태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "종훈쏠라 유한회사",
    rn: 9206
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "화양1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.498,
    company: "화양1호태양광발전소",
    rn: 9207
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이서울햇빛발전소 신내 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 1.705,
    company: "주식회사 케이서울햇빛발전소",
    rn: 9208
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "건우태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.99,
    company: "건우태양광발전소",
    rn: 9209
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "한갑평태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "한갑평태양광발전소",
    rn: 9210
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은영태양광발전소 3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "은영태양광발전소 3호",
    rn: 9211
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "영인이엔지 태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.246,
    company: "한국지붕태양광2 유한회사",
    rn: 9212
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)석양발전소3호(도포1호 태양광)",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 석양발전소3호",
    rn: 9213
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 기찬발전소3호",
    rn: 9214
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해7호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "주식회사 압해발전소7호",
    rn: 9215
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해8호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.997,
    company: "(주)압해발전소8호",
    rn: 9216
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "은서에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.5,
    company: "은서에너지 태양광발전소",
    rn: 9217
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "웰빙2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.699,
    company: "주식회사 웰빙발전소2호",
    rn: 9218
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)아글라이아 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 아글라이아",
    rn: 9219
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)휴먼발전소3호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 휴먼발전소3호",
    rn: 9220
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세영에너지2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "주식회사 세영에너지",
    rn: 9221
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "경인에너지 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.767,
    company: "경인에너지",
    rn: 9222
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "현원 1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "현원1호 태양광발전소",
    rn: 9223
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "휴먼2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "주식회사 휴먼발전소2호",
    rn: 9224
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "압해1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "주식회사 그린발전소3호",
    rn: 9225
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "담훈2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.85,
    company: "주식회사 담훈개발",
    rn: 9226
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정율1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "정율1호태양광발전소",
    rn: 9227
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)세광알이텍 제1발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.958,
    company: "(주)세광알이텍제1발전소",
    rn: 9228
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정우 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "정우태양광발전소",
    rn: 9229
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주현 3호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.3,
    company: "주현3호 태양광발전소",
    rn: 9230
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수경태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.18,
    company: "수경태양광발전소",
    rn: 9231
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "W-CP1태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.997,
    company: "충청에너지서비스(주)",
    rn: 9232
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "케이그린에너지 15호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "유한회사 케이그린에너지15호",
    rn: 9233
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "이엔씨강남태양광",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "신후이엔씨 주식회사",
    rn: 9234
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동영햇빛발전2호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.099,
    company: "동영햇빛발전1호 태양광발전소",
    rn: 9235
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "아미고 칠호발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.994,
    company: "디-솔라텍태양광발전소",
    rn: 9236
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "익산1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.424,
    company: "주식회사 중종솔라",
    rn: 9237
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "익산2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.463,
    company: "주식회사 중종솔라",
    rn: 9238
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)금곡에너지",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.464,
    company: "주식회사 금곡에너지",
    rn: 9239
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인지솔라(주)7호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.678,
    company: "인지솔라 주식회사",
    rn: 9240
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인지솔라(주)11호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.995,
    company: "인지솔라 주식회사",
    rn: 9241
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "인지솔라(주)12호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.356,
    company: "인지솔라 주식회사",
    rn: 9242
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "비전에너지1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.499,
    company: "주식회사 비전에너지 1호",
    rn: 9243
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(유)대곡에너지 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "유한회사 대곡에너지",
    rn: 9244
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "유에스피1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 2.239,
    company: "주식회사 유에스피",
    rn: 9245
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)쏠라루트 비전의령14-2호",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.696,
    company: "주식회사 쏠라루트",
    rn: 9246
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)새만금세빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 98.993,
    company: "주식회사 새만금세빛발전소",
    rn: 9247
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "제이에스태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.989,
    company: "주식회사 제이에스에너지",
    rn: 9248
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "태양4호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "태양4호 태양광발전소",
    rn: 9249
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수망햇빛1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "수망햇빛1호태양광발전소",
    rn: 9250
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "수망햇빛2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.299,
    company: "수망햇빛2호 태양광발전소",
    rn: 9251
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "광교1호 햇빛발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.999,
    company: "광교햇빛발전소 주식회사",
    rn: 9252
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "지제1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.344,
    company: "주식회사 2018동부하이웨이솔라",
    rn: 9253
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "송정1호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.616,
    company: "주식회사 2018동부하이웨이솔라",
    rn: 9254
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕남1호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.706,
    company: "주식회사 우주에너지",
    rn: 9255
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "덕남2호태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.658,
    company: "주식회사 우주에너지",
    rn: 9256
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "알씨태양광 발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.252,
    company: "한국지붕태양광6 유한회사",
    rn: 9257
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "동양태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.134,
    company: "한국지붕태양광6 유한회사",
    rn: 9258
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "세영 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.1,
    company: "한국지붕태양광6 유한회사",
    rn: 9259
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "신후이엔씨16호 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.451,
    company: "신후이엔씨",
    rn: 9260
    },
    {
    area: "비수도권",
    genFom: "연료전지",
    genSrc: "신재생에너지",
    genNm: "빛고을에코에너지(주)",
    cent: "비중앙",
    fuel: "연료전지",
    pcap: 12.32,
    company: "빛고을에코에너지 주식회사",
    rn: 9261
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#21 삼동3호",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 9262
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#22 형근솔라",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 9263
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#29 여명",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 9264
    },
    {
    area: "비수도권",
    genFom: "기타",
    genSrc: "기타",
    genNm: "한전가상발전기#30 소풍3호",
    cent: "비중앙",
    fuel: "기타",
    pcap: 0,
    company: "한국전력공사",
    rn: 9265
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "정석태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.199,
    company: "정석 태양광발전소",
    rn: 9266
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 휴 휴태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "주식회사 휴태양광발전소",
    rn: 9267
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "주식회사 삼영 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.983,
    company: "주식회사 삼영태양광발전소",
    rn: 9268
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래엔서해 태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.226,
    company: "주식회사미래엔서해에너지",
    rn: 9269
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "미래엔세종 태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.822,
    company: "주식회사미래엔서해에너지",
    rn: 9270
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "(주)경동 상덕 태양광발전소 ",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.495,
    company: "(주)경동",
    rn: 9271
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "상송 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.497,
    company: "주식회사 제이디",
    rn: 9272
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "단비장곡 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.991,
    company: "주식회사 제이디",
    rn: 9273
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "신재생에너지",
    genNm: "혜성정밀 태양광발전소",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0.152,
    company: "한국지붕태양광5 유한회사",
    rn: 9274
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSLC001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "솔라커넥트주식회사",
    rn: 9275
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSKENS001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "에스케이이엔에스 주식회사",
    rn: 9276
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fDGS001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 대건소프트",
    rn: 9277
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKT001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 케이티",
    rn: 9278
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fHZ001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 해줌",
    rn: 9279
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKCSNE001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "케이씨솔라앤에너지(주)",
    rn: 9280
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKOEN001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국남동발전(주)",
    rn: 9281
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fEWP001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국동서발전(주)",
    rn: 9282
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fAZ001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "안좌스마트팜앤쏠라시티(주)",
    rn: 9283
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fGSDEP001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "(주)지에스동해전력",
    rn: 9284
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fkesol001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "켑코솔라 주식회사",
    rn: 9285
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "ftop001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "탑솔라(주)",
    rn: 9286
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fYA001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "영암태양광발전 주식회사",
    rn: 9287
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fMAHB001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 무안햇빛발전소",
    rn: 9288
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "DER",
    genNm: "fGSWP001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "GS풍력발전",
    rn: 9289
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fMASOL001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 무안솔라파크",
    rn: 9290
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fBJE001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "부산정관에너지 주식회사",
    rn: 9291
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fBJE002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "부산정관에너지 주식회사",
    rn: 9292
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKMP001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국중부발전(주)",
    rn: 9293
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKMP002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국중부발전(주)",
    rn: 9294
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSLC002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "엔라이튼 주식회사",
    rn: 9295
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fGG001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "(주)거금솔라파크",
    rn: 9296
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSLC003",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "솔라커넥트주식회사",
    rn: 9297
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fHDEC001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "현대에코에너지주식회사",
    rn: 9298
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSLSD001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "솔라시도 태양광발전 주식회사",
    rn: 9299
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fBJE003",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "부산정관에너지 주식회사",
    rn: 9300
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKMP003",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국중부발전(주)",
    rn: 9301
    },
    {
    area: "제주",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fGNWIN001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "제주김녕풍력발전 주식회사",
    rn: 9302
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fslwiz001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 솔라위즈",
    rn: 9303
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fiwest001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국서부발전(주)",
    rn: 9304
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKT002",
    cent: "비중앙",
    fuel: "태양광",
    pcap: 0,
    company: "주식회사 케이티",
    rn: 9305
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKHNP001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국수력원자력(주)",
    rn: 9306
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fBJE004",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "부산정관에너지 주식회사",
    rn: 9307
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSLC004",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "엔라이튼 주식회사",
    rn: 9308
    },
    {
    area: "제주",
    genFom: "풍력",
    genSrc: "DER",
    genNm: "fKMP004",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국중부발전(주)",
    rn: 9309
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fINUPS001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "인업스",
    rn: 9310
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fGSDEP002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "(주)지에스동해전력",
    rn: 9311
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKT003",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 케이티",
    rn: 9312
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fENDOT001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 에너닷",
    rn: 9313
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSE001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "서울에너지공사",
    rn: 9314
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fDGS002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 대건소프트",
    rn: 9315
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fBJE005",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "부산정관에너지 주식회사",
    rn: 9316
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fVGEN001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "브이젠 주식회사",
    rn: 9317
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fHZ002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 해줌",
    rn: 9318
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fHZ003",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 해줌",
    rn: 9319
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fiwest002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국서부발전(주)",
    rn: 9320
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fKMP005",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국중부발전(주)",
    rn: 9321
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fjara001",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "빛솔라에너지주식회사",
    rn: 9322
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fEWP002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국동서발전(주)",
    rn: 9323
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fEWP003",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "한국동서발전(주)",
    rn: 9324
    },
    {
    area: "비수도권",
    genFom: "풍력",
    genSrc: "DER",
    genNm: "fGSWP002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "GS풍력발전",
    rn: 9325
    },
    {
    area: "수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fSLC005",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "엔라이튼 주식회사",
    rn: 9326
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fMAHB002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 무안햇빛발전소",
    rn: 9327
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fMASOL002",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 무안솔라파크",
    rn: 9328
    },
    {
    area: "비수도권",
    genFom: "태양광",
    genSrc: "DER",
    genNm: "fMAHB003",
    cent: "비중앙",
    fuel: "DER",
    pcap: 0,
    company: "주식회사 무안햇빛발전소",
    rn: 9329
    }
    ];

    export default mydata;

   